<template>
  <div class="settings-container">
    <div
      v-if="$store.state.alert.alert"
      class="alert alert-warning alert-dismissible fade show copyLinkMessage"
      role="alert"
      style='position: absolute; width: 80%;'
    >
      <CopyLinkBell class="copyLink-bell" />
      <p>Your password has been changed successfully!</p>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        style="color: white"
        @click="alertHandler"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <button class='back'
      @click='$router.go(-1)'
    >
      <img :src="require('pinnacle-lib/assets/icon/arrow-left.svg')" />
    </button>
    <div class="user-wrap">
      <p class='tiny'>BETA</p>
      <p class='pTitle'>Settings</p>
      <hr />

      <div class="user-info">
        <div class="name">
          <div class="avatar avatar-md">
            <img
              :src="require('pinnacle-lib/assets/img/mtn.png')"
              class="avatar-img rounded-circle"
              alt="..."
            />
          </div>

          <div class="user-name">{{email}}</div>
        </div>
        <b-button
          variant="primary"
          size="sm"
          class="mb-3"
          @click="logout"
          :disabled="submitting"
        >
          <span v-if="submitting">
            <b-spinner small label="Spinning" variant="light"></b-spinner>
          </span>
          <span v-else>Logout</span>
        </b-button>
      </div>
      <hr />
      <div v-if="showCredits && creditsLoaded">
        <h3 class='credits' >
            Credits
        </h3>
        <div class="row">
          <div class="col">
            <div v-if="pinnacle_datasets_access">
              <h6 class='greyText'>
                PINNACLE DATASETS
              </h6>
              <h2 class='result'>
                {{ dataset_credits }}
              </h2>
            </div>
            <div v-if="pinnacle_reports_access">
              <h6 class='greyText'>
                PINNACLE REPORTS
              </h6>
              <h2 class='result'>
                {{ report_credits }}
              </h2>
            </div>
          </div>
        
          <div class="col">
            <b-button
              v-if="canPurchaseCredits"
              variant="primary"
              size="sm"
              class="mb-3"
              @click="purchaseModal"
            >
              Purchase Additional Credits
            </b-button>
          </div>
        </div>

        <b-modal 
          id="modal-purchase-credits" 
          title="Purchase Additional Credits"
          ok-title="Checkout"
          @ok="handlePurchaseOk">
          <div class="row">
            <div class="col">
              <b-form-group label="Report Credits" class="credit-radios" v-slot="{ ariaDescribedby }">
                <b-form-radio v-for="option in creditOptions.report_prices" :key="option.priceId" v-model="creditOptionSelected" :aria-describedby="ariaDescribedby" name="some-radios" :value="option.priceId" >
                  {{ option.numCredits }} Credits - {{ formatPrice(option.price) }}
                </b-form-radio>
              </b-form-group>             
            </div>
            <!--div class="col">
              <h2>Dataset Credits</h2>
            </div-->
          </div>
        </b-modal>

        <hr />
      </div>
 
      <PassChange :email='email' />
    </div>
  </div>
</template>

<script>
import PassChange from "../components/form/PassChange";
import CopyLinkBell from "../components/partials/CopyLinkBell";

export default {
  components: {
    PassChange,
    CopyLinkBell
  },
  data() {
    return {
      submitting: false,
      err: "",
      email: "",
      dataset_credits: "",
      report_credits: "",
      pinnacle_datasets_access: false,
      pinnacle_reports_access: false,
      creditsLoaded: false,
      creditOptions: {},
      creditOptionSelected: null,
      canPurchaseCredits: false,
    };
  },
  mounted() {
    if(this.email === ''){
      this.getUser();
    }
    this.getPermission();
    this.getCreditOptions();
  },
  computed: {
    showCredits() {
      return this.pinnacle_datasets_access || this.pinnacle_reports_access;
    },
  },
  methods: {
    routeHandler(){
      alertHandler;
      this.$router.go(-1)
    },
    alertHandler(){
      this.$store.commit("setAlert", false)
    },
    async purchaseModal() {
      this.$bvModal.show('modal-purchase-credits');
    },
    formatPrice(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      return formatter.format(value);
    },
    async purchaseCheckout() {
      const thing = await this.refreshToken();
      this.err = "";
      let headers = this.getHeaders();
      headers.headers['Cache-Control'] = 'no-cache';
      headers.headers['Pragma'] ='no-cache';
      headers.headers['Expires'] ='0';
      console.log(this.creditOptionSelected);
      let prices = [this.creditOptionSelected];
      try {
        const res = await this.$http.post("/v2/credit-checkout-session", {prices:prices} ,headers);
        window.location.href = res.data.session_url;
      } catch (err) {
        console.log(err);
        // TODO show error to user
      }
    },
    async handlePurchaseOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (!this.creditOptionSelected) {
        alert('Please select a Credit Option');
        return;
      }
      this.purchaseCheckout();
    },
    async logout() {
      this.submitting = true;
      this.err = "";
      const headers = this.getHeaders();
      this.$store.commit("setAlert", false);
      this.$sessionStore.commit('clearMapState');
      this.$sessionStore.commit('clearSearchState');
      this.$sessionStore.commit("setDataAgreementAccepted", false);
      try {
        const res = await this.$http.post("/v1/logout", {}, headers);
        if (res.data) {
          localStorage.setItem("accessToken", "");
          localStorage.setItem("refreshToken", "");
          localStorage.removeItem("vuex")
          this.$sessionStore.commit("setSearchDefault")
          this.$sessionStore.commit("setStudyDefault")
          this.$router.push("/login");
          
        }
      } catch (err) {
        console.log({ err });
        localStorage.setItem("accessToken", "");
        localStorage.setItem("refreshToken", "");
        localStorage.removeItem("vuex")
        this.$sessionStore.commit("setSearchDefault")
        this.$sessionStore.commit("setStudyDefault")
        this.$router.push("/login");
      }
      this.submitting = false;
    },
    getPermission(){
      // let explore = this.$persistingStore.state.user.permissions.find(item => item.name === "Explorer")
      let superAdmin = this.$persistingStore.state.user.permissions.find(item => item.name === "Super Admin")
      let hasDatasetsPermission = this.$persistingStore.state.user.permissions.find(item => item.name === "Pinnacle Bulk Export Access")
      if( superAdmin || hasDatasetsPermission ){
        this.pinnacle_datasets_access = true;
      }
      else{
        this.pinnacle_datasets_access = false;
      }
      // TODO update permissions relating to access to Pinnacle Reports
      this.pinnacle_reports_access = false;
      let hasReportsPermission = this.$persistingStore.state.user.permissions.find(item => item.name === "Card Report")
      if (superAdmin || hasReportsPermission) {
        this.pinnacle_reports_access = true;
      } else {
        this.pinnacle_reports_access = false;
      }
      if (this.showCredits) {
        this.getCredits();
      }
      this.canPurchaseCredits = this.$persistingStore.state.user.permissions.find(item => item.name === "SaaS Admin")
    },
    async getCredits(){
      console.log('getting credits')
      const thing = await this.refreshToken();
      this.err = "";
      let headers = this.getHeaders();
      headers.headers['Cache-Control'] = 'no-cache';
      headers.headers['Pragma'] ='no-cache';
      headers.headers['Expires'] ='0';
      try {
        const res = await this.$http.get("/v2/credits", headers);
        if (res.data.companyCreditPlans) {
          if (res.data.companyCreditPlans.length === 1) {
            if (res.data.companyCreditPlans[0].unlimited) {
              this.dataset_credits = 'Unlimited'
            }
            else {
              this.dataset_credits = res.data.companyCreditPlans[0].creditCountRemaining;
            }
          }
          else {
            res.data.companyCreditPlans.forEach(function (plan) {
              if (!plan.unlimited && plan.creditCountRemaining) {
                this.dataset_credits = this.dataset_credits + plan.creditCountRemaining;
              }
            })
          }
        }

        // Check if user has location Credits
        if (res.data.locationCreditPlan) {
          let locationCreditPlan = res.data.locationCreditPlan;
          if (locationCreditPlan['Location Report Unlimited'] && locationCreditPlan['Location Report Unlimited']['value'] === 'true') {
            this.report_credits = 'Unlimited';
          } else {
            this.report_credits = 0;
            if (locationCreditPlan["Location Report Count Remaining"]) {
              this.report_credits += parseInt(locationCreditPlan["Location Report Count Remaining"]["value"]);
            }
            if (locationCreditPlan["Location Report Supplemental Count Remaining"]) {
              this.report_credits += parseInt(locationCreditPlan["Location Report Supplemental Count Remaining"]["value"]);
            }
          }

        }
        
      } catch (err) {
        console.log(err);
        this.dataset_credits = "N/A";
        this.report_credits = "N/A";
      } finally {
        this.creditsLoaded = true;
      }
    },
    async getCreditOptions() {
      const thing = await this.refreshToken();
      this.err = "";
      let headers = this.getHeaders();
      headers.headers['Cache-Control'] = 'no-cache';
      headers.headers['Pragma'] ='no-cache';
      headers.headers['Expires'] ='0';
      try {
        const res = await this.$http.get("/v2/credit-options", headers);
        this.creditOptions.report_prices = res.data.report_prices.map((p) => {
          return {
            priceId: p['id'],
            productType: p['metadata']['Product Type'],
            sortOrder: parseInt(p['metadata']['Sort Order']),
            numCredits: parseInt(p['metadata']['Product Quantity']),
            price: p['unit_amount'] / 100
          }
        });
        this.creditOptions.report_prices.sort((a, b) => {
          return a.sortOrder - b.sortOrder
        });
      } catch (err) {
        console.log(err);
        // TODO show error to user
      }

    },

    async getUser(){
      //this isn't used anymore
      this.submitting = true;
      this.err = "";
      const headers = this.getHeaders();
      try {
        const res = await this.$http.post("/v1/get-user", {}, headers);
        if (res.data) {
          //console.log(res.data)
          this.email = res.data.find(item => item.Name === 'email').Value;
        }
      } catch (err) {
        console.log(err)
        err.response.data.errors.forEach((element) => {
         console.log(element)
         if(element.message === 'Access Token has expired' || element.message === 'Invalid Access Token' || element.message === 'Access Token has been revoked' || element.message === 'Please provide a valid access token' ){
          console.log(headers)
          console.log('refresh route')
          this.refreshToken().then((res) =>
          {
            if(res.success){
              //this can be re-enginerred to not be recursive, but I added a return in the other function just in case
              this.getUser();
            }
            else{
              ///this should push the user to login page to grab a new refresh token
            }
          })
         }
         else{
          console.log({ err });
         }
        })
      }
      this.submitting = false;
    }
  },
};
</script>

<style scoped lang="scss">
.settings-container {
  overflow-y: scroll;
}

.user-wrap {
  padding: 2rem 3rem;
  margin-left: 17.5rem;
  margin-top: -77px;
  width: 52.5rem;
  h2 {
    font-family: "Cerebri Sans Bold";
    font-weight: bold;
    font-size: 28px;
  }
  hr {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e3ebf6;
    border-top:none;
  }

  .user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3.5rem;
    margin-bottom: 2rem;
    .name {
      display: flex;

      .user-name {
        margin-left: 20px;
        font-family: "Cerebri Sans Bold";
        font-size: 15px;
        color: #12263f;
        line-height: 40px;
        font-weight: 400
      }
    }
  }
}
.tiny{
  font-size: 10px;
  margin-bottom: 0px;
  color: #6E84A3;
  font-weight: 600;
}
.back{
  background: #FFFFFF;
  border: 1px solid #E3EBF6;
  box-sizing: border-box;
  border-radius: 1000px;
  width: 40px;
  height: 40px;
  margin-left: 35px;
  margin-top: 39px;
}
.copyLinkMessage {
  position: relative;
  z-index: 7;
  display: flex;
  background-color: #2c7be5;
  width: 100%;
  height: 3.2em;
  top: 0%;
  border-radius: 0;
  border-color: transparent;
  p {
    font-family: Cerebri Sans;
    font-size: 15px;
    color: white;
    margin-left: 1.25em;
    font-weight: 100;
  }
  .copyLink-bell {
    margin-left: 1.25em;
  }
}
  .pTitle{
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 28px;
  }
  .mb-3{
    margin-bottom: 0.25rem !important;
  }
  .greyText{
    color: #95AAC9;
    font-weight: 600;
  }
  .credits{
    font-weight: 700;
  }
  .result{
    font-weight: 600;
  }

  ::v-deep #modal-purchase-credits .modal-title {
    font-size:20px;
  }
  ::v-deep .credit-radios > legend {
    font-weight: 600;
  }
</style>
