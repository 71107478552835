<template>
  <div id="map">
    <div class="alert-container">
      <b-alert
        v-for="msg in alert.error"
        :key="msg"
        :show="alert.error.length > 0"
        variant="danger"
        dismissible
        fade
        @dismissed="alertDismissed(msg, 'error')"
        >{{ msg }}</b-alert
      >
      <b-alert
        :show="alert.info !== undefined"
        variant="info"
        dismissible
        fade
        @dismissed="alertDismissed(alert.info, 'info')"
        class="info-alert"
        ><span style="display: inline-block; margin-right: 0.5rem"
          ><CopyLinkBell /></span
        >{{ alert.info }}</b-alert
      >
    </div>
    <div class="insightsBtn" @click="openInsights">
      <img
        :src="require('pinnacle-lib/assets/icon/openBtn-Insights2.svg')"
        alt="open-insights"
      />
    </div>
    <div ref="mapComponent"></div>
    <basemaps></basemaps>
  </div>
</template>

<script>
import { createMarker } from "@/services/map/markers";
import { MapService } from "@/services/map/mapService";
import ThreeDimensionControl from "@/services/map/controls/threeDimensionControl";
import { provisionAvatar } from "@/utils/avatars";
import CopyLinkBell from "@/components/partials/CopyLinkBell.vue";

import Basemaps from "@/components/partials/Basemaps.vue";
import getBounds from "@/utils/getBounds";
import { bus } from "@/main";
import { getTypeById } from "../../services/map/types";

const WH_LOADING_ALERT =
  "The home and work layer will finish loading momentarily.";

export default {
  components: {
    Basemaps,
    CopyLinkBell,
  },
  watch: {
    locationsLoading() {
      if (!this.locationsLoading) this.locationsReady = true;
    },
    disabledIds() {
      for (const location of this.studyLocations) {
        if (this.disabledIds[location.id])
          this.studyLocationMarkers[location.id].remove();
        else this.studyLocationMarkers[location.id].addTo(this.map);
      }
    },
    placeLink: function () {
      this.updatedSelectedPin();
    },
  },
  data() {
    return {
      map: undefined,
      mapService: undefined,
      studyLocationMarkers: {},
      studyLocationMarkersLoaded: false,
      refresh: false,
      locationsReady: false,
      alert: {
        info: undefined,
        error: [],
      },
      clickedLocation: undefined,
      openedLocation: null,
      whAlertTimeout: undefined,
    };
  },
  beforeDestroy() {
    this.mapService = undefined;
    this.map = undefined;
  },
  mounted() {
    this.$on("mapbound", () => this.loadMap());
    if (this.map === undefined) this.initMap();
    else this.$emit("mapbound");

    // to update pins from map details card
    bus.$off("updateMapMarkersOn");
    bus.$on("updateMapMarkersOn", () => {
      this.updateStudyMarkers();
    });

    //to change the alert message width
    bus.$on("showDatePicker", () => {
      $(".alert-container")[0].style.width = "calc(100% - 380px)";
    });
    bus.$on("closeDatePicker", () => {
      $(".alert-container")[0].style.width = "100%";
    });

    bus.$on("openInsights", () => {
      $(".alert-container")[0].style.width = "calc(100% - 380px)";
    });
    bus.$on("closeInsights", () => {
      $(".alert-container")[0].style.width = "100%";
    });
  },
  computed: {
    studyLocations() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split("/")[1] === "preview"
        ? this.$sessionStore.state.study.previews
        : this.$sessionStore.state.study.locations;
    },
    disabledIds() {
      return this.$sessionStore.state.study.disabledIds;
    },
    locationsLoading() {
      return this.$sessionStore.state.study.loading;
    },
    comparisonLocations() {
      return this.$sessionStore.state.study.locations;
    },
    placeLink() {
      if (
        this.$route.name === "study-map-details" ||
        this.$route.name === "preview-map-details"
      ) {
        return this.$route.query.place;
      }
      if (this.$route.name === "preview-map") {
        return this.studyLocationMarkersLoaded;
      }
      return null;
    },
  },
  methods: {
    openInsights() {
      bus.$emit("openInsights");
    },
    addStudyLocation(location, idx) {
      const { url, type } = provisionAvatar(location);
      if (typeof location.centroid == "string")
        location.centroid = JSON.parse(location.centroid);
      this.studyLocationMarkers[location.id] = createMarker({
        map: this.map,
        center: location.centroid.coordinates,
        iconURL: url,
        iconType: type,
        iconBGColor: location.baseColor,
        address: location.address,
        label: location.name,
        loc: location,
        index: idx,
        iconSize: "18px",
        // comparisonLocations: this.comparisonLocations,
        zIndex: 2,
        click_handler: this.openLocationDetails,
      });
      return this.studyLocationMarkers[location.id];
    },
    updateStudyMarkers() {
      for (const locationId in this.studyLocationMarkers) {
        const marker = this.studyLocationMarkers[locationId];
        marker.remove();
        delete this.studyLocationMarkers[locationId];
      }
      this.studyLocations.forEach((location, idx) => {
        const marker = this.addStudyLocation(location, idx);
        if (this.disabledIds[location.id]) marker.remove();
      });
    },
    openLocationDetails(location) {
      if (this.$route.path.split("/")[1] === "preview") {
        this.$router.push({
          name: "preview-map-details",
          query: { place: btoa(JSON.stringify(location)) },
        });
      } else {
        this.$router.push({
          name: "study-map-details",
          query: { place: btoa(JSON.stringify(location)) },
        });
      }
    },
    updatedSelectedPin() {
      $(".location-pin").removeClass("location-pin-selected");

      if (this.$route.query.place) {
        this.openedLocation = JSON.parse(atob(this.$route.query.place));
        $(".location-pin-" + this.openedLocation.id).removeClass(
          "result-highlight"
        );
        $(".location-pin-" + this.openedLocation.id).addClass(
          "location-pin-selected"
        );
      } else if (this.$route.name === "preview-map") {
        $(".location-pin-" + this.$route.params.ids).addClass(
          "location-pin-selected"
        );
      }
    },

    async initMap() {
      if (!this.locationsLoading) this.locationsReady = true;

      if (typeof mapboxgl != "object" || !this.locationsReady) {
        setTimeout(this.initMap, 1000);
        return;
      }

      mapboxgl.accessToken = process.env.VUE_APP_MAP_ACCESS_TOKEN;

      let mapData = window.localStorage.getItem("lastSelectedMap");
      let mapID = "cku4lf4wi1qn217pq9kh4w3ol";
      if (mapData) {
        mapData = JSON.parse(mapData);
        mapID = mapData.mapID;
      }
      const bounds = getBounds(this.studyLocations);
      const centerInfo = {};
      if (!this.$route.query.center) centerInfo.bounds = bounds;
      else {
        centerInfo.center = JSON.parse(this.$route.query.center);
        centerInfo.zoom = 12;
      }

      const map = new mapboxgl.Map({
        container: this.$refs.mapComponent,
        style: `mapbox://styles/nearco/${mapID}`,
        attributionControl: false,
        preserveDrawingBuffer: true,
        ...centerInfo,
        transformRequest: (url, resourceType) => {
          if (resourceType === "Tile" && url.indexOf("mapbox") === -1) {
            if (this.tokenNeedsRefresh()) {
              this.refreshToken().then(() => {
                const { headers } = this.getHeaders();
                return {
                  url,
                  headers,
                };
              });
            } else {
              const { headers } = this.getHeaders();
              return {
                url,
                headers,
              };
            }
          }
        },
      });

      const nav = new mapboxgl.NavigationControl({ showCompass: false });
      map.addControl(nav, "bottom-right");

      const scale = new mapboxgl.ScaleControl({
        maxWidth: 260,
        unit: "imperial",
      });
      map.addControl(scale);

      const geolocate = new mapboxgl.GeolocateControl();
      geolocate.on("error", (error) => {
        if (error.PERMISSION_DENIED) {
          this.setAlert(
            "Please share your location via your browser and/or system settings to use the my location button."
          );
        }
      });
      map.addControl(geolocate, "bottom-right");

      const threeDimensionControl = new ThreeDimensionControl();
      map.addControl(threeDimensionControl, "bottom-right");

      const attribution = new mapboxgl.AttributionControl({
        compact: false,
      });
      map.addControl(attribution, "bottom-left");

      this.map = map;

      bus.$off("resizeMap");
      bus.$on("resizeMap", () => {
        setTimeout(() => {
          this.map.resize();
        }, 1);
      });

      this.$emit("mapbound");
    },
    clearWorkHomeAlert(prefix) {
      if (this.whAlertTimeout) {
        clearTimeout(this.whAlertTimeout);
        this.whAlertTimeout = undefined;
      }
      if (this.alert.info === WH_LOADING_ALERT && prefix === "wh") {
        this.alert.info = undefined;
      }
    },
    loadMap() {
      this.mapService = new MapService(this.map);

      bus.$off("dataService");
      bus.$on("dataService", (options) => {
        this.mapService.sourceMgr.dataService[options.function](options.params);
      });

      bus.$emit("dataServiceReady");
      this.studyLocationMarkersLoaded = false;
      this.map.on("load", () => {
        bus.$emit("mapLoaded", this.map);
        this.studyLocations.forEach((loc, idx) => {
          const marker = this.addStudyLocation(loc, idx);
          if (this.disabledIds[loc.id]) marker.remove();
        });
        this.studyLocationMarkersLoaded = true;
      });

      // Pass these events to bus
      this.mapService.on("addvisualization", (evt) => {
        bus.$emit("addvisualization", evt.detail);
        this.updatedSelectedPin();

        const type = getTypeById(evt.detail.id);
        this.clearWorkHomeAlert(type.prefix);
      });
      this.mapService.on("cancelvisualization", (evt) => {
        bus.$emit("cancelvisualization", evt.detail);

        const type = getTypeById(evt.detail.id);
        this.clearWorkHomeAlert(type.prefix);
      });

      this.map.on("zoom", () => {
        bus.$emit("mapZoom", { zoom: this.map.getZoom() });
      });
      this.map.on("idle", () => bus.$emit("mapIdle"));
      this.map.on("dataloading", (evt) => bus.$emit("mapDataLoading", evt));

      bus.$off("mapService");
      bus.$on("mapService", async (options) => {
        try {
          if (
            (options.function === "addTileVisualization" ||
              options.function === "updateTileVisualization") &&
            options.filters.prefix === "cl"
          ) {
            // options.clickHandler = this.toggleLocationModal;
            options.clickHandler = this.openLocationDetails;
            options.omitLocations =
              this.$route.path.split("/")[1] === "preview"
                ? "previews"
                : "locations";
          }

          if (
            options.function.includes("add") &&
            options.function.includes("Visualization") &&
            options.filters.prefix === "wh"
          ) {
            this.whAlertTimeout = setTimeout(() => {
              this.setAlert(WH_LOADING_ALERT, "info");
            }, 10000);
          }
          
          await this.mapService[options.function](options);
        } catch (err) {
          this.clearWorkHomeAlert(options.filters.prefix);
          if (err.err) err = err.err;
          console.log(err);
          this.setAlert(err.message);
          bus.$emit("mapServiceError", err);
        }
      });

      bus.$off("layerMgr");
      bus.$on("layerMgr", async (options) => {
        try {
          await this.mapService.layerMgr[options.function](options);
        } catch (err) {
          bus.$emit("mapServiceError", err);
        }
      });

      bus.$off("newBaseMapLayerSelected");
      bus.$on("newBaseMapLayerSelected", (evt) => {
        this.mapService.changeMapStyle(evt);
      });

      // bus.$off("toggleStudyLocation");
      // bus.$on("toggleStudyLocation", (newVal) => {
      //   if (newVal) this.studyLocationMarker.addTo(this.map);
      //   else this.studyLocationMarker.remove();
      // });

      bus.$off("flyTo");
      bus.$on("flyTo", (evt) => {
        this.map.flyTo(evt);
      });

      bus.$off("fitBounds");
      bus.$on("fitBounds", (evt) => {
        this.map.fitBounds([
          [evt.bounds[0], evt.bounds[1]],
          [evt.bounds[2], evt.bounds[3]],
        ]);
      });

      bus.$off("studyMapAlert");
      bus.$on("studyMapAlert", (evt) => {
        if (evt.dismiss) {
          this.alertDismissed(evt.msg, evt.type);
          return;
        }
        this.setAlert(evt.msg, evt.type);
      });
    },
    setAlert(msg, type = "error") {
      if (type === "error") this.alert[type].push(msg);
      else this.alert[type] = msg;
    },
    alertDismissed(msg, type = "error") {
      if (type === "error")
        this.alert[type] = this.alert[type].filter((_msg) => _msg !== msg);
      else this.alert[type] = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.insightsBtn {
  position: absolute;
  width: 130px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  z-index: 5;
  margin-right: -4px;
  margin-top: 40px;
  cursor: pointer;
}

.alert {
  margin-bottom: 0.5rem !important;
}

.info-alert {
  background-color: $blue;
}

#map {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  .alert-container {
    position: absolute;
    // z-index: 3;
    z-index: 10001;
    // left: 1vw;
    // top: 1vw;
    left: 0vw;
    top: 0vw;
    width: 100%; //when the right pane is closed
  }
}

.mapboxgl-map {
  height: 100%;
}
</style>