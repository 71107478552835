<template>
  <div class="cluster-option-container">
    <div class="input-container">
      <slot></slot>
    </div>
    <div class="description-container">
      <p class="subtext">
        {{ description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ['description']
}
</script>
<style lang="scss" scoped>
.cluster-option-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input-container {
    width: 20%;
  }

  .description-container {
    width: 65%;
    margin-left: 1rem;
  }
}
</style>