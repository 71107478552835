<template>
    <div class="content">
        <div class="selectHeader">
            <p class="title">Polygon Format</p>
            <div class="radio-thing">
                <input @click="changePolygonFormat('GEOJSON')" class='radio' v-model="uploadInput.selection"
                    type="radio" id="geojson" name="picker" value="GEOJSON">
                <label for="geojson">GeoJSON</label>
            </div>
            <div class="radio-thing">
                <input @click="changePolygonFormat('KML')" class='radio' v-model="uploadInput.selection" type="radio"
                    id="kml" name="picker" value="KML">
                <label for="kml">KML</label>
            </div>
            <div class="radio-thing">
                <input @click="changePolygonFormat('ESRI_SHAPEFILE_ZIP')" class='radio' v-model="uploadInput.selection"
                    type="radio" id="esri" name="picker" value="ESRI_SHAPEFILE_ZIP">
                <label for="esri">ESRI Shapefile<span class='info'>(WGS-84 or EPSG:4326)</span></label>
            </div>
        </div>
        <div class="text" v-if="uploadInput.selection === 'GEOJSON'">
            <b-textarea id="GEOJSON" name="GEOJSONn" rows="10" cols="60" placeholder="Enter GeoJSON"
                v-model='uploadInput.json' @update="jsonHandler()" :state='jState'
                :disabled="$route.query.clone !== undefined" @copy="handleCopy($event)">
            </b-textarea>
        </div>
        <div class="text" v-if="uploadInput.selection === 'KML'">
            <b-textarea id="KML" name="KML" rows="10" cols="60" placeholder="Enter KML string" v-model='uploadInput.kml'
                @update="kmlHandler()" :state='kState'>
            </b-textarea>
        </div>
        <div class='esriContainer' v-if="uploadInput.selection === 'ESRI_SHAPEFILE_ZIP'">
            <label class='title'>Upload File
                <input accept=".zip" type="file" id="file" v-on:change="handleFileUpload($event)"/>            
            </label>
            <label class='title'>Reference Field Name
                <input @change='fieldHandler()' v-model="uploadInput.esriField" placeholder="Required">
            </label>
            <p class="description">This is the reference field in the ESRI Shapefile, often just "name", which helps the
                DATA API extract the polygon name.</p>
            <div>
                <a @click="infoHandler()" class='link'>Additional information on using ESRI Shapefiles</a>
            </div>
        </div>
    </div>
</template>
<script>
import { bus } from "@/main";
import { syncState } from '../../utils/syncState.js';
export default {
    data() {
        return {
            jState: null,
            kState: null,
            rawFile: null
        }
    },
    computed: {
        ...syncState({
            uploadInput: 'state.jobRequest.uploadInput|setUploadInput',
            polygonInputOptions: 'state.jobRequest.polygonInputOptions|setPolygonInputOptions',
            attachedFile: 'state.jobRequest.attachedFile|setAttachedFile'
        }, { context: this, defaultStore: '$sessionStore' })
    },
    beforeMount() { 
        if (this.uploadInput.json !== '' || this.uploadInput.kml !== '') this.jsonHandler();
    },
    methods: {
        changePolygonFormat(change) {
            this.uploadInput.kml = '';
            this.uploadInput.json = '';
            this.uploadInput.file = '';
            this.uploadInput.esriField = '';
            this.jState = null;
            this.kState = null;
            this.attachedFile = undefined;
            this.$sessionStore.commit('setPolygonString', '');
            this.polygonInputOptions = { polygonFormat: change, polygonNameAliasElement: this.uploadInput.esriField || null };
            if (Object.keys(this.$route.query).length > 0) {
                setTimeout(() => {
                    this.$router.replace({ query: {} });
                }, 0);
            }
        },
        handleCopy($event) {
            if (this.$route.query.clone !== undefined) $event.preventDefault();
        },
        async handleFileUpload(event) {

            if (event.target.files[0].size >= 1000000000) {
                this.attachedFile = undefined;
                console.log('File Too Large')
                return
            }

            // console.log(event.target.files[0].type);
            if (event.target.files[0].type === "application/x-zip-compressed" || event.target.files[0].type === "application/zip") {
                // console.log(event.target.files)
                // clear json (in case it was set from clone job)
                this.polygonInputOptions = { polygonFormat: "ESRI_SHAPEFILE_ZIP", polygonNameAliasElement: this.uploadInput.esriField || null };
                this.uploadInput.kml = '';
                this.uploadInput.json = '';
                this.jState = null;
                this.kState = null;
                this.$sessionStore.commit('setPolygonString', '');

                this.uploadInput.file = Object.freeze(event.target.files[0]);
                this.attachedFile = this.uploadInput.file;
            }
            else {
                console.log('File is not zipped')
                this.attachedFile = undefined;
            }
        },
        fieldHandler() {
            //   if(this.file instanceof File){
            this.polygonInputOptions = { polygonFormat: this.uploadInput.selection, polygonNameAliasElement: this.uploadInput.esriField };
            //    }
        },
        infoHandler() {
            bus.$emit("info", true)
        },
        jsonHandler() {
            this.jsonValidate(this.uploadInput.json)
        },
        kmlHandler() {
            this.jsonValidate(this.uploadInput.kml)
        },
        jsonValidate(json) {
            if (json === '') {
                this.uploadInput.selection === 'GEOJSON' ? this.jState = null : this.kState = null;
                this.$sessionStore.commit('setPolygonString', '');
                return;
            }
            try {
                //parse the file before entering it as a var
                let text = json.trim();
                if (this.uploadInput.selection === 'GEOJSON') {
                    let parsed = JSON.parse(text);
                    let result = JSON.stringify(parsed, null, "\t")
                    this.$sessionStore.commit('setPolygonString', result);
                } else {
                    this.$sessionStore.commit('setPolygonString', text);
                }
                this.uploadInput.selection === 'GEOJSON' ? this.jState = true : this.kState = true;
            }
            catch (err) {
                this.uploadInput.selection === 'GEOJSON' ? this.jState = false : this.kState = false;
                this.$sessionStore.commit('setPolygonString', '');
                console.log([{ message: 'please reformat JSON' }, err])
            }
        }
    }
}

</script>
<style lang="scss" scoped>

.content {
    padding: 2rem;
    padding-top:1rem;
}
.selectHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .radio-thing {
        padding: 1rem;
    }

    input {
        margin: 0;
        margin-right: 0.5rem;
        cursor: pointer;
    }

    label {
        margin: 0;
        cursor: pointer;
    }
}

.title {
    font-weight: bold;
    margin-bottom: 30px;
}

.esriContainer {
    display: flex !important;
    flex-direction: column;
}

.info {
    font-size: 10px;
    color: #858EA7;
    margin-left: 0.2rem;
}

.radio {
    text-align: center;
    margin-top: 3.5px;
    margin-left: 20px;
    margin-right: 5px;
}

.link {}

.text {
}

.description {
    max-width: 500px;

}

a:hover {
    cursor: pointer;
}
</style>