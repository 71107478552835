<template>
  <div class="details-container">
    <PseudoBreadcrumbs
      v-if="!isPreview"
      :origin="origin"
      currentTitle="Place"
    ></PseudoBreadcrumbs>
    <div class="details-info">
      <div class="details-header">
        <div class="details-header-icon">
          <div
            v-if="$route.name !== 'study-charts-details' && $route.name !== 'study-map-details' && $route.name !=='preview-map-details' "
            class="brand-img brand-avatar brand-avatar-64"
            :style="getImgContainerStyle(location)"
          >
            <img
              :src="getCategoryAvatar(location)"
              :style="getImgStyle(location)"
            />
          </div>
          <slot name="study-icon"></slot>
        </div>
        <div class="details-header-info">
          <h2>{{ location.name | str_limit }}</h2>
          <i>{{ location.address | str_limit }}</i>
        </div>
        <div class="checkbox-container">
          <slot name="study-checkbox"></slot>
        </div>
      </div>
      <div>
        <slot name="buttons"></slot>
      </div>
      <div class="estimated-visits-header">
        <p class="description">Estimated Visits</p>
      </div>
      <div class="estimated-visits">
        <div>
          <p>PAST DAY</p>
          <div v-if="estVisits.pastDay.enoughData">
            <b>{{ estVisits.pastDay.value | truncate_number }}</b>
          </div>
          <div v-if="!estVisits.pastDay.enoughData" v-b-tooltip.hover.bottom="notEnoughDataTooltip(estVisits.pastDay)">
            <b><img class="not-enough-data-icon" :src="require('pinnacle-lib/assets/icon/alert-triangle.svg')" /></b>
          </div>
        </div>
        <div>
          <p>PAST WEEK</p>
          <div v-if="estVisits.pastWeek.enoughData">
            <b>{{ estVisits.pastWeek.value | truncate_number }}</b>
          </div>
          <div v-if="!estVisits.pastWeek.enoughData" v-b-tooltip.hover.bottom="notEnoughDataTooltip(estVisits.pastWeek)">
            <b><img class="not-enough-data-icon" :src="require('pinnacle-lib/assets/icon/alert-triangle.svg')" /></b>
          </div>
        </div>
        <div>
          <p>PAST MONTH</p>
          <div v-if="estVisits.pastMonth.enoughData">
            <b>{{ estVisits.pastMonth.value | truncate_number }}</b>
          </div>
          <div v-if="!estVisits.pastMonth.enoughData" title="Not Enough Data" v-b-tooltip.hover.bottom="notEnoughDataTooltip(estVisits.pastMonth)">
            <b><img class="not-enough-data-icon" :src="require('pinnacle-lib/assets/icon/alert-triangle.svg')" /></b>
          </div>
        </div>
      </div>
      <div class="details-list-header">
        <p class="description">Place Information</p>
      </div>
      <ul class="details-list">
        <li class="details-list-item">
          <div class="details-list-item-label">
            <b>Industry</b>
          </div>
          <div class="details-list-item-value">
            <p
              class="search-link"
              @click="
                $emit('searchDetails', {
                  type: 'category',
                  field: 'subsector',
                  value: location.subsector,
                })
              "
              >{{ location.subsector }}</p
            >
          </div>
        </li>
        <li class="details-list-item">
          <div class="details-list-item-label">
            <b>Brand</b>
          </div>
          <div class="details-list-item-value">
            <p
              class="search-link"
              @click="
                $emit('searchDetails', {
                  type: 'category',
                  field: 'category',
                  value: location.category,
                })
              "
              >{{ location.category }}</p
            >
          </div>
        </li>
        <li class="details-list-item">
          <div class="details-list-item-label">
            <b>Street Address</b>
          </div>
          <div class="details-list-item-value">
            <p>{{ location.address }}</p>
          </div>
        </li>
        <li class="details-list-item">
          <div class="details-list-item-label">
            <b>City</b>
          </div>
          <div class="details-list-item-value">
            <p>{{ location.city }}</p>
          </div>
        </li>
        <li class="details-list-item">
          <div class="details-list-item-label">
            <b>Market</b>
          </div>
          <div class="details-list-item-value">
            <p>{{ location.municipality }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import numeral from "numeral";
import PseudoBreadcrumbs from "@/components/partials/PseudoBreadcrumbs.vue";
import { bus } from "@/main";

export default {
  props: ["selectedLocation", "isPreview"],
  components: { PseudoBreadcrumbs },
  computed: {
    origin() {
      if (this.$route.name.includes('map') || this.$route.name.includes('charts')) {
        return {
          name: this.$route.name.split('-details')[0],
          title: 'Comparison',
        }
      } else if (this.lastCrumb === 'comparison-details') {
        return {
          name: 'comparison-details',
          title: 'Comparison'
        }
      } else {
        return {
          name: 'search-details',
          title: 'Search'
        }
      }
    },
    lastCrumb() {
      return this.$sessionStore.state.crumbs.trail[this.$sessionStore.state.crumbs.trail.length - 2].name;
    }
  },
  watch: {
    location() {
      this.estVisits = {
        pastDay: {value: "-", enoughData: true},
        pastWeek: {value: "-", enoughData: true},
        pastMonth: {value: "-", enoughData: true},
      },
      this.disabled = false;
      if (this.location !== null) this.getEstimatedVisits();
    },
    $route() {
      if (this.$route.query.place !== undefined)
      this.loadFromQueryParams();
    }
  },
  data() {
    return {
      estVisits: {
        pastDay: {value: "-", enoughData: true},
        pastWeek: {value: "-", enoughData: true},
        pastMonth: {value: "-", enoughData: true},
      },
      disabled: true,
      location: null
    };
  },
  created() {
    if (this.selectedLocation === undefined) {
      this.loadFromQueryParams();
    } else this.location = this.selectedLocation;
    console.log(this.location);
    bus.$emit("focusPlace", this.location);
  },
  mounted() {
    this.disabled = false;
    if (this.location !== null) this.getEstimatedVisits();
  },
  methods: {
    loadFromQueryParams() {
      this.location = JSON.parse(atob(this.$route.query.place));
    },
    notEnoughDataTooltip(estVisitsData) {
      return "Insufficient data";
      //return "Not Enough Data value: "+estVisitsData.value + ', percentWithData: ' +  Math.round(estVisitsData.percentData * 100) + '%' ;
    },
    async getEstimatedVisits() {
      try {
        const headers = this.getHeaders();
        const res = await this.$http.get(
          `/v2/location-details/${this.location.id}`,
          headers
        );
        let loc = res.data.locations[0];
        let dataThreshold = 0.001;

        const estVisits = loc["estimated-foot-trafic"];
        this.estVisits.pastDay.value = Math.round(
          estVisits.last_day.estimated_visitors
        ).toString();
        this.estVisits.pastDay.enoughData = (estVisits.last_day.percent_matches >= dataThreshold);
        this.estVisits.pastDay.percentData = estVisits.last_day.percent_matches;
        
        this.estVisits.pastWeek.value = Math.round(
          estVisits.last_week.estimated_visitors
        ).toString();
        this.estVisits.pastWeek.enoughData = (estVisits.last_week.percent_matches >= dataThreshold);
        this.estVisits.pastWeek.percentData = estVisits.last_week.percent_matches;
        
        this.estVisits.pastMonth.value = Math.round(
          estVisits.last_month.estimated_visitors
        ).toString();
        this.estVisits.pastMonth.enoughData = (estVisits.last_month.percent_matches >= dataThreshold);
        this.estVisits.pastMonth.percentData = estVisits.last_month.percent_matches;
      } catch (err) {
        console.log(err);
      }
    },
  },
  filters: {
    truncate_number(number) {
      if (number === "-" || number < 1000) return number;
      return numeral(number).format("0.0a");
    },
  },
};
</script>
<style lang="scss" scoped>
.details-container {
  background-color: white;
  width: 100%;

  .details-info {
    .details-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      // margin-bottom: 6%;
      // padding: 2rem 2rem 0 2rem;
      padding: 16px 1.5rem;

      .checkbox-container {
        margin-left: auto;
        align-items: center;
      }

      .details-header-info {
        height: 100%;
        margin-left: 6%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        h2 {
          margin: 0;
          font-size: 16px;
        }

        i {
          color: #6E84A3;
          font-weight: 600;
          font-style: normal;
          font-size: 14px;
        }
      }
    }

    .estimated-visits {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      width: 100%;
      padding: 2rem;
      // border-bottom: 1px solid $border-color;

      b {
        font-size: 24px;
      }

      div {
        //margin-left: 12px;

        p {
          margin: 0;
          margin-bottom: 5%;
          color: rgb(172, 172, 172);
          font-weight: 600;
        }
        .not-enough-data-icon {
          filter: invert(84%) sepia(0%) saturate(0%) hue-rotate(196deg) brightness(84%) contrast(86%);
        }
      }

      div:first-child {
        margin-left: 0;
      }
    }

    .details-list {
      list-style: none;
      padding: 0 2rem 0 2rem;
      margin: 0;
    }

    .details-list-item {
      display: flex;
      padding: 5% 0;

      // .search-link {
      //   // cursor: pointer;
      // }

      .details-list-item-label {
        width: 40%;
        color: rgba(110, 132, 163, 1);
      }

      .details-list-item-value {
        width: 60%;
        text-align: right;

        p {
          margin: 0;
        }
      }
    }
  }
  // .estimated-visits-header {
  //   border-top: 1px solid $border-color;
  // }
  .estimated-visits-header, .details-list-header {
    background: #F9FAFC;
    height: 4em;
    display: flex;
    align-content: center;
    padding: 18px 24px;
    // border-bottom: 1px solid $border-color;

    .description {
      margin: auto 0;
      color: #6E84A3;
      font-weight: 700;
      font-size: 14px;
    }
  }
}
</style>