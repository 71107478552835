<template>
  <div class="documentation-wrap">
  </div>
</template>

<script>
import { ConstantsService } from "@/services/constants.js";
  
export default {
  mixins: [ConstantsService],
  created(){
    window.open(this.Constants.documentation_link, "_blank");
    this.$router.go(-1)
  },
  components: {},
};
</script>

<style scoped lang='scss'>

</style>