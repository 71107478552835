import { syncState } from '../../utils/syncState.js';
import { validatePlaceSelection } from './individual/places.js';
import { validateTimeframe } from './individual/timeframe.js';
import JobService from '../dataExplorer/jobService.js';
import { validateDatasetSelections } from './individual/datasetSelections.js';
import { validateDatasetDetails } from './individual/datasetDetails.js';

const datasetValidator = {
  validators: [{
    validate: () => (true)
  },
  {
    validate: function() {
      return validatePlaceSelection.call(this);
    }
  },
  {
    validate: function() {
      return validateTimeframe.call(this);
    }
  },
  {
    validate: function() {
      return validateDatasetSelections.call(this);
    }
  },
  {
    validate: function() {
      return validateDatasetDetails.call(this);
    }
  }],
  submitter: async function() {
    this.isSubmitting = true;
    try {
      const data = await this.requestJob();
      if (data.succeeded) {
        return data;
      }
      if ('error' in data) return {succeeded: false, error: data.error};
      return {succeeded: false, error: data}
    } catch (raw) {
      if ('error' in raw) return {succeeded: false, error: raw.error};
      return {succeeded: false, error: raw};
    } finally {
      this.isSubmitting = false;
    }
  },
  postSubmitter: function(response) {
    if (response.succeeded) {
      this.hasError = false;
      if (this.isLargeJob) {
        this.$router.push({name: 'home-datasets', query: {"large-job-submitted": response.run_id}});
      } else {
        this.$router.push({name: 'home-datasets', query: {submitted: response.jobId}});
      }
      
      analytics.track('Run Pinnacle Dataset', {
        jobId: response.jobId,
        reportTypes: [...this.selectedReportIds].join(","),
      });
      
      this.$sessionStore.commit('resetJobRequest');
      //console.log(response);
      
      return;
    }
    this.hasError = true;
    if (response && response.error && response.error.response && response.error.response.data && response.error.response.data.message !== undefined) {
      this.errorMsg = response.error.response.data.message;
    }
    if (response && response.error && response.error.friendlyMessage !== undefined) {
      this.errorMsg = response.error.friendlyMessage;
    }
    
  }
};
 
export default {
  mixins: [ JobService ],
  data() {
    return {
      datasetValidator
    };
  },
  computed: {
    ...syncState({
      selectedLocationMethod: 'state.jobRequest.selectedLocationMethod',
      selectedPolygons: 'state.jobRequest.selectedPolygons',
      excludedPolygons: 'state.jobRequest.excludedPolygons',
      selectAllParams: 'state.jobRequest.selectAllParams',
      polygonInputOptions: 'state.jobRequest.polygonInputOptions',
      uploadInput: 'state.jobRequest.uploadInput',
      polygonString: 'state.jobRequest.polygonString',
      attachedFile: 'state.jobRequest.attachedFile',
      drawnPolygonGeoJSON: 'state.jobRequest.drawnPolygonGeoJSON',
      startDateTime: 'state.jobRequest.startDateTime',
      endDateTime: 'state.jobRequest.endDateTime',
      evOptions: 'state.jobRequest.estimatedVisits2Options',
      generatorSecondsBefore: 'state.jobRequest.generatorSecondsBefore',
      locationAffinityReportOptions: 'state.jobRequest.locationAffinityReportOptions',
      secondsBefore: 'state.jobRequest.secondsBefore',
      secondsAfter: 'state.jobRequest.secondsAfter',
      clusterReportOptions: 'state.jobRequest.clusterReportOptions',
      locationReportOptions: 'state.jobRequest.locationReportOptions',
      minDevices: 'state.jobRequest.minDevices',
      dwellOptions: 'state.jobRequest.dwellTimeReportOptions',
      jobName: 'state.jobRequest.reportOutputOptions.reportName',
      isLargeJob: "state.jobRequest.isLargeJob|setIsLargeJob"
    }, { context: this, defaultStore: '$sessionStore' })
  }
}