<template>
  <div class="search-details-container">
    <PlaceDetails>
      <template v-slot:study-checkbox>
        <!-- <b-checkbox
          @change="studyChanged({ status: $event, location })"
          :disabled="!isInStudy && studyLocations.length === 5"
          class="study-checkbox"
          :checked="isInStudy"
        ></b-checkbox> -->
        <template v-if="isInStudy">
          <img class="play-remove" :src="require('pinnacle-lib/assets/icon/play-check.svg')" id='remove-target-'
            @click="studyChanged({ location, status: false })"
          />
          <b-tooltip  target='remove-target-' triggers="hover" placement="top-right" >
            Remove from comparison
          </b-tooltip>
        </template>
        <template v-else-if="studyLocations.length < 5">
          <img class="play-check" :src="require('pinnacle-lib/assets/icon/play-plus.svg')" id='add-target-'
            @click="studyChanged({ location, status: true })"
          />
          <b-tooltip  target='add-target-' triggers="hover" placement="top-right" >
            Add to comparison
          </b-tooltip>
        </template>
        <!-- over 5 locations ---> 
        <template v-else-if="studyLocations.length >= 5">
          <img class="play-check" :src="require('pinnacle-lib/assets/icon/play-unavailable.svg')" id='unavailable-target-'
          />
          <b-tooltip  target='unavailable-target-' triggers="hover" placement="top-right" >
            Comparison full
          </b-tooltip>
        </template>

      </template>
      <template v-slot:buttons>
        <div class="button-container">
          <button
            @click="$router.push({ path: `/preview/${location.id}/charts` })"
            class="secondary-button"
          >
            Open Insights
          </button>
        </div>
      </template>
    </PlaceDetails>
  </div>
</template>

<script>
import PlaceDetails from "@/components/partials/PlaceDetails.vue";
// import ComparisonBanner from "@/components/search/ComparisonBanner.vue";

export default {
  components: { PlaceDetails },
  data() {
    return {};
  },
  created() {
  },
  computed: {
    location() {
      const parsed = JSON.parse(atob(this.$route.query.place));
      return parsed;
    },
    studyLocations() {
      return this.$sessionStore.state.study.locations;
    },
    isInStudy() {
      return this.studyLocations.some((loc) => loc.id === this.location.id);
    },
  },
  methods: {
    studyChanged(evt) {
      const { status, location } = evt;
      if (status) {
        if (this.studyLocations.length === 5) return;
        // Append the location to the study if not already there
        const newLocations = [...this.studyLocations, location];
        this.$sessionStore.commit("setLocations", newLocations);
      } else {
        // Remove the location
        const newLocations = this.studyLocations.filter(
          (loc) => loc.id !== location.id
        );
        this.$sessionStore.commit("setLocations", newLocations);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-details-container {
  width: 100%;
  position: relative;
  top: 0;
  margin-bottom: 100px;

  .play-check, .play-remove {
    cursor: pointer;
  }

  // .study-checkbox {
  //   ::v-deep .custom-control-label::after {
  //     background-color: white;
  //     border: 1px solid rgba(192, 196, 209, 1);
  //     border-radius: 3px;
  //   }

  //   ::v-deep .custom-control-input:checked ~ .custom-control-label::after {
  //     background-color: $blue;
  //     border: 1px solid $blue;
  //   }

  //   ::v-deep .custom-control-input:disabled ~ .custom-control-label::after {
  //     background-color: lightgray;
  //     border: 1px solid rgba(192, 196, 209, 1);
  //   }
  // }

  .button-container {
    width: 100%;
    // padding: 0 2rem 2rem 2rem;
    padding: 0 1.5rem 16px 1.5rem;
    display: flex;
    justify-content: space-between;

    button {
      width: 100%;
    }
    .secondary-button {
      // width: 49%;
      border-radius: 4px;
      border-width: 0;
      padding-top: 12px;
      padding-bottom: 12px;
      background: #2c7be5;
      color: #ffffff;
    }
    .primary-button {
      // width: 49%;
      border-radius: 4px;
      border-width: 0;
      padding-top: 12px;
      padding-bottom: 12px;
      background: #edf2f9;
      color: #6e84a3;
    }

    .secondary-button:disabled {
      background-color: lightgray;
    }
  }
}
</style>