 export function byString(obj, accessor) {

  // https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
  accessor = accessor.replace(/\[(w+)\]/g, '.$1'); // Converts indices to props
  accessor = accessor.replace(/^\./, ''); // Strips any leading dot
  const keys = accessor.split('.');
  for (let idx = 0, n = keys.length; idx < n; ++idx) {
    const key = keys[idx];
    if (key in obj) {
      obj = obj[key];
    } else return undefined;
  }
  return obj;
  
}