<template>
  <SignLog :form-name="'Register'" :emailAdd="emailAdd" :token="token"/>
</template>

<script>
import SignLog from '@/components/form/SignLog'

export default {
  components: {
    SignLog
  },
   props: ["email", "token"],
  computed: {
    emailAdd(){
      return this.email.replaceAll(' ', '+')
    }
  }
}
</script>

<style lang="scss" scoped></style>