<template>
  <div class="draw-polygon-container">
    <b-input-group class="mb-2">
      <b-form-input placeholder="Search an address" v-model="address" id="map-autocomplete" class="map-autocomlete"
        type="search" ref="Autocomplete" v-on:keyup.enter="searchAddressString" />
      <b-input-group-append is-text v-on:click="searchAddressString" class="cursor-pointer">
        <b-icon icon="search"></b-icon>
      </b-input-group-append>
    </b-input-group>

    <div class="map-container">
      <div id="map" class="map" ref="Map" />
      <b-button variant="danger" class="btn-clear-map" v-if="drawnPolygon" v-on:click="ClearDrawData">Clear
        Map</b-button>
    </div>
    <b-form-group class="name-form" v-if="$route.name.includes('dataset')">
      <b-form-input @change="nameHandler" placeholder="Name for drawn polygon (optional)" v-model="drawnPolygonName"
        type="text" />
    </b-form-group>
    <div class="debug" v-if="false">
      <pre>{{ JSON.stringify(drawnPolygonGeoJSON, null, 2) }}</pre>
      <b-button variant="info" v-on:click="generateDrawnGeoJSON">Debug GeoJSON</b-button>
    </div>
    <modal-data-agreement
      ref="modalDataAgreement"
      :cancel="AgreementCancel"
      :agree="AgreementAgree"
    ></modal-data-agreement>
</div>
</template>
<script>
import { syncState } from '../../utils/syncState.js';
import ModalDataAgreement from "./shared/ModalDataAgreement.vue";

export default {
  components: {
    ModalDataAgreement
  },
  data() {
    return {
      address: '',
      map: null,
      drawingManager: null,
      autocomplete: null,
      drawnPolygon: null,
      drawnPolygonName: null,
      highlight_marker: null,
      loadedDrawn: false
    }
  },
  mounted() {
    this.resize();
  },
  computed: {
    ...syncState({
      drawnPolygonGeoJSON: "state.jobRequest.drawnPolygonGeoJSON|setDrawnPolygonGeoJSON"
    }, { context: this, defaultStore: '$sessionStore' })
  },
  methods: {
    nameHandler() {
      if (this.drawnPolygonGeoJSON !== undefined) {
        this.drawnPolygonGeoJSON.properties.name = this.drawnPolygonName;
      }
    },
    
    initMap() {
      if (!this.map) {
        this.map = new google.maps.Map(this.$refs.Map, {
          center: { lat: 34.1469595, lng: -118.1442913 },
          zoom: 10,
          tilt: 0,
          fullscreenControl: false,
        });
        if (navigator.geolocation) {
          let scope = this;
          const { drawnPolygonGeoJSON } = this.$sessionStore.state.jobRequest;

          const polygonOptions = {
            fillColor: '#1E90FF',
            strokeWeight: 0,
            fillOpacity: 0.7,
            editable: true
          };

          this.drawingManager = new google.maps.drawing.DrawingManager({
            //drawingMode: google.maps.drawing.OverlayType.MARKER,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [
                google.maps.drawing.OverlayType.POLYGON
              ],
            },
            polygonOptions,
            map: this.map
          });

          if (drawnPolygonGeoJSON !== undefined) {
            google.maps.Polygon.prototype.getBounds = function() {
                var bounds = new google.maps.LatLngBounds();
                var paths = this.getPaths();
                var path;        
                for (var i = 0; i < paths.getLength(); i++) {
                    path = paths.getAt(i);
                    for (var ii = 0; ii < path.getLength(); ii++) {
                        bounds.extend(path.getAt(ii));
                    }
                }
                return bounds;
            };
            const paths = this.generatePolygon(drawnPolygonGeoJSON);
            this.loadedDrawn = true;
            this.drawnPolygon = new google.maps.Polygon({
              paths, ...polygonOptions
            });
            const bounds = this.drawnPolygon.getBounds();
            this.map.fitBounds(bounds);
            this.drawnPolygon.setMap(this.map);
            this.map.setCenter(new google.maps.LatLng(
              paths[0].lat, paths[0].lng
            )); // Good enough
            this.drawingManager.setDrawingMode(null);
            this.drawingManager.setOptions({ drawingControlOptions: { position: google.maps.ControlPosition.TOP_CENTER, drawingModes: [] } });
            
          } else {
            navigator.geolocation.getCurrentPosition(function (position) {
              var center = new google.maps.LatLng(
                position.coords.latitude,
                position.coords.longitude
              );
              var zoom = 10;

              scope.map.setCenter(center);
              scope.map.setZoom(zoom);
            }, function () { });

          }

        }
        google.maps.event.addListener(this.drawingManager, "overlaycomplete", this.OverlayComplete);
        google.maps.event.addListener(this.drawingManager, 'drawingmode_changed', this.DrawingModeChanged);

        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('map-autocomplete'), {
          fields: ["address_components", "geometry"],
          types: [],
        });
        this.autocomplete.addListener("place_changed", this.PlaceChanged);
      }
    },
    resize() {
      this.initMap();
      google.maps.event.trigger(this.map, "resize");
    },
    OverlayComplete(mapDrawing) {
      this.drawingManager.setDrawingMode(null);
      this.drawingManager.setOptions({ drawingControlOptions: { position: google.maps.ControlPosition.TOP_CENTER, drawingModes: [] } });
      this.drawnPolygon = mapDrawing;
      this.UpdateOverlayEvents();
    },
    DrawingModeChanged() {
      let drawing_mode = this.drawingManager.getDrawingMode();
      if (drawing_mode == 'polygon') {
        this.$refs.modalDataAgreement.openModal();
      }
    },
    UpdateOverlayEvents() {
      let scope = this;
      this.drawnPolygon.overlay.getPaths().forEach(function (path, index) {
        google.maps.event.clearListeners(path, 'insert_at');
        google.maps.event.addListener(path, 'insert_at', scope.UpdateOverlayEvents);
        google.maps.event.clearListeners(path, 'remove_at');
        google.maps.event.addListener(path, 'remove_at', scope.UpdateOverlayEvents);
        google.maps.event.clearListeners(path, 'set_at');
        google.maps.event.addListener(path, 'set_at', scope.UpdateOverlayEvents);
      });
      // this.updateStore();        
    },
    ClearDrawData() {
      if (this.drawnPolygon) {
        if (this.drawnPolygon.overlay !== undefined) {
          this.drawnPolygon.overlay.setMap(null);
        } else {
          this.drawnPolygon.setMap(null);
        }
      }
      this.drawnPolygon = null;
      this.drawingManager.setOptions({ drawingControlOptions: { position: google.maps.ControlPosition.TOP_CENTER, drawingModes: ['polygon'] } });
      // this.updateStore();
    },
    setPlaceOnMap(place) {
      if (typeof place !== "undefined" && place && place.geometry) {
        if (this.highlight_marker) {
          this.highlight_marker.setMap(null);
        }
        this.highlight_marker = new google.maps.Marker({
          position: place.geometry.location,
          map: this.map,
        });
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(18);
        //this.map.panToBounds(place.geometry.viewport);
      }
    },
    PlaceChanged() {
      const place = this.autocomplete.getPlace();
      this.setPlaceOnMap(place);
    },
    searchAddressString() {
      let scope = this;
      let placesService = new google.maps.places.PlacesService(this.map); // i.e. <div id="map"></div>

      const request = {
        query: this.address,
        fields: ['formatted_address', 'name', 'geometry']
      };
      placesService.findPlaceFromQuery(request, this.handlePlaceResults.bind(this));

    },
    handlePlaceResults(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        const place = results[0];
        this.setPlaceOnMap(place);
        this.address = '';
        if (place.name) {
          this.address = place.name + ' ';
        }
        this.address += place.formatted_address;

      } else {
        alertData.setMessage('Places failed due to: ' + status);
      }
    },
    generatePolygon(geojson) {
      const paths = [];
      const json = typeof geojson === 'string' ? JSON.parse(geojson) : geojson;
      console.log(geojson)
      for (const arr of json.geometry.coordinates[0]) {
        paths.push({
          lng: arr[0],
          lat: arr[1]
        });
      }
      return paths;
    },
    generateDrawnGeoJSON() {
      var vertices = this.drawnPolygon.overlay.getPaths();

      var coords = [];
      vertices.forEach(function (elem, index) {
        elem.forEach(function (pair, pair_index) {
          var lat = pair.lat();
          var lng = pair.lng();
          coords.push([lng, lat]);
        });
      });
      coords.push(coords[0]);
      let polygonName = this.drawnPolygonName;
      if (!polygonName) polygonName = "User Drawn Polygon";
      let geojson = {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [coords]
        },
        "properties": {
          "name": polygonName
        }
      };
      this.drawnPolygonGeoJSON = geojson;
      return this.drawnPolygonGeoJSON;
    },
    validateState() {
      if (this.drawnPolygon) {
        if (this.loadedDrawn) {
          this.loadedDrawn = false;
          return;
        }
        this.drawnPolygonGeoJSON = this.generateDrawnGeoJSON();
        return;
      }
      this.drawnPolygonGeoJSON = undefined;
    },
    AgreementAgree() {
    },
    AgreementCancel() {
      this.drawingManager.setDrawingMode(null);
    }
  },
  watch: {
    drawnPolygon() {
      this.validateState();
    }
  }
}

</script>
<style lang="scss" scoped>
.draw-polygon-container {
  padding: 15px;

  .form-control {
    border: 1px solid #ced4da;
  }

  .input-group-text {
    background-color: #e9ecef;
    color: #495057;
    border: 1px solid #ced4da;
  }
}

.map-container {
  position: relative;
  //width:calc(100vw - 250px);
  width: 100%;
  height: 65vh;
  margin-bottom: 1rem;

  .btn-clear-map {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.map {
  width: 100%;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.name-form {
  margin-bottom: 0;
}
</style>
