<template>
  <div :class="`status-badge-container ${computedVariant}`">
    <p class="status-text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: ['text', 'variant'],
  computed: {
    computedVariant() {
      return this.variant || 'pending';
    }
  }
}
</script>

<style lang="scss" scoped>

.success {
  background-color: #e8ffed;
  color: #14cc10;
}

.error {
background-color: #ffe8e8;
color: #fa1818;
}

.pending {
  background-color: #FFF7E8;
  color: #FAAF18;
}

.disabled {
  background-color: #f2f1ed;
  color: #8a8988;
}
.status-badge-container {
  border-radius: 0.2rem;
  width: fit-content;
  padding: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .status-text {
    margin: 0;
  }
}
</style>