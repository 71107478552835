<template>
  <div class="home-container">
    <div class="top-bar">
      <h1 class="title">Home</h1>
      <ShortButton classes="create-button" @click="createNew()">Create New</ShortButton>
    </div>
    <PageDivider />
    <div class="control-bar">
      <div class="half-container first-half">
        <PageTabs @changed="changeTab" :tabs="tabs"></PageTabs>
      </div>
      <div class="half-container second-half">
        <router-view name="table-filters"></router-view>
      </div>
    </div>
    <div class="table-container">
      <router-view name="table"></router-view>
    </div>
  </div>
</template>

<script>
import  { ShortButton, PageDivider, PageTabs } from 'pinnacle-lib';
import RouteGuard from "pinnacle-lib/mixins/RouteGuard";

export default {
  mixins: [RouteGuard],
  components: {ShortButton, PageDivider, PageTabs},
  mounted() {
    if (this.$route.name === 'home-feeds') {
      if (this.hasReportPermission && !this.hasDatasetPermission) this.$router.push({name: 'home-reports'});
      else if (this.hasDatasetPermission) this.$router.push({name: 'home-datasets'})
      else if (this.hasPlacesPermission) this.$router.push({name: 'explore'});
      else this.$router.push({path: '/customer-support'})
    }
    this.$nextTick(() => {
      this.$sessionStore.commit('resetJobRequest'); // insurance
    });
  },
  computed: {
    permissions() {
      return this.$persistingStore.state.user.permissions;
    },
    hasReportPermission() {
      return this.hasPermission('Card Report', this.permissions);
    },
    hasDatasetPermission() {
      return this.hasPermissions(['Super Admin', 'Pinnacle Bulk Export Access'], this.permissions, false);
    },
    hasPlacesPermission() {
      return this.hasPermissions(['Super Admin', "Pinnacle Enterprise Access", "Pinnacle Mid Tier Access", "Pinnacle Free Access"], this.permissions, false);
    },
    tabs() {
      return [
        {
          title: 'Datasets',
          path: '/home/feeds/datasets',
          icon: require('pinnacle-lib/assets/icon/view_list_black.svg'),
          style: 'height: 24px; width: 24px;',
          disabled: !this.hasDatasetPermission
        },
        {
          title: 'Reports',
          path: '/home/feeds/reports',
          icon: require('pinnacle-lib/assets/icon/blank-file.svg'),
          style: 'height: 22px; width: 22px;',
          disabled: !this.hasReportPermission
        }
      ]
    }
  },
  methods: {
    /**
     * Navigates the user to the create new page.
     */
    createNew() {
      if (this.$route.name.includes('home-datasets')) {
        this.$router.push({name: 'create-dataset'});
      } else {
        this.$router.push({name: 'create-report'});
      }
    },
    /**
     * Handles the route change when the user interacts with the page tabs.
     * @param {*} tab 
     */
    changeTab(tab) {
      this.$router.push(tab.path);
    },
  }  
}
</script>

<style lang="scss" scoped>

$separation-amt: 2rem;

.half-container {
  width: 50%;
  height: 100%;
  .second-half {
    justify-content: flex-end;
  }
}

.home-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $bg-color;
  overflow: auto;
  

  ::v-deep h1 {
    margin: 0;
  }

  .top-bar {
    width: 100%;
    height: 144px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin: 0;

    .title {
      margin-left: $separation-amt;
    }

    ::v-deep .create-button {
      height: 2.4rem;
      margin-right: $separation-amt;
    }

    
    
  }
  .control-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $separation-amt;
    // height: 144px;
  }

  .table-container {
    padding: $separation-amt;
    padding-top: 0;
    width: 100%;
    background: transparent;
    border: 0;
    flex: 1;
    min-height: 50%;
  }

}

</style>