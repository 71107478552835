
export const Constants = {
  "company_name": "Azira",
  "company_link": "https://azira.com",
  "support_email": "support@azira.com",
  "vista_support_email": "vistasupport@azira.com",
  "pinnacle_link": "https://pinnacle.azira.com",
  "documentation_link": "https://azira-pinnacle.elevio.help",
  "vista_documentation_link": "https://knowledge.azira.com",
  "sensitive_data_policy_link": "https://azira.com/privacy-policy/#sensitive-policy",
  "privacy_policy_link": "https://azira.com/privacy-policy/",
  "terms_link": "https://azira.com/privacy-policy/",
  "contact_link": "https://contact.azira.com",
  "business_link": "https://business.azira.com"
}
export default Constants;


export const ConstantsService = {
  data() {
    return {
      Constants: Constants
    };
  },
}