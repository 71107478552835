<template>
  <div class="app-container container-fluid p-0">
    <LeftNav :router="$router" :route="$route" :permissions="permissions" app="pinnacle" />
        <div class="main-content">
      <router-view class="router-view" />
    </div>
 
    <MaintenanceModal />
 
  </div>
</template>

<script>
import { LeftNav } from 'pinnacle-lib';
import MaintenanceModal from "@/components/partials/MaintenanceModal";
import 'moment-timezone';
import {explorePerms, homePerms} from '../router/index.js';

export default {
  components: {
    LeftNav,
    MaintenanceModal,
  },
  computed: {
    permissions() {
      return this.$persistingStore.state.user.permissions;
    }
  },
  beforeMount() {
    if (this.$route.name === 'app-layout') {
      const last = localStorage.getItem('last');
      if (last !== null) {
        const lastRoute = this.$router.resolve(last).route;
        if (lastRoute.meta && lastRoute.meta.routeGuard && !this.hasPermissions(lastRoute.meta.routeGuard, this.permissions, false)) {
          console.log('Insufficent permissions for lastRoute '+ last);
          localStorage.removeItem('last');
        } else {
          localStorage.removeItem('last');
          this.$router.push(last);
          return;
        }
      }

      if (this.hasPermissions(homePerms, this.permissions, false)) {
        this.$router.push('/home');
        return;
      }
      
      if (this.hasPermissions(explorePerms, this.permissions, false)) {
        this.$router.push('/explore');
        return;
      }

      this.$router.push('/settings');
    }
  },
  data() {
    return {
      isLoggedIn: false,
      changeWidth: false,
      maintenanceMode: false,
      redshiftMaintenanceWindow: false,
      redshiftMaintenanceEnd: null,
      checkIntervalId: null
    };
  },
  created() {
    this.isLoggedIn = this.$route.name != "login";
  },
  watch: {
    "$route.name": function(routeName) {
      this.isLoggedIn = routeName != "login";
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  height: 100%;
  width: calc(100% - 80px);
  margin-left: 80px;
  overflow: hidden;
}

.app-container {
  height: 100%;
  width: 100%;
}

.router-view {
  height: 100%;
  width: 100%;
}

</style>
