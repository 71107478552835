const threeDIcon = require('pinnacle-lib/assets/icon/3d-icon.svg');
const twoDIcon = require('pinnacle-lib/assets/icon/2d-icon.svg');
const layerSettings = {
  id: '3d_buildings',
  source: 'composite',
  'source-layer': 'building',
  filter: ['==', 'extrude', 'true'],
  type: 'fill-extrusion',
  minzoom: 15,
  paint: {
    'fill-extrusion-color': '#aaa',
    'fill-extrusion-height': [
    'interpolate',
    ['linear'],
    ['zoom'],
    15,
    0,
    15.05,
    ['get', 'height']
    ],
    'fill-extrusion-base': [
    'interpolate',
    ['linear'],
    ['zoom'],
    15,
    0,
    15.05,
    ['get', 'min_height']
    ],
    'fill-extrusion-opacity': 0.6
  }
};

export default class ThreeDimensionControl {

  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this._container.style.position = 'absolute';
    this._container.style.right = '76px';
    this._container.style.bottom = '0px';
    this._button = document.createElement('button');
    this._button.className = 'mapboxgl-ctrl-3d';
    this._icon = document.createElement('img');
    this._icon.src = threeDIcon;
    this._button.appendChild(this._icon);
    this._button.addEventListener('click', () => this.toggleMode());

    this._map.on('pitch', (evt) => { 
      if ('originalEvent' in evt) {
        // User event
        if ((this._map.getPitch() !== 0 && this._currentMode === '2d') || (this._map.getPitch() === 0 && this._currentMode === '3d')) {
          this.toggleMode();
        }
      }
    })
    this._container.appendChild(this._button);
    this._currentMode = '2d';
    return this._container;
  }

  onRemove() {
    this._button.removeChild(this._icon);
    this._container.removeChild(this._button);
    this._container.parentNode.removeChild(this._container);
    delete this._map;
  }

  toggleMode(override) {
    if (override) this._currentMode = override;
    else this._currentMode = this._currentMode === '2d' ? '3d' : '2d';
    if (this._currentMode === '2d') {
      this._map.removeLayer('3d_buildings');
      if (override === undefined) this._map.flyTo({pitch: 0, bearing: 0});
    }
    else {
      if (this._map.getLayer('3d_buildings') === undefined) {
        const layers = this._map.getStyle().layers;
        const targetId = layers.find((layer) => layer.type === 'symbol' && layer.layout['text-field']).id;
        this._map.addLayer(layerSettings, targetId);
      }
      if (override === undefined) this._map.flyTo({pitch: 60});
    }
    this._updateIcon();
  }

  _updateIcon() {
    if (this._currentMode === '2d') {
      this._icon.src = threeDIcon;
    } else {
      this._icon.src = twoDIcon;
    }
  }

}