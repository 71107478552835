<template>
  <div class="activity-container">
    <InsightsContainer 
      title="Activity"
      :icon="require('pinnacle-lib/assets/icon/map-tod-large.svg')"
      :tooltip="tooltip"
      insight="pd"
      :selection="pd"
      insightFamily="ci"
      :toggleSelectedLayer="toggleSelectedLayer"
    >
      <div class="activity-content">
        <div class="options">
          <label>Key</label>
          <color-pill
            class="color-pill"
            :colors="['#ffffff', '#ff1900']"
          ></color-pill>
          <div class="color-pill-labels">
            <label>Less Activity</label>
            <label>More Activity</label>
          </div>
        </div>
        <div class="options no-border">
          <div class="quarters">
            <label>Quarter(s)</label>
            <select
              @change="updateSelectedLayer(pd)"
              name="quarter_options"
              v-model="quarter"
            >
              <template v-for="option in quarter_options">
                <option :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </template>
            </select>
          </div>
          <div class="days-of-week">
            <div class="dow-top">
              <label>Day(s) of Week</label>
              <b-form-checkbox
                v-model="daysCheckbox"
                @change="toggleTODDay"
                switch
              ></b-form-checkbox>
            </div>
            <TODDayGrid 
              @newSelection="updateTODDay"
              @toggleOn="daysCheckbox = true"
              :toggleSelectedLayer="toggleSelectedLayer"
              :selection="pd"
            ></TODDayGrid>
          </div>
          <div class="hours">
            <div class="hours-top">
              <label>Hour(s)</label>
              <b-form-checkbox
                v-model="hoursCheckbox"
                @change="toggleTODTime"
                switch
              ></b-form-checkbox>
            </div>
            <TODTimeGrid 
              @newSelection="updateTODTime"
              @toggleOn="hoursCheckbox = true"
              :toggleSelectedLayer="toggleSelectedLayer"
              :selection="pd"
            ></TODTimeGrid>
          </div>
        </div>
      </div>
    </InsightsContainer>
  </div>  
</template>

<script>
import axios from "axios";
import { bus } from "@/main";

import InsightsContainer from "@/components/maps/InsightsContainer.vue";
import ColorPill from "@/components/maps/ColorPill";
import TODTimeGrid from "@/components/maps/TODTimeGrid";
import TODDayGrid from "@/components/maps/TODDayGrid";

export default {
  components: {
    InsightsContainer,
    ColorPill,
    TODTimeGrid,
    TODDayGrid
  },
  props: ['toggleSelectedLayer', 'updateSelectedLayer'],
  data() {
    return {
      tooltip: 'Density of devices seen in each area during the time period',
      debounce: {
        pd: undefined,
      },
      quarter_options: [{ label: "All", value: "all" }],
      daysCheckbox: true,
      hoursCheckbox: true
    }  
  },
  created() {
    this.loadQuarterOptions();
  },
  computed: {
    pd: {
      get() {
        return this.$sessionStore.state.studyMap.ci.pd;
      },
      set(value) {
        this.$sessionStore.commit("setCIPulseDaypart", value);
      }
    },
    quarter: {
      get() {
        return this.$sessionStore.state.studyMap.ci.pd.api_params.quarter;
      },
      set(value) {
        this.$sessionStore.commit("setPDQuarter", value);
      }
    }
  },
  methods: {
    toggleTODTime() {
      if (this.hoursCheckbox) {
        bus.$emit("timeButtonsOn");
      } else {
        bus.$emit("timeButtonsOff")
      }
    },
    toggleTODDay() {
      if (this.daysCheckbox) {
        bus.$emit("dayButtonsOn");
      } else {
        bus.$emit("dayButtonsOff")
      }
    },
    updateTODTime(selection) {
      this.$sessionStore.commit("setTODTime", selection);
      this.updateTOD();
    },
    updateTODDay(selection) {
      this.$sessionStore.commit("setTODDay", selection);
      this.updateTOD();
    },
    updateTOD() {
      if (this.debounce.pd) {
        clearTimeout(this.debounce.pd);
        delete this.debounce.pd;
      }
      this.debounce.pd = setTimeout(() => {
        this.updateSelectedLayer(this.pd);
      }, 500);
    },
    async loadQuarterOptions() {
      try {
        let headers = this.getHeaders();
        
        const { data } = await axios.get(
          `${process.env.VUE_APP_PINNACLE_API}/v1/pulse-daypart-options`,
          {
            ...headers,
          }
        );
        this.quarter_options = data.records;
      } catch (err) {
        if (err && err.reponse && err.response.data && err.response.data.message) {
          console.log(err.response.data);
          if (!err.response.data.success && !err.response.data.statusCode) {
            console.log("refresh route");
            this.refreshToken().then((res) => {
              if (res.success && attempt < 5) {
                attempt++;
                //this can be re-enginerred to not be recursive, but I added a return in the other function just in case
                this.loadQuarterOptions();
              } else {
                ///this should push the user to login page to grab a new refresh token
              }
            });
          } else {
            this.err = err.response.data.errors[0].message;
            console.log({ err });
          }
        } else {
          console.log(err);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .activity-container {
    padding-bottom: 62px;
    .activity-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;

      label {
        font-size: 14px;
        color: #858EA7;
      }
      .options {
        border-bottom: 1px solid $border-color;
        padding: 16px 24px;

        .color-pill {
          width: 100%;
          height: 28px;
          border-radius: 4px;
        }
        .color-pill-labels {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
        }

        .quarters {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;

          select {
            padding: 10px 16px;
            border: 1px solid #CFD3DC;
            border-radius: 4px;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            background-image: url('pinnacle-lib/assets/icon/arrow-down-select.svg');
            background-repeat: no-repeat;
            background-position: calc(100% - 16px) center;
            background-size: 10px;
          }
        }

        .days-of-week {
          margin-bottom: 16px;

          .dow-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            label {
              margin: auto 0;
            }
          }
        }

        .hours {

          .hours-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            label {
              margin: auto 0;
            }
          }
        }
      }

      .no-border {
        border: none;
      }
    }
  }
</style>