<template>
  <b-modal @hide="handleModalHide" size="md" centered hide-footer scrollable :visible="visible">
    <template #modal-header>
      <div class="clone-modal-header">
        <div class="title-bar">
          <slot name="title"></slot>
          <h2 v-if="title">{{ title }}</h2>
        </div>
      </div>
    </template>
    <div class="clone-modal-body">
      <div class="modal-section">
        <div class="section-content">
          <WorldLoader></WorldLoader>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <br />
    </template>
  </b-modal>
</template>

<script>
import WorldLoader from './WorldLoader.vue';
export default {
  props: ['title', 'visible'],
  components: {WorldLoader},
  methods: {
    handleModalHide(evt) {
      evt.preventDefault();
    }
  }
}
</script>

<style lang="scss" scoped>
p,
h2 {
  margin: 0;
}

.clone-modal-header {
  display: flex;
  flex-direction: column;
  flex: 1;

  .modal-description {
    width: 65%;

    p {
      color: #858EA7;
    }
  }
}

.title-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;

  ::v-deep .title {
    color: #6E84A3;
  }

  ::v-deep h2 {
    font-weight: 700;
    flex: 1;
  }
}

.section-header {
  p {
    color: #6E84A3;
    margin-bottom: 0.3rem;
  }
}

::v-deep .modal-header,
::v-deep .modal-body {
  padding: 3rem !important;
}

::v-deep .modal-header {
  padding-bottom: 0 !important;
}

::v-deep .modal-body {
  padding-top: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clone-modal-body {
  padding-top: 0 !important;
}

.modal-section+.modal-section {
  margin-top: 2rem;
}
</style>