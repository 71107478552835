<template>
  <div class="m-4 allow-container">
    <ToggleOption label="Allow Large Polygons" subtext="Polygons must be less than 5 million square feet. Turn this field on to accept a larger polygon.">
      <b-checkbox v-model="allowLargePolygons" switch></b-checkbox>
    </ToggleOption>
  </div>
</template>

<script>
import { syncState } from "../../../utils/syncState.js";
import OptionsPanel from '../shared/OptionsPanel.vue';
import ToggleOption from '../shared/ToggleOption.vue';

export default {
  components: { ToggleOption },
  computed: {
    ...syncState({
      allowLargePolygons: "state.jobRequest.allowLargePolygons|setLargePoly"
    }, {context: this, defaultStore: '$sessionStore'})
  }
}
</script>

<style lang="scss" scoped>
.allow-container {
  width: 50%;
}
</style>