<template>
  <div class="filter-controls-container">
    <!-- Search bar -->
    <div class="searchbar-container">
      <b-form-input v-model="inputValue" placeholder="Search:" class="search-input"
        @change="submitSearch()"></b-form-input>
      <span class="search-icon-container">
        <img :src="require('pinnacle-lib/assets/icon/search-icon.svg')" alt="" />
      </span>
    </div>
    <!-- Company dropdown -->
    <b-dropdown @shown="dropdownShown" class="status-dropdown ml-3" :text="company.text">
      <div class="my-2 cmp-search-container">
      <b-input ref="companySearchInput" class="cmp-search-input" @change="submitCompanySearch" placeholder="Search a company"
          v-model="companyInputValue"></b-input>
      </div>
      <b-dropdown-divider v-if="companyFilters.length > 0"></b-dropdown-divider>
      <div v-for="company in companyFilters" :key="company.id">
        <b-dropdown-item @click="companyFilterChanged(company)">{{
            company.name
        }}</b-dropdown-item>
        <b-dropdown-divider v-if="company.id === '__MY_PROJECTS' && companies.length > 0"></b-dropdown-divider>
      </div>
    </b-dropdown>
    <!-- Filter by status -->
    <b-dropdown class="status-dropdown ml-3"
      :text="status.text === 'Filter By Status' ? status.text : `Status: ${status.text}`">
      <div v-for="friendlyName in Object.keys(REPORT_STATUS)" :key="friendlyName">
        <b-dropdown-item @click="statusFilterChanged(friendlyName)">{{
            friendlyName
        }}</b-dropdown-item>
        <b-dropdown-divider v-if="friendlyName === 'All'"></b-dropdown-divider>
      </div>
    </b-dropdown>
    <!-- Refresh -->
    <b-button @click="reload()" class="ml-3" style="background-color: #E3EBF6; color: #858ea7; border-width: 0px"
      size="md">
      Reload
    </b-button>
  </div>
</template>

<script>
import { syncState } from "../../../utils/syncState";
import { REPORT_STATUS } from "./ReportsTable.vue";

export default {
  data() {
    return {
      inputValue: "",
      companyInputValue: "",
      REPORT_STATUS
    };
  },
  computed: {
    ...syncState(
      {
        searchTerm: "state.reportFilters.searchTerm|setReportFeedSearchTerm",
        status: "state.reportFilters.status|setReportFeedStatus",
        shouldReload: "state.reportFilters.reload|setReportFeedReload",
        company: "state.reportFilters.company|setReportFeedCompanyFilter",
        companies: "state.reportFilters.companies",
        companySearchTerm: "state.reportFilters.companySearchTerm|setReportFeedCompanySearchTerm"
      },
      { context: this, defaultStore: "$store" }
    ),
    companyFilters() {
      return [{
        id: "__ALL",
        name: "My Company"
      },
      {
        id: "__MY_PROJECTS",
        name: "My Projects"
      },
      ...this.companies];
    }
  },
  watch: {
    inputValue() {
      if (this.inputValue === "") this.searchTerm = "";
    },
    companyInputValue() {
      if (this.companyInputValue === "") this.companySearchTerm = "";
    }
  },
  methods: {
    /**
     * Updates the filter store to trigger a report reload with the new status filter.
     */
    statusFilterChanged(text) {
      this.status = {
        text: text,
        filter: REPORT_STATUS[text]
      };
    },
    /**
     * Updates the filter store for the company dropdown filter.
     * @param {*} company 
     */
    companyFilterChanged(company) {
      if (company.id === "__ALL") {
        this.company = {
          text: "Filter By Company",
          filter: ""
        };
      } else {
        this.company = {
          text: company.name,
          filter: company.id
        };
      }
    },
    /**
     * Updates the filter store to trigger a report reload with the new search term.
     */
    submitSearch() {
      this.searchTerm = this.inputValue;
    },
    /**
     * Updates the filter store to trigger a companies reload with the new search term.
     */
    submitCompanySearch() {
      this.companySearchTerm = this.companyInputValue;
    },
    /**
     * Updates the store to trigger a report reload.
     */
    reload() {
      this.shouldReload = true;
    },
    dropdownShown() {
      this.$nextTick(() => {
        this.$refs.companySearchInput.focus();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.filter-controls-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  .status-dropdown {
    min-width: 227px;
    ::v-deep button {

      background-color: white;
      border: 1px solid #D2DDEC;
      color: #858ea7;
    }

    ::v-deep button:active {
      background-color: white;
      color: #858ea7;
    }

  }
}

.searchbar-container {
  position: relative;

  .search-input {
    width: 20vw;
    padding-right: 30px;
  }

  .search-input::placeholder {
    color: #858ea7 !important;
    // font-weight: 700;
  }

  .search-input::after {
    background-image: url("pinnacle-lib/assets/icon/search-icon.svg");
  }

  .search-icon-container {
    position: absolute;
    top: 7px;
    right: 7px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.cmp-search-container {
  display: flex;
  min-width: 225px;
  height: 100%;
  justify-content: center;
  align-items: center;

  .cmp-search-input {
    width: 80%;
    }
}
</style>