<template>
  <div>
    <ReportOptions>
      <TimePeriod
        :id="'ogs-report-options'"
        @timePeriodSeconds="setTimePeriodSeconds"
        :presetSecondsBefore="
          $sessionStore.state.jobRequest.locationReportOptions.ogsSecondsBefore
        "
        :presetSecondsAfter="
          $sessionStore.state.jobRequest.locationReportOptions.ogsSecondsAfter
        "
      />
    </ReportOptions>
    <ReportOptions>
      <b-form-group
        label="Optimal GeoSpace Input Type"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox-group
          id="checkbox-group-ogsReportInputTypes"
          v-model="locationReportOptions.ogsReportInputTypes"
          :options="ogsReportInputTypeOptions"
          :aria-describedby="ariaDescribedby"
          name="ogsReportInputTypes"
          stacked
          @change="setInputType()"
        ></b-form-checkbox-group>
      </b-form-group>

      <ToggleOption v-if="false" label="Use Density Thresholds">
        <b-checkbox
          v-model="locationReportOptions.densityMode"
          switch
        ></b-checkbox>
      </ToggleOption>

      <ToggleOption label="Use Equal Weight">
        <b-checkbox
          @change="setP2PWeight()"
          v-model="locationReportOptions.p2pEqualWeight"
          switch
        ></b-checkbox>
      </ToggleOption>
      <b-row
        ><b-col cols="3">
          <b-form-group
            id="input-group-2"
            label="Minimum Number of Visitors"
            label-for="input-minDevices"
          >
            <b-input-group append="visitors">
              <b-form-input
                id="input-minDevices"
                v-model="minDevices"
                @change="setMinDevice()"
                placeholder="Minimum Number of Visitors"
                type="number"
                min="1"
              ></b-form-input>
            </b-input-group>
          </b-form-group> </b-col
      ></b-row>

      <b-form-group label="% of Data Retained" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="btn-radios-maxRetained"
          v-model="locationReportOptions.maxRetained"
          :options="maxRetainedOptions"
          :aria-describedby="ariaDescribedby"
          button-variant="outline-primary"
          name="radios-btn-maxRetained"
          @change="setDataRetained()"
          buttons
        ></b-form-radio-group>
      </b-form-group>
      <div class="helper-text">
        <p>
          Set the amount of data points from visitors found in the polygon to
          develop the Optimal GeoSpace contour. Retaining a smaller percentage
          of Optimal GeoSpace data will reduce the size of the Optimal GeoSpace.
        </p>
      </div>
    </ReportOptions>
  </div>
</template>
<script>
import ReportOptions from "../shared/ReportOptions.vue";
import ToggleOption from "../shared/ToggleOption.vue";
import TimePeriod from "../TimePeriod.vue";

export default {
  components: { ReportOptions, ToggleOption, TimePeriod },
  data() {
    return {
      locationReportOptions: {
        ogsReportInputTypes:
          this.$sessionStore.state.jobRequest.locationReportOptions
            .ogsReportInputTypes,
        densityMode:
          this.$sessionStore.state.jobRequest.locationReportOptions.densityMode,
        maxRetained:
          this.$sessionStore.state.jobRequest.locationReportOptions.maxRetained,
        densityMinDevicesMedium:
          this.$sessionStore.state.jobRequest.locationReportOptions
            .densityMinDevicesMedium,
        densityMinDevicesHigh:
          this.$sessionStore.state.jobRequest.locationReportOptions
            .densityMinDevicesHigh,
        densityMinSquareMilesMedium:
          this.$sessionStore.state.jobRequest.locationReportOptions
            .densityMinSquareMilesMedium,
        ogsSecondsBefore:
          this.$sessionStore.state.jobRequest.locationReportOptions
            .ogsSecondsBefore,
        ogsSecondsAfter:
          this.$sessionStore.state.jobRequest.locationReportOptions
            .ogsSecondsAfter,
        p2pEqualWeight:
          this.$sessionStore.state.jobRequest.locationReportOptions
            .p2pEqualWeight,
      },
      minDevices: this.$sessionStore.state.jobRequest.minDevices,
      ogsReportInputTypeOptions: [
        { text: "Path-To-Purchase", value: "P2P" },
        { text: "Common Evening Location", value: "CEL" },
        { text: "Common Daytime Location", value: "CDL" },
      ],
      maxRetainedOptions: [
        { text: "60%", value: 0.6 },
        { text: "65%", value: 0.65 },
        { text: "70%", value: 0.7 },
        { text: "75%", value: 0.75 },
        { text: "80%", value: 0.8 },
        { text: "85%", value: 0.85 },
      ],
    };
  },
  watch: {},
  methods: {
    setTimePeriodSeconds(timePeriod) {
      // sets both screen date times based on inputs from time period component
      this.screenMinDateTime = timePeriod.beforeSeconds;
      this.screenMaxDateTime = timePeriod.afterSeconds;
      let timeObj = {
        ogsSecondsBefore: this.screenMinDateTime,
        ogsSecondsAfter: this.screenMaxDateTime,
      };
      this.$sessionStore.commit("setOGSTime", timeObj);
    },
    setDataRetained() {
      this.$sessionStore.commit(
        "setOGSMaxRetainted",
        this.locationReportOptions.maxRetained
      );
    },
    setInputType() {
      this.$sessionStore.commit(
        "setOGSOptions",
        this.locationReportOptions.ogsReportInputTypes
      );
    },
    setP2PWeight() {
      this.$sessionStore.commit(
        "setOGSWeight",
        this.locationReportOptions.p2pEqualWeight
      );
    },
    setMinDevice() {
      this.minDevices = ~~Math.max(0, parseInt(this.minDevices));
      this.$sessionStore.commit("setOGSMinDevice", this.minDevices);
    },
  },
};
</script>

<style lang="scss" scoped>
.time-period-container {
  margin-bottom: 2rem;
}
.helper-text {
  font-size: 14px;
  color: lighten(grey, 10%);
  width: 60%;
}
</style>

