
import PostProcessor from "@/services/map/postProcessor";
import DataService from "@/services/map/dataService";
import MapManager from "@/services/map/mapManager";
import { getTypeById, VisualizationType } from "@/services/map/types";

function sourceDataListener() {

  this.pendingSources.forEach(sourceId => {
    if (this.isSourceLoaded(sourceId)) {
      this.pendingSources.delete(sourceId);
      this.emit("loadsource", { sourceId });
    }
  });

}

export default class SourceManager extends MapManager {
  constructor(mapInstance, mapService) {
    super(mapInstance);
    this.sourceIds = new Set();
    this.pendingIds = {}; // Maps the source ID to an axios cancel token
    this.mapService = mapService;
    this.sourceDataListener = sourceDataListener.bind(this);
    this.dataService = new DataService();
  }


  isSourceLoaded(sourceId) {

    const srcLoaded = this.map.getSource(sourceId) !== undefined;
    if (srcLoaded && !this.sourceIds.has(sourceId)) this.sourceIds.add(sourceId);
    return srcLoaded;

  }

  _tryAddSource(id, source) {
    try {
      this.map.addSource(id, source);
    } catch (err) {
      console.log({ err });
    }
  }

  addTileSource({id, filters}, overwrite=false) {

    return new Promise((resolve, reject) => {
      if (this.isSourceLoaded(id)) {
        if (overwrite) this.removeSource(id);
        else return reject({code: 0, err: `Source ${id} already exists, set overwrite=true`});
      }

      const url = this.dataService.fetchTileURL({filters});

      if (url === undefined) {
        return resolve(undefined);
      }

      let source = {
        type: 'vector',
        tiles: [url]
      };

      const type = getTypeById(id);
      if (type.isCommunity) {
        source.minzoom = 9;
      }

      if (type.isCluster) {
        source.cluster = true;
      }
      if (type.minzoom) {
        source.minzoom = type.minzoom;
      }
      if (type.maxzoom) {
        source.maxzoom = type.maxzoom; 
      }

      this._tryAddSource(id, source);
      this.listenForSourceLoaded(id, resolve);

    });

  }

  addBatchSource({id, filters, locations}, overwrite=false) {
    return new Promise((resolve, reject) => {
      if (this.isSourceLoaded(id)) {
        if (overwrite) this.removeSource(id);
        else return reject({code: 0, err: `Source ${id} already exists, set overwrite=true`});
      }

      this.dataService.fetchBatchData({id, locations, filters}).then(raw => {
        if (raw === undefined) {
          return resolve(undefined);
        }

        const data = PostProcessor.processConflicts(raw, locations);
        let source = {
          type: 'geojson',
          data
        };

        this._tryAddSource(id, source);
        this.listenForSourceLoaded(id, resolve);
        
      }).catch(err => {
        return reject({code: -2, err});
      });
    });
  }

  addSource({id, filters, location}, overwrite=false) {

    return new Promise((resolve, reject) => {
      
      if (this.isSourceLoaded(id)) {
        if (overwrite) this.removeSource(id);
        else return reject({code: 0, err: `Source ${id} already exists, set overwrite=true`});
      }

      this.dataService.fetchData({id, location, filters}).then(data => {

        if (data === undefined) {
          // Request was cancelled
          return resolve(undefined);
        }

        let source = {
          type: 'geojson',
          data
        };
  
        this._tryAddSource(id, source);
        this.listenForSourceLoaded(id, resolve);

      }).catch(err => {
        return reject({code: -2, err});
      });
  
    });
  }

  listenForSourceLoaded(id, resolve) {
    const helper = (event) => {
      if (this.isSourceLoaded(event.sourceId) && event.sourceId === id) {
        this.emit('addsource', { id });
        this.sourceIds.add(event.sourceId);
        this.map.off('sourcedata', helper);
        return resolve(id);
      }
    };
    this.map.on('sourcedata', helper);
  }


  removeSource(sourceId) {

    if (this.mapService.layerMgr.isLayerLoaded(sourceId)) {
      this.mapService.layerMgr.removeLayer(sourceId);
    }
    if (this.isSourceLoaded(sourceId)) this.map.removeSource(sourceId);
    this.sourceIds.delete(sourceId);
    this.emit("removesource", { id: sourceId});

  }

}