<template>
  <div class="tab-container">
    <b-btn :disabled="tab.disabled" @click="tabClicked(tab)" :class="{'tab-active': isActive(tab)}" class="tab-btn" v-for="tab in tabs.slice(0, 2)" :key="tab.title">
      <img :src="tab.icon" :style="tab.style" class="icon" :alt="`Icon for ${tab.title} tab`" />
      <p>{{tab.title}}</p>
    </b-btn>
  </div>
</template>

<script>
// Only supports 2 tabs for now
export default {
  props: ['tabs'],
  methods: {
    isActive(tab) {
      return this.$route.path.includes(tab.path);
    },
    tabClicked(tab) {
      this.$emit('changed', tab);
    }
  }
}
</script>

<style lang="scss" scoped>

.tab-container {
  display: flex;

  ::v-deep .btn:focus {
    box-shadow: none !important;
  }

  .tab-btn {
    border-radius: 0.2rem;
    border: 1px solid $border-color;
    background-color: white;
    color: #4A577C;
    font-weight: 700;
    width: 144px;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0 !important;
      margin-right: 1rem;
    }

    img {
      height: 16px;
      width: 16px;
      margin-right: 0.375rem;
    }
  }
  .tab-btn:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .tab-btn:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }

  .tab-btn.tab-active {
    color: white;
    background-color: $blue;
    border: 0;

    img {
      filter: brightness(0) invert(1);
    }
  }
}

</style>