<template>
  <div class="comparison-container">
    <PseudoBreadcrumbs
      :origin="origin"
      currentTitle="Comparison"
    ></PseudoBreadcrumbs>
    <div class="comparison-info-container">
      <ComparisonInfo></ComparisonInfo>
    </div>
    <div class="button-container">
      <b-button
        :disabled="locations.length === 0"
        class="secondary-button"
        @click="
          $router.push({
            path: `/study/${locations
              .map((loc) => loc.id.toString())
              .join(',')}/charts`,
          })
        "
      >
        Open Insights
      </b-button>
    </div>
    <div class="toolbar-container">
      <div class="toolbar-header">Contents</div>
      <div class="toolbar-action">
        <!-- <span @click="removeAll()">Remove All</span> -->
      </div>
    </div>
    <div class="comparison-item-container">
      <div
        v-for="location of locations"
        :key="location.id"
        class="comparison-item"
      >
        <div 
          class="location-container"
          @click="clickSelectedLocation(location)"
        >
          <div class="location-icon">
            <div
              class="brand-img brand-avatar brand-avatar-48"
              :style="getImgContainerStyle(location)"
            >
              <img
                :src="getCategoryAvatar(location)"
                :style="getImgStyle(location)"
              />
            </div>
          </div>
          <div class="location-info">
            <div>
              <p class="name-bold">{{ location.name | str_limit }}</p>
              <p>{{ location.address | str_limit }}</p>
            </div>
            <div>
              <img 
                class="play-remove"
                :src="require('pinnacle-lib/assets/icon/play-check.svg')" 
                :id="`playlist-remove-${location.id}`"
                @click="removeLocation(location);"
                @click.stop="''"
              />
              <b-tooltip
                :target="`playlist-remove-${location.id}`"
                triggers="hover"
                :noninteractive="true"
                delay="0"
                :no-fade="true"
              >
                Remove from comparison
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PseudoBreadcrumbs from "@/components/partials/PseudoBreadcrumbs.vue";
import ComparisonInfo from "@/components/partials/ComparisonInfo.vue";
import { bus } from '@/main';

export default {
  components: { PseudoBreadcrumbs, ComparisonInfo },
  computed: {
    locations() {
      return this.$sessionStore.state.study.locations;
    },
    origin() {
      if (this.lastCrumb.name === 'search-details') {
        return {
          path: this.lastCrumb.fullPath,
          title: 'Place'
        }
      } else {
        return {
          name: 'search',
          title: 'Search'
        }
      }
    },
    lastCrumb() {
      return this.$sessionStore.state.crumbs.trail[this.$sessionStore.state.crumbs.trail.length - 2];
    },
    showNearbyPlaces: {
      get() {
        return this.$sessionStore.state.search.showNearbyPlaces;
      },
      set(val) {
        this.$sessionStore.commit('setShowNearbyPlaces', val);
      }
    },
  },
  methods: {
    removeAll() {
      this.$sessionStore.commit("setLocations", []);
    },
    cancel() {
      this.$sessionStore.commit("setLocations", []);
      let crumb;
      const lastTwo = this.$sessionStore.state.crumbs.trail.slice(-2);
      if (lastTwo[0].fullPath === lastTwo[1].fullPath) {
        crumb = Object.assign(
          {}, 
          this.$sessionStore.state.crumbs.trail.slice(-3)[0]
        );
      } else {
        crumb = Object.assign(
          {},
          this.$sessionStore.state.crumbs.trail.slice(-2)[0]
        );
      }
      this.$router.push(crumb);
      bus.$emit("focusSearchLocations");
    },
    removeLocation(location) {
      // Remove the location
        const newLocations = this.locations.filter(
          (loc) => loc.id !== location.id
        );
        this.$sessionStore.commit("setLocations", newLocations);
    },
    focusComparisonLocations() {
      bus.$emit("focusComparisonLocations");
    },
    clickSelectedLocation(location) {
      this.$router.push({ name: 'search-details', query: { place: btoa(JSON.stringify(location)) }})
    }
  },
  mounted() {
    if (this.showNearbyPlaces) this.showNearbyPlaces = false;
    bus.$emit("focusComparisonLocations");
  }
};
</script>

<style lang="scss" scoped>
.comparison-container {
  background-color: white;
  position: relative;
  width: 100%;

  .button-container {
    width: 100%;
    // padding: 0 24px 16px 24px;
    padding: 0 1.5rem 16px 1.5rem;
    display: flex;
    justify-content: space-between;

    .secondary-button {
      width: 100%;
      border-radius: 4px;
      border-width: 0;
      padding-top: 12px;
      padding-bottom: 12px;
      background: #2c7be5;
      color: #ffffff;
    }

    .secondary-button:disabled {
      background-color: lightgray;
    }
  }

  .toolbar-container {
    border-right: 1px solid $border-color;
    color: rgba(110, 132, 163, 1);
    background-color: rgba(249, 250, 252, 1) !important;
    // flex: 1;
    height: 56px;
    display: flex;

    .toolbar-header {
      width: 50%;
      font-weight: 450;
      padding-left: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .toolbar-action {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 24px;
      span {
        font-weight: 100;
        cursor: pointer;
      }
    }
  }

  .comparison-item-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    margin: 0;

    .comparison-item {
      border-bottom: 1px solid $border-color;
      background-color: white;
      height: 80px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .location-container {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 16px;
        gap: 10px;

        .location-icon {
          flex: 1;
          margin-right: 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .location-info {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;

          .name-bold {
            font-weight: bold;
            font-size: 16px;
            color: #253561;
          }

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            font-weight: bold;
            font-size: 14px;
            color: #6E84A3;

          }

          .play-remove:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
