import { getEnvironment, getOrigin } from "./environmentsUtil";

/**
 * Checks localStorage to see if the user needs to refresh their access token.
 * @returns {boolean} Returns true if the access token is expired.
 */
export function tokenNeedsRefresh() {
  const tokenExpiration = localStorage.getItem(
    "accessTokenExpiration"
  );
  const currentTimeSeconds = Math.floor(new Date().getTime() / 1000);
  return (tokenExpiration === null || parseInt(tokenExpiration) < currentTimeSeconds);
}

/**
 * Retrieves the API authentication headers from localStorage.
 * @returns {{headers: {Authorization: string | undefined, refreshToken: string | undefined}}} Returns the auth headers.
 */
export function getHeaders() {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      refreshToken: refreshToken
    },
  };
}

/**
 * Returns the user's permissions.
 * @param {*} http Pass an http client if you are outside the current Vue context.
 * @returns {{success: boolean, permissions: Array | undefined, companyRoles: Array | undefined, error: any | undefined}} User permissions and roles.
 */
export async function getPermissions(http) {
  let headers = getHeaders();
  let client = http;
  if (http === undefined) client = this.$http;
  try {
    if (tokenNeedsRefresh()) {
      await refreshToken(http);
      headers = getHeaders();
    }
    const res = await http.post(`${process.env.VUE_APP_PINNACLE_API}/v1/get-perm`, {}, headers);
    if (res.data) {
      return {success: true, ...res.data};
    }
    return {success: false, error: { msg: "API did not return any permissions." }};
  } catch (err) {
    return {success: false, error: err};
  }
}

/**
 * Refreshes a user's access token.
 * @param {*} http Pass an http client if you are outside the current Vue context.
 * @returns {{success: boolean, error?: any}} Returns an object that indicates success.
 */
export async function refreshToken(http) {
  const headers = getHeaders();
  let client = http;
  if (http === undefined) client = this.$http;
  try {
    const res = await client.post(`${process.env.VUE_APP_PINNACLE_API}/v1/refresh-token`, {}, headers);
    if (res.data) {
      localStorage.setItem('accessToken', res.data.accessToken);
      localStorage.setItem('accessTokenExpiration', Math.floor(new Date().getTime() / 1000) + parseInt(res.data.expiresIn));
      return {
        success: true
      };
    }
    return {
      success: false,
      error: {
        msg: 'API did not return data.'
      }
    };
  } catch (err) {
    if (err.response.status === 403 || err.response.status === 400) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("accessTokenExpiration");

      const environment = getEnvironment();
      const origin = getOrigin("pinnacle", environment);
      window.location.origin.replace(origin + '/login');
    }
    return {
      success: false,
      error: err
    };
  }
}