<template>
  <div class="absolute-container">
    <div class="banner-container">
      <div class="comparison-info-container">
        <ComparisonInfo size="sm" :dark="false"></ComparisonInfo>
      </div>
      <div class="button-container">
        <b-button @click="openComparison()" class="open-button">View</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ComparisonInfo from "@/components/partials/ComparisonInfo.vue";

export default {
  components: { ComparisonInfo },
  methods: {
    cancelComparison() {
      this.$sessionStore.commit('setLocations', []);
      this.$router.push({ path: '/explore/search', query });
    },
    openComparison() {
      this.$router.push({ path: '/explore/comparison' });
    }
  }
}
</script>

<style lang="scss" scoped>
.absolute-container {

  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), white);

  .banner-container {

    position: relative;
    margin-bottom: 36px;
    margin-right: 24px;
    margin-left: 24px;
    background-color: white;
    border: 0.5px solid rgba(207, 211, 220, 1);
    display: flex;  
    padding: 16px;
    border-radius: 4px;

    .button-container {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: flex-end;
      flex: 1;

      button {
        width: 45%;
      }
      
      .open-button {
        background-color: $blue;
        color: white;
        border-radius: 4px;
        border: none;
        font-weight: 600;
      }
    }

  }

}
</style>