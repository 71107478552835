<template>
  <!-- Maintenance modal -->
  <b-modal
    v-model="isModalOpen"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="maintenanceAlertModal">
      <p v-if="isInternetDown">
        Please check your internet connection to continue using Pinnacle.
      </p>
      <p v-else-if="!isInternetDown && redshiftMaintenanceWindow">
        Pinnacle is down for weekly scheduled maintenance and will resume
        service around {{ redshiftMaintenanceEnd }}.
      </p>
      <p v-else>
        Pinnacle is currently experiencing internal server issues.
      </p>
      <p>
        Please contact
        <a :href="`mailto:${ Constants.support_email }`">{{ Constants.support_email }}</a> if you
        need assistance.
      </p>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";
import "moment-timezone";
import { ConstantsService } from "@/services/constants.js";

export default {
  mixins: [ConstantsService],
  data() {
    return {
      isModalOpen: false,
      isInternetDown: false,
      isAPIDown: false,
      redshiftMaintenanceWindow: false,
      redshiftMaintenanceEnd: null,
      checkIntervalId: null,
    };
  },
  mounted() {
    clearInterval(this.checkInteralId);
    setTimeout(this.checkForOutage, 1000);
    this.checkIntervalId = setInterval(this.checkForOutage, 30000);
  },
  beforeDestroy() {
    clearInterval(this.checkIntervalId);
  },
  methods: {
    async checkForOutage() {
      this.isInternetDown = !window.navigator.onLine || await this.checkInternet();
      this.isAPIDown = await this.checkAPIHealth();
      this.isModalOpen = this.isInternetDown || this.isAPIDown;
    },
    async checkInternet() {
      try {
        await this.$http.head(`${window.location.origin}/logo.png`);
        return false;
      } catch {
        return true;
      }
    },
    async checkAPIHealth() {
      try {
        let t = new Date().getTime();
        await this.$http.get(`/health?t=${t}`);
        return false;
      } catch (err) {
        console.log(err);
        let pst_time = moment().tz("America/Los_Angeles");
        console.log("API Maintenance");
        //console.log(pst_time);
        console.log("day", pst_time.isoWeekday());
        console.log("hour", pst_time.hours());

        // check the current time and see if it falls within redshiftMaintenanceWindow
        if (pst_time.isoWeekday() == 7 && pst_time.hours() == 2) {
          this.redshiftMaintenanceWindow = true;
          let local_time = pst_time.hours(pst_time.hours() + 1).local();
          this.redshiftMaintenanceEnd = local_time.format("hh:00 A");
        } else {
          this.redshiftMaintenanceWindow = false;
        }
        return true;
      }
    },
  },
};
</script>
<style lang='scss' scope>
::v-deep .modal-backdrop {
  box-shadow: inset 0 0 2000px hwb(0deg 100% 0% / 50%) !important;
  background-color: rgb(22 34 68 / 50%) !important;
  backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
}
</style>