import cleanCrumb from "@/utils/cleanCrumb";

export default {
  state: {
    root: null,
    trail: [],
    lastViewedStudy: undefined
  },
  mutations: {
    addCrumb(state, crumb) {
      if (crumb.meta.crumbName === undefined) return;
      const newTrail = Object.assign([], state.trail);
      const lastCrumb = state.trail[state.trail.length - 1];
      if (state.trail.length > 0) {
        if (lastCrumb.meta.crumbName === crumb.meta.crumbName && crumb.meta.crumbName !== "Comparison") {
          newTrail.pop();
        }
      }
      if (newTrail.length > 10) newTrail.shift();
      const cleanRoute = cleanCrumb(crumb);

      // If the previous crumb was in comparison and the next crumb is in preview, add the lastViewedStudy
      if (lastCrumb !== undefined && lastCrumb.path.includes('/study/') && cleanRoute.path.includes('/preview/')) {
        state.lastViewedStudy = lastCrumb;
      }

      // If the user navigates away from study or preview, wipe the lastViewedStudy
      if (!cleanRoute.path.includes('/study/') && !cleanRoute.path.includes('/preview/')) {
        delete state.lastViewedStudy;
      }

      newTrail.push(cleanRoute);
      state.trail = newTrail;
    },
    popCrumb(state) {
      const newTrail = Object.assign([], state.trail);
      newTrail.pop();
      state.trail = newTrail;
    }
  },
};
