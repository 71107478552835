<template>
  <div class="search-map-container">
    <SearchMap />
  </div>
</template>

<script>
import SearchMap from "@/components/shared/SearchMap";

export default {
  components: {
    SearchMap,
  },
};
</script>

<style lang="scss" scoped>

.search-map-container {
  // width: 75%;
  width: 100%;
}

</style>
