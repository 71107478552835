const baseColors = ['#2c7be5', '#ff595e', '#8ac926', '#ffca3a', '#9980FA'];
const previewColors = ['#2c7be5', '#95aac9', '#95aac9', '#95aac9', '#95aac9']
const colorRamps = [
  [{"value":0,"color":"#C5FBF6"},{"value":0.05,"color":"#B1E2F6"},{"value":0.10,"color":"#9EC9F7"},{"value":0.15,"color":"#8BB0F7"},{"value":0.20,"color":"#7798F8"},{"value":0.25,"color":"#647FF8"},{"value":0.30,"color":"#5166F9"},{"value":0.40,"color":"#3D4EF9"},{"value":0.60,"color":"#2A35FA"},{"value":0.80,"color":"#171CFA"},{"value":1,"color":"#0404FB"}],
  [{"value":0,"color":"#FFA2A2"},{"value":0.05,"color":"#F79191"},{"value":0.10,"color":"#F08181"},{"value":0.15,"color":"#E97171"},{"value":0.20,"color":"#E16161"},{"value":0.25,"color":"#DA5151"},{"value":0.3,"color":"#D34040"},{"value":0.4,"color":"#CB3030"},{"value":0.6,"color":"#C42020"},{"value":0.8,"color":"#BD1010"},{"value":1,"color":"#B60000"}],
  [{"value":0,"color":"#ABFFA2"},{"value":0.05,"color":"#99EF91"},{"value":0.1,"color":"#88E081"},{"value":0.15,"color":"#77D071"},{"value":0.2,"color":"#66C161"},{"value":0.25,"color":"#55B251"},{"value":0.3,"color":"#44A240"},{"value":0.4,"color":"#339330"},{"value":0.6,"color":"#228320"},{"value":0.8,"color":"#117410"},{"value":1,"color":"#006500"}],
  [{"value":0,"color":"#FFCC94"},{"value":0.05,"color":"#FAC385"},{"value":0.1,"color":"#F5BB76"},{"value":0.15,"color":"#F0B267"},{"value":0.2,"color":"#ECAA58"},{"value":0.25,"color":"#E7A14A"},{"value":0.3,"color":"#E2993B"},{"value":0.4,"color":"#DE902C"},{"value":0.6,"color":"#D9881D"},{"value":0.8,"color":"#D47F0E"},{"value":1,"color":"#D07700"}],
  [{"value":0,"color":"#D7A2FF"},{"value":0.05,"color":"#CA91F1"},{"value":0.1,"color":"#BD81E3"},{"value":0.15,"color":"#B071D6"},{"value":0.2,"color":"#A361C8"},{"value":0.25,"color":"#9651BB"},{"value":0.3,"color":"#8940AD"},{"value":0.4,"color":"#7C309F"},{"value":0.6,"color":"#6F2092"},{"value":0.8,"color":"#621084"},{"value":1,"color":"#550077"}]
];

export default {
  state: {
    locations: [],
    previews: [],
    disabledIds: {},
    loading: true,
    dateRange: {},
    chart:{
      est: {},
      day: {}, 
      week: {},
      dwell: {},
      origin: {},
    },
    avgAddress: '',
    downloading: false,
    previewLocation: [],
    showMedian: false
  },
  mutations: {
    setLocations: (state, payload) => {
      state.locations = payload.map((location, i) => {
        location.shortenedName =  location.name.length >= 23 ? location.name.substring(0, 23) + "..." : location.name;
        location.baseColor = baseColors[i];
        location.colorRamp = colorRamps[i];
        return location;
      });
    },
    setPreviews: (state, payload) => {
      state.previews = payload.map((location, i) => {
        location.shortenedName =  location.name.length >= 23 ? location.name.substring(0, 23) + "..." : location.name;
        location.baseColor = previewColors[i];
        location.colorRamp = colorRamps[i];
        return location;
      });
    },
    setDisabledIds: (state, payload) => {
      state.disabledIds = payload;
    },
    setShowMedian: (state, payload) => (state.showMedian = payload),
    setLoading: (state, payload) => (state.loading = payload),
    setAvg: (state, payload) => (state.name = payload),
    setDateRange: (state, dateRange) => (state.dateRange = dateRange),
    setStudyDefault: (state) => {
      state.locations = [];
      state.previews = [];
      state. disabledIds = {};
      state.loading = true;
      state.dateRange = {};
    },
    setEstChart: (state, payload) => {
      state.chart.est = payload;
    },
    setDayChart: (state, payload) => {
      state.chart.day = payload;
    },
    setWeekChart: (state, payload) => {
      state.chart.week = payload;
    },
    setDwellChart: (state, payload) => {
      state.chart.dwell = payload;
    },
    setOriginChart: (state, payload) => {
      state.chart.origin = payload;
    },
    setAvgAddress: (state, payload) =>{
      state.avgAddress = payload;
    },
    setDownloading: (state, payload) =>{
      state.downloading = payload;
    },
    set1Preview: (state, payload) =>{
      state.previewLocation = payload;
    },
  },
};
