<template>
  <b-modal id="modal-data-agreement" 
        ok-title="Agree"
        :no-close-on-backdrop="true"
        :hide-header-close="true"
        :centered="true"
        @ok="onDataAgreementAccept"
        @cancel="onDataAgreementCancel">
      <h4>Sensitive Data Agreement</h4>
      <p class="my-4">You shall not use the Services to target sensitive communities or to track/identify data subjects at sensitive locations, which includes without limitation, such communities or locations from where Sensitive Personal Data may be collected, and thereafter processed or stored. Unauthorized or unlawful use of {{ Constants.company_name }}'s data may result in account suspension and/or termination.</p>
      <p>
        For more information please review our
        <a :href="Constants.sensitive_data_policy_link" target="_blank">Sensitive Data Policy</a>.
      </p>
    </b-modal>
</template>

<script>
import { ConstantsService } from "@/services/constants.js";
export default {
  mixins: [ConstantsService],
  props: {
    cancel: Function,
    agree: Function,
  },
  data() {
    return {
    }
  },
  methods: {
    openModal() {
      let accepted = this.$sessionStore.state.dataAgreement.accepted;
      console.log("Data Agreement", accepted);
      if (!accepted) {
        this.$bvModal.show("modal-data-agreement");
      }
    },
    onDataAgreementAccept() {
      console.log('Data Agreement Accept Clicked');

      this.$sessionStore.commit("setDataAgreementAccepted", true);
      this.agree();
    },
    onDataAgreementCancel() {
      console.log('Data Agreement Cancel');
      this.$sessionStore.commit("setDataAgreementAccepted", false);
      this.cancel();
    },
  }
};

</script>

<style scoped>

::v-deep #modal-data-agreement .modal-footer {
  margin-top:-40px;
  margin-bottom:25px;
}

</style>