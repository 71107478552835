import chroma from 'chroma-js';

export default class PostProcessor {

  static processConflicts(geojsons, locations) {

    const cellCounts = {};
    let maxCount = 0;

    for (const locationId in geojsons) {
      const geojson = geojsons[locationId];
      for (const feature of geojson.features) {
        cellCounts[feature.id] = feature.id in cellCounts 
          ? cellCounts[feature.id] + feature.properties.raw_count 
          : feature.properties.raw_count;
        if (cellCounts[feature.id] > maxCount) maxCount = cellCounts[feature.id];
      }
    }

    const newFeatures = {};

    for (const locationId in geojsons) {
      const geojson = geojsons[locationId];
      for (const feature of geojson.features) {
        if (!(feature.id in newFeatures)) {
          newFeatures[feature.id] = {
            cellShare: {},
            density: {},
            geometry: feature.geometry
          };
        }
        newFeatures[feature.id].density = cellCounts[feature.id] / maxCount;
        newFeatures[feature.id].cellShare[locationId] = feature.properties.raw_count / cellCounts[feature.id];
      }
    }

    const fc = {
      type: 'FeatureCollection',
      features: []
    };

    const locationKeys = locations.map(loc => loc.id);
    const colorRamps = locations.map(loc => loc.colorRamp);

    for (const featureId in newFeatures) {
      const feature = newFeatures[featureId];
      const csKeys = Object.keys(feature.cellShare).sort((a, b) => {
        return locationKeys.indexOf(a) - locationKeys.indexOf(b);
      });
      let cellShares = new Array(colorRamps.length).fill(0);
      for (const csKey of csKeys) {
        const idx = locationKeys.findIndex(key => key === csKey);
        cellShares[idx] = feature.cellShare[csKey];
      }
      feature.cellShare = cellShares;
      const color = chroma.average(colorRamps.map(colorRamp => {
        const closest = colorRamp.reduce((prev, cur) => {
          return Math.abs(cur.value - feature.density) < Math.abs(prev.value - feature.density) ? cur : prev;
        });
        return closest.color;
      }), 'rgb', cellShares);

      fc.features.push({
        geometry: feature.geometry,
        id: featureId,
        properties: {
          id: featureId,
          cell_share: feature.cellShare,
          color: color.hex(),
          density: feature.density,
          weighted_density: feature.density * 1000
        }
      });
    }

    console.log(fc);
    return fc;
  }

}