<template>
  <div id="dwell" class="card chart-card">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <b-skeleton-wrapper :loadingA="isLoadingToLocation">
          <template #loadingA>
            <b-skeleton height="50px" width="50px" class="mr-3"></b-skeleton>
          </template>
          <div class="icon">
            <img :src="require('pinnacle-lib/assets/icon/stopwatch-square.svg')" alt="stopwatch" />
          </div>
        </b-skeleton-wrapper>
        <b-skeleton-wrapper :loadingB="isLoadingToLocation">
          <template #loadingB>
            <b-skeleton width="100px"></b-skeleton>
            <b-skeleton width="400px"></b-skeleton>
          </template>
          <div>
            <h2>Visit Length</h2>
            <p>Average time of a visit</p>
          </div>
        </b-skeleton-wrapper>
      </div>
      <b-dropdown
        id="dwell-options"
        ref="dwellOptions"
        style="margin-right: -20px"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        data-html2canvas-ignore="true"
      >
        <template #button-content>
          <img :src="require('pinnacle-lib/assets/icon/elip.svg')" alt="foot" />
        </template>
        <li>
          <b-dropdown id="export-submenu" class="dropdown-item" variant="link">
            <template #button-content> Export </template>
            <b-dropdown-item @click="saveChart('Time Spent in Place', '#dwell')"
              >Download Image</b-dropdown-item
            >
            <b-dropdown-item @click="saveData('Time Spent in Place', 'dwell')"
              >Download Data</b-dropdown-item
            >
          </b-dropdown>
        </li>
      </b-dropdown>
    </div>
    <b-skeleton-wrapper :loading="isLoadingToLocation">
      <template #loading>
        <div
          class="d-flex align-items-center justify-content-between chart-nav"
        >
          <b-skeleton height="24px" width="120px"></b-skeleton>
          <div class="d-flex">
            <b-skeleton height="24px" width="60px" class="mr-3"></b-skeleton>
            <b-skeleton height="24px" width="60px"></b-skeleton>
          </div>
        </div>
      </template>
      <span class="line"></span>
      <div class="d-flex align-items-center justify-content-between chart-nav">
        <name-card
          chart-type="dwell"
          v-bind:insufficient-data-ids="insufficientDataIds"
        ></name-card>
      </div>
    </b-skeleton-wrapper>
    <div class="charts-container-min">
      <b-skeleton
        class="originD"
        v-if="isLoading"
        type="image"
        style="min-height: 400px; border-radius: 6px"
      ></b-skeleton>
      <div
        v-bind:class="{ originD: isLoading }"
        id="horizontalBarChart"
        v-bind:style="isLoading ? 'z-index: -99' : 'z-index: 1'"
      >
        <div>
          <HorizontalBarChart
            ref="dwellTimeHorizontalBarChart"
            :chart-data="dwell.bar.data"
            :options="dwell.bar.option"
            :class="`${isLoading ? 'opacity-25' : 'opacity-100'}`"
          />
        </div>
        <div style="position: absolute; top: 35%; left: 50%"></div>
      </div>
      <div v-if="showWarn" class="warning-container">
        <div class="warning">Data unavailable for the selected dates</div>
      </div>
    </div>
  </div>
</template>
<script>
import HorizontalBarChart from "./HorizontalBarChart";
import NameCard from "../partials/NameCard.vue";
import { bus } from "@/main";
import {
  getBarGraphOptionsNoTime,
} from "@/config/chart";
import MultiDropdownMixin from "pinnacle-lib/mixins/multiDropdown.js";
import ChartsDownloadMixin from "../../services/charts/download.js";
import ChartUtilMixin from "../../services/charts/chartUtil.js";
import moment from "moment";

export default {
  components: {
    HorizontalBarChart,
    NameCard,
  },
  mixins: [MultiDropdownMixin, ChartsDownloadMixin, ChartUtilMixin],
  data() {
    return {
      attempt: false,
      dwell: {
        bar: {
          data: null,
          option: null,
        },
        result: {
          median: "",
        },
      },
      dwellData: [],
      dwellResult: [],
      dwellMedianData: null,
      dwellMedianResult: "",
      isLoading: true,
      insufficientDataIds: [],
      show: [true, true, true, true, true],
      showWarn: false,
    };
  },
  props: {
    filterString: {
      type: String,
    },
    id: {
      type: Array,
    },
  },
  methods: {
    saveCSV() {
      const rows = [["Place", "Time Spent in Place (minutes)"]];
      let tempRow = [
        [
          `${
            this.medianName
          }: ${this.$sessionStore.state.study.avgAddress.replace(/,/gi, "")}`,
          this.dwellMedianData,
        ],
      ];
      let dataD = this.dwell.bar.data.datasets[0].data;
      let dataL = this.dwell.bar.data.datasets[0].label;
      let tempAdd =
        this.$route.path.split("/")[1] === "preview"
          ? this.$sessionStore.state.study.previews
          : this.$sessionStore.state.study.locations;
      let j = 0;
      if (this.showMedian) {
        j = 1;
      }
      for (let i = 0; i < dataD.length - j; i++) {
        tempRow.push([
          `${dataL[i + j].replace(/,/gi, "")} ${tempAdd[i].address.replace(
            /,/gi,
            ""
          )}`,
          dataD[i + j],
        ]);
      }
      let rew = rows.concat(tempRow);
      let csvContent = "";
      rew.forEach(function (rowArray) {
        let row = rowArray.join("\t");
        csvContent += row + "\r\n";
      });
      this.$sessionStore.commit("setDwellChart", csvContent);
    },
    async transformData(dwellEntries, medianEntries) {
      this.dwellData = [];
      this.dwellResult = [];
      this.dwellMedianData = [];
      this.dwellMedianResult = [];
      // this.dwellData = (Math.trunc(dwellHours) * 60) + Math.trunc(dwellMinutes);
      for (let i = 0; i < this.id.length; i++) {
        /// this code block is for converting the format from seconds to a more readabile format
        if (dwellEntries[this.id[i]] && dwellEntries[this.id[i]][0]) {
          const ms = dwellEntries[this.id[i]][0]["dwell-time-average"] * 1000;
          const dwell = moment.utc(ms);
          const dwellHours = dwell.hours();
          const dwellMinutes = dwell.minutes();
          let tempResult = `${Math.trunc(dwellHours)}h ${Math.trunc(
            dwellMinutes
          )}min`;

          let tempData = Math.trunc(dwellHours) * 60 + Math.trunc(dwellMinutes);
          this.dwellData.push(tempData);
          this.dwellResult.push(tempResult);
        } else {
          this.dwellData.push(null);
          this.dwellResult.push(null);
        }
      }
      const ms = medianEntries[0]["dwell-time-average"] * 1000;
      const dwellMedian = moment.utc(ms);
      const dwellMedianHours = dwellMedian.hours();
      const dwellMedianMinutes = dwellMedian.minutes();

      let tempMedianResult = `${Math.trunc(dwellMedianHours)}h ${Math.trunc(
        dwellMedianMinutes
      )}min`;
      let tempMedianData =
        Math.trunc(dwellMedianHours) * 60 + Math.trunc(dwellMedianMinutes);
      this.dwellMedianData = tempMedianData;
      this.dwellMedianResult = tempMedianResult;
    },
    setData(res) {
      const [dwellEntries, median] = res;
      if (!Object.entries(dwellEntries).length) return;
      let color = ["#2c7be5", "#ff595e", "#8ac926", "#ffca3a", "#9980FA"];
      let tempColor = color.filter((item, i) => {
        let id = this.id[i];
        if (this.show[i] !== false && !this.insufficientDataIds.includes(id)) {
          return true;
        }
      });
      this.transformData(dwellEntries, median);
      let tempC = this.dwellData.filter((item, i) => {
        let id = this.id[i];
        if (this.show[i] !== false && !this.insufficientDataIds.includes(id)) {
          return true;
        }
      });
      if (this.showMedian) tempC.unshift(this.dwellMedianData);
      let tempOC = this.location
        .filter((item, i) => {
          if (
            this.show[i] !== false &&
            !this.insufficientDataIds.includes(item.id)
          ) {
            return true;
          }
        })
        .map((value) => value.shortenedName);
      if (this.showMedian) tempOC.unshift(this.medianName);
      let tempRes = this.dwellResult.filter((item, i) => {
        let id = this.id[i];
        if (this.show[i] !== false && !this.insufficientDataIds.includes(id)) {
          return true;
        }
      });
      if (this.showMedian) tempRes.unshift(this.dwellMedianResult);
      if (this.showMedian) tempColor.unshift("#B1C2D9");
      this.dwell = {
        bar: {
          data: {
            labels: tempOC,
            datasets: [
              {
                label: tempOC,
                data: tempC,
                backgroundColor: tempColor,
                borderRadius: 2,
                borderSkipped: false,
                barThickness: 26,
                maxBarThickness: 28,
                datalabels: {
                  anchor: "end",
                  align: "right",
                  offset: 50,
                  formatter: (value, context) => {
                    return tempRes[context.dataIndex];
                  },
                },
              },
            ],
          },
          option: getBarGraphOptionsNoTime({
            labelStringXAxes: "Minutes",
            labelStringYAxes: "Places",
            hideYAxes: true,
            barThickness: 26,
            layoutPadding: {
              right: 120,
            },
          }),
          options: {
            tooltips: {
              enabled: false,
            },
            title: {
              display: true,
              text: "Places",
              position: "left",
              fontStyle: "normal",
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    // minRotation: 30,
                    // maxRotation: 30,
                    maxTicksLimit: 6,
                    stepSize: 1,
                    callback: function (label) {
                      const wordArray = label
                        .split(" ")
                        .filter((word) => word !== "-");
                      const finalLabel = [];
                      for (let i = 0; i < wordArray.length; i += 2) {
                        if (wordArray[i + 1]) {
                          const wordPair =
                            wordArray[i] + " " + wordArray[i + 1];
                          finalLabel.push(wordPair);
                        } else {
                          finalLabel.push(wordArray[i]);
                        }
                      }
                      return finalLabel;
                    },
                    // callback: function(label) {
                    //   if (/\s/.test(label)) {
                    //     return label.split(/(.{20})/).filter((O) => O);
                    //   } else {
                    //     return label;
                    //   }
                    // }
                  },
                  //barThickness: 26,
                  //maxBarThickness: 28,
                },
              ],
              xAxes: [
                {
                  ticks: {
                    stepSize: 1,
                    maxTicksLimit: 6,
                  },
                  categoryPercentage: 0.3,
                  barPercentage: 1,
                  scaleLabel: {
                    display: true,
                    labelString: "Minutes",
                    position: "center",
                    fontStyle: "bold",
                  },
                },
              ],
            },
            title: {
              display: true,
              text: "Places",
              position: "left",
              fontStyle: "bold",
            },
            maintainAspectRatio: false,
            responsive: true,
            responsiveAnimationDuration: 5,
            layout: {
              padding: {
                right: 120,
              },
            },
          },
        },
      };
      this.saveCSV();
      this.isLoading = false;
      //      setTimeout(() => {
      //        this.$refs.dwellTimeHorizontalBarChart.renderChart(
      //          this.dwell.bar.data,
      //          this.dwell.bar.options
      //        );
      //      }, 500);
    },
    loadChart() {
      this.requestData().then((res) => {
        //console.log(res);
        this.isLoading = false;
        this.setData(res);
      });
    },
    async requestData() {
      this.showWarn = false;
      const promises = [this.getChartData(`/v2/dwell-time/${this.$route.params.ids}`)];
      const responses = await Promise.allSettled(promises);
      let check = responses[0].value.data.series_data;
      if (Object.keys(check).length === 0) {
        console.log("no dwell data");
        this.showWarn = true;
        this.isLoading = false;
        return;
      }
      // Check Insufficient Data
      let threshold = 0.66;
      let insufficientDataIds =
        responses[0].value.data.meta_data.insufficientDataIds;
      let scope = this;
      if (insufficientDataIds) {
        this.insufficientDataIds = insufficientDataIds;
      }
      let dwell = responses[0].value.data;
      // let max = day.aggregation_data.max_line;
      // let min = day.aggregation_data.min_line;
      let median = dwell.aggregation_data.median_data
        ? dwell.aggregation_data.median_data
        : [];
      let dwellEntries = dwell.series_data;
      return [dwellEntries, median];
    },
  },
  mounted() {
    this.registerMultiDropdown(this.$refs.dwellOptions);
    let ids = this.id;
    let tempArr = [true, true, true, true, true];
    Object.entries(this.disabledIds).forEach(function (o) {
      if (ids.indexOf(o[0]) !== undefined) {
        tempArr[ids.indexOf(o[0])] = false;
      }
    });
    this.show = tempArr;
    //this.loadChart();  // Wait until filters event before populating chart
    bus.$on("onHover", (res) => {
      let index = this.id.indexOf(res.selected);
      let color = ["#2c7be5", "#ff595e", "#8ac926", "#ffca3a", "#9980FA"];
      let altColor = [
        "rgba(44, 123, 229, 0.2)",
        "rgba(255, 89, 94, 0.2)",
        "rgba(138, 201, 38, 0.2)",
        "rgba(255, 202, 58, 0.2)",
        "rgba(153, 128, 250, 0.2)",
      ];
      let medianColor = "rgba(177, 194, 217, 0.6)";
      if (res.selected === "median") {
        medianColor = "rgba(177, 194, 217, 1)";
      }
      if (res.value) {
        //On Mouse Enter
        altColor[index] = color[index];
        let tempColor = altColor.filter((item, i) => {
          let id = this.id[i];
          if (
            this.show[i] !== false &&
            !this.insufficientDataIds.includes(id)
          ) {
            return true;
          }
        });
        if (this.showMedian) tempColor.unshift(medianColor);
        this.dwell.bar.data.datasets[0].backgroundColor = tempColor;
        this.$refs.dwellTimeHorizontalBarChart.renderChart(
          this.dwell.bar.data,
          this.dwell.bar.options
        );
      } else {
        //On Mouse Leave
        let tempColor = color.filter((item, i) => {
          let id = this.id[i];
          if (
            this.show[i] !== false &&
            !this.insufficientDataIds.includes(id)
          ) {
            return true;
          }
        });
        if (this.showMedian) tempColor.unshift("#B1C2D9");
        this.dwell.bar.data.datasets[0].backgroundColor = tempColor;
        this.$refs.dwellTimeHorizontalBarChart.renderChart(
          this.dwell.bar.data,
          this.dwell.bar.options
        );
      }
    });
    bus.$on("locationsListChanged", (res) => {
      if (!this.dwell.bar.data) return false;
      if (res.locationId === "median") {
        this.$sessionStore.commit("setShowMedian", res.selected);
        //this.showMedian = res.selected;
      }
      let color = ["#2c7be5", "#ff595e", "#8ac926", "#ffca3a", "#9980FA"];
      let index = this.id.indexOf(res.locationId);
      this.show[index] = res.selected;
      let tempColor = color.filter((item, i) => {
        let id = this.id[i];
        if (this.show[i] !== false && !this.insufficientDataIds.includes(id)) {
          return true;
        }
      });
      let temp = this.dwellData.filter((item, i) => {
        let id = this.id[i];
        if (this.show[i] !== false && !this.insufficientDataIds.includes(id)) {
          return true;
        }
      });
      let tempO = this.location
        .filter(
          (item, i) =>
            this.show[i] !== false &&
            !this.insufficientDataIds.includes(item.id)
        )
        .map((value) => value.name);
      let tempRes = this.dwellResult.filter((item, i) => {
        let id = this.id[i];
        if (this.show[i] !== false && !this.insufficientDataIds.includes(id)) {
          return true;
        }
      });
      //this is the code to shift the median line in
      if (this.showMedian) tempRes.unshift(this.dwellMedianResult);
      if (this.showMedian) temp.unshift(this.dwellMedianData);
      if (this.showMedian) tempO.unshift(this.medianName);
      if (this.showMedian) tempColor.unshift("#B1C2D9");
      this.dwell.bar.data.datasets[0].backgroundColor = tempColor;
      this.dwell.bar.data.datasets[0].data = temp;
      this.dwell.bar.data.labels = tempO;
      //need to reuse formatter to get chart end labels
      this.dwell.bar.data.datasets[0].datalabels = {
        anchor: "end",
        align: "right",
        offset: 50,
        formatter: (value, context) => {
          return tempRes[context.dataIndex];
        },
      };
      this.$refs.dwellTimeHorizontalBarChart.renderChart(
        this.dwell.bar.data,
        this.dwell.bar.options
      );
    });
    bus.$on("filters", (res) => {
      //this.filterString = res;
      this.isLoading = true;
      if (this.isLoadingToLocation === false) {
        this.loadChart();
      } else {
        this.attempt = true;
      }
    });
  },
  computed: {
    showMedian() {
      return this.$sessionStore.state.study.showMedian;
    },
    location() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split("/")[1] === "preview"
        ? this.$sessionStore.state.study.previews
        : this.$sessionStore.state.study.locations;
    },
    isLoadingToLocation() {
      return this.$sessionStore.state.study.loading;
    },
    disabledIds() {
      return this.$sessionStore.state.study.disabledIds;
    },
    medianName() {
      return this.$sessionStore.state.study.name;
    },
  },
  watch: {
    isLoadingToLocation() {
      if (!this.isLoadingToLocation && this.attempt) {
        this.requestData().then((res) => {
          this.setData(res);
        });
      }
    },
  },
  // beforeDestroy() {
  //   this.unwatch();
  // },
};
</script>
<style lang="scss" scoped>
@import "./chartStyles.scss";
</style>