<template>
  <div class="d-flex align-items-start flex-column pinnacle-card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PCard' 
}
</script>

<style scoped lang="scss">
.pinnacle-card {
  border: 1px solid #e3ebf6;
  border-radius: 6px;
  margin: 1em 0 !important;
  padding: 1em !important;
  background: white;
  flex-direction: column;
}
</style>