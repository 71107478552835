import { tokenNeedsRefresh, getHeaders, refreshToken } from "../utils/permissionsUtil";

export default {
  methods: {
    hasRole(roleName, userRoles = []) {
      return userRoles.some(role => {
        return role.name === roleName;
      });
    },
    hasRoles(roleNames, userRoles = [], matchAll = true) {
      const arr = roleNames.map(roleName => (this.hasRole(roleName, userRoles)));
      if (!matchAll) return arr.find(hasRole => (hasRole === true)) !== undefined;
      return arr.every(hasRole => {
        return hasRole;
      });
    },
    /**
     * Checks if a user has a permission.
     * @param {string} permissionName The permission name.
     * @param {*} userPermissions The permissions object contained in the user object returned from Permissions API.
     * @returns {boolean} Returns true if the user has the permission or is a Super Admin.
     */
    hasPermission(permissionName, userPermissions = []) {
      return userPermissions.some(permission => {
        return permission.name === permissionName || permission.name === 'Super Admin';
      });
    },
    /**
     * Checks if a user has an array of permissions.
     * @param {Array<string>} permissionNames An array of permission names. 
     * @param {*} userPermissions The permissions object contained in the user object returned from Permissions API.
     * @param {boolean} matchAll Matches every permission if true. If false, will only look to match one permission.
     * @returns {boolean} Returns true if the user has permission or is a Super Admin.
     */
    hasPermissions(permissionNames, userPermissions = [], matchAll = true) {
      const arr = permissionNames.map((permission) => (this.hasPermission(permission, userPermissions)));
      if (!matchAll) return arr.find(hasPermission => (hasPermission === true)) !== undefined;
      return arr.every(hasPermission => {
        return hasPermission;
      });
    },
    /**
     * Fetches the user object from the vuex-persist localStorage variable.
     * @returns The user object from Permissions API.
     */
    getUserFromStorage() {
      const vuex = JSON.parse(localStorage.getItem('vuex'));
      if (vuex === null) return { success: false, user: undefined };
      if (vuex.user === undefined) return { success: false, user: undefined };
      return { success: true, user: vuex.user };
    },
    tokenNeedsRefresh,
    getHeaders,
    refreshToken
  },
  computed: {
    routeRoles() {
      return {
        'home': ['Reports', 'Reports Admin', 'Reports Free']
      }
    },
    routePermissions() {
      return {
        'home': ['Pinnacle Bulk Export Access', 'Card Report'],
        'explore': ['Pinnacle Enterprise Access', 'Pinnacle Mid Tier Access', 'Pinnacle Free Access'],
        // 'export-data': ['Pinnacle Bulk Export Access']
      };
    }
  }
}