<template>
  <div @click="click" class="close-button" :style="customStyle" :class="noBorder ? '' : 'border'">
    <img :src="require('pinnacle-lib/assets/icon/x-icon.svg')" />
  </div>
</template>

<script>

import { bus } from "@/main";

export default {
  props: ["id", "noBorder", "customStyle"],
  methods: {
    click() {
      bus.$emit("closeButton", {id: this.id});
      this.$emit("closeButton");
    }
  }
}
</script>

<style lang="scss" scoped>
.close-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  border-radius: 8px;
  cursor: pointer;
img {
  width: 16px;
  height: 16px;
  }
}

.border {
  border: 1px solid darken($border-color, 1);
}
</style>
