<template>
  <div class="calculator-container d-flex">
    <input 
      v-model="submitValue"
      type="number"
      class="submit-value"
      :state="isValid"
    />
    <div 
      :id="calculatorId"
      title="Calculator"
    >
      <inline-svg :src="require('pinnacle-lib/assets/icon/calculator.svg')" />
    </div>
    <b-popover 
      :target="calculatorId" 
      triggers="click" 
      placement="right"
      custom-class="custom-popover"
      :show.sync="show"
      title="Seconds Calculator"
    >
      <hr>
      <div class="calculator-input d-flex justify-content-center">
        <label for="hours">Hours</label>
        <input 
          type="number"
          v-model="hours"
          name="hours"
        />
      </div>
      <div class="calculator-input d-flex justify-content-center">
        <label for="minutes">Minutes</label>
        <input 
          type="number"
          v-model="minutes"
          name="minutes"
        />
      </div>
      <div class="calculator-input d-flex justify-content-center">
        <label for="seconds">Seconds</label>
        <input 
          type="number"
          v-model="seconds"
          name="seconds"
        />
      </div>
      <hr>
      <div class="calculator-input d-flex justify-content-center">
        <label for="total-seconds">Total Seconds</label>
        <input 
          type="number"
          v-model="totalSeconds"
          name="total-seconds"
        />   
      </div>
      <div class="d-flex justify-content-end">
        <b-button
          target="calculator"
          @click="closeCalculator"
        >Cancel</b-button>
        <b-button
          target="calculator"
          @click="closeCalculator(); updateSeconds()"
          variant="primary"
          class="ml-2"
        >Use Total</b-button>               
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  
  props: [
    'id', // should be passed down from Time Period id prop
    'buttonInput', // if button is pressed on Time Period, the value is passed here
    'buttonPressCheck', // checks if button was pressed in Time Period
    'role', // denotes whether calculator is changing before or after value in Time Period
    'presetSeconds', // sets the starting value to be something other than 0
  ],
  data() {
    const presetSeconds = this.presetSeconds;
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
      totalSeconds: 0,
      submitValue: presetSeconds,
      show: false,
    }
  },
  methods: {
    closeCalculator() {
      this.show = !this.show;
    },
    updateSeconds() {
      this.submitValue = this.totalSeconds;
    },
    setSubmitValue(val) {
      this.submitValue = val;
    }
  },
  computed: {
    timeValues() {
      // links hours, minutes, and seconds so that the watcher can recalculate if any values change
      const { hours, minutes, seconds } = this;
      return {
        hours,
        minutes,
        seconds,
      }
    },
    calculatorId() {
      // generates unique id for this calculator based on props passed down
      return `${this.role}:${this.id}`;
    },
    isValid() {
      try {
        const valid = Number.parseInt(this.totalSeconds) > 0;
        return valid;
      } catch {
        return false;
      }
    }
  },
  watch: {
    // the following three watchers prevent negative values from being entered
    hours() {
      if (this.hours < 0) {
        this.hours = 0;
      }
    },
    minutes() {
      if (this.minutes < 0) {
        this.minutes = 0;
      }
    },
    seconds() {
      if (this.seconds < 0) {
        this.seconds = 0;
      }
    },
    timeValues() {
      // sets totalSeconds equal to the sum of all values converted to seconds
      const hoursToSeconds = this.hours * 3600;
      const minutesToSeconds = this.minutes * 60;
      const seconds = this.seconds * 1;
      this.totalSeconds = hoursToSeconds + minutesToSeconds + seconds;
    },
    totalSeconds() {
      // prevents totalSeconds from having a negative value
      
      if (this.totalSeconds < 0) {
        this.totalSeconds = 0;
      }
      // takes totalSeconds and converts to the number of hours, minutes, and seconds
      const hours = Math.floor(this.totalSeconds / 3600);
      const hoursRemainder = this.totalSeconds % 3600;
      const minutes = Math.floor(hoursRemainder / 60);
      const minutesRemainder = hoursRemainder % 60;
      const seconds = minutesRemainder;

      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
    },
    submitValue() {
      // prevents submitValue from being negative
      if (this.submitValue < 0) {
        this.submitValue = 0;
      }
      // updates totalSeconds when submitValue is updated
      this.totalSeconds = this.submitValue;
      // emits event that parent component can listen for
      this.$emit('submitTotalSeconds', this.submitValue, this.role);
    },
    buttonPressCheck() {
      // watches for TimePeriod component button press and changes submit value to the button value
      this.submitValue = this.buttonInput;
    }
  }
}
</script>

<style lang="scss" scoped>
.calculator-container {
  width: 100%;
}
.submit-value {
  border: none;
  border-bottom: 1px solid black;
  width: 75px;
}
.calculator-input {
  width: 100%;
  margin: 20px 0;
  input {
    width: 25%;
  }
  label {
    width: 35%;
  }
}
.custom-popover {
  width: 300px;
  height: 325px;
  max-width: 300px;
  max-height: 500px;
}
</style>