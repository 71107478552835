import { sessionStore, persistingStore } from '../.././store/index'
import Constants from "@/services/constants.js";

function extrapolated() {
    let explore = persistingStore.state.user.permissions.find(item => item.name === "Extrapolated Visit Estimation")
    let superAdmin = persistingStore.state.user.permissions.find(item => item.name === "Super Admin")
    if( explore || superAdmin ){
      return true;
    }
    else{
      return false;
    }
  };

  function uniques() {
    let explore = persistingStore.state.user.permissions.find(item => item.name === "Uniques Report")
    let superAdmin = persistingStore.state.user.permissions.find(item => item.name === "Super Admin")
    if( explore || superAdmin ){
      return true;
    }
    else{
      return false;
    }
  };
  

export let locVisReportsDetails = [
    {
        name: "Pin Dataset",
        id: "PIN_REPORT",
        type: "pip",
        selectionId: "PIN_REPORT",
        reportSection: ["location"],
        permission: "Pin Report",
        question: "Which devices were in this location, where exactly were they, and when were they there?",
        addTitle: "Pin Dataset",
        addInfo: "This dataset returns which visitors were seen inside the polygon, the time they were there, and the exact lat/long of the visit."
    },
    {
        name: "Uniques Dataset",
        id: "UNIQUES_REPORT",
        type: "pip",
        selectionId: "UNIQUES_REPORT",
        reportSection: ["location"],
        permission: "Uniques Report",
        question: "Which unique visitors were in this location?",
        addTitle: "Uniques Dataset",
        addInfo: "This dataset returns ONLY a list of visitors seen inside the polygon during a set time frame, and is designed for use for audience building."
    },
    {
        name: "Zero Point Dataset",
        id: "ZERO_POINT_REPORT",
        type: "pip",
        selectionId: "ZERO_POINT_REPORT",
        reportSection: ["location"],
        permission: "Zero Point Report",
        question: "Which visitors were in this location during a specific timeframe, and when were they there?",
        addTitle: "Zero Point Dataset",
        addInfo: "This dataset returns a polygon name/ID, UM ID, and a timestamp of the visit."
    },
    {
        name: "Cross-Visitation Dataset",
        id: "CROSS_SHOPPING_REPORT",
        type: "pip",
        selectionId: "CROSS_SHOPPING_REPORT",
        reportSection: ["location"],
        permission: "Cross-Visitation Report",
        question: "How many visitors went to multiple locations during the same timeframe?",
        addTitle: "Cross-Visitation Report",
        addInfo: "This dataset returns a matrix where each cell represents the number of visitors seen in the two locations represented by the corresponding row and column during a set timeframe. The dataset is limited to 100 locations."
    }
];

export let footTrafficReportsDetails = [
    {
        name: "Daily Visit Count Dataset",
        id: "DAILY_VISIT_COUNT_REPORT",
        type: "ft",
        selectionId: "DAILY_VISIT_COUNT_REPORT",
        reportSection: ["location"],
        permission: "Daily Visit Count Report",
        question: "How many visitors were in this location in a specific timeframe?",
        addTitle: "Daily Visit Count Dataset",
        addInfo: "This dataset gives a day-by-day breakdown of total count of visitors to a set of locations."
    },
    // {
    //     name: "Estimated Visits 2.0",
    //     id: "ESTIMATED_VISITS_2_REPORT",
    //     type: "ft",
    //     selectionId: "ESTIMATED_VISITS_REPORT",
    //     permission: "Estimated Visits",
    //     reportSection: ["location"],
    //     addTitle: "Estimated Visits 2.0 Dataset",
    //     question: "What is the daily estimated amount of actual visits this location received?",
    //     addInfo: "This dataset uses a demographically-balanced panel of devices, a proprietary device continuity scoring mechanism, and a industry that you select for the dataset.",
    //     addInfo2: "Please note that this dataset is valid from January 1, 2019 to 5 days before the current day, and currently only supported in the United States, Canada, Australia and New Zealand."
    // },
    // {
    //     name: "Extrapolated Visit Estimation",
    //     id: "ESTIMATED_VISITORS_REPORT",
    //     type: "ft",
    //     selectionId: "ESTIMATED_VISITORS_REPORT",
    //     reportSection: ["location"],
    //     permission: "Extrapolated Visit Estimation",
    //     question: "What is an estimated amount of real world visits/visitors this location received?",
    //     addTitle: "Extrapolated Visit Estimation",
    //     addInfo: "This dataset uses the visit behavior of a panel of visitors who have opted-in to having their devices share data on a persistent basis in the background. Using this panel’s visit behavior, the dataset uses a straightforward extrapolation model to estimate the number of ‘real-world’ visitors/visits to a location. This dataset is an estimate only and is not intended to replace a technology like beacons to track actual visitors.",
    //     addInfo2: "Please note that this dataset is ONLY available in the United States."

    // },
    // {
    //     name: "Heartbeat Dataset",
    //     id: "HEARTBEAT_REPORT",
    //     type: "ft",
    //     selectionId: "HEARTBEAT_REPORT",
    //     reportSection: ["location"],
    //     permission: "Heartbeat Report",
    //     question: "How is a store doing over time? What are foot traffic trends, baselined to overall mobile data levels?",
    //     addTitle: "Heartbeat Dataset",
    //     addInfo: "This dataset gives a day-by-day breakdown of foot traffic to a set of locations.",
    //     addInfo2: "Please note that this estimation is restricted to USA and Canada only. Given its nature of calculating baselines to an index, this dataset can only be run for dates from January 2018 until the end of the previous month."
    // },

    {
        name: "Visitor Count Dataset",
        id: "VISITOR_COUNT_REPORT",
        type: "ft",
        selectionId: "VISITOR_COUNT_REPORT",
        reportSection: ["location"],
        permission: "Visitor Count Report",
        question: "How many visitors were in this location in a specific timeframe?",
        addTitle: "Visitor Count Dataset",
        addInfo: "This dataset returns the total count of visitors and visits in a set timeframe, for each included polygon."
    },
    {} // for some reason need 3+ items in this array
];

export let CECDLocationReports = [
    {
        name: "Common Evening and Daytime Expanded Dataset",
        id: "BASE_CEL_CDL_REPORT",
        type: "cl",
        selectionId: "EXPANDED_CEL_CDL_REPORT_LOCATION",
        reportSection: ["location"],
        permission: "Vista UI",
        question: "What are the common daytime and evening locations of the visitors observed within this location during a specific timeframe?",
        addTitle: "CEL/CDL Expanded",
        // addInfo: "For visitors seen in a particular polygon during a set timeframe, this report returns locations where these visitors spend the majority of their time during the evening (CEL: Common Evening Location) and during the day (CDL: Common Daytime Location). These are high confidence locations that are not available for all visitors. The Expanded version of this report automatically includes boundary information for over 175 countries and territories, a summary file containing this data, and the distance traveled from the CEL/CDL to the study location."
        addInfo: "For visitors seen in a particular polygon during a set timeframe, this dataset returns locations where these visitors spend the majority of their time during the evening (CEL: Common Evening Location) and during the day (CDL: Common Daytime Location). These are high confidence locations that are not available for all visitors.",
        formats: {
            'EXPANDED_CEL_CDL_REPORT': {
                id: 'EXPANDED_CEL_CDL_REPORT',
                addTitle: 'EXPANDED STANDARD DATASET',
                name: 'Expanded Standard Dataset',
                question: 'What are the common evening and daytime locations of the visitors observed within this location during a specific timeframe?',
                addInfo: 'For visitors seen in a particular polygon during a set timeframe, this dataset returns locations where these visitors spend the majority of their time during the evening (CEL: Common Evening Location) and during the day (CDL: Common Daytime Location). These are high confidence locations that are not available for all visitors.',
                addInfo2: 'The Expanded version of this dataset automatically includes boundary information for over 175 countries and territories, a summary file containing this data, and the distance traveled from the CEL/CDL to the study location.'
            },
            'EXPANDED_CEL_CDL_DETAILED_REPORT': {
                id: 'EXPANDED_CEL_CDL_DETAILED_REPORT',
                addTitle: 'EXPANDED DETAILED DATASET',
                name: 'Expanded Detailed Dataset',
                question: 'What are the common evening and daytime locations of the visitors observed within this location during a specific timeframe?',
                addInfo: 'For visitors seen in a particular polygon during a set timeframe, this dataset returns locations where these visitors spend the majority of their time during the evening (CEL: Common Evening Location) and during the day (CDL: Common Daytime Location). These are high confidence locations that are not available for all visitors.',
                addInfo2:'The Expanded version of this dataset automatically includes boundary information for over 175 countries and territories, a summary file containing this data, and the distance traveled from the CEL/CDL to the study location.'
            },
            'CEL_CDL_DISTANCE_REPORT': {
                id: 'CEL_CDL_DISTANCE_REPORT',
                name: 'Legacy Distance Dataset',
                addTitle: 'LEGACY DISTANCE DATASET',
                question: 'What are the common evening and daytime locations of the visitors observed within this location during a specific timeframe?',
                addInfo: 'For visitors seen in a particular polygon during a set timeframe, this dataset returns locations where these visitors spend the majority of their time during the evening (CEL: Common Evening Location) and during the day (CDL: Common Daytime Location). This also calculates the distance from the CEL/CDL point to the centroid of each polygon in the input geojson. CEL is a good proxy for home locations. These are high confidence locations that are not available for all visitors.'
            }, 
            'CEL_CDL_LOCATION_REPORT': {
                id: 'CEL_CDL_LOCATION_REPORT',
                name: 'Legacy Location Dataset',
                addTitle: '',
                question: 'What are the common evening and daytime locations of the visitors observed within this location during a specific timeframe?',
                addInfo: ''
            }
        }
    },
    {
        name: "Resident Worker Dataset",
        id: "RESIDENT_WORKER_REPORT",
        type: "cl",
        selectionId: "RESIDENT_WORKER_REPORT",
        reportSection: ["location"],
        permission: "Resident Worker Report",
        question: "Which visitors are people who live and/or work in this location, and where do they work/live?",
        addTitle: "Resident Worker Dataset",
        addInfo: "This dataset returns information on people who live and work in a particular location, including Common Evening and Daytime Location information."
    },
    {
        name: "Visitors Home Dataset",
        id: "VISITORS_HOME_REPORT",
        type: "cl",
        selectionId: "VISITORS_HOME_REPORT",
        reportSection: ["location"],
        permission: "Visitors Home Report",
        question: "What is the common evening location of all visitors to a polygon?",
        addTitle: "Visitor Home Dataset",
        addInfo: "This dataset will provide all visitors and, if available, the Common Evening Location for each, as well as which polygon was visited."
    },
    {
        name: "Generator Dataset",
        id: "GENERATOR_REPORT",
        type: "cl",
        selectionId: "GENERATOR_REPORT",
        reportSection: ["location"],
        permission: "Generator Report",
        question: "What is the likely point of origin (evening and/or daytime) for visitors of this location, based on the last 7 days of observation points?",
        addTitle: "Generator Dataset",
        addInfo: "This is similar to Common Evening Location and Daytime Location dataset, but is based on the last 7 days of data. It is much lower confidence than Common Evening Location and Daytime Location data, but generally will have more visitors listed."
    },
];

export let visitorBehavioralReports = [
    {
        name: "Audience Affinity Dataset",
        id: "AUDIENCE_REPORT",
        type: "did",
        selectionId: "AUDIENCE_REPORT_LOCATION",
        reportSection: ["location"],
        permission: "Audience Affinity Report",
        question: "For visitors seen in this location during a set timeframe, what audiences do they belong to? What are their behaviors or interests?",
        addTitle: "Audience Affinity Dataset",
        addInfo: "This dataset allows you to analyze the audience membership of visitors that have been seen in a location. It returns a list of audiences that polygon visitors are in, as well as the count and percentage of visitors that are in the audience."
    },
    {
        name: "Demographics Dataset",
        id: "DEMOGRAPHICS_REPORT",
        type: "did",
        selectionId: "DEMOGRAPHICS_REPORT_LOCATION",
        reportSection: ["location"],
        permission: "Demographics Report",
        question: "For people who visit a location, what are their demographics by census block group, based on their Common Evening Location?",
        addTitle: "Demographics Dataset",
        addInfo: "This dataset returns demographic information of the visitors seen in a particular location over a set timeframe based on census data. This data will be provided by census block and by polygon, as well as a summary. Please note that this dataset is currently restricted to data from the USA and Australia. If you would like data from other countries, please contact your salesperson."
    },
    {
        name: "GeoSocial Affinity Dataset",
        id: "GEOSOCIAL_AFFINITY_REPORT",
        type: "did",
        // type: "cl",
        selectionId: "GEOSOCIAL_AFFINITY_REPORT_LOCATION",
        reportSection: ["location"],
        permission: "GeoSocial Affinity Report",
        question: "For visitors seen in this location during a set timeframe, what GeoSocial segment do they belong to?",
        addTitle: "GeoSocial Affinity Dataset",
        addInfo: "This dataset gives you the ability to narrow in on observed visitors within a POI or retail location and discover dominant and uniquely significant social media segments who are frequenting the location. Please note that this dataset is only available in the USA."
    },
    {
        name: "Location Affinity Dataset",
        id: "LOCATION_AFFINITY_REPORT",
        type: "did",
        // type: "cl",
        selectionId: "LOCATION_AFFINITY_REPORT_LOCATION",
        reportSection: ["location"],
        permission: "Location Affinity Report",
        question: "For visitors seen in this location during a set timeframe, what locations do they often or rarely visit?",
        addTitle: "Location Affinity Dataset",
        addInfo: "This dataset provides information on a visitor's location visit behavior- specifically what kind of locations they over/under index to. Please note that this dataset is not recommended for locations outside of the USA and Canada."
    }

];

export let p2pReports = [
    {
        name: "Pathing Dataset",
        id: "PATHING_REPORT",
        type: "p2p",
        subtype: "PATHING",
        selectionId: "PATHING_REPORT",
        reportSection: ["location"],
        permission: "PathingX Report",
        question: "Where was someone before and after they were seen in this location?",
        addTitle: "Pathing X",
        addInfo: "This dataset provides information on where a visitor was leading up to and after a visit to a polygon as well as when it was seen in that observation point.",
        formats: {
            'PATHING_X_REPORT': {
                id: 'PATHING_X_REPORT',
                addTitle: 'Pathing X',
                name: 'Pathing X Dataset',
                question: 'Where was someone before and after they were seen in this location?',
                addInfo: 'This dataset provides information on where a visitor was leading up to and after a visit to a polygon as well as when it was seen in that observation point.',
            },
            'PATHING_X_CONTEXT_REPORT': {
                id: 'PATHING_X_CONTEXT_REPORT',
                addTitle: 'Pathing X with Context',
                name: 'Pathing X with Context Dataset',
                question: 'Where was someone before and after they were seen in this location?',
                addInfo: 'This version of the PathingX dataset returns information on where a visitor was before their visit, including any custom context POI polygon a visitor was seen in. Please note that this dataset is not recommended for locations outside of the USA and Canada.',
            },
            'PATHING_X_CONTEXT_ONLY_REPORT': {
                id: 'PATHING_X_CONTEXT_ONLY_REPORT',
                addTitle: 'Pathing X with Context Only',
                name: 'Pathing X with Context Only Dataset',
                question: 'Where was someone before and after they were seen in this location?',
                addInfo: 'This version of the PathingX dataset lists only those observation points that are within a custom context POI polygon.',
            },
            'PRE_POST_REPORT': {
                id: 'PRE_POST_REPORT',
                addTitle: 'Pre-Post',
                name: 'Pathing Pre Post Dataset',
                question: 'Where was someone before and after they were seen in this location? Provide all locations.',
                addInfo: 'This dataset provides all points a visitor was seen in the time before and after visiting a polygon. The timeframe before and after the visit is set by the user.',
            },
            'PRE_POST_SAMPLED_REPORT': {
                id: 'PRE_POST_SAMPLED_REPORT',
                addTitle: 'Pre-Post Sampled',
                name: 'Pathing Pre Post Sampled Dataset',
                question: 'Where was someone before and after they were seen in this location? Provide a sample.',
                addInfo: 'This dataset provides a sample observation point of where a visitor was seen in the time before and after visiting a polygon. The timeframe before and after the visit is set by the user.',
            },
            'FEEDER_REPORT': {
                id: 'FEEDER_REPORT',
                addTitle: 'Feeder',
                name: 'Pathing Feeder Dataset',
                question: 'What kind of locations do people visit before and after visiting this location?',
                addInfo: 'This dataset provides an output of where visitors go before and after visiting a particular polygon. The results include information on polygons, sector, and sub-sector. Please note that this dataset is not recommended for locations outside of the USA.',
            },
        }
    }, 
    {
        name: "Clustering Dataset",
        id: "CLUSTER_REPORT",
        type: "p2p",
        subtype: "CLUSTER",
        selectionId: "CLUSTER_REPORT",
        reportSection: ["location", "device"],
        permission: "Clustering Report",
        question: "",
        addTitle: "",
        addInfo: "Identifies clusters using contour shapes of areas where visitors, from a set of locations, visit regardless of whether the shape is a POI or some other location."
    },
];

export let locationReportsDetails = [
    {
        name: "Optimal GeoSpace",
        id: "OGS",
        type: "loc",
        selectionId: "OGS",
        reportSection: ["location"],
        permission: "OGS Reporting",
        //example: example_base_url + "OGS.zip",
        question: "What is the trade area for this location? Where do location customers come from?",
        addTitle: "Optimal GeoSpace",
        addInfo: "This dataset can be run using any combination of Path-To-Purchase, Common Evening Location, and Common Daytime Location data to determine a draw area or a trade area for a location."
    },
    {
        name: "Dwell Time Dataset",
        id: "DWELL_TIME",
        type: "loc",
        selectionId: "DWELL_TIME",
        reportSection: ["location"],
        permission: "Dwell Time Report",
        question: "This dataset will generate inner and outer dwell times for this location(s), as well as a median dwell time for all locations in the set.",
        addTitle: "Dwell Time Dataset",
        addInfo: `By default, this dataset excludes employees.  More information about the details of this dataset can be obtained from your ${ Constants.company_name } contact.`
    }
];

export const LegacyReports = [
    {
        name: 'Device History Dataset',
        id: 'DEVICE_HISTORY_REPORT'
    },
    {
        name: 'ESRI Geodatabase Dataset',
        id: 'ESRI_GDB'
    },
    {
        name: 'PIP Raw Only Dataset',
        id: 'PIP_RAW_ONLY_REPORT'
    },
    {
        name: 'Device Lookup by Attribute Dataset',
        id: 'DEVICE_LOOKUP_BY_ATTRIBUTE_REPORT'
    },
    {
        name: 'Device History Aggregated Context Dataset',
        id: 'DEVICE_HISTORY_AGGREGATED_CONTEXT_REPORT'
    },
    {
        name: 'Device History Context Dataset',
        id: 'DEVICE_HISTORY_CONTEXT_REPORT'
    },
    {
        name: 'Audience Membership Dataset',
        id: 'AUDIENCE_MEMBERSHIP_REPORT'
    },
    {
        id: 'CEL_CDL_LOCATION_REPORT2',
        name: 'Legacy Location Dataset v2'
    }
];

export const ReportAliases = [
    {
        id: 'DERIVED_DEMOGRAPHICS_REPORT',
        name: 'Derived Demographics Dataset'
    }
];

export const Reports = [...locationReportsDetails, ...p2pReports, ...CECDLocationReports, ...visitorBehavioralReports, ...footTrafficReportsDetails, ...locVisReportsDetails, ...LegacyReports, ...ReportAliases];