

export default {
  state: {
    geographies: [],
    results: [],
    categories: [],
    terms: {
      location: [],
      geo: []
    }
  },
  mutations: {
    setRecentGeographies(state, geo) {
      state.geographies = geo.slice(0, 5);
    },
    setRecentResults(state, results) {
      state.results = results.slice(0, 5);
    },
    setRecentCategories(state, categories) {
      state.categories = categories.slice(0, 5);
    },
    setRecentLocationSearches(state, terms) {
      state.terms.location = terms.slice(0, 5);
    },
    setRecentGeoSearches(state, terms) {
      state.terms.geo = terms.slice(0, 5);
    }
  },
};
