<template>
  <div class="filter-controls-container">
    <!-- Search bar -->
    <div class="searchbar-container">
      <b-form-input
        v-model="inputValue"
        placeholder="Search:"
        class="search-input"
        @change="submitSearch()"
      ></b-form-input>
      <span class="search-icon-container">
        <img
          :src="require('pinnacle-lib/assets/icon/search-icon.svg')"
          alt=""
        />
      </span>
    </div>
    <!-- Filter by status -->
    <b-dropdown class="status-dropdown ml-3" :text="status.text === 'Filter By Status' ? status.text : `Status: ${status.text}`">
      <div v-for="friendlyName in Object.keys(DAPI_STATUS)" :key="friendlyName">
        <b-dropdown-item @click="statusFilterChanged(friendlyName)">{{
          friendlyName
        }}</b-dropdown-item>
        <b-dropdown-divider v-if="friendlyName === 'All'"></b-dropdown-divider>
      </div>
    </b-dropdown>
    <!-- Refresh -->
    <b-button
      @click="reload()"
      class="ml-3"
      style="background-color: #E3EBF6; color: #858ea7; border-width: 0px"
      size="md"
    >
      Reload
    </b-button>
  </div>
</template>

<script>
import { syncState } from "../../../utils/syncState";
import { DAPI_STATUS } from "./DatasetsTable.vue";

export default {
  data() {
    return {
      inputValue: "",
      DAPI_STATUS
    };
  },
  computed: {
    ...syncState(
      {
        searchTerm: "state.datasetFilters.searchTerm|setDataFeedSearchTerm",
        status: "state.datasetFilters.status|setDataFeedStatus",
        shouldReload: "state.datasetFilters.reload|setDataFeedReload"
      },
      { context: this, defaultStore: "$store" }
    ),
  },
  watch: {
    inputValue() {
      if (this.inputValue === "") this.searchTerm = "";
    },
  },
  methods: {
    /**
     * Updates the filter store to trigger a dataset reload with the new status filter.
     */
    statusFilterChanged(text) {
      this.status = {
        text: text,
        filter: DAPI_STATUS[text]
      };
    },
    /**
     * Updates the filter store to trigger a dataset reload with the new search term.
     */
    submitSearch() {
      this.searchTerm = this.inputValue;
    },
    /**
     * Updates the store to trigger a dataset reload.
     */
    reload() {
      this.shouldReload = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.filter-controls-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  .status-dropdown {
    min-width: 227px;

    ::v-deep button {

      background-color: white;
      border: 1px solid #D2DDEC;
      color: #858ea7;
    }

    ::v-deep button:active {
      background-color: white;
      color: #858ea7;
    }

  }
}

.searchbar-container {
  position: relative;

  .search-input {
    width: 20vw;
    padding-right: 30px;
  }

  .search-input::placeholder {
    color: #858ea7 !important;
    // font-weight: 700;
  }

  .search-input::after {
    background-image: url("pinnacle-lib/assets/icon/search-icon.svg");
  }

  .search-icon-container {
    position: absolute;
    top: 7px;
    right: 7px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>