<template>
  <div class="d-flex align-items-center main-accordion-btn-container">
    <button
      class="d-flex align-items-center justify-content-between main-toggle-btn"
      :class="dropdown ? null : 'collapsed'"
      :aria-expanded="dropdown ? 'true' : 'false'"
      @click="$emit('dropdownChanged', !dropdown)"
    >
      <div class="details details-no-icon"><span class="layer-title">{{ title }}</span></div>
      <!-- <b-form-checkbox
        class="checkbox"
        :checked="status"
        @change="$emit('statusChanged', !status)"
        switch
      /> -->
    </button>
  </div>
</template>

<script>

export default {
  props: ['status', 'dropdown', 'title']
}
</script>

<style lang="scss" scoped>
.main-accordion-btn-container {
  height: 60px;
  background-color: #F9FBFD;
  width: 100%;
  border: none !important;

}

.layer-title {
  margin-left: 1em;
    font-size: 14px;
    color: #6E84A3;
}
.main-toggle-btn {
    background-color: #F9FBFD !important;
}

.main-toggle-btn,
.toggle-btn {
  flex: auto;
  height: 54px;
  background: #ffffff;
  box-sizing: border-box;
  // border-radius: 10px 10px 0 0;
  border: 0;
  outline: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: #1f2d3d;
  padding: 0;
}
.toggle-btn {
  padding-left: 10px;
  // border-top: 1px solid #e3ebf6;
  &.collapsed {
    color: #6e84a3;
  }
}

</style>
