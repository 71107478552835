<template>
  <div class="nearby-places-container">
    <InsightsContainer
      title="Nearby Places"
      :icon="require('pinnacle-lib/assets/icon/nearByPlaces.svg')"
      :tooltip="tooltip"
      insight="cl"
      :selection="cl"
      insightFamily="pi"
      :toggleSelectedLayer="toggleSelectedLayer"
    >
      <div class="nearby-places-content">
        <div class="input-container">
          <label style="color: #858ea7; font-size: 14px; font-weight: 200">
            Show
          </label>
          <b-form-group>
            <b-form-tags
              input-id="cl-search-tags"
              v-model="tags.cl"
              no-outer-focus
              class="cl-form-tags"
            >
              <template v-slot="{ tags, disabled, addTag, removeTag }">
                <ul v-if="tags.length > 0" class="list-inline d-inline-block">
                  <li
                    v-for="tag in tags"
                    :key="tag"
                    class="list-inline-item mb-3 cl-tag"
                  >
                    <b-form-tag
                      @remove="removeCLTag({ tag, removeTag })"
                      :title="tag"
                      :disabled="disabled"
                      variant="info"
                      >{{ tag }}</b-form-tag
                    >
                  </li>
                </ul>
                <b-dropdown
                  variant="outline-secondary"
                  class="cl-dropdown w-100"
                  block
                  no-caret
                  @show="setFocus"
                >
                  <!-- <template #button-content>Choose industries</template> -->
                  <template #button-content
                    >Choose industries
                    <img
                      class="chooseIndustry-caret"
                      :src="require('pinnacle-lib/assets/icon/arrow-down-select.svg')"
                      alt="Choose Industries"
                    />
                  </template>

                  <div class="cl-dropdown-content">
                    <b-dropdown-form
                      @submit.stop.prevent="() => {}"
                      class="mb-0"
                    >
                      <b-form-group class="mb-0">
                        <b-form-input
                          placeholder="Search an industry"
                          autofocus
                          id="cl-search"
                          v-model="search.cl"
                          autocomplete="off"
                        ></b-form-input>
                      </b-form-group>
                    </b-dropdown-form>
                    <b-dropdown-divider></b-dropdown-divider>
                    <div class="subsectors-list">
                      <b-dropdown-item-button class="subsectors-list-item"
                        v-for="(option, idx) of filtered_subsectors"
                        :key="idx"
                        @click="addCLTag({ option, addTag })"
                      >
                        {{ option.name }}
                      </b-dropdown-item-button>
                      <b-dropdown-text v-if="filtered_subsectors.length === 0"
                        >No industry found</b-dropdown-text
                      >
                    </div>
                  </div>
                </b-dropdown>
              </template>
            </b-form-tags>
          </b-form-group>
        </div>
      </div>
    </InsightsContainer>
  </div>
</template>

<script>
import InsightsContainer from "@/components/maps/InsightsContainer.vue";
import { GeoType } from "@/services/map/types";

export default {
  components: {
    InsightsContainer,
  },
  props: ["toggleSelectedLayer", "locations", "updateSelectedLayer"],
  data() {
    return {
      subsectors: [],
      search: {
        cl: "",
      },
      tags: {
        cl: [],
      },
      tooltip:
        "Add nearby places to the map by selecting one or more industries. You can add nearby places to a comparison group.",
    };
  },
  watch: {
    locations: {
      immediate: true,
      handler(newVal, oldVal) {
        const newIds = newVal.map(loc => (loc.id));
        if (oldVal !== undefined && newIds.length === oldVal.length && oldVal.every(loc => {
          return newIds.includes(loc.id);
        })) return;
        this.clearCLFilters();
        for (const loc of newVal) {
          this.addCLFilter(loc);
        }
        
        // if (this.tags.cl.length > 0) {
        //   const filters = Object.assign([], this.cl.api_params.filter);
        //   for (const tag of this.tags.cl) {
        //     filters.push({
        //       field: "subsector",
        //       value: tag,
        //     });
        //   }
        //   this.$sessionStore.commit("setCLFilters", filters);
        // }
      },
    },
  },
  mounted() {
    this.fetchCLData();
    const subsectors = localStorage.getItem("subsectors");
    if (subsectors) this.subsectors = JSON.parse(subsectors);
  },
  beforeDestroy() {
    this.clearCLFilters();
  },
  computed: {
    geography_levels() {
      return Object.values(GeoType);
    },
    filtered_subsectors() {
      return this.subsectors
        .filter((subsector) => {
          return subsector.name
            .toLowerCase()
            .includes(this.search.cl.toLowerCase());
        });
    },
    status() {
      return this.$sessionStore.state.studyMap.pi.status;
    },
    cl: {
      get() {
        return this.$sessionStore.state.studyMap.pi.cl;
      },
      set(value) {
        this.$sessionStore.commit("setPICompetitiveLandscape", value);
      },
    },
  },
  methods: {
    setFocus() {
      const input = document.getElementById("cl-search");
      this.$nextTick(function () {
        this.$nextTick(function () {
          input.focus();
        });
      });
    },
    async fetchCLData() {
      const headers = this.getHeaders();
      if (this.tokenNeedsRefresh()) await this.refreshToken();
      let url = `${process.env.VUE_APP_PINNACLE_API}/v1/categories`;
      const subsectors = await this.$http.get(url, {
        ...headers,
        params: { field: "subsector", collection: "PPOI" },
      });

      this.subsectors = subsectors.data.records.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      window.localStorage.setItem(
        "subsectors",
        JSON.stringify(this.subsectors)
      );
    },
    addCLFilter(location) {
      const filters = Object.assign([], this.cl.api_params.filter);
      filters.push({
        field: "subsector",
        value: location.subsector,
      });
      this.$sessionStore.commit("setCLFilters", filters);
      this.tags.cl.push(location.subsector);
    },
    clearCLFilters() {
      this.$sessionStore.commit("setCLFilters", []);
      this.tags.cl = [];
    },
    addCLTag({ option, addTag }) {
      this.search.cl = "";
      addTag(option.name);
      const filters = Object.assign([], this.cl.api_params.filter);
      filters.push({
        field: "subsector",
        value: option.name,
      });
      this.$sessionStore.commit("setCLFilters", filters);
      this.tags.cl.push(option.name);
      this.updateSelectedLayer(this.cl);
    },
    removeCLTag({ tag, removeTag }) {
      removeTag(tag);
      const filters = this.cl.api_params.filter.filter((opt) => {
        return opt.value !== tag;
      });
      this.$sessionStore.commit("setCLFilters", filters);
      this.tags.cl = this.tags.cl.filter((val) => val !== tag);
      this.updateSelectedLayer(this.cl);
    },
  },
};
</script>

<style lang="scss" scoped>
.nearby-places-container {
  .nearby-places-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 24px;
  }
}
.cl-dropdown {
  ul {
    width: 100% !important;
    position: absolute;
  }
}


.subsectors-list {
  max-height: 330px;
  max-width: 310px;
  overflow-y: scroll;
  overflow-x: hidden;
  .subsectors-list-item::v-deep .dropdown-item {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;    
  }
}

#cl-search {
  border: 1px solid #cfd3dc !important;
  border-radius: 0 !important;
}

.cl-form-tags {
  padding: 0 !important;
  border: none !important;
}

.cl-tag {
  span {
    background-color: $blue !important;
    color: white !important;
    border-radius: 4px;
    // width: 59px;
    width: 94px;
    height: 28px;
    font-size: 14px;
    * {
      color: white !important;
    }
  }
}

.cl-dropdown {
  border-radius: 0;
  padding: 0;

  button {
    border-radius: 0 !important;
  }

  ul {
    width: 100% !important;
    border-radius: 0 !important;
  }
}

::v-deep .btn-outline-secondary {
  border-radius: 4px !important;
  display: flex;
  justify-content: space-between;
}

::v-deep .btn-outline-secondary:hover {
  background-color: white !important;
  color: #253561 !important;
  border: 1px solid #cfd3dc !important;
  box-shadow: none !important;
}

::v-deep .btn-outline-secondary:not(:hover) {
  background-color: white !important;
  color: #253561 !important;
  border: 1px solid #cfd3dc !important;
  box-shadow: none !important;
}

::v-deep .dropdown-menu {
  font-size: 14px !important;
}
::v-deep .form-control {
  height: 30px;
}
::v-deep .b-dropdown-form {
  height: 30px;
}
.cl-dropdown.show {
  color: white !important;
}

.map-tooltip {
  position: relative;
  min-width: 10px;
  margin-left: 10px;
  .tooltip-txt {
    visibility: hidden;
    width: 200px;
    background-color: #e3ebf6;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.3);
    position: absolute;
    z-index: 10;
    top: calc(100% + 10px);
    right: -100px;
  }
  &:hover .tooltip-txt {
    visibility: visible;
  }
}

.chooseIndustry-caret {
  height: 24px;
  width: 10px;
}

</style>
<style lang="scss" scoped>

.dropdown-item {
  max-width: 310px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

</style>