<template>
  <div :style="childStyle" :class="childClass" class="de-option">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['childStyle', 'childClass'],
}
</script>

<style lang="scss" scoped>
.de-option {
  flex-direction: column;
  width: 100%;
}
</style>