<template>
  <div>
    <!-- <ReportOptions> -->
    <ReportOptions>
      <p>Output Format</p>
      <p class="subtext caption mb-4">
        Specify the format you would like for the dataset
      </p>
      <div class="d-flex mb-4">
        <div class="ml-4">
          <input type="radio" v-model="primaryReportType" name="output-format" value="PATHING_X_REPORT"
            @click="clearSecondary" />
          <label @click="clearSecondary">Pathing X</label>
          <div class="d-flex flex-column ml-4">
            <div class="d-flex">
              <input type="radio" v-model="secondaryReportType" name="pathing" value="PATHING_X_CONTEXT_REPORT"
                @click="selectParent('PATHING_X_REPORT')" />
              <label>Add Context</label>
            </div>
            <div class="d-flex">
              <input type="radio" v-model="secondaryReportType" name="pathing" value="PATHING_X_CONTEXT_ONLY_REPORT"
                @click="selectParent('PATHING_X_REPORT')" />
              <label>Context Only</label>
            </div>
            <div class="d-flex" v-if="
              secondaryReportType && primaryReportType === 'PATHING_X_REPORT'
            ">
              <input type="checkbox" v-model="jsonContext" name="jsonContext" @change="showJSON()" />
              <label>Custom Context</label>
            </div>
          </div>
        </div>
        <div class="ml-4">
          <input type="radio" v-model="primaryReportType" name="output-format" value="PRE_POST_REPORT"
            @click="clearSecondary" @change="hideJSON()" />
          <label>Pre-Post</label>
          <div class="d-flex flex-column ml-4">
            <div class="d-flex">
              <input type="radio" v-model="secondaryReportType" name="pre-post" value="PRE_POST_SAMPLED_REPORT"
                @click="selectParent('PRE_POST_REPORT')" />
              <label>Sampled Pre-Post</label>
            </div>
          </div>
        </div>
        <div class="ml-4">
          <input type="radio" v-model="primaryReportType" name="output-format" value="FEEDER_REPORT"
            @click="clearSecondary" @change="hideJSON()" />
          <label>Feeder</label>
        </div>
      </div>
    </ReportOptions>

    <ReportOptions>
      <TimePeriod :id="'pathing-report-options'" :presetSecondsBefore="secondsBefore" :presetSecondsAfter="secondsAfter"
        ref="timePeriodComponent" @timePeriodSeconds="setTimePeriodSeconds" />
    </ReportOptions>
  </div>
</template>

<script>
import TimePeriod from "./TimePeriod.vue";
import ReportOptions from "./shared/ReportOptions.vue";

import { bus } from "@/main";

const PRIMARY_REPORTS = [
  "PATHING_X_REPORT",
  "PRE_POST_REPORT",
  "FEEDER_REPORT",
];

export default {
  components: {
    TimePeriod,
    ReportOptions,
  },
  data() {
    return {
      primaryReportType: "PATHING_X_REPORT",
      secondaryReportType: "",
      jsonContext: false,
    };
  },
  mounted() {
    const { secondsBefore, secondsAfter, p2pReportType, contextPoiGeojson } =
      this.$sessionStore.state.jobRequest;
    if (secondsBefore !== undefined) {
      setTimeout(() => {
        this.$refs.timePeriodComponent.setSeconds(secondsBefore, "before");
      }, 0);
    }
    if (secondsAfter !== undefined) {
      setTimeout(() => {
        this.$refs.timePeriodComponent.setSeconds(secondsAfter, "after");
      }, 0);
    }
    if (p2pReportType !== undefined && p2pReportType !== "") {
      if (!this.isPrimary(p2pReportType)) {
        this.secondaryReportType = p2pReportType;
        this.primaryReportType = this.getParent(p2pReportType);
      } else this.primaryReportType = p2pReportType;
    }
    if (contextPoiGeojson !== undefined) {
      this.jsonContext = true;
    }
  },
  methods: {
    hideJSON() {
      this.jsonContext = false;
      bus.$emit("showJSON", false);
    },
    showJSON() {
      bus.$emit("showJSON", this.jsonContext);
    },
    isPrimary(id) {
      return PRIMARY_REPORTS.includes(id);
    },
    getParent(id) {
      if (id.includes("PATHING_")) return "PATHING_X_REPORT";
      return "PRE_POST_REPORT";
    },
    setTimePeriodSeconds(timePeriod) {
      // sets both screen date times based on inputs from time period component
      this.$sessionStore.commit("setTimePeriod", {
        secondsBefore: timePeriod.beforeSeconds,
        secondsAfter: timePeriod.afterSeconds,
      });
    },
    selectParent(parent) {
      // if a secondary report type is chosen, this method selects the parent as well
      this.primaryReportType = parent;
    },
    clearSecondary() {
      // clears secondary report options if a primary button is selected
      this.secondaryReportType = "";
    },
  },
  computed: {
    reportType() {
      // computes report type
      if (this.secondaryReportType) {
        return this.secondaryReportType;
      }
      return this.primaryReportType;
    },
    secondsBefore() {
      if (this.$sessionStore.state.jobRequest.secondsBefore !== undefined) {
        return this.$sessionStore.state.jobRequest.secondsBefore;
      } else {
        return 1800;
      }
    },
    secondsAfter() {
      if (this.$sessionStore.state.jobRequest.secondsAfter !== undefined) {
        return this.$sessionStore.state.jobRequest.secondsAfter;
      } else {
        return 1800;
      }
    },
  },
  watch: {
    reportType(reportType) {
      // event tells ReportSelection which descriptive text to render and commits report type to store
      bus.$emit("pathingReportOption", reportType);
      this.$sessionStore.commit("setP2PReportType", reportType);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  margin-left: 0.5rem;
}

.caption {
  margin-bottom: 1rem;
}

p {
  margin: 0;
}
</style>