<template>
  <div class="text-container">
    <h1>{{ useRouteMeta ? $route.meta.headerTitle : ($route.meta.headerTitle || title) }}</h1>
    <p>{{ useRouteMeta ? $route.meta.headerDescription : ($route.meta.headerDescription || description) }}</p>
  </div>
</template>

<script>
export default {
  props: ['useRouteMeta', 'title', 'description']
}
</script>

<style lang="scss" scoped>
.text-container {
  h1 {
    margin-bottom: 1rem;
    color: #4A577C;
  }

  p {
    color: #858EA7;
    font-weight: 400;
    font-size: 14px;
  }
}
</style>