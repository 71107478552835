<template>
  <div>
    <h2 class="tTitle">Change Your Password</h2>
    <p class="greyText">
      We will email you a verification code and then prompt you for your new
      password.
    </p>
    <div class="cont">
      <b-button
        block
        variant="primary"
        size="lg"
        class="mb-3"
        style="margin-top: 4rem; width: 50%"
        v-if="!code"
        @click="codeRequest()"
      >
        Request Code
      </b-button>
      <div class="bcont" v-if="code">
        <b-form @submit="onChangePass">
          <div role="group" class="mb-4">
            <div class="row">
              <label for="oldPassword" class="col">Verification code</label>
            </div>
            <div class="toggle-password">
              <b-form-input
                id="password"
                v-model="oPassword"
                :state="nPasswordState"
                placeholder="Verification code"
                class="password-input"
              >
              </b-form-input>
            </div>
            <!-- This will only be shown if the preceding input has an invalid state -->
            <!-- <div class="error grey" >Your password must:</div> -->
          </div>
          <div role="group" class="mb-4">
            <div class="row">
              <label for="password" class="col">New password</label>
            </div>
            <div class="toggle-password">
              <b-form-input
                id="password"
                v-model="nPassword"
                :type="showNewPassword ? 'text' : 'password'"
                :state="nPasswordState"
                placeholder="Enter your password"
                class="password-input"
              >
              </b-form-input>
              <img
                v-if="showNewPassword"
                :src="require('pinnacle-lib/assets/icon/eye-focused.svg')"
                alt=""
                @click="showNewPassword = !showNewPassword"
              />
              <img
                v-else
                :src="require('pinnacle-lib/assets/icon/eye.svg')"
                class="eye-icon"
                alt=""
                @click="showNewPassword = !showNewPassword"
              />
            </div>
            <!-- This will only be shown if the preceding input has an invalid state -->
          </div>
          <div role="group" class="mb-4">
            <div class="row">
              <label for="cpassword" class="col">Confirm new password</label>
            </div>
            <div class="toggle-password">
              <b-form-input
                id="password"
                v-model="cpassword"
                :type="showConfirmPassword ? 'text' : 'password'"
                :state="cpasswordState"
                placeholder="Enter your password"
                class="password-input"
              >
              </b-form-input>
              <img
                v-if="showConfirmPassword"
                :src="require('pinnacle-lib/assets/icon/eye-focused.svg')"
                alt=""
                @click="showConfirmPassword = !showConfirmPassword"
              />
              <img
                v-else
                :src="require('pinnacle-lib/assets/icon/eye.svg')"
                class="eye-icon"
                alt=""
                @click="showConfirmPassword = !showConfirmPassword"
              />
            </div>
            <!-- This will only be shown if the preceding input has an invalid state -->
            <div class="error" v-if="cpasswordState || cpassword === ''">
              <span class="text-danger">{{ err }}</span>
            </div>
            <b-form-invalid-feedback
              :state="cpasswordState"
              id="input-live-feedback"
            >
              Your password does not match.
            </b-form-invalid-feedback>
            <div class="error">
              <span class="text-danger">{{ errResetData }}</span>
            </div>
          </div>

          <b-button
            block
            variant="primary"
            size="lg"
            class="mb-3"
            style="margin-top: 1rem"
            type="submit"
            :disabled="
              !(cpasswordState && oPasswordState && nPasswordState) ||
              submittingConfirm
            "
          >
            <span v-if="submittingConfirm"
              ><b-spinner small label="Spinning" variant="light"></b-spinner
            ></span>
            <span v-else>Update password</span>
          </b-button>
        </b-form>
      </div>
      <div>
        <div class="reqBox" v-if="code">
          <h2 class="reqHead">Password requirements</h2>
          <p class="reqText">
            To create a new password, you have to meet all of the following
            requirements:
          </p>
          <div
            class="req"
            v-bind:class="len === null ? 'grey' : len ? 'pass' : 'grey'"
          >
            <img alt="" class="check" :src="len ? check : x" />Minimum 8 characters
          </div>
          <div
            class="req"
            v-bind:class="upper === null ? 'grey' : upper ? 'pass' : 'grey'"
          >
            <img alt="" class="check" :src="upper ? check : x" />At least one uppercase
            character
          </div>
          <div
            class="req"
            v-bind:class="lower === null ? 'grey' : lower ? 'pass' : 'grey'"
          >
            <img alt="" class="check" :src="lower ? check : x" />At least one lowercase
            character
          </div>
          <div
            class="req"
            v-bind:class="num === null ? 'grey' : num ? 'pass' : 'grey'"
          >
            <img alt="" class="check" :src="num ? check : x" />At least one number
          </div>
          <div
            class="req"
            v-bind:class="spec === null ? 'grey' : spec ? 'pass' : 'grey'"
          >
            <img alt="" class="check" :src="spec ? check : x" />At least one special
            character, e.g. ! @ # ?
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      err: "",
      errReset: "",
      password: "",
      oPassword: "",
      nPassword: "",
      cpassword: "",
      remember: false,
      submitting: false,
      submittingConfirm: false,
      showPassword: false,
      showConfirmPassword: false,
      showNewPassword: false,
      isActive: true,
      check: "img/check_mark.svg",
      x: "img/x_mark.svg",
      code: false,
    };
  },
  props: {
    email: {
      type: String,
    },
  },
  computed: {
    oPasswordState() {
      return this.oPassword === ""
        ? null
        : this.oPassword.length > 4
        ? true
        : false;
    },
    nPasswordState() {
      let reg = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
      return this.nPassword === ""
        ? null
        : this.nPassword.length > 7
        ? reg.test(this.nPassword)
        : false
        ? true
        : false;
    },
    cpasswordState() {
      return this.cpassword === ""
        ? null
        : this.cpassword === this.nPassword
        ? true
        : false;
    },
    errData() {
      return this.err;
    },
    errResetData() {
      return this.errReset;
    },
    upper() {
      let reg = /(?=.*[A-Z])/;
      return this.nPassword === ""
        ? null
        : reg.test(this.nPassword)
        ? true
        : false;
    },
    lower() {
      let reg = /(?=.*[a-z])/;
      return this.nPassword === ""
        ? null
        : reg.test(this.nPassword)
        ? true
        : false;
    },
    num() {
      let reg = /(?=.*[0-9])/;
      return this.nPassword === ""
        ? null
        : reg.test(this.nPassword)
        ? true
        : false;
    },
    spec() {
      let reg = /(?=.*\W)/;
      return this.nPassword === ""
        ? null
        : reg.test(this.nPassword)
        ? true
        : false;
    },
    len() {
      return this.nPassword === ""
        ? null
        : this.nPassword.length > 7
        ? true
        : false;
    },
  },
  methods: {
    onChangePass(e) {
      e.preventDefault();
      this.changePass();
    },
    async codeRequest() {
      this.submitting = true;
      this.err = "";
      try {
        const res = await this.$http.post("/v1/reset-password", {
          email: this.email,
        });
        if (res.data) {
          this.code = true;
        }
      } catch (err) {
        const error = err.response.data.errors[0].message;
        this.err = error;
      }
      this.submitting = false;
    },
    async changePass() {
      this.submitting = true;
      this.err = "";
      const password = this.nPassword;
      const code = this.oPassword.trim();
      const { email } = this;
      try {
        const res = await this.$http.post("/v1/reset-password/confirm", {
          code,
          email,
          password,
        });
        if (res.data) {
          this.errReset = "";
          this.code = false;
          console.log("password changed");
          this.$store.commit("setAlert", true);
          this.oPassword = "";
          this.nPassword = "";
          this.cpassword = "";
        }
      } catch (err) {
        console.log(err);
        const error = err.response.data.errors[0].message;
        this.errReset = error;
      }
      this.submittingConfirm = false;
    },
  },
};
</script>
<style lang="scss"  scoped>
.toggle-password {
  position: relative;
  img {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
.notVisibleBtn {
  display: none;
}
.form-control {
  border-color: #d2ddec;
}

button {
  margin-top: 3em;
  height: 58px;
}

form {
  margin-top: 38px;
}

::v-deep .btn-primary {
  border-radius: 6px;
}
.right-img {
  height: 100vh;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.greyText {
  color: #6e84a3;
  font-size: 15px;
  margin-bottom: 0px;
  font-weight: 400;
}
.inner {
  width: 50%;
  margin: 0px;
}
.reqBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: #edf2f9;
  border: 1px solid #e3ebf6;
  box-sizing: border-box;
  border-radius: 6px;
  width: 21rem;
  margin-left: 45px;
  margin-top: 63px;
}
.error {
  height: 23px;
  font-size: 13px;
  padding-top: 4px;
}
.req {
  font-size: 13px;
  color: #6e84a3;
  margin-bottom: 0.25rem;
}
.pass {
  color: #2c7be5;
}
.reqHead {
  font-size: 15px;
  color: #12263f;
  margin-bottom: 0.75rem;
}
.reqText {
  font-size: 13px;
  color: #6e84a3;
  margin-bottom: 1rem;
}
.check {
  margin-right: 10px;
}
.bcont {
  width: 22.5rem;
}
.cont {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
}
.tTitle {
  margin-top: 2.5rem;
  margin-bottom: 12px;
  font-weight: 600 !important;
}
.is-invalid {
  border-color: #2c7be5 !important;
}
.is-valid {
  border-color: #d2ddec !important;
}

.password-input.is-valid + .eye-icon,
.password-input.is-invalid + .eye-icon {
  right: 32px;
}
</style>
