<template>
  <div v-if="!validDates" class="warning-container">
    <p class="warning">
      Heartbeat datasets are only valid for intervals later than January 1, 2018 and earlier than the current month.
    </p>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  data() {
    return {}
  },
  watch: {
    validDates(val) {
      if (val) bus.$emit('reportTypeErrorOff');
      else bus.$emit('reportTypeErrorOn', 'Heartbeat datasets are only valid for intervals later than January 1, 2018 and earlier than the current month.');
    }
  },
  computed: {
    validDates() {
      const startDateTime = new Date(this.$sessionStore.state.jobRequest.startDateTime);
      const endDateTime = new Date(this.$sessionStore.state.jobRequest.endDateTime);
      const firstDate = new Date("2018-01-01 00:00:00");
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      const lastDate = new Date(currentYear, currentMonth, 0, 23, 59, 59);
      return startDateTime >= firstDate && endDateTime <= lastDate;
    }
  },
  mounted() {
    if (this.validDates) bus.$emit('reportTypeErrorOff');
    else bus.$emit('reportTypeErrorOn', 'Heartbeat datasets are only valid for intervals later than January 1, 2018 and earlier than the current month.');
  },
  beforeDestroy() {
    bus.$emit('reportTypeErrorOff');
  }
}
</script>

<style lang="scss" scoped>
.warning-container {
  height: 30px;
  .warning {
    color: #f7617b;
    font-size: 11px;
    position: relative;
    top: 23px;
    left: 20px;
  }
}
</style>