<template>
  <table class="data-table table table-hover">
    <slot></slot>
  </table>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

.data-table {
  margin: 0;
  border: 1px solid $border-color;
  border-radius: 0.3rem;
  overflow: visible;
  border-collapse: unset !important;
  border-spacing: 0px !important;

  ::v-deep thead {

    // border: 0 !important;

    // tr {
    //   border: 0 !important;
    // }

    

    th {
      border: 1px solid $border-color;
      background-color: $border-color;
      color: #858ea7;
    }

    // th:first-of-type {
    //   border-top-left-radius: 0.5rem;
    // }

    // th:last-of-type {
    //   border-top-right-radius: 0.5rem;
    // }
  }
  ::v-deep tbody {
    tr {
      background-color: white;
    }
  }
}
</style>