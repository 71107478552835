<template>
  <div class="loading-container">
    <vue-lottie-player
        autoplay
        loop
        mode="normal"
        :animationData="require('pinnacle-lib/assets/lottie/world-loader.json')"
        width="48px"
        height="48px"
        name="loader">
      </vue-lottie-player>
  </div>
</template>

<script>
import VueLottiePlayer from "vue-lottie-player";
export default {
  components: {
    VueLottiePlayer
  }
}
</script>

<style lang="scss">
.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  padding: 1rem;
}
</style>