<template>
  <div class="layer-picker-container">
    <div class="accordion-main" v-show="selected === 'insights'">
      <div class="d-flex align-items-center justify-content-between layer-picker-header">
        <div class="d-flex insights-header">     
          <img :src="require('pinnacle-lib/assets/icon/map-insights-outlined.svg')" />
          <p>Insights</p>
        </div>
      </div>

      <LayerToggle
        :status="status.place_insights"
        :dropdown="dropdown.place_insights"
        @statusChanged="
          status.place_insights = $event;
          toggleLayerVisibility($event, 'Place');
        "
        @dropdownChanged="dropdown.place_insights = $event"
        title="Place Insights"
      ></LayerToggle>

      <PlaceInsightsCollapse
        :toggleSelectedLayer="toggleSelectedLayer"
        :updateSelectedLayer="updateSelectedLayer"
        :dropdown="dropdown.place_insights"
        ref="placeCollapse"
        :loading="loading"
        :locations="locations"
        @insightSelected="selectInsight"
      >
      </PlaceInsightsCollapse>

      <LayerToggle
        :status="status.community_insights"
        :dropdown="dropdown.community_insights"
        @statusChanged="
          status.community_insights = $event;
          toggleLayerVisibility($event, 'Community');
        "
        @dropdownChanged="dropdown.community_insights = $event"
        title="Community Insights"
      ></LayerToggle>

      <CommunityInsightsCollapse
        :dropdown="dropdown.community_insights"
        :toggleSelectedLayer="toggleSelectedLayer"
        :updateSelectedLayer="updateSelectedLayer"
        ref="communityCollapse"
        :loading="loading"
        @insightSelected="selectInsight"
      >
      </CommunityInsightsCollapse>
    </div>
    <!--- test  -->
      <div v-show="selected === 'nerbyPlaces'">
      <NearByPlaces 
        :toggleSelectedLayer="toggleSelectedLayer"
        :locations="locations"
        :updateSelectedLayer="updateSelectedLayer"
      ></NearByPlaces>
    </div>

    <div v-show="selected === 'routes'">
      <Routes 
        :toggleSelectedLayer="toggleSelectedLayer"
      ></Routes>
    </div>
    <div v-show="selected === 'wh'">
      <WorkAndHome
        :toggleSelectedLayer="toggleSelectedLayer"
        :updateSelectedLayer="updateSelectedLayer"
      ></WorkAndHome>
    </div>
    <div v-show="selected === 'pd'">
      <Activity
        :toggleSelectedLayer="toggleSelectedLayer"
        :updateSelectedLayer="updateSelectedLayer"
      ></Activity>
    </div> 
    <div v-show="selected === 'gt'">
      <Popularity
        :toggleSelectedLayer="toggleSelectedLayer"
        :updateSelectedLayer="updateSelectedLayer"
      ></Popularity>
    </div>
  </div>
</template>

<script>
import LayerToggle from "@/components/maps/LayerToggle";
import PlaceInsightsCollapse from "@/components/maps/PlaceInsightsCollapse";
import CommunityInsightsCollapse from "@/components/maps/CommunityInsightsCollapse";
// import LayerIcon from "@/components/maps/LayerIcon";
// import CloseButton from "@/components/partials/CloseButton";
import getBounds from '@/utils/getBounds';
import Routes from "@/components/maps/Routes";
import WorkAndHome from "@/components/maps/WorkAndHome";
import Activity from "@/components/maps/Activity";
import Popularity from "@/components/maps/Popularity";
import NearByPlaces from "@/components/maps/NearByPlaces";
import moment from "moment";


import {
  getTypeByName,
  createId,
  createBatchId,
  createCommunityId,
  VisualizationType,
  CommunityLayerTypes,
  getTypeById,
  LayerType,
} from "@/services/map/types";
import { bus } from "@/main";
import { getISO3 } from "@/utils/countryMapping";

export default {
  components: {
    LayerToggle,
    PlaceInsightsCollapse,
    CommunityInsightsCollapse,
      NearByPlaces,
    // LayerIcon,
    // CloseButton

    Routes,
    WorkAndHome,
    Activity,
    Popularity
  },
  computed: {
    disabledIds() {
      return this.$sessionStore.state.study.disabledIds;
    },
    places(){
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split('/')[1] === 'preview' ? this.$sessionStore.state.study.previews : this.$sessionStore.state.study.locations;
    },
    locations() {
      return this.places.filter(location => !(location.id in this.disabledIds));
    },
    loading: {
      get() {
        return this.$sessionStore.state.studyMap.ui.loading;
      },
      set(val) {
        this.$sessionStore.state.studyMap.ui.loading = val;
      }
    }
  },
  watch: {
    '$sessionStore.state.study.disabledIds'() {
      this.reactToUpdates();
    },
    '$sessionStore.state.study.locations'() {
      if (this.$route.path.split("/")[1] === "study") this.reactToUpdates();
    },
    '$sessionStore.state.study.previews'() {
      if (this.$route.path.split("/")[1] === "preview") this.reactToUpdates();
    },
    '$sessionStore.state.study.dateRange'(val) {
      const piStatusKeys = Object.keys(this.$refs.placeCollapse.status);
      for (const key of piStatusKeys) {
        if (key === 'cl') continue;
        if (this.$refs.placeCollapse.status[key]) {
          this.updateSelectedLayer(this.$refs.placeCollapse[key]);
        }
      }
    }
  },
  data() {
    return {
      selected: 'insights',
      status: {
        place_insights: true,
        community_insights: true,
      },
      dropdown: {
        place_insights: true,
        community_insights: true,
      },
      remove: {
        wh: [],
        cl: [],
        routes: [],
        pd: [],
        gt: [],
      },
      reactToUpdatesTimeout: null
    };
  },
  mounted() {
    // TODO find out what is setting all locations as loading even when some locations are toggled off and fix it there
    this.removeFromLoading("routes");
    
    bus.$off("mapServiceError");
    bus.$on("mapServiceError", (error) => {
      const type = getTypeByName(error.type);
      this.remove[type.prefix] = this.remove[type.prefix].filter(
        (id) => id !== error.id
      );
      if (type.prefix == "routes") {
        // Sometimes routes are marked as loading, but then don't get launched if a previous location results in an error
        this.removeFromLoading(type.prefix);
      } else {
        this.removeFromLoading(type.prefix, error.id);
      }
    });

    bus.$off("mapZoom");
    bus.$on("mapZoom", (evt) => {
      this.validateZoomLevel(evt);
    });

    bus.$off("mapDataLoading");
    bus.$on("mapDataLoading", (evt) => {
      if (evt.dataType === "source" && evt.source.type === "vector") {
        const type = getTypeById(evt.sourceId);
        if (type !== undefined) this.addToLoading(type.prefix, evt.sourceId);
      }
    });

    bus.$off("mapIdle");
    bus.$on("mapIdle", () => {
      this.removeFromLoading("cl");
      this.removeFromLoading("gt");
      this.removeFromLoading("pd");
    });

    bus.$off("mapLoaded");
    bus.$on("mapLoaded", () => {
      this.$refs.placeCollapse.loadLayers();
      this.$refs.communityCollapse.loadLayers();
    });

    bus.$off("cancelvisualization");
    bus.$on("cancelvisualization", (evt) => {
      const prefix = evt.id.split("_")[0];
      this.remove[prefix] = this.remove[prefix].filter((id) => id !== evt.id);
      this.removeFromLoading(prefix, evt.id);
    });

    bus.$off("addvisualization");
    bus.$on("addvisualization", (evt) => {
      this.validateZoomLevel(evt);
      const type = getTypeById(evt.id);
      if (!type.isVectorTile) this.removeFromLoading(type.prefix, evt.id);

      if (type.isCommunity) {
        this.toggleLayerVisibility(
          this.status.community_insights,
          LayerType.COMMUNITY.name
        );
      } else {
        this.toggleLayerVisibility(
          this.status.place_insights,
          LayerType.PLACE.name
        );
      }
    });

    bus.$off("backToInsights");
    bus.$on("backToInsights", () => {
      this.selected = 'insights';
    });

    bus.$off("insightToggle");
    bus.$on("insightToggle", (value, selected) => {
      this.toggleSelectedLayer(value, selected);
    });
  },
  methods: {
    selectInsight(insight) {
      this.selected = insight;
    },
    reactToUpdates() {
      clearTimeout(this.reactToUpdatesTimeout);
      this.reactToUpdatesTimeout = setTimeout(() => {
        this.reactToUpdatesDebounced();
      }, 1000); 
    },
    reactToUpdatesDebounced() {
      if (this.locations.length === 0) {
        if (this.remove.wh.length > 0) this.removeVisualization({prefix: 'wh'});
        if (this.remove.routes.length > 0) this.removeVisualization({prefix: 'routes'});
        return;
      }
      const piStatusKeys = Object.keys(this.$refs.placeCollapse.status);
      for (const key of piStatusKeys) {
        if (this.$refs.placeCollapse.status[key]) {
          this.updateSelectedLayer(this.$refs.placeCollapse[key]);
        }
      }
    },
    toggleSelectedLayer(value, selection) {
      const filters = Object.assign({}, selection);
      const type = getTypeByName(filters.visualization);

      if (value) {
        if (type.isCommunity) {
          for (const visType of CommunityLayerTypes) {
            if (visType.prefix !== type.prefix) {
              const id = createCommunityId(visType);
              bus.$emit("mapService", {
                function: "removeVisualization",
                id,
              });
              this.removeFromLoading(visType.prefix, id);
            }
          }
        }

        const bounds = getBounds(this.locations);
        if (type.needsDateRange) this.provisionDateRange(filters);

        if (type.hasBoundary) {
          filters.api_params.country_code = this.getCountryCode().toLowerCase(); // TODO Implement getCountryCode?
        }

        this.cmdVisualization(type, filters, bounds, "add", false);
      } else {
        this.removeVisualization(type);
      }
    },
    updateSelectedLayer(selection) {
      const filters = Object.assign({}, selection);
      const type = getTypeByName(filters.visualization);

      if (this.shouldCancel(type)) return;

      const bounds = getBounds(this.locations);
      if (type.needsDateRange) this.provisionDateRange(filters);

      if (type.hasBoundary) {
        filters.api_params.country_code = this.getCountryCode().toLowerCase();
      }

      this.cmdVisualization(type, filters, bounds, "update");
    },
    cmdVisualization(type, filters, bounds, cmd = "add", noZoom = true) {
      //console.log('cmdVisualization', type, cmd);
      if (!this.validateVisualization(type, filters)) {
        this.removeVisualization(type);
        return;
      }

      this.remove[type.prefix] = [];
      if (type.batch && this.locations.length > 1) {
        const id = createBatchId(type, this.locations);
        bus.$emit("mapService", {
          function: `${cmd}BatchVisualization`,
          filters,
          locations: this.locations,
          id,
          bounds,
          noZoom
        });
        this.addToLoading(type.prefix, id);
        this.remove[type.prefix] = [id];
      } else if (type.isVectorTile) {
        const id = createCommunityId(type);
        bus.$emit("mapService", {
          function: `${cmd}TileVisualization`,
          filters,
          id
        });
        this.addToLoading(type.prefix, id);
        this.remove[type.prefix] = [id];
      } else if (cmd == "update") {
        let ids = [];
        for (const location of this.locations) {
          const id = createId(type, location.id);
          ids.push(id);
          this.addToLoading(type.prefix, id);
          this.remove[type.prefix].push(id);          
        }
        bus.$emit("mapService", {
          function: `${cmd}Visualization`,
          filters,
          locations: this.locations,
          ids,
          bounds,
          noZoom
        });
      } else {
        for (const location of this.locations) {
          const id = createId(type, location.id);
          bus.$emit("mapService", {
            function: `${cmd}Visualization`,
            filters,
            location: location,
            id,
            bounds,
            noZoom
          });
          this.addToLoading(type.prefix, id);
          this.remove[type.prefix].push(id);          
        }

        
      }
    },
    validateVisualization(type, filters) {
      if (type.name === VisualizationType.PD.name) {
        if (
          filters.api_params.day_of_week === "" ||
          filters.api_params.time_of_day === ""
        ) {
          return false;
        }
      }

      if (type.name === VisualizationType.CL.name && filters.api_params.filter.length === 0) {
        return false;
      }

      return true;
    },
    removeVisualization(type) {
//      for (const id of this.remove[type.prefix]) {
//        bus.$emit("mapService", {
//          function: "removeVisualization",
//          id,
//        });
//      }
      bus.$emit("mapService", {
        function: "clearVisualizations",
        prefix: type.prefix,
      });
      this.remove[type.prefix] = [];
    },
    toggleLayerVisibility(value, layerType) {
      if (value) {
        bus.$emit("layerMgr", {
          function: "showLayers",
          layerType,
        });
      } else {
        bus.$emit("layerMgr", {
          function: "hideLayers",
          layerType,
        });
      }
    },
    shouldCancel(type) {
      const { placeCollapse, communityCollapse } = this.$refs;
      if (type.isCommunity) {
        return !this.status.community_insights || !communityCollapse.status[type.prefix];
      }
      return !this.status.place_insights || !placeCollapse.status[type.prefix];
    },
    provisionDateRange(filters) {
      const dateRange = this.$sessionStore.state.study.dateRange;
      if (dateRange && Object.keys(dateRange).length !== 0) {
        const { start_date, end_date } = dateRange;
        filters.api_params.start_date = start_date;
        filters.api_params.end_date = end_date;
      } else {    
        // filters.api_params.start_date = "1/1/2020";
        // filters.api_params.end_date = "1/1/2021";    
        const defaultStartDate = moment().subtract(1, "year").subtract(2, "days").format("MM/DD/YY");
        const defaultEndDate = moment().subtract(2, "days").format("MM/DD/YY");
        filters.api_params.start_date = defaultStartDate;
        filters.api_params.end_date = defaultEndDate;
      }
    },
    addToLoading(prefix, id) {
      const copy = Object.assign({}, this.loading[prefix]);
      copy[id] = true;
      this.loading[prefix] = copy;
      bus.$emit("studyMapLoading", this.loading);
    },
    removeFromLoading(prefix, id) {
      if (!id) this.loading[prefix] = {};
      else {
        const copy = Object.assign({}, this.loading[prefix]);
        delete copy[id];
        this.loading[prefix] = copy;
      }
      bus.$emit("studyMapLoading", this.loading);
    },
    getCountryCode() {
      const countryCode = this.places[0].country_code;
      return getISO3(countryCode);
    },
    validateZoomLevel({zoom}) {
      const { placeCollapse, communityCollapse } = this.$refs;

      if (
        (communityCollapse.status.pd || communityCollapse.status.gt) &&
        this.status.community_insights &&
        (zoom < 9 || zoom > 15)
      ) {
        // bus.$emit("studyMapAlert", {
        //   msg: `Please zoom in to see the ${
        //     communityCollapse.status.pd ? "Time of day" : "Growth trends"
        //   } layer`,
        //   type: "info",
        // });
        bus.$emit("studyMapAlert", {
          msg: `Please zoom ${
            zoom < 9 ? "in" : "out"
          } to see the ${
            communityCollapse.status.pd ? "Activity" : "Popularity"
          } layer.`,
          type: "info",
        });
      } else {
        if (communityCollapse.status.pd || communityCollapse.status.gt) {
          bus.$emit("studyMapAlert", {
            dismiss: true,
            type: "info",
          });
        }
      }

      if (
        placeCollapse.status.wh &&
        placeCollapse.wh.visualization === "Heatmap" &&
        this.status.place_insights &&
        zoom > 15
      ) {
        bus.$emit("studyMapAlert", {
          msg: "Please zoom out to see the home and work heatmap",
          type: "info",
        });
      } else {
        if (
          placeCollapse.status.wh &&
          placeCollapse.wh.visualization === "Heatmap"
        ) {
          bus.$emit("studyMapAlert", {
            dismiss: true,
            type: "info",
          });
        }
      }
    },
    closeInsights(){
      bus.$emit("closeInsights");
    }
  },
};
</script>

<style lang="scss" scoped>
.layerCloseBtn-tooltip {
  z-index: 12000;
  top: 0 !important;
}

.insights-header {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  // margin-left: 12px;
  p {
    margin-bottom: 0px;
    font-size: 14px;
    color: #6E84A3;
    margin-left: 10px;
  }
  img {
    height: 16px;
    width: 16px;
  }
}
.insight-closeBtn{
  width: 8%;
    .minimizeBtnInsights {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}
.layer-picker-container {
  display: block;
  height: 100%;
  max-height: 100%;
  width: 100%;
  position: relative;
  overflow-y: scroll;
  margin-bottom: 40px;
}

.accordion-main {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #F9FAFC;
  box-sizing: border-box;

  .layer-picker-header {
  display: flex;
  background-color: white;
  height: 60px;
  justify-content: center;
  border-bottom: 1px solid #e3ebf6;
  padding: 24px; 
    h2 {
      margin: 0;
    }
  }

  .main-accordion-btn-container,
  .main-toggle-content {
    padding: 0 4px;
  }

  ::v-deep .main-toggle-content {
    width: 100%;
    max-height: calc(100vh - 330px);
    overflow: visible;

    .custom-control-input:checked ~ .custom-control-label::before {
        color: #FFFFFF !important;
        border-color: #2C7BE5 !important;
        background-color: #2C7BE5 !important;
    }

    .custom-control-label::before {
      background-color: white;
      border: 0.5px solid #b1c2d9;
    }

    .insight-list {
      padding: 0;
      margin: 0;
      list-style: none;

      .active-border {
        background-image: linear-gradient(#edf2f9 33%, white 0%);
        background-position: 8px;
        background-size: 1px 10px;
        background-repeat: repeat-y;
      }
      .details {
        flex: auto;
        position: relative;
        display: flex;
        align-items: center;
      }
      .details-no-icon {
        margin-left: 18px;
      }

      .toggle-content {
        padding-left: 88px;
        * {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 21px;
          letter-spacing: -0.01em;
        }
        .input-container {
          margin-bottom: 15px;
        }
        label {
          margin-bottom: 11px;
          display: flex;
          align-items: center;
          color: #6e84a3;
        }

        select {
          width: 100%;
          height: 34px;
          padding: 4px 10px 4px 10px;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 21px;
          letter-spacing: -0.01em;
          appearance: none;
          color: #3b516c;
          border: 1px solid #e3ebf6;
          box-sizing: border-box;
          // border-radius: 4px;
          outline: 0;
        }

        input::-webkit-calendar-picker-indicator {
          display: none !important;
        }
      }
    }

    .insight-list li:first-child .toggle-btn {
      border-top: none;
    }

    .main-toggle-btn,
    .toggle-btn {
      width: 100%;
      flex: auto;
      height: 54px;
      background: #ffffff;
      box-sizing: border-box;
      border: 0;
      outline: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 23px;
      letter-spacing: -0.01em;
      color: #1f2d3d;
      padding: 0;
    }

    .toggle-btn {
      padding-left: 10px;
      &.collapsed {
        color: #6e84a3;
      }
    }
  }
}

// Scrollbar stuff
// /* width */
// ::-webkit-scrollbar {
//   display: none;
//   // border-radius: 30px;
// }
// /* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }
// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: rgba(18, 38, 63, 0.25);
// }
// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }
</style>