<template>
  <div>
    <div class="section">
      <CreateHeader title="Select the Format for your Insights" description="Pinnacle offers a variety of ways to view and export place insights. After selecting your preferred format, you'll be able to choose the places you're interested in learning more about and other options."></CreateHeader>
    </div>
    <div class="section selection-container">
      <b-form-group>
        <b-form-radio :disabled="!hasReportPermission" v-model="selection" name="some-radios" value="report">
          <div class="radio-content">
            
            <IconWrapper :src="require('pinnacle-lib/assets/icon/blank_file_blue.svg')" />
            <div class="radio-text">
              <h2>Single Place Report</h2>
              <p>Visualized insights about a single place. Reports can be viewed in the Pinnacle interface or exported as a PDF</p>
            </div>
          </div>
        </b-form-radio>
        <b-form-radio :disabled="!hasDatasetPermission" v-model="selection" name="some-radios" value="dataset">
          <div class="radio-content">
          <IconWrapper :src="require('pinnacle-lib/assets/icon/view_list.svg')" />
            <div class="radio-text">
              <h2>Datasets</h2>
              <p>Datasets that can be analyzed using spreadsheets and common GIS tools</p>
            </div>
          </div>
        </b-form-radio>
      </b-form-group>
    </div>
  </div>
</template>

<script>

import CreateHeader from "./CreateHeader.vue";
import IconWrapper from "./IconWrapper.vue";
import { syncState } from "../../../../utils/syncState";

export default {
  components: { CreateHeader, IconWrapper },
  data() {
    return {selection: this.$route.name.includes('report') ? 'report' : 'dataset'}
  },
  computed: {
    ...syncState({
      selectedPolygons: 'state.jobRequest.selectedPolygons|setSelectedPolygons',
      selectAllParams: 'state.jobRequest.selectAllParams|setSelectAllParams'
    }, {context: this, defaultStore: '$sessionStore'}),
    permissions() {
      return this.$persistingStore.state.user.permissions;
    },
    hasDatasetPermission() {
      return this.hasPermission('Pinnacle Bulk Export Access', this.permissions, false);
    },
    hasReportPermission() {
      return this.hasPermission('Card Report', this.permissions);
    },
  },
  watch: {
    selection(newVal) {
      if (newVal === 'report') {
        // Check the number of selected locations
        if (this.selectedPolygons.length > 1 || this.selectAll !== undefined) {
          this.selectedPolygons = [];
        }
        this.$router.replace({name: 'create-report'})
      } else {
        this.$router.replace({name: 'create-dataset'})
      }
    }
  },
}
</script>

<style lang="scss" scoped>

::v-deep .custom-control-label {
  width: 80%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

::v-deep .custom-control-label::before {
  top: 40%;
}

::v-deep .custom-control-label::after {
  top: 40%;
}

::v-deep .custom-control.custom-radio:first-of-type {
  padding-top: 0;
}

::v-deep .custom-control.custom-radio + .custom-control.custom-radio {
  border-top: 1px solid #F7F8F9;
}

::v-deep .custom-control.custom-radio {
  padding: 1rem 0rem;
}

.selection-container {
  margin-left: 2.5rem;
}

.radio-content {
  display: flex;
  align-items: center;
  padding-left: 0.7rem;
}

.radio-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;

  h2 {
    font-size: 16px;
  }

  p {

    color: #858EA7;
    font-size: 14px;
  }

  p, h2 {
    margin: 0;
  }
}
</style>