var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-container"},[[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isInitialState),expression:"isInitialState"}]},[_c('div',{staticClass:"initial-container"},[_c('div',{staticClass:"initial-header"},[_c('h1',[_vm._v("Places")]),_c('p',[_vm._v(" Instant place-based insights powered by "+_vm._s(_vm.Constants.company_name)+"'s Data Intelligence Platform. ")]),_c('p',[_vm._v(" Learn more about our place library. ")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isInitialState),expression:"!isInitialState"}]},[_c('SearchAreaButton',{attrs:{"shouldRender":!_vm.liveSearch && _vm.renderSearchAreaButton},on:{"searchArea":_vm.onSearchArea}}),_c('PseudoBreadcrumbs',{attrs:{"staticTitle":"Search"},on:{"closeButton":function($event){return _vm.resetToInitial()}}}),_c('LiveSearchToggle',{attrs:{"disabled":_vm.search.loading,"shouldRender":_vm.liveSearch || !_vm.renderSearchAreaButton}})],1),_c('SearchVisualizationControl',{attrs:{"disabled":_vm.search.loading,"shouldRender":true}}),_c('div',{staticClass:"search-topbar"},[_c('div',{staticClass:"searchbar-container"},[_c('div',{staticClass:"searchbar-input-container"},[_c('b',{staticClass:"input-prefix"},[_vm._v("Find")]),_c('b-input',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.focusChanged),expression:"focusChanged"}],ref:"searchInput",staticClass:"searchbar",class:{
              'searchbar-with-typeahead': _vm.hasTypeahead('category'),
            },attrs:{"type":"text","placeholder":"Places, Brands, Industries","id":"searchInput","autocomplete":"off"},on:{"input":_vm.onSearchInput,"blur":function($event){_vm.search.submitted = true},"focus":function($event){return _vm.clearTypeahead('near')}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnter('category')}},model:{value:(_vm.search.term),callback:function ($$v) {_vm.$set(_vm.search, "term", $$v)},expression:"search.term"}}),_c('Typeahead',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clearTypeahead),expression:"clearTypeahead"}],attrs:{"items":_vm.searchSuggestions},on:{"viewDetail":_vm.viewDetail,"searchSuggestion":_vm.emitSearchSuggestion,"searchRecentTerm":function($event){return _vm.emitSearchRecentTerm($event, 'category')}}}),(_vm.search.term === '')?[_c('img',{staticClass:"input-postfix",attrs:{"src":require('pinnacle-lib/assets/icon/location-marker.svg')}})]:[_c('img',{staticClass:"input-postfix close-postfix",attrs:{"src":require('pinnacle-lib/assets/icon/close-icon.svg'),"id":"near-close"},on:{"click":_vm.resetFindInput}})]],2),_c('div',{staticClass:"searchbar-input-container"},[_c('b',{staticClass:"input-prefix"},[_vm._v("Near")]),_c('b-input',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.focusChanged),expression:"focusChanged"}],ref:"geoInput",staticClass:"searchbar",class:{
              'searchbar-with-typeahead': _vm.hasTypeahead('geography'),
            },attrs:{"type":"text","placeholder":"Address, City, Province, Postal Code...","id":"geoInput","autocomplete":"off","role":"combobox","aria-expanded":_vm.geoSuggestions.length > 0,"aria-autocomplete":"list","aria-control":"geoInput"},on:{"input":_vm.onGeoInput,"blur":function($event){_vm.search.submitted = true},"focus":function($event){return _vm.clearTypeahead('find')}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnter('geography')}},model:{value:(_vm.search.geo.term),callback:function ($$v) {_vm.$set(_vm.search.geo, "term", $$v)},expression:"search.geo.term"}}),_c('Typeahead',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clearTypeahead),expression:"clearTypeahead"}],attrs:{"logo":require('pinnacle-lib/assets/img/powered_by_google_on_white_hdpi.png'),"items":_vm.geoSuggestions},on:{"viewDetail":_vm.viewDetail,"searchSuggestion":_vm.emitSearchSuggestion,"searchRecentTerm":function($event){return _vm.emitSearchRecentTerm($event, 'geography')}}}),(_vm.search.geo.term === '')?[_c('img',{staticClass:"input-postfix",attrs:{"id":"map-icon","src":require('pinnacle-lib/assets/icon/map-grey.svg')}})]:[_c('img',{staticClass:"input-postfix close-postfix",attrs:{"src":require('pinnacle-lib/assets/icon/close-icon.svg'),"id":"find-close"},on:{"click":_vm.resetGeoInput}})]],2)])]),(!_vm.isInitialState)?[_vm._m(0),_c('div',{staticClass:"results-container",class:{ 'flex-center': _vm.isInitialState }},[(_vm.isInitialState)?[_c('div',{staticClass:"empty-search-msg"},[_c('img',{attrs:{"src":require('pinnacle-lib/assets/icon/search-icon.svg')}}),_c('h1',[_vm._v("Search for a place")]),_c('h2',[_vm._v("or geographic area")])])]:[_c('div',{staticClass:"search-results",on:{"scroll":_vm.checkScroll}},[(
                _vm.search.term === '' &&
                _vm.resultsEmpty &&
                !_vm.ui.loading.autocomplete &&
                !_vm.ui.loading.search &&
                _vm.routeEmpty
              )?_c('div',{staticClass:"no-result-container"},[_c('div',{staticClass:"empty-search-msg"},[_c('img',{attrs:{"src":require('pinnacle-lib/assets/icon/search-icon.svg')}}),_c('h1',[_vm._v("Search for a place")]),_c('h2',[_vm._v("or geographic area")])])]):_vm._e(),(
                _vm.resultsEmpty &&
                !_vm.ui.loading.autocomplete &&
                !_vm.ui.loading.search &&
                !_vm.routeEmpty
              )?_c('div',{staticClass:"no-result-container"},[_c('div',{staticClass:"empty-search-msg"},[_c('img',{attrs:{"src":require('pinnacle-lib/assets/icon/alert-circle-outlined.svg')}}),_c('h1',[_vm._v("No results found")]),_vm._m(1)])]):_vm._e(),(!_vm.resultsEmpty || !_vm.autocompleteEmpty)?[_c('SearchResults',{attrs:{"results":_vm.search.results,"searchResultHighlighted":_vm.ui.searchResultHighlighted},on:{"viewDetail":_vm.viewDetail,"mouseEnter":_vm.hoverSearchResult,"mouseLeave":_vm.hoverSearchResult,"loadMoreLocations":_vm.submitLoadMore}})]:_vm._e()],2)]],2)]:_vm._e()]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-toolbar"},[_c('div',{staticClass:"toolbar-header"},[_c('span',{staticClass:"searchText"},[_vm._v("Search Results")])]),_c('div',{staticClass:"toolbar-action"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"empty-search-info-msg"},[_c('h2',[_vm._v("Pinnacle does not have any instant insights for this place. You can: use Pinnacle Data for this site, run an insights report using Vista Insights and "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/customer-support"}},[_vm._v("drop us a note")]),_vm._v(" asking us to add this place to Pinnacle Places.")])])
}]

export { render, staticRenderFns }