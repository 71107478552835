import featurecollection from 'turf-featurecollection';
import bbox from '@turf/bbox';
import point from 'turf-point';
import buffer from '@turf/buffer';
import distance from '@turf/distance';


export default function getBounds(locations) {
  const points = [];
  let lastLoc = null;
  let dist = 0;
  for (const loc of locations) {
    if (typeof loc.centroid == 'string') loc.centroid = JSON.parse(loc.centroid);
    if (lastLoc) dist += distance(lastLoc.centroid.coordinates, loc.centroid.coordinates, {units: 'miles'});
    lastLoc = loc;
    points.push(point(loc.centroid.coordinates));
  }
  if (dist === 0) dist = 2;
  const fc = featurecollection(points);
  const buffered = buffer(fc, dist / locations.length, {units: 'miles'});
  const bounds = bbox(buffered);
  return [[bounds[0], bounds[1]], [bounds[2], bounds[3]]];
}