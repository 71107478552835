<template>

<div class="search-area-container">
  <button
    class="btn btn-primary search-area-btn"
    @click="searchArea"
    v-if="shouldRender"
  >
    <span> Search this area </span>

    <!-- SEARCH BUTTON -->
    <svg
      class="search-area-icon"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0536 6.82122C13.0536 9.9584 10.5102 12.5018 7.37297 12.5018C4.23579 12.5018 1.69238 9.9584 1.69238 6.82122C1.69238 3.68403 4.23579 1.14062 7.37297 1.14062C10.5102 1.14062 13.0536 3.68403 13.0536 6.82122Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0537 11.6265L18.3073 16.8596"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</div>

</template>

<script>

export default {

  props: ['shouldRender'],
  methods: {
    searchArea() {
      this.$emit('searchArea');
    }
  }

}

</script>

<style lang="scss" scoped>

.search-area-container {
  position: fixed;
  top: 2.5vh;
  right: 2.5vh;
  z-index: 1;

  .search-area-icon {
    margin-left: 5px;
  }

}

</style>