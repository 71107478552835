<template>
    <b-modal size="xl" centered hide-footer scrollable :visible="$route.query.exportPDF !== undefined"
      @hidden="removeExportPDFQuery()" @shown="getPDF(true)">
      <template #modal-header>
        <div class="pdf-export-header header">
          <div class="title-bar">
            <h2>Report PDF for <span class="title">"{{ exportReport.name }}"</span></h2>
            <img class="close-button" @click="closeExportPDFModal()" alt="close"
              :src="require('pinnacle-lib/assets/icon/close-icon.svg')" />

          </div>
          <!--div class="modal-description">
            <p>Create a link to share interactive visualization reports with anyone. A Pinnacle account is not required
              to access shared reports. Share links can be deactivated at any time.</p>
          </div-->

        </div>
      </template>
      <div class="body">
        <div class="modal-section">
          <div class="section-content">
            <div v-if="!loading.getPDF && pdfLinkStatus">
              <div v-if="pdfLinkStatus == 'denied'">
                <h5>Access Denied</h5>
              </div>
              <div v-else>
                <div v-if="pdfLink">
                  <h5>PDF Generated</h5>
                  <a :href="pdfLink">{{ pdfFilename }}</a>
                </div>
                <div v-else>
                  <h5>Generating PDF</h5>
                  <b-progress :value="pdfLinkProgress" variant="info" striped animated class="mt-2"></b-progress>
                </div>
              </div>
            </div>
            <div v-if="loading.getPDF" class="text-center w-100 my-2" style="color: #6e84a3">
              <WorldLoader></WorldLoader>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <br />
      </template>
    </b-modal>
</template>

<script>
import WorldLoader from "./WorldLoader.vue";
import CardUtil from "../../../services/cards/cardUtil";
export default {
  mixins: [CardUtil],
  components: {
    WorldLoader
  },
  data() {
    return {
      loading: {
        getPDF: false,
      },
      pdfLink: null,
      pdfFilename: null,
      pdfLinkStatus: null,
      pdfLinkProgress: 0,
    }
  },
  computed: {
    exportReport() {
      if (this.$route.query.exportPDF) {
        return JSON.parse(atob(this.$route.query.exportPDF));
      }
      return {};
    },
  },
  methods: {
    /**
     * Closes the modal.
     */
    closeExportPDFModal() {
      this.$router.push({
        query: {}
      });
      this.pdfLink = null;
      this.pdfLinkStatus = null;
    },
    async getPDF(initProgress = false) {
      // TODO handle case when modal closed before report is generated
      
      this.pdfLink = null;
      this.pdfFilename = null;
      console.log(initProgress);
      if (!initProgress) {
        this.pdfLinkProgress = this.pdfLinkProgress + 5;
        if (this.pdfLinkProgress > 100) {
          this.pdfLinkProgress = 100;
        }
      } else {
        this.pdfLinkStatus = null;
        this.loading.getPDF = true;
        this.pdfLinkProgress = 5;
      }
      console.log(this.pdfLinkProgress);
      try {
        const res = await this.getCardData(`/v2/get-pdf/${this.exportReport.id}`, true);
        console.log(res);
        //this.shares = res.data.data;
        if (res.data.url) {
          this.pdfLink = res.data.url;
          this.pdfFilename = res.data.filename;
        }
        if (res.data.status) {
          this.pdfLinkStatus = res.data.status;
        }
        if (this.pdfLinkStatus == "generating") {
          setTimeout(this.getPDF, 5000);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.getPDF = false;
      }
    },
    /**
     * Clears the share field from query params.
     */
     removeExportPDFQuery() {
      this.shares = [];
      setTimeout(() => {
        const query = Object.assign({}, this.$route.query);
        if ('exportPDF' in query) {
          delete query.exportPDF;
          this.$router.replace({ query });
        }
      }, 100);
    },
  },
}
</script>

<style lang="scss">

.close-button {
  cursor: pointer;
}

.header {
  display: flex;
  flex-direction: column;
  flex: 1;

  .modal-description {
    width: 65%;

    p {
      color: #858EA7;
    }
  }
}

.title-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;

  .title {
    color: #6E84A3;
  }

  h2 {
    font-weight: 700;
    margin-bottom:0;
  }

  img {
    width: 22px;
    height: 22px;
  }
}

.pdf-export-header {
  margin-bottom:0 !important;
  .title-bar {
    margin-bottom:0 !important;
  }
}

.modal-header,
.modal-body {
  padding: 3rem !important;
}

.modal-body {
  padding-top: 0 !important;
}

</style>