<template>
  <ChartCard
    id="week"
    :isLoading="isLoading"
    :isLoadingToLocation="isLoadingToLocation"
  >
    <template #icon>
      <img
        :src="require('pinnacle-lib/assets/icon/details-avatar.svg')"
        style="margin-right: 14px"
        alt="foot"
      />
    </template>
    <template #title>
      <h2>Visits by Day of Week</h2>
      <p>Percent of visits that occurred on a given day of the week</p>
    </template>
    <template #dropdown>
      <b-dropdown
        id="week-options-menu"
        ref="weekOptionsMenu"
        style="margin-right: -20px"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        data-html2canvas-ignore="true"
      >
        <template #button-content>
          <img :src="require('pinnacle-lib/assets/icon/elip.svg')" alt="foot" />
        </template>
        <li>
          <b-dropdown id="export-submenu" class="dropdown-item" variant="link">
            <template #button-content> Export </template>
            <b-dropdown-item
              @click="saveChart('Visits by Day of Week', '#week')"
              >Download Image</b-dropdown-item
            >
            <b-dropdown-item @click="saveData('Visits by Day of Week', 'week')"
              >Download Data</b-dropdown-item
            >
          </b-dropdown>
        </li>
        <li>
          <b-dropdown
            id="chart-type-submenu"
            class="dropdown-item"
            variant="link"
          >
            <template #button-content> Chart Type </template>
            <b-dropdown-item @click="changeView({ week: 'line' })"
              >Line Chart</b-dropdown-item
            >
            <b-dropdown-item @click="changeView({ week: 'bar' })"
              >Bar Chart</b-dropdown-item
            >
          </b-dropdown>
        </li>
        <div v-if="chartType.week === 'line'" class="dropdown-checkbox">
          Range Band
          <b-checkbox  style="margin-left: 10px;" @change="changeRange" v-model="showRange"></b-checkbox>
        </div>
      </b-dropdown>
    </template>
    <template #name-card>
      <name-card
        chart-type="dayWeek"
        v-bind:insufficient-data-ids="insufficientDataIds"
      ></name-card>
    </template>
    <template #charts-container>
      <div id="lineChart" v-if="chartType.week === 'line'">
        <line-chart
          ref="dayOfWeekLineChart"
          :chart-data="week.line.data"
          :options="week.line.option"
          :class="`${isLoading.dayOfWeek ? 'opacity-25' : 'opacity-100'}`"
        />
        <div style="position: absolute; top: 35%; left: 50%"></div>
      </div>
      <div id="barChart" v-if="chartType.week === 'bar'">
        <bar-chart
          ref="dayOfWeekBarChart"
          :chart-data="week.bar.data"
          :options="week.bar.option"
          :class="`${isLoading ? 'opacity-25' : 'opacity-100'}`"
        />
        <div style="position: absolute; top: 35%; left: 50%"></div>
      </div>
      <div v-if="showWarn" class="warning-container">
        <div class="warning">Data unavailable for the selected dates</div>
      </div>
    </template>
  </ChartCard>
</template>
<script>
import { isEqual } from "lodash";
import LineChart from "../charts/LineChart";
import BarChart from "../charts/BarChart";
import NameCard from "../partials/NameCard.vue";
import { bus } from "@/main";
import {
  getLineGraphOptionsNoTime,
  getBarGraphOptionsNoTime,
} from "@/config/chart";
import MultiDropdownMixin from "pinnacle-lib/mixins/multiDropdown.js";
import ChartsDownloadMixin from "../../services/charts/download";
import ChartUtilMixin from "../../services/charts/chartUtil.js";
import ChartCard from "./chartCard.vue";

export default {
  components: {
    BarChart,
    LineChart,
    NameCard,
    ChartCard,
  },
  mixins: [ChartsDownloadMixin, MultiDropdownMixin, ChartUtilMixin],
  data() {
    return {
      attempt: false,
      week: {
        line: {
          data: null,
          option: null,
        },
        bar: {
          data: null,
          option: null,
        },
      },
      chartType: {
        month: "line",
        day: "bar",
        week: "bar",
      },
      isLoading: true,
      insufficientDataIds: [],
      show: [true, true, true, true, true],
      showRange: true,
      showWarn: false,
      chartRefs: ["dayOfWeekLineChart", "dayOfWeekBarChart"]
    };
  },
  props: {
    filterString: {
      type: String,
    },
    id: {
      type: Array,
    },
  },
  methods: {
    changeRange(){
      console.log(this.showRange)
      this.loadChart();
    },
    saveCSV() {
      const labels = ["Place", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      this.saveVisitsByCSV(this.week.line.data.datasets, labels, "setWeekChart");
    },
    changeView(view) {
      if (!isEqual(view, this.chartType)) {
        this.chartType = { ...this.chartType, ...view };
      }
    },
    createData(weekEntries) {
      const { color } = this.chartColors;
      for (let i = 0; i < this.id.length; i++) {
        let polygon = {};
        polygon = {
          label: this.location[i].shortenedName,
          data: weekEntries[this.id[i]].map((values) =>
            values.percent.toFixed(2)
          ),
          borderColor: color[i],
          backgroundColor: color[i],
          fill: false,
          hidden: !this.show[i],
          categoryPercentage: 0.3,
          barPercentage: 1,
          datalabels: {
            display: false,
          },
        };
        this.week.line.data.datasets.push(polygon);
        this.week.bar.data.datasets.push(polygon);
      }
    },
    setData(res) {
      const [weekEntries, median] = res;
      if (!Object.entries(weekEntries).length) return;
      // calculate min_line and max_line
      const { minLine, maxLine } = this.calcMinMax(this.weekData["series_data"], "percent", "dow");
      this.week = {
        line: {
          data: {
            weekData: [],
            labels: median.map((entry) => entry.label),
            datasets: [
              {
                label: this.medianName + ":",
                data: median.map((values) => values.percent.toFixed(2)),
                borderColor: "#B1C2D9",
                borderDash: [20, 10],
                borderWidth: 1,
                backgroundColor: "#B1C2D9",
                fill: false,
                hidden: !this.showMedian,
                type: "line",
                datalabels: {
                  display: false,
                },
              },
              {
                label: "Max",
                data: maxLine.map(({ ...values }) =>
                  values.percent.toFixed(2)
                ),
                borderColor: "rgba(177, 194, 217, 0)",
                fill: "+1",
                hidden: !this.showRange,
                // backgroundColor: "rgba(177, 194, 217, 0.2)",
                backgroundColor: "#F8FAFD",
                datalabels: {
                  display: false,
                },
              },
              {
                label: "Min",
                data: minLine.map(({ ...values }) =>
                  values.percent.toFixed(2)
                ),
                borderColor: "rgba(177, 194, 217, 0)",
                fill: "+1",
                hidden: !this.showRange,
                // backgroundColor: "rgba(177, 194, 217, 0.2)",
                backgroundColor: "#F8FAFD",
                datalabels: {
                  display: false,
                },
              },
            ],
          },
          option: getLineGraphOptionsNoTime({
            labelStringXAxes: "Day of Week",
            labelStringYAxes: "Percent of Visits",
            tooltipSuffix: "%",
          }),
        },
        bar: {
          data: {
            labels: median.map((entry) => entry.label),
            datasets: [
              {
                label: this.medianName + ":",
                data: median.map((values) => values.percent.toFixed(2)),
                borderColor: "#B1C2D9",
                borderDash: [20, 10],
                borderWidth: 1,
                backgroundColor: "#B1C2D9",
                fill: false,
                hidden: !this.showMedian,
                type: "line",
                datalabels: {
                  display: false,
                },
              },
              {},
              {},
            ],
          },
          option: getBarGraphOptionsNoTime({
            labelStringXAxes: "Day of Week",
            labelStringYAxes: "Percent of Visits",
            tooltipSuffix: "%",
          }),
        },
      };
      this.createData(weekEntries);
      this.saveCSV();
      this.isLoading = false;
      if (this.$refs.dayOfWeekBarChart && this.$refs.dayOfWeekBarChart.render) {
        this.$refs.dayOfWeekBarChart.render();
      }
    },
    loadChart() {
      this.requestData().then((res) => {
        this.setData(res);
      });
    },
    async requestData() {
      this.showWarn = false;
      const promises = [this.getChartData(`/v2/day-of-week/${this.$route.params.ids}`)];
      const responses = await Promise.allSettled(promises);
      // Check Insufficient Data
      let insufficientDataIds =
        responses[0].value.data.meta_data.insufficientDataIds;
      let scope = this;
      if (insufficientDataIds) {
        this.insufficientDataIds = insufficientDataIds;
      }
      const seriesData = Object.entries(responses[0].value.data.series_data);
      if (this.insufficientDataIds.length === seriesData.length) {
        this.showWarn = true;
        this.isLoading = false;
        return;
      }
      let week = responses[0].value.data;
      this.weekData = responses[0].value.data;
      // let max = day.aggregation_data.max_line;
      // let min = day.aggregation_data.min_line;
      let median = week.aggregation_data.median_data
        ? week.aggregation_data.median_data
        : [];
      let weekEntries = week.series_data;
      return [weekEntries, median];
    },
  },
  mounted() {
    this.registerMultiDropdown(this.$refs.weekOptionsMenu);
    let ids = this.id;
    let tempArr = [true, true, true, true, true];
    Object.entries(this.disabledIds).forEach(function (o) {
      if (ids.indexOf(o[0]) !== undefined) {
        tempArr[ids.indexOf(o[0])] = false;
      }
    });
    this.show = tempArr;
    //this.loadChart();  // Wait until filters event before populating chart
    bus.$on("locationsListChanged", (res) => {
      const rangeBandData = this.calcMinMax(this.weekData.series_data, "percent", "dow");
      this.handleLocationsListChanged(res, this.week, rangeBandData, (line) => {
        return line.map(values => values.percent.toFixed(2));
      });
      this.renderChartRefs();
    });
    bus.$on("filters", (res) => {
      //this.filterString = res;
      this.isLoading = true;
      if (this.isLoadingToLocation === false) {
        this.requestData().then((res) => {
          this.setData(res);
        });
      } else {
        this.attempt = true;
      }
    });
    bus.$on("onHover", (res) => this.handleOnHover(res));
  },
  computed: {
    showMedian() {
      return this.$sessionStore.state.study.showMedian;
    },
    location() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split("/")[1] === "preview"
        ? this.$sessionStore.state.study.previews
        : this.$sessionStore.state.study.locations;
    },
    isLoadingToLocation() {
      return this.$sessionStore.state.study.loading;
    },
    disabledIds() {
      return this.$sessionStore.state.study.disabledIds;
    },
    medianName() {
      return this.$sessionStore.state.study.name;
    },
  },
  watch: {
    isLoadingToLocation() {
      if (!this.isLoadingToLocation && this.attempt) {
        this.requestData().then((res) => {
          this.setData(res);
        });
      }
    },
  },
  // beforeDestroy() {
  //   this.unwatch();
  // },
};
</script>

<style lang="scss" scoped>
@import "./chartStyles.scss";
</style>