<template>
  <SignLog :form-name="'Forgot Password'" />
</template>

<script>
import SignLog from "@/components/form/SignLog";

export default {
  components: {
    SignLog,
  },
};
</script>

<style lang="scss" scoped></style>