<template>
  <ReportOptions>
    <div>
      <div class="switchContainer">
        <div class="text-container">
          <p>Include Full Geographic Information</p>
          <p class="subtext">
            Turning Include Full Geo on will provide additional information for
            Geo Rollup datasets including Country, State, Municipality, Postal
            Codes and other governmental boundaries. This choice applies to the Common Evening and Daytime Legacy
            Location Dataset, the Resident Worker Dataset, and the Visitors Home Dataset.
          </p>
        </div>
        <b-checkbox value="true" v-model="outputOptions.includeFullGeo" switch class="scatterToggle"
          @change="switchHandler()"></b-checkbox>
      </div>
    </div>
  </ReportOptions>
</template>
<script>
import ReportOptions from "../shared/ReportOptions.vue";
import { syncState } from "../../../utils/syncState";
export default {
  components: { ReportOptions },
  computed: {
    ...syncState({
      outputOptions: 'state.jobRequest.reportOutputOptions|setReportOutputOptions'
    }, { context: this, defaultStore: '$sessionStore' })
  }
};
</script>

<style lang="scss" scoped>
.radioContainer {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  margin-top: 40px;
  margin-left: 25%;
  margin-right: 25%;
}

.radio {
  // text-align: center;
  // margin-top: 3.5px;
  margin-left: 20px;
  margin-right: 5px;
}

.switchContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .text-container {
    width: 50%
  }
}

.scatterToggle {
  margin-left: 2rem;
}
</style>