<template>
  <PlaceDetails>
    <template v-slot:study-icon>
      <div
        class="brand-img brand-avatar brand-avatar-64"
        :style="getImgContainerStyle(location)"
        :class="className"
        id="target"
        @mouseover="hoverHandler(true)"
        @mouseleave="hoverHandler(false)"
        @click="toggleLocation(pinCheckbox, location)"
      >
        <img
          :src="getCategoryAvatar(location)"
          :style="getImgStyle(location)"
        />
        <img
          :class="{ eye: !hover || !pinCheckbox || !isInStudy }"
          :src="require('pinnacle-lib/assets/icon/white-eye.svg')"
          @click="toggleLocation(false, location)"
        />
        <img
          :class="{ eye: !hover || pinCheckbox || !isInStudy }"
          :src="require('pinnacle-lib/assets/icon/white-no-eye.svg')"
          @click="toggleLocation(true, location)"
        />
        <b-tooltip
          target="target"
          triggers="hover"
          placement="right"
          boundary="parent"
          animation="false"
          interactive="false"
          v-if="isInStudy && $route.name !== 'preview-map-details'"
        >
          {{ pinCheckbox ? "Hide Insights" : "Show Insights" }}
        </b-tooltip>
      </div>
    </template>
    <template v-slot:study-checkbox>
      <img
        style="cursor: pointer"
        class="placesCheckboxes"
        :src="require('pinnacle-lib/assets/icon/play-check.svg')"
        id="remove-target-"
        @click="studyChanged({ location, status: false })"
        :class="{ eye: !isInStudy }"
      />
      <b-tooltip target="remove-target-" triggers="hover" placement="top-right">
        Remove from comparison
      </b-tooltip>
      <img
        style="cursor: pointer"
        class="placesCheckboxes"
        :src="require('pinnacle-lib/assets/icon/play-plus.svg')"
        id="add-target-"
        @click="studyChanged({ location, status: true })"
        :class="{ eye: isInStudy || !not5Locations }"
      />
      <b-tooltip target="add-target-" triggers="hover" placement="top-right">
        Add to comparison
      </b-tooltip>
    </template>

    <template v-slot:buttons>
      <template v-if="isInStudy && !previewRoute">
        <div class="button-container">
          <button
            class="primary-buttonB"
            @click="$router.push({ path: `/preview/${location.id}/charts` })"
          >
            Open Insights
          </button>
        </div>
      </template>

      <!-- btns for preview-map-details -->

      <!-- <template v-else-if="previewRoute">
        <div class="button-container">
          <button class="primary-button" @click="compareHandler()">
            Compare
          </button>
          <template v-if="!selectedLocation">
            <button class="secondary-button" @click="previewOpenInsights()">
              Open Insights
            </button>
          </template>

          <template v-else>
            <button class="secondary-button" @click="copyPreviewLink()">
              Copy Link
            </button>
          </template>
        </div>
      </template> -->
      <template v-else-if="previewRoute">
        <div class="button-container">
          <button
            class="preview-primary-button opened-button"
            v-if="isInPreview"
          >
            Opened
          </button>
          <button
            class="preview-primary-button open-insights-button"
            @click="$router.push({ path: `/preview/${location.id}/charts` })"
            v-if="!isInPreview"
          >
            Open Insights
          </button>
          <button
            @click="copyLink"
            class="preview-secondary-button copy-button"
          >
            <img :src="require('pinnacle-lib/assets/icon/link-icon.svg')" alt="Copy Button" />
            Copy Link
          </button>
        </div>
      </template>

      <template v-else-if="!isInStudy && not5Locations && !previewRoute">
        <div class="button-container">
          <button
            class="primary-buttonB"
            @click="$router.push({ path: `/preview/${location.id}/charts` })"
          >
            Open Insights
          </button>
          <template v-if="isInStudy">
            <button
              class="secondary-button"
              @click="studyChanged({ location, status: false })"
            >
              Remove
            </button>
          </template>
        </div>
      </template>

      <template v-else>
        <div class="button-container">
          <button
            class="primary-button-long"
            @click="$router.push({ path: `/preview/${location.id}/charts` })"
          >
            Open Insights
          </button>
        </div>
      </template>
    </template>
  </PlaceDetails>
</template>

<script>
import PlaceDetails from "@/components/partials/PlaceDetails.vue";
import { bus } from "@/main";

export default {
  components: { PlaceDetails },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    pinCheckbox() {
      return this.disabledIds[this.location.id] === undefined ? true : false;
    },
    location() {
      const parsed = JSON.parse(atob(this.$route.query.place));
      return parsed;
    },
    studyLocations() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      // return this.$route.path.split('/')[1] === 'preview' ? this.$sessionStore.state.study.previews : this.$sessionStore.state.study.locations;
      return this.$sessionStore.state.study.locations;
    },
    previewLocations() {
      return this.$sessionStore.state.study.previews;
    },
    isInStudy() {
      return this.studyLocations.some((loc) => loc.id === this.location.id);
    },
    isInPreview() {
      return this.previewLocations.some((loc) => loc.id === this.location.id);
    },
    not5Locations() {
      return this.studyLocations.length < 5;
    },
    previewRoute() {
      return this.$route.name === "preview-map-details";
    },
    selectedLocation() {
      return this.location.id === this.$route.path.split("/")[2];
    },
    disabledIds() {
      return this.$sessionStore.state.study.disabledIds;
    },
    // selectedStudyLocations (){
    //   return this.$sessionStore.state.study.locations;
    // },
    className() {
      if (this.pinCheckbox) {
        let num = parseInt(this.index) + 1;
        return "colorBord" + num;
      } else {
        return "unSelected";
      }
    },
    index() {
      return this.studyLocations.findIndex((object) => {
        return object.id === this.location.id;
      });
    },
  },
  methods: {
    hoverHandler(state) {
      if (this.$route.name === "preview-map-details") {
        return;
      }
      this.hover = state;
    },
    studyChanged(evt) {
      const { status, location } = evt;
      if (status) {
        if (this.studyLocations.length === 5) return;
        // Append the location to the study if not already there

        //preview route

        if (this.$route.path.split("/")[1] === "preview") {
          const previewLocation = this.previewLocations[0];

          if (previewLocation.id === location.id) {
            // centroid check
            if (typeof location.centroid === "string")
              location.centroid = JSON.parse(location.centroid);
            const newLocation = location;

            const newLocations = [...this.studyLocations, newLocation];
            this.$sessionStore.commit("setLocations", newLocations);
          } else if (
            this.studyLocations.some(
              (selectedLocation) => selectedLocation.id === previewLocation.id
            )
          ) {
            // centroid check
            if (typeof location.centroid === "string")
              location.centroid = JSON.parse(location.centroid);
            const newLocation = location;
            const newLocations = [...this.studyLocations, newLocation];
            this.$sessionStore.commit("setLocations", newLocations);
          } else {
            if (typeof location.centroid === "string")
              location.centroid = JSON.parse(location.centroid);
            const newLocation = location;
            if (typeof previewLocation.centroid === "string")
              previewLocation.centroid = JSON.parse(previewLocation.centroid);
            const newPreviewLocation = previewLocation;
            const newLocations = [
              ...this.studyLocations,
              newPreviewLocation,
              newLocation,
            ];
            this.$sessionStore.commit("setLocations", newLocations);
          }
        }
        //comparison route
        else {
          this.$router.push({
            params: { ids: this.$route.params.ids + `,${location.id}` },
            query: this.$route.query,
          });

          const newLocations = [...this.studyLocations, location];
          this.$sessionStore.commit("setLocations", newLocations);
        }
        // const newLocations = [...this.studyLocations, location];
        // this.$sessionStore.commit(`set${
        //   this.$route.path.split('/')[1] === 'preview' ? 'Previews' : 'Locations'
        // }`, newLocations);
      } else {
        // Remove the location
        const newLocations = this.studyLocations.filter(
          (loc) => loc.id !== location.id
        );
        const oldIds = this.$route.params.ids.split(",");

        this.$router.push({
          params: { ids: oldIds.filter((id) => id != location.id).join(",") },
          query: this.$route.query,
        });
        this.$sessionStore.commit(
          `set${
            this.$route.path.split("/")[1] === "preview"
              ? "Previews"
              : "Locations"
          }`,
          newLocations
        );

        //    this.studyLocationMarkers[location.id].remove();
        // delete this.studyLocationMarkers[location.id];

        if (
          this.studyLocations.length === 0 ||
          (this.$route.path.split("/")[1] === "preview" &&
            this.studyLocations.length <= 1)
        ) {
          this.$router.push({ path: "/explore" });
          return;
        }
      }
      bus.$emit("updateMapMarkersOn");
    },
    toggleLocation(val, location) {
      // if (this.bool == "false") {
      //   this.bool = true;
      // } else {
      //   this.bool = false;
      // }
      this.pinCheckbox = val;
      // this.checked = !this.checked;
      const disabledIds = Object.assign({}, this.disabledIds);

      if (!val) disabledIds[location.id] = true;
      else delete disabledIds[location.id];
      this.$sessionStore.commit("setDisabledIds", disabledIds);

      // a function to emit selectedLocation to Charts to remove it
      bus.$emit("locationsListChanged", {
        selected: val,
        locationId: location.id,
      });
    },
    hideLocation(evt) {
      this.pinCheckbox = !this.pinCheckbox;
      const { status, location } = evt;
      bus.$emit("togglePins", status, location);
    },
    // copyPreviewLink() {
    //   const clipboardInput = document.createElement("input");
    //   const url = window.location.href;
    //   document.body.appendChild(clipboardInput);
    //   clipboardInput.value = url;
    //   clipboardInput.select();
    //   document.execCommand("copy");
    //   document.body.removeChild(clipboardInput);

    //   bus.$emit("copyLinkAlert");
    // },
    copyLink() {
      const clipboardInput = document.createElement("input");
      const url = window.location.href;
      document.body.appendChild(clipboardInput);
      clipboardInput.value = url;
      clipboardInput.select();
      document.execCommand("copy");
      document.body.removeChild(clipboardInput);

      bus.$emit("copyLinkAlert");
    },
    compareHandler() {
      if (typeof this.location.centroid === "string")
        this.location.centroid = JSON.parse(this.location.centroid);
      const newLocations = [this.location];
      this.$sessionStore.commit("setLocations", newLocations);
      this.$router.push({ path: `/explore/search` });
    },
    previewOpenInsights() {
      this.$router.push({ path: `/preview/${this.location.id}/charts` });
    },
  },
};
</script>

<style lang="scss" scoped>
@function select($index) {
  @return nth($location-colors, $index);
}

.preview-secondary-button {
  width: 40%;
  border-radius: 4px;
  border-width: 0;
  margin-bottom: 16px;
  margin-left: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #edf2f9;
  color: #6e84a3;
}

.preview-primary-button {
  width: 60%;
  height: 46px;
  border-radius: 4px;
  border-width: 0;
  margin-bottom: 16px;
}

.opened-button {
  background: #6e84a3;
  color: #ffffff;
}

.open-insights-button {
  background: #2c7be5;
  color: #ffffff;
}

.study-checkbox {
  ::v-deep .custom-control-label::after {
    background-color: white;
    border: 1px solid rgba(192, 196, 209, 1);
    border-radius: 3px;
  }

  ::v-deep .custom-control-input:checked ~ .custom-control-label::after {
    background-color: $blue;
    border: 1px solid $blue;
  }

  ::v-deep .custom-control-input:disabled ~ .custom-control-label::after {
    background-color: lightgray;
    border: 1px solid rgba(192, 196, 209, 1);
  }
}

.checkboxHidden {
  display: none !important;
}

.button-container {
  width: 100%;
  padding: 0 1.5rem 16px 1.5rem;
  display: flex;
  justify-content: space-between;
  .secondary-button {
    min-width: 49%;
    border-radius: 4px;
    border-width: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #2c7be5;
    color: #ffffff;
  }
  .primary-button {
    width: 98%;
    border-radius: 4px;
    border-width: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #edf2f9;
    color: #6e84a3;
  }
  .primary-buttonB {
    width: 98%;
    border-radius: 4px;
    border-width: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #2c7be5;
    color: #ffffff;
  }

  .secondary-button:disabled {
    background-color: lightgray;
  }
  .primary-button-long {
    width: 100%;
    border-radius: 4px;
    border-width: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #edf2f9;
    color: #6e84a3;
  }
}
.eye {
  display: none;
}
.unSelected {
  border: 4px solid #e3ebf6 !important;
  &:hover {
    background: rgba(22, 34, 68, 0.5) !important;
  }
}
.colorBord1 {
  border: 4px solid select(1) !important;
  &:hover {
    background: rgba(22, 34, 68, 0.5) !important;
  }
}
.colorBord2 {
  border: 4px solid select(2) !important;
  &:hover {
    background: rgba(22, 34, 68, 0.5) !important;
  }
}
.colorBord3 {
  border: 4px solid select(3) !important;
  &:hover {
    background: rgba(22, 34, 68, 0.5) !important;
  }
}
.colorBord4 {
  border: 4px solid select(4) !important;
  &:hover {
    background: rgba(22, 34, 68, 0.5) !important;
  }
}
.colorBord5 {
  border: 4px solid select(5) !important;
  &:hover {
    background: rgba(22, 34, 68, 0.5) !important;
  }
}
</style>