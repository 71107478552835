<template>
  <div class="lottie-container">
    <vue-lottie-player
      autoplay
      loop
      mode="normal"
      path="https://assets8.lottiefiles.com/packages/lf20_ni5dd3q1.json"
      width="75px"
      height="80.5px"
      name="mail"
    />
  </div>
</template>

<script>
import VueLottiePlayer from "vue-lottie-player";
export default {
  name: "MailIcon",
  components: {
    VueLottiePlayer,
  },
};
</script>

<style lang="scss" scoped>
</style>
