<template>
    <b-modal id="embed-modal" centered hide-header hide-footer  no-close-on-esc >
      <h3 style="margin:15px 0">Embed HTML</h3>
      
      <div class="embed-input-container" style="margin:15px 0">
        <b-textarea
          id="embedInputContent"
          name="embedInputContent"
          rows="5"
          width="100%"
          v-model="embedInputContent"
          readonly
        >
        </b-textarea>
        <b-icon @click="copyToClipboard" icon="clipboard" class="copy-icon" font-scale="2"></b-icon>
      </div>

      <button
          class="primary-buttonB"
          variant="primary"
          @click='$bvModal.hide("embed-modal")'
          >
          Done
      </button>    
  </b-modal>
</template>

<script>
export default {
  name: "EmbedModal",
  props: ["embedSrc"],
  computed: {
    embedInputContent() {      
      return `<iframe src="${this.embedSrc}" frameBorder="0" width="100%" height="600px"></iframe>`;
    },
  },
  methods: {
    copyToClipboard() {
      const $input = $('.embed-input-container').find('textarea');

      /* Select the text field */
      $input.select();

      /* Copy the text inside the text field */
      document.execCommand("copy");
      alert('Copied to Clipboard');
    }
  }
};
</script>

<style lang="scss" scoped>
    .primary-buttonB{
        width: 100%;
        border-radius: 4px;
        border-width: 0;
        padding-top: 12px;
        padding-bottom: 12px;
        background: #2c7be5;
        color: #ffffff;
    }
    .embed-input-container {
      position:relative;
      .copy-icon {
        position:absolute;
        top: 5px;
        right: 5px;
        cursor:pointer;
        color: #ccc;
        z-index: 1;
      }
    }
    .modal-header, .modal-body {
      padding-bottom:0 !important;
    }
</style>