<template>
  <div class="color-pill" :style="{ backgroundImage: gradientString }">
  </div>
</template>

<script>

export default {
  name: "ColorPill",
  components: {
    
  },
  props: ['colors'],
  computed: {
    gradientString() {
      if (this.colors.length === 1) {
        return `${this.colors[0]}`;
      } else {
        return `linear-gradient(90deg, ${this.colors[0]}, ${this.colors[1]})`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.color-pill {
  width: 24px;
  height: 12px;
  border-radius: 12px;
}
</style>
