import { byString } from "./byString";

function validateWithContext(context, store, accessor) {
  // Extra validation
  try {
    byString(context[store], accessor);
  } catch (err) {
    return {success: false, error: err}
  }
  return {success: true};
}

function createGetProp(store, accessor) {
  return {
    get() {
      const value = byString(this[store], accessor);
      if (value === undefined) {
        console.warn(`Tried to fetch ${accessor} from ${store}, but it returned undefined`);
      }
      return value;
    }
  };
}

function createGetSetProp(store, accessor, mutation) {
  return {
    ...createGetProp(store, accessor),
    set(val) {
      this[store].commit(mutation, val);
    }
  };
}

export function syncState(map, {context, defaultStore='$sessionStore'}) {
  let mapped = {};

  for (const key of Object.keys(map)) {

    const cfg = map[key];

    if (typeof cfg === 'object') {
      if (cfg.accessor === undefined) throw {msg: 'No accessor provided in syncState cfg'};

      if (context !== undefined) {
        const { success, error } = validateWithContext(context, cfg.store || defaultStore, cfg.accessor);
        if (!success) throw error;
      }

      if (cfg.mutation) {
      
        mapped[key] = {
          get() {
            return byString(this[cfg.store || defaultStore], cfg.accessor);
          },
          set(val) {
            this[cfg.store || defaultStore].commit(cfg.mutation, val);
          }
        };

      } else {
        mapped[key] = {
          get() {
            return byString(this[cfg.store || defaultStore], cfg.accessor);
          }
        };
      }
    } else if (typeof cfg === 'string') {

      const split = cfg.split('|');

      const accessor = split[0];
      const mutation = split[1];
      const store = split[2];

      if (accessor === undefined) throw { message: 'No accessor provided.' };

      if (context !== undefined) {
        const { success, error } = validateWithContext(context, store || defaultStore, accessor);
        if (!success) throw error;
      }

      if (mutation !== undefined) {
        mapped[key] = createGetSetProp(store || defaultStore, accessor, mutation);
      } else {
        mapped[key] = createGetProp(store || defaultStore, accessor);
      }
      
    }
  }

  return mapped;

}