<template>
  <div class="rpdp-container">
    <div class="d-flex rightPaneHeader">
      <p>Time Period Selection</p>
      <div class="d-flex rightPaneCloseBtn">
        <img
          :src="require('pinnacle-lib/assets/icon/maps-minimize-icon.svg')"
          @click="closeDatePicker"
          class="rightPane-closeBtn"
          v-b-tooltip.hover="{ customClass: 'dtp-tooltip', placement:'right'}" 
         alt="Minimize"
         title="Minimize"
        />
      </div>
    </div>

    <div class="d-flex time-period-selection">
      <h4>Time Period Selection</h4>
    </div>

    <!--- START CALENDAR -->

    <div class="d-flex start-period">
      <p>Start of Period</p>
      <!-- month-->
      <div class="d-flex start-calendars">
        <div class="month-picker">
          <div class="d-flex month-box">
            <p>Month</p>
            <div class="selectedMonth">{{ startMonthSelected }}</div>
            <div class="month-calendar">
              <b-dropdown>
                <b-dropdown-item
                  v-for="month in months.options"
                  :key="month"
                  :value="month"
                  @click="getStartMonthOption(month)"
                >
                  {{ month }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <img :src="require('pinnacle-lib/assets/icon/arrow-down-select.svg')" alt="select-date" />
          </div>
        </div>
        <!-- day --->
        <div class="d-flex calendar-card day-picker">
          <p>Day</p>
          <div class="selectedDay">{{ startDaySelected }}</div>
          <b-form-datepicker
            id="start-datepicker"
            v-model="startDateSelected"
            class="endDatePicker"
            :start-weekday="weekday"
            :locale="locale"
            :weekday-header-format="dayFormat"
            no-highlight-today
            button-only
          ></b-form-datepicker>
          <img :src="require('pinnacle-lib/assets/icon/arrow-down-select.svg')" alt="select-date" />
        </div>
        <!-- year -->
        <div class="d-flex calendar-card year-picker">
          <p>Year</p>
          <div class="selectedYear">{{ startYearSelected }}</div>
          <div class="yearBox">
            <b-dropdown class="yearDropdown">
              <b-dropdown-item
                v-for="year in yearOptions"
                :key="year"
                :value="year"
                @click="getStartYearOption(year)"
              >
                {{ year }}</b-dropdown-item
              >
            </b-dropdown>
            <img
              class="dropdown-arrow"
              :src="require('pinnacle-lib/assets/icon/arrow-down-select.svg')"
              alt="select-date"
            />
          </div>
        </div>
      </div>

      <div class="d-flex dateErrorMessage" v-if="!startDateValidated">
        <img :src="require('pinnacle-lib/assets/icon/error-dates.svg')" alt="date-error" />
        <p>Error, the selected start date is invalid.</p>
        </div>
    </div>

    <!--- END CALENDAR -->

    <div class="d-flex end-period">
      <p>End of Period</p>
      <!-- month -->
      <div class="d-flex end-calendars">
        <div class="month-picker">
          <div class="d-flex month-box">
            <p>Month</p>
            <div class="selectedMonth">{{ endMonthSelected }}</div>
            <div class="month-calendar">
              <b-dropdown>
                <b-dropdown-item
                  v-for="month in months.options"
                  :key="month"
                  :value="month"
                  @click="getEndMonthOption(month)"
                >
                  {{ month }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <img :src="require('pinnacle-lib/assets/icon/arrow-down-select.svg')" alt="select-date" />
          </div>
        </div>

        <div class="d-flex calendar-card day-picker">
          <p>Day</p>
          <div class="selectedDay">{{ endDaySelected }}</div>
          <b-form-datepicker
            id="end-datepicker"
            v-model="endDateSelected"
            class="endDatePicker"
            :start-weekday="weekday"
            :locale="locale"
            :weekday-header-format="dayFormat"
            no-highlight-today
            button-only
          ></b-form-datepicker>
          <img :src="require('pinnacle-lib/assets/icon/arrow-down-select.svg')" alt="select-date" />
        </div>
        <!-- year -->
        <div class="d-flex calendar-card year-picker">
          <p>Year</p>
          <div class="selectedYear">{{ endYearSelected }}</div>

          <div class="yearBox">
            <b-dropdown class="yearDropdown">
              <b-dropdown-item
                v-for="year in yearOptions"
                :key="year"
                :value="year"
                @click="getEndYearOption(year)"
              >
                {{ year }}</b-dropdown-item
              >
            </b-dropdown>
            <img
              class="dropdown-arrow"
              :src="require('pinnacle-lib/assets/icon/arrow-down-select.svg')"
              alt="select-date"
            />
          </div>
        </div>

      </div>
    <div class="d-flex dateErrorMessage" v-if="!endDateValidated">
        <img :src="require('pinnacle-lib/assets/icon/error-dates.svg')" alt="date-error" />
        <p>Error, the selected end date is invalid.</p>
    </div>

    </div>
    <!---SHORTCUTS --->
    <div class="d-flex shortcuts">
      <p>Shortcuts</p>
      <div class="shortcutsBtns">
        <b-button class="shortcutBtn" @click="shortcutClicked(allTime)">
          <p class="shortcut-name">All Time</p>
          <p class="shortcut-time">{{ allTime }}</p>
        </b-button>
        <b-button class="shortcutBtn" @click="shortcutClicked(previousWeek)">
          <p class="shortcut-name">Previous Week</p>
          <p class="shortcut-time">{{ previousWeek }}</p>
        </b-button>
        <b-button
          class="shortcutBtn"
          @click="shortcutClicked(previousTwoWeeks)"
        >
          <p class="shortcut-name">Previous Two Weeks</p>
          <p class="shortcut-time">{{ previousTwoWeeks }}</p>
        </b-button>
        <b-button class="shortcutBtn" @click="shortcutClicked(previousMonth)">
          <p class="shortcut-name">Previous 1 Month</p>
          <p class="shortcut-time">{{ previousMonth }}</p>
        </b-button>
        <b-button class="shortcutBtn" @click="shortcutClicked(previous6Month)">
          <p class="shortcut-name">Previous 6 Months</p>
          <p class="shortcut-time">{{ previous6Month }}</p>
        </b-button>
        <b-button class="shortcutBtn" @click="shortcutClicked(previousYear)">
          <p class="shortcut-name">Previous Year</p>
          <p class="shortcut-time">{{ previousYear }}</p>
        </b-button>
        <b-button class="shortcutBtn" @click="shortcutClicked(previous2Years)">
          <p class="shortcut-name">Previous 2 Years</p>
          <p class="shortcut-time">{{ previous2Years }}</p>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { bus } from "@/main";
import { range } from "lodash";
let current_year = new Date().getFullYear();
let yearOptions = range(2019, current_year+1).map(String);

export default {
  data() {
    return {
      months: {
        options: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ],
      },
      yearOptions: yearOptions,

      startMonthSelected: "",
      endMonthSelected: "",
      startDateSelected: "",
      endDateSelected: "",
      startYearSelected: "",
      endYearSelected: "",
      startDaySelected: "",
      endDaySelected: "",

      format: "mm/dd/yyyy",
      weekday: 1,
      weekdays: [
        { value: 0, text: "S" },
        { value: 1, text: "M" },
        { value: 2, text: "T" },
        { value: 3, text: "W" },
        { value: 4, text: "T" },
        { value: 5, text: "F" },
        { value: 6, text: "S" },
      ],
      locale: "en",
      dayFormat: "narrow",
      selectedRange: "",

    };
  },
  mounted() {
    if (Object.keys(this.$sessionStore.state.study.dateRange).length === 0) {
      let defaultStart = moment()
        .subtract(1, "year")
        .subtract(2, "days")
        .format("MM/DD/YY");
      let defaultEnd = moment().subtract(2, "days").format("MM/DD/YY");
      let defaultStartDate = defaultStart.split("/");
      let defaultEndDate = defaultEnd.split("/");
      
      this.startDaySelected = defaultStartDate[1];
      this.endDaySelected = defaultEndDate[1];
      this.startMonthSelected = defaultStartDate[0];
      this.endMonthSelected = defaultEndDate[0];
      this.startYearSelected = defaultStartDate[2];
      this.endYearSelected = defaultEndDate[2];
    } else {
      let startDateStorage =
        this.$sessionStore.state.study.dateRange.start_date.split("/");
      let endDateStorage =
        this.$sessionStore.state.study.dateRange.end_date.split("/");
      this.startDaySelected = startDateStorage[1];
      this.endDaySelected = endDateStorage[1];
      this.startMonthSelected = startDateStorage[0];
      this.endMonthSelected = endDateStorage[0];
      this.startYearSelected = startDateStorage[2];
      this.endYearSelected = endDateStorage[2];
    }
    },
  methods: {
    closeDatePicker() {
      bus.$emit("closeDatePicker");
    },
    getStartMonthOption(month) {
      this.startMonthSelected = month;
      this.updateDateRange();
    },
    getStartYearOption(year) {
      this.startYearSelected = year;
      this.updateDateRange();
    },
    getEndMonthOption(month) {
      this.endMonthSelected = month;
      this.updateDateRange();
    },
    getEndYearOption(year) {
      this.endYearSelected = year;
      this.updateDateRange();
    },
    shortcutClicked(value) {
      this.selectedRange = value;
    },
    updateDateRange() {
      if(this.startDateValidated && this.endDateValidated){
        bus.$emit('update-date-range', this.newStartDateSelected, this.newEndDateSelected);
      }
    }
  },
  computed: {
    newStartDateSelected() {
      return `${this.startMonthSelected}/${this.startDaySelected}/${this.startYearSelected}`;
    },
    newEndDateSelected() {
      return `${this.endMonthSelected}/${this.endDaySelected}/${this.endYearSelected}`;
    },
    allTime() {
      let startAllDay = "01/01/19";
      let endAllDay = moment().subtract(2, "days").format("MM/DD/YY");
      let startEndAllDay = startAllDay + "-" + endAllDay;
      return startEndAllDay;
    },
    previousWeek() {
      let startWeek = moment()
        .subtract(1, "week")
        .startOf("week")
        .add(1, "day")
        .format("MM/DD/YY");
      let endWeek = moment()
        .subtract(1, "week")
        .endOf("week")
        .add(1, "second")
        .format("MM/DD/YY");
      let startEndWeek = startWeek + "-" + endWeek;
      return startEndWeek;
    },
    previousTwoWeeks() {
      let start2Week = moment()
        .subtract(2, "week")
        .startOf("week")
        .add(1, "day")
        .format("MM/DD/YY");
      let end2Week = moment()
        .subtract(1, "week")
        .endOf("week")
        .add(1, "second")
        .format("MM/DD/YY");
      let startEnd2Week = start2Week + "-" + end2Week;
      return startEnd2Week;
    },
    previousMonth() {
      let startMonth = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("MM/DD/YY");
      let endMonth = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("MM/DD/YY");
      let startEndMonth = startMonth + "-" + endMonth;
      return startEndMonth;
    },
    previous6Month() {
      let start6Month = moment()
        .subtract(6, "month")
        .startOf("month")
        .format("MM/DD/YY");
      let end6Month = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("MM/DD/YY");
      let startEnd6Month = start6Month + "-" + end6Month;
      return startEnd6Month;
    },
    previousYear() {
      let startYear = moment()
        .subtract(1, "year")
        .startOf("year")
        .format("MM/DD/YY");
      let endYear = moment()
        .subtract(1, "year")
        .endOf("year")
        .format("MM/DD/YY");
      let startEndYear = startYear + "-" + endYear;
      return startEndYear;
    },
    previous2Years() {
      let start2Years = moment()
        .subtract(2, "year")
        .startOf("year")
        .format("MM/DD/YY");
      let end2Years = moment()
        .subtract(1, "year")
        .endOf("year")
        .format("MM/DD/YY");
      let startEnd2Years = start2Years + "-" + end2Years;
      return startEnd2Years;
    },
    startDateValidated(){
      let todayDate = moment().format('L');
      let startBeforeToday = new Date(todayDate) >= new Date(this.newStartDateSelected);
        if(startBeforeToday){
        return true;
        } else {
         return false;
         }
    },
    endDateValidated(){
      let todayDate = moment().format('L');
      let endBeforeToday = new Date(todayDate) >= new Date(this.newEndDateSelected);
      let startBeforeEnd = new Date(this.newStartDateSelected) <= new Date(this.newEndDateSelected);
        if(endBeforeToday && startBeforeEnd){
        return true;
       } else {
        return false;
        }
    }
  },
  watch: {
    startDateSelected() {
      let startDateToString = this.startDateSelected.toString();
      let newStartDate = startDateToString.split("-");
      this.startDaySelected = newStartDate[2];
        this.updateDateRange();
    },
    endDateSelected() {
      let endDateToString = this.endDateSelected.toString();
      let newEndDate = endDateToString.split("-");
      this.endDaySelected = newEndDate[2];
        this.updateDateRange();
    },
    selectedRange() {
      let selectedRangeDates = this.selectedRange.split("-");
      let startRangeDate = selectedRangeDates[0];
      let endRangeDate = selectedRangeDates[1];

      let newSelectedRangeStart = startRangeDate.split("/");
      let newSelectedRangeEnd = endRangeDate.split("/");

      this.startDaySelected = newSelectedRangeStart[1];
      this.endDaySelected = newSelectedRangeEnd[1];
      this.startMonthSelected = newSelectedRangeStart[0];
      this.endMonthSelected = newSelectedRangeEnd[0];

     //need to adjust the logic for the full year
      this.startYearSelected = "20" + newSelectedRangeStart[2];
      this.endYearSelected = "20" + newSelectedRangeEnd[2];
      this.updateDateRange();
    },
  }
};
</script>
<style lang="scss" scoped>
.rpdp-container {
  height: 100%;
  position: relative;
  overflow-y: scroll;
  margin-bottom: 60px;
}

.dtp-tooltip {
z-index: 12000;
top: 0 !important;
}
.selectedMonth,
.selectedDay,
.selectedYear {
  font-size: 13px !important;
  line-height: 16px !important;
  color: #253561;
}

.year-picker,
.day-picker,
.month-picker {
  ::v-deep .btn-secondary {
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.selectedYear {
  font-size: 14px !important;
}
.yearDropdown {
  ::v-deep ul {
    transform: translate3d(-42px, 12px, 0px) !important;
    z-index: 2222222;
  }
  ::v-deep .dropdown-menu {
    text-align: center;
    min-width: 6.4rem !important;
  }
  ::v-deep .btn-secondary {
    width: 100px;
    height: 24px !important;
    margin-top: -12px !important;
    background-color: transparent !important;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    z-index: 10001;
    margin-top: -8px;
    margin-left: -40px;
    position: absolute;
    color: transparent;
  }
}
.dropdown-arrow {
  margin-left: 44px;
}

.year-picker {
  margin-left: 10px;
}

.yearBox {
  position: absolute;

  width: 56px;
  margin-left: 40px;
}

.day-picker {
  margin-left: 10px;
  cursor: pointer !important;

  ::v-deep .dropdown-toggle {
    background-color: transparent !important;
    background-repeat: no-repeat;
  }
  .selectedDay {
    position: absolute;
    z-index: 0;
    margin-left: 20px;
  }
  .day-picker {
    margin-left: 6px;
  }

  ::v-deep .btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }
  ::v-deep .b-icon {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }
}

.calendar-card {
  border: 0.5px solid #cfd3dc;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  padding: 8px;
  p {
    display: flex;
    margin-bottom: 0;
    margin-right: 8px;
  }
}

::v-deep #end-datepicker {
  height: 20px !important;
  z-index: 10000 !important;
  margin-left: -50px !important;
  background-color: transparent !important;
  color: transparent !important;
  margin-top: 4px;
  position: absolute !important;
  width: 100px !important;
}
::v-deep #start-datepicker {
  height: 20px !important;
  width: 100px !important;
  z-index: 11000 !important;
  margin-left: -50px !important;
  margin-top: 4px;
  position: absolute !important;
}

.month-picker {
  .month-box {
    border: 0.5px solid #cfd3dc;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;
    padding: 8px;
    p {
      display: flex;
      margin-bottom: 0;
      margin-right: 8px;
    }
  }

  ::v-deep .btn-sm {
    width: 20px !important;
    border: none !important;
  }
  .month-calendar {
    display: flex !important;
    height: 30px;
    width: 40px;
  }

  .dropdown-menu {
    z-index: 10005;
  }
  ::v-deep .dropdown-menu.show {
    display: flex;
    flex-wrap: wrap;
    background: white !important;
    padding: 0px !important;
    left: -20px !important;
  }
  ::v-deep .btn {
    background-color: transparent !important;
    color: transparent !important;
    background-repeat: no-repeat;
    position: absolute;
    height: 30px !important;
    width: 100px !important;
    margin-left: -70px !important;
  }
  ::v-deep .dropdown-item {
    width: 48px !important;
    height: 32px !important;
    display: flex !important;
    align-items: center !important;
    align-content: center !important;
    background-color: white !important;
  }
  ::v-deep .card-body {
    padding: 6px;
  }

  ::v-deep .btn-secondary {
    padding: 1px;
    background-color: #ffffff;
    color: #253561;
    font-size: 14px !important;
    border: 0.5px solid #e6eaf2;
    margin: 2px;
    height: 24px;
    width: 40px;
    border-radius: 0;
  }
}

.endDatePicker {
  ::v-deep .b-calendar-grid {
    border: 1px solid #e3ebf6;
  }
  ::v-deep .b-calendar-nav {
    display: none !important;
  }
  ::v-deep .b-calendar-grid-caption.text-center {
    display: none !important;
  }
  ::v-deep .small {
    display: none;
  }
  ::v-deep .form-control-sm {
    display: none;
  }
  ::v-deep .dropdown-menu {
    padding: 0px;
    border: none;
    z-index: 22222222;
  }
  ::v-deep .btn {
    border-radius: 0 !important;
  }
  ::v-deep .text-muted {
    background-color: #f3f4f6 !important;
  }
  ::v-deep .b-calendar-grid-weekdays {
    height: 30px !important;
  }
  ::v-deep .text-truncate {
    font-size: 16px !important;
    font-weight: 500;
  }
}

.rightPaneHeader,
.time-period-selection,
.start-period,
.end-period,
.shortcuts {
  padding: 24px;
}
.rightPaneHeader {
  height: 60px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  p {
    line-height: 18px;
    font-size: 14px;
    font-weight: 500;
    color: #858ea7;
    margin-top: unset;
    margin-bottom: unset;
    letter-spacing: -0.03em;
  }
  .rightPane-closeBtn {
    // width: 14px;
    // height: 14px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.time-period-selection {
  height: 100px;
  flex-direction: column;
  border-top: 1px solid #e3ebf6;
  align-content: center;
  h4 {
    color: #4a577c;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.03em;
  }
  p {
    color: #858ea7;
    font-size: 13px;
    letter-spacing: -0.02em;
    margin-top: unset;
    margin-bottom: unset;
  }
}
.start-period,
.end-period,
.shortcuts {
  height: 102px;
  border-top: 1px solid #e3ebf6;
  padding: 16px 24px 16px 24px !important;
  p {
    color: #858ea7;
    font-weight: 300;
    font-size: 13px;
    letter-spacing: 0.02em;
  }
}

.start-period, .end-period{
height: 120px;
}

.start-period,
.end-period {
  display: flex;
  flex-direction: column;
}

.end-calendars,
.start-calendars {
  flex-direction: row;
  display: flex;
}

.endDatePicker {
  height: 20px;
  width: 20px;
}
.shortcutsBtns {
  display: flex;
  flex-direction: column;
  width: 96%;
}

.shortcutBtn {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 6px;
  background-color: #f9fafc;
  border: none;
  color: #6e84a3;
  height: 40px;
  .shortcut-name,
  .shortcut-time {
    margin: 0px !important;
  }
}

.btn-secondary:active,
.btn-secondary:hover,
.btn-secondary:visited,
.btn-secondary:focus,
.btn-secondary.active {
  background-color: #2c7be5 !important;
  border: none !important;
  border-color: none !important;
  box-shadow: none !important;
  .shortcut-name,
  .shortcut-time {
    color: white !important;
  }
}

.shortcuts {
  display: flex;
  flex-direction: column;
}

.dateErrorMessage {
  margin-top: 10px;
  p {
    margin: 0px;
    margin-left: 10px;
  }
}
h4 {
  width: fit-content;
}
</style>