<template>
  <div>
    <ReportOptions>
      <p>Velocity Maximum</p>
      <ClusterOption
        description="Visitor must be traveling below this speed in order to be considered having stopped somewhere en route to/from the location in question. Recommended this value be no higher than 5mph, but must be non-negative."
      >
        <template>
          <div class="input-container">
            <b-input
              :state="validateSpeedLimit(speedLimit)"
              v-model="speedLimit"
              type="number"
            ></b-input>
            <label>mph</label>
          </div>
        </template>
      </ClusterOption>
      <p>Minimum Number of Visitors per Contour</p>
      <ClusterOption
        description="In order for a cluster to be created, at least this many unique visitors must have visited that immediate area. Recommended value should be 10 or greater. The higher the value, the more clusters will appear in only the most popular areas."
      >
        <template>
          <div class="input-container">
            <b-input
              :state="validateMinDevices(minDevices)"
              v-model="minDevices"
              type="number"
            ></b-input>
            <label>min</label>
          </div>
        </template>
      </ClusterOption>
      <p>Contour Level</p>
      <ClusterOption
        description="Determines how exclusive a contour should be. A high number like 10 will only create a contour around the most densely visited areas. This is useful to differentiate clusters in urban or densely visited areas like DisneyWorld. For non-US locations where visitors are more sparse, or for rural areas, a contour of 5 is more appropriate to encircle less-densely packed areas."
      >
        <template>
          <div class="input-container">
            <b-select v-model="contourLevel" :options="['5', '10']"></b-select>
          </div>
        </template>
      </ClusterOption>
    </ReportOptions>
    <ReportOptions>
      <TimePeriod
        ref="timePeriodComponent"
        @timePeriodSeconds="timePeriodChanged"
        :id="'cluster-report-options'"
        :presetSecondsBefore="secondsBefore"
        :presetSecondsAfter="secondsAfter"
      >
        <!-- <template v-slot:description>
        Enter timeframes for both before and after to determine how far back/forward to look after a visit to determine clusters. The two values do not have to be the same, but must be any whole number greater than or equal to 0.
        <span style="font-weight: 700"> Please note: </span>Seconds Before and After are limited to one month time periods.
      </template> -->
      </TimePeriod>
    </ReportOptions>
  </div>
</template>
<script>
import ReportOptions from "../shared/ReportOptions.vue";
import ClusterOption from "../shared/ClusterOption.vue";
import TimePeriod from "../TimePeriod.vue";

export default {
  components: { ReportOptions, ClusterOption, TimePeriod },
  mounted() {
    const { speedLimit, minDevices, contourLevel } =
      this.$sessionStore.state.jobRequest.clusterReportOptions;
    const { secondsBefore, secondsAfter } =
      this.$sessionStore.state.jobRequest;
    if (secondsBefore !== undefined) {
      this.$refs.timePeriodComponent.setSeconds(secondsBefore, "before");
    }
    if (secondsAfter !== undefined) {
      this.$refs.timePeriodComponent.setSeconds(secondsAfter, "after");
    }
    if (speedLimit !== undefined) this.speedLimit = speedLimit;
    if (minDevices !== undefined) this.minDevices = minDevices;
    if (contourLevel !== undefined) this.contourLevel = contourLevel;
  },
  watch: {
    speedLimit(speedLimit) {
      if (this.validateSpeedLimit(speedLimit)) {
        this.$sessionStore.commit("setClusterReportOptions", {...this.storedOptions, speedLimit});
      } else this.$sessionStore.commit("setClusterReportOptions", { ...this.storedOptions, speedLimit: undefined })
    },
    minDevices(minDevices) {
      if (this.validateMinDevices(minDevices)) {
        this.$sessionStore.commit("setClusterReportOptions", { ...this.storedOptions, minDevices });
      } else this.$sessionStore.commit("setClusterReportOptions", { ...this.storedOptions, minDevices: undefined })
    },
    contourLevel(contourLevel) {
      this.$sessionStore.commit("setClusterReportOptions", { ...this.storedOptions, contourLevel });
    },
  },
  computed: {
    storedOptions() {
      return this.$sessionStore.state.jobRequest.clusterReportOptions;
    },
    contourOptions() {
      return ["5", "10"];
    },
    secondsBefore() {
      if (this.$sessionStore.state.jobRequest.secondsBefore !== undefined) {
        return this.$sessionStore.state.jobRequest.secondsBefore;
      } else {
        return 1800;
      }
    },
    secondsAfter() {
      if (this.$sessionStore.state.jobRequest.secondsAfter !== undefined) {
        return this.$sessionStore.state.jobRequest.secondsAfter;
      } else {
        return 1800;
      }
    },
  },
  data() {
    return {
      contourLevel: "5",
      minDevices: 3,
      speedLimit: 5,
    };
  },
  methods: {
    validateSpeedLimit(val) {
      return Number.parseInt(val) >= 0;
    },
    validateMinDevices(val) {
      return Number.parseInt(val) >= 0;
    },
    timePeriodChanged(val) {
      this.$sessionStore.commit("setTimePeriod", {
        secondsBefore: val.beforeSeconds,
        secondsAfter: val.afterSeconds,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p:not(:first-child) {
  margin-top: 1rem;
}

p {
  margin: 0;
}

.input-container {
  display: flex;
  align-items: center;

  ::v-deep input {
    width: 6rem;
  }

  ::v-deep select {
    width: 6rem;
  }

  label {
    margin-left: 1rem;
  }
}
</style>