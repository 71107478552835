<template>
  <div class="topbar-nav-container">
    <nav class="navbar navbar-expand-md navbar-light p-0 topbar-nav">
      <div class="container-fluid justify-content-between list-container">
        <ul class="navbar-nav">
          <li
            class="nav-item"
            v-for="item in items"
            :class="{ 'nav-item-active': isActiveLink(item) }"
            :key="item.displayName"
          >
            <p class="nav-link" @click="$emit('navigate', item)">{{ item.displayName }}</p>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>

const props = ['route', 'items'];

export default {
  name: 'TopNav',
  props,
  methods: {
    isActiveLink(item) {
      // Checks the item matches the current route
      const { name, path } = item.routeParams;
      if (name && this.route.name === name) return true;
      if (path && this.route.path === path) return true;

      // Check if the current route is a child of the item
      let current = this.$router.matcher.getRoutes().find(route => this.route.path === route.path);
      while (current !== undefined) {
        if (name && current.name === name) return true;
        if (path && current.path === path) return true;
        current = current.parent;
      }
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
.topbar-nav-container {
  width: 100%;
  position: absolute;
  z-index: 9;
  background: #f9fafc !important;
  height: 56px;
  position: relative;

  .topbar-nav {
    height: 100%;

    .list-container {
      height: 100%;

      ul {
        height: 100%;
      }
    }
  }
  .navbar-nav {
    margin-left: 2em;

    .nav-item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .nav-link {
        margin: 0;
      }
    }
  }
}
.nav-item-active {
  border-bottom: 1px solid #2c7be5;
  span {
    color: black;
  }
}
</style>