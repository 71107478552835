import { render, staticRenderFns } from "./PageTabs.vue?vue&type=template&id=d9705424&scoped=true&"
import script from "./PageTabs.vue?vue&type=script&lang=js&"
export * from "./PageTabs.vue?vue&type=script&lang=js&"
import style0 from "./PageTabs.vue?vue&type=style&index=0&id=d9705424&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9705424",
  null
  
)

export default component.exports