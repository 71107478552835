import { Bar, mixins } from "vue-chartjs";
import { generateChart } from 'vue-chartjs';


export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: ["chartData", "options"],
  data() {
    return {};
  },
  mounted() {
    this.renderBarChart();
  },
  methods: {
    render() {
      this.renderBarChart();
    },
    renderBarChart() {
      this.renderChart(this.chartData, this.options);
    },
  },
};
