export default {
  computed: {
    chartColors() {
      const color = ["#2c7be5", "#ff595e", "#8ac926", "#ffca3a", "#9980FA"];
      const altColor = [
        "rgba(44, 123, 229, 0.1)",
        "rgba(255, 89, 94, 0.2)",
        "rgba(138, 201, 38, 0.2)",
        "rgba(255, 202, 58, 0.2)",
        "rgba(153, 128, 250, 0.2)",
      ];
      const medColor = "rgba(177, 194, 217, 1)";
      const medAltColor = "rgba(177, 194, 217, 0.6)";
      return { color, altColor, medColor, medAltColor };
    }
  },
  methods: {
    /**
     * Calculates the min/max lines.
     * @param {*} data The chart data
     * @param {*} valueField The value field of the data
     * @param {*} indexField The index field of the data
     * @returns {{minLine, maxLine}}
     */
    calcMinMax(data, valueField, indexField) {
      let minLine = {};
      let maxLine = {};
      Object.entries(data).forEach(([_polygonId, polygonData]) => {
        Object.entries(polygonData).forEach(([_rowIdx, rowData]) => {
          const idx = rowData[indexField];
          if (
            !minLine[idx] ||
            rowData[valueField] < minLine[idx][valueField]
          ) {
            minLine[idx] = { ...rowData };
          }
          if (
            !maxLine[idx] ||
            rowData[valueField] > maxLine[idx][valueField]
          ) {
            maxLine[idx] = { ...rowData };
          }

        });
      });
      minLine = _.sortBy(Object.values(minLine), (o) => o[indexField]);
      maxLine = _.sortBy(Object.values(maxLine), (o) => o[indexField]);
      return { minLine, maxLine };
    },
    /**
     * Parses the datasets into CSV format. Only use for Visits by X charts.
     * @param {Array} datasets The array of datasets.
     * @param {Array} labels The row labels.
     * @param {String} commitName The commit name 
     */
    saveVisitsByCSV(datasets, labels, commitName) {
      const tempAdd = this.$route.path.split("/")[1] === "preview" ? this.$sessionStore.state.study.previews : this.$sessionStore.state.study.locations;
      const addArr = [
        this.$sessionStore.state.study.avgAddress.replace(/,/gi, ""),
        "",
        ""
      ];
      const tempRow = [];
      tempAdd.map(e => addArr.push(e.address.replace(/,/gi, "")));
      let rawData = datasets.map((o, idx) => [
        `${o.label.replace(/,/gi, "")} ${addArr[idx]},`.concat(o.data)
      ]);
      rawData = rawData.map(o => [o[0].replace(/,/gi, "\t")]);
      tempRow.push(labels);
      rawData = tempRow.concat(rawData);
      rawData.splice(2, 2);
      let csvContent = "";
      rawData.forEach((rowArray) => {
        let row = rowArray.join("\t");
        csvContent += row + "\r\n";
      });
      this.$sessionStore.commit(commitName, csvContent);
    },
    /**
     * Handles the onHover event for dayWeek and dayTime charts.
     * @param {*} res The "onHover" payload.
     */
    handleOnHover(res, dataField = "week") {
      let index = this.id.indexOf(res.selected) + 3;
      const { color, medColor, medAltColor, altColor } = this.chartColors;
      if (res.value) {
        //On Mouse Enter
        for (let i = 0; i < this.id.length; i++) {
          this[dataField].line.data.datasets[i + 3].borderColor = altColor[i];
          this[dataField].line.data.datasets[i + 3].backgroundColor = altColor[i];
        }
        this[dataField].line.data.datasets[0].borderColor = medAltColor;
        this[dataField].line.data.datasets[0].backgroundColor = medAltColor;
        if (res.selected === "median") {
          this[dataField].line.data.datasets[0].borderColor = medColor;
          this[dataField].line.data.datasets[0].backgroundColor = medColor;
        } else {
          this[dataField].line.data.datasets[index].borderColor = color[index - 3];
          this[dataField].line.data.datasets[index].backgroundColor =
            color[index - 3];
        }
      } else {
        //On Mouse Leave
        for (let i = 0; i < this.id.length; i++) {
          this[dataField].line.data.datasets[i + 3].borderColor = color[i];
          this[dataField].line.data.datasets[i + 3].backgroundColor = color[i];
        }
        this[dataField].line.data.datasets[0].borderColor = medColor;
        this[dataField].line.data.datasets[0].backgroundColor = medColor;
      }
      this.renderChartRefs(); 
    },
    /**
     * Event handler for combo line + bar charts.
     * Only used for estFoot, dayWeek, dayTime.
     * @param {*} res The "locationsListChanged" event payload.
     * @param {*} charts The object that contains the line and bar chart data.
     * @param {*} rangeBandData The object that contains the minLine and maxLine.
     * @param {*} parseLine A function that takes the line data and transforms the values.
     * @returns {Boolean} Returns true if successful.
     */
    handleLocationsListChanged(res, charts, { minLine, maxLine }, parseLine) {
      if (!charts.line.data) return false;
      if (this.handlingLocationsChange) {
        // Throw it in the async queue
        setTimeout(() => {
          this.handleLocationsListChanged(res, charts, { minLine, maxLine }, parseLine);
        }, 0);
      }
      this.handlingLocationsChange = true;
      if (res.locationId === "median") {
        charts.line.data.datasets[0].hidden = !res.selected;
        charts.bar.data.datasets[0].hidden = !res.selected;
      } else if (res.locationId === "range") {
        this.showRange = res.selected;
        charts.line.data.datasets[1].hidden = !res.selected;
        charts.line.data.datasets[2].hidden = !res.selected;
      } else {
        const index = this.id.indexOf(res.locationId) + 3;
        this.show[index - 3] = res.selected;

        const _updateData = (dataset, line) => {
          dataset.data = parseLine(line);
        }
        _updateData(charts.line.data.datasets[1], maxLine);
        _updateData(charts.line.data.datasets[2], minLine);

        charts.line.data.datasets[index].hidden = !res.selected;
        charts.bar.data.datasets[index - 2].hidden = !res.selected;
      }
      this.handlingLocationsChange = false;
      return true;
    },
    /**
     * Requests the Pinnacle API for chart data.
     * @param {*} endpoint The target endpoint.
     * @param {*} extraParams Any extra params.
     * @returns Returns the axios response.
     */
    async getChartData(endpoint, extraParams) {
      const hasFilterString = this.filterString.length;
      if (typeof this.$route.params.ids === "undefined") return;
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const headers = this.getHeaders();
        let paramString = '?';
        for (const key in extraParams) {
          if (paramString !== '?') paramString += "&";
          paramString += `${key}=${extraParams[key].toString()}`;
        }
        if (hasFilterString) paramString += `&${this.filterString}`;
        const data = await this.$http.get(endpoint + paramString, headers);
        return data;
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errors.length) {
          this.err = err.response.data.errors[0].message;
        }
        console.log({ err });
      }
    },
    /**
     * Reads from this.chartRefs and attempts to call the render function.
     */
    renderChartRefs() {

      if (!Array.isArray(this.chartRefs)) return;

      this.chartRefs.forEach(ref => {
        if (this.$refs[ref] && this.$refs[ref].render)
        this.$refs[ref].render();
      });
    }
  }
}