
const initialState = {
  searchTerm: '',
  status: {
    text: 'Filter By Status',
    filter: ""
  },
  company: {
    text: 'Filter By Company',
    filter: "__ALL"
  },
  companySearchTerm: '',
  companies: [],
  reload: false
};


export default {
  state: Object.assign({}, initialState),
  mutations: {
    setReportFeedSearchTerm: (state, searchTerm) => {
      state.searchTerm = searchTerm;
    },
    setReportFeedStatus: (state, status) => {
      state.status = status;
    },
    setReportFeedReload: (state, reload) => {
      state.reload = reload;
    },
    setReportFeedCompanyFilter: (state, companyFilter) => {
      state.company = companyFilter;
    },
    setReportFeedCompanies: (state, companies) => {
      state.companies = companies;
    },
    setReportFeedCompanySearchTerm: (state, searchTerm) => {
      state.companySearchTerm = searchTerm;
    }
  },
};
