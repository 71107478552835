<template>
  <div class="study-layout">
      <!-- Copy Link pop-up message -->
      <div
        v-if="showAlert"
        class="alert alert-info alert-dismissible fade show copyLinkMessage"
        role="alert"
      >
        <CopyLinkBell class="copyLink-bell" />
        <p>Link has been copied to your clipboard!</p>
        <button
          type="button"
          class="close"
          style="color: white; font-size: 18px"
          @click="closeAlert"
        >x
        </button>

      </div>
    <!-- <div class="topbar-container">
      <Topbar />
    </div> -->

        <!-- invalid Polygon alert modal -->
    <b-modal v-model="invalidLocationModal" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
    <div class="polygonErrorAlertModal">
    <p>Selected place is invalid. Please return to search and try again.</p>
    <b-button variant="primary" @click="returnToSearch()">Return to Search</b-button>
    </div>
  </b-modal>

    <div class="content-container">
    <LeftPane>
        <PseudoBreadcrumbs
          v-if="!$route.name.includes('details')"
          :noClose="true"
          :origin="origin"
          currentTitle="Comparison"
        ></PseudoBreadcrumbs>
        <router-view  name="header"></router-view>
        <router-view  name="sidebar"></router-view>
  
       <!--   <StudyDetailsCheckbox v-if="detail" />
     <PlaceDetails :selectedLocation='studyLocations[index]' v-if="detail">
          <template v-slot:study-checkbox>
            <b-checkbox
                value="true"
                :checked="bool"
                @change="toggleLocation($event, studyLocations[index])"
                size="lg"
                class="placesCheckboxes"
              ></b-checkbox>
          </template>
        </PlaceDetails> -->
    </LeftPane>
    <div class="right-panel">
    <div class="topbar-container">
      <Topbar />
    </div> 
        <div class="charts-content">

      <div class="study-content">
        <router-view />
      </div>

    <div class="closeInsightsBtn" :class="{ 'd-none': !showCloseInsights }">
      <img 
        :src="require('pinnacle-lib/assets/icon/closeBtn-Insights2.svg')" 
        @click="closeInsights"
        alt="close-insights"
      />
    </div>
    <div 
      :class="!showPicker && !onMap ? 'd-none' : 'right-panel-datepicker'"
      v-show="showRightPane && ((this.$route.name !== 'study-charts') || this.showPicker)"
    >
          <RightPane>
          </RightPane>
        </div>
    </div>

    </div> 
    </div>
  </div>
</template>

<script>
import Topbar from "@/components/partials/Topbar";
import CopyLinkBell from "@/components/partials/CopyLinkBell.vue";
import { bus } from "@/main";
import LeftPane from "@/components/partials/LeftPane";
import PseudoBreadcrumbs from "@/components/partials/PseudoBreadcrumbs.vue";
import RightPane from "@/components/partials/RightPane";
import RouteGuard from "pinnacle-lib/mixins/RouteGuard";

export default {
  mixins: [RouteGuard],
  components: {
    Topbar,
    CopyLinkBell,
    LeftPane,
    RightPane,
    PseudoBreadcrumbs

  },
  data() {
    return {
      ids: [],
      showAlert: false,
      index: 0,
      detail: false,
      bool: false,
      showRightPane: false,
      invalidLocationModal: false,
      showInsights: false,
      showPicker: false
    };
  },
  computed: {
    studyLocations() {
      return this.$route.path.split('/')[1] === 'preview' ? this.$sessionStore.state.study.previews : this.$sessionStore.state.study.locations;
    },
    routeName() {
      return this.$route.name;
    },
    origin() {
      return {
        title: 'Search',
        path: '/explore/comparison'
      }
    },
    onMap() {
      return this.$route.name === "study-map" || this.$route.name === "study-map-details";
    },
    showCloseInsights() {
      if (this.onMap && this.showInsights && !this.showPicker) {
        return true; 
      } else {
        return false; 
      }
    }
  },
  created() {
  },
  mounted() {
    this.syncStudy();

    bus.$off("copyLinkAlert");
    bus.$on("copyLinkAlert", () => {
      this.showAlert = !this.showAlert;
      setTimeout(() => (this.showAlert = false), 3000);
    });  
    // bus.$on("detailsSelected", (res) => {
    //   console.log(res)
    //   this.index = res.index;
    //   this.bool = res.bool === undefined ? true : false;
    //   this.detail = true;
    // });
    bus.$on("showDatePicker", () =>{
      this.showPicker = true;
      this.showRightPane = true;
    });
    
    bus.$on("closeDatePicker", () => {
      if(this.showInsights){
        this.showRightPane = true;
        this.showPicker = false;
        } else {
        this.showRightPane = false;
        this.showPicker = false;
        }
    });

    bus.$on("openInsights", () => {
       this.showRightPane = true;
      this.showInsights = true;
    });

    bus.$on("closeInsights", () => {
       this.showRightPane = false;
      this.showInsights = false;
    });

  },
  methods: {
    toggleLocation(val, location) {
      this.bool = !this.bool;
      // this.checked = !this.checked;
      const disabledIds = Object.assign({}, this.disabledIds);

      if (!val) disabledIds[location.id] = true;
      else delete disabledIds[location.id];

      this.$sessionStore.commit("setDisabledIds", disabledIds);

      // a function to emit selectedLocation to Charts to remove it
      bus.$emit("locationsListChanged", {
        selected: val,
        locationId: location.id,
      });
    },
    async syncStudy() {
      // Checks the route param ids against the ids in the sessionStore
      this.$sessionStore.commit('setLoading', true);
      this.ids = this.$route.params.ids.split(',');
      const matchIds = this.studyLocations.map(loc => loc.id);
      const matches = this.ids.every(id => {
        return matchIds.includes(id);
      });
      if (!matches) {
        try {
          if (this.tokenNeedsRefresh()) await this.refreshToken();
          const headers = this.getHeaders();
          const res = await this.$http.get(`/v2/location-details/${this.$route.params.ids}`, headers);
          this.$sessionStore.commit('setLocations', res.data.locations.sort((a, b) => {
            return this.ids.indexOf(a.id) - this.ids.indexOf(b.id);
          }));
        } catch (err) {
          console.log(err)
          if(err.response.status == 400) {
            this.invalidLocationModal = true;
            return;
          }
          
          this.err = err.response.data.errors[0].message;
          console.log({ err });
        }
      }
      this.$sessionStore.commit('setLoading', false);
      // Preload routes
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const headers = this.getHeaders();
        // Trigger cache not needed anymore
        //const res = await this.$http.get(`/v1/trigger-cache/${this.$route.params.ids}`, headers);
        //console.log('Preload response: ', res);
      } catch (err) {
        console.log(err);
      }
    },
    closeAlert(){
      this.showAlert = !this.showAlert;
    },
    returnToSearch(){
      this.$router.push({ path: `/explore/search` });
    },
    closeInsights() {
      bus.$emit("closeInsights");
    }
  },
  watch: {
    "$route.params.ids"() {  
     this.syncStudy();
    },
  },
  beforeDestroy(){
    // bus.$off("detailsSelected");
    bus.$off("locationsListChanged");
  }
};
</script>
<style lang="scss" scoped>

.study-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.content-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.study-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.charts-content {
  display: flex;
  flex-direction: row;
  height: 100%;
width: 100%;
}

.right-panel {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.right-panel-datepicker{
  display: flex;
    height: 100%;
    width: 380px;
    background-color: white;
    border-left: 1px solid $border-color;
    z-index: 6;
    position: absolute;
    right: 0;
    overflow-y: auto;
}
.closeInsightsBtn {
  position: absolute;
  right: 375px;
  top: 95px;
  z-index: 5;

  &:hover {
    cursor: pointer;
  }
}

.copyLinkMessage {
  position: absolute;
  z-index: 100001;
  display: flex;
  background-color: #2c7be5;
  width: 100%;
  height: 3.2em;
  top: 0%;
  border-radius: 0;
  border-color: transparent;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.03);
  p {
    font-family: Cerebri Sans;
    font-size: 15px;
    color: white;
    margin-left: 1.25em;
    font-weight: 100;
  }
  .copyLink-bell {
    margin-left: 1.25em;
  }
}
.placesCheckboxes{
  position: absolute;
  right: 30px;
}
.polygonErrorAlertModal {
  display: flex;
  align-items: center;
  flex-direction: column;
}
::v-deep .modal-backdrop {
  box-shadow: inset 0 0 2000px hwb(0deg 100% 0% / 50%) !important;
  background-color: rgb(22 34 68 / 50%) !important;
  backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
}
</style>