<template>
    <OptionsPanel>
        <h2>Set Notifications</h2>
        <Option>
            <label style="display: block;">Notify Email Recipients</label>
            <b-input type="text" @change="emailHandler()" v-model="emails" :state="isValid"/>
            <p class='subtext'>Separate emails using commas. Users will be notified if a job has failed.</p>
        </Option>
    </OptionsPanel>
</template>
<script>

import OptionsPanel from './shared/OptionsPanel.vue';
import Option from './shared/Option.vue';

export default {
    components: { OptionsPanel, Option },
  data () {
    return {
        emails: '',
        isValid: null,
    }
  },
  mounted() {
      this.emails = this.$sessionStore.state.jobRequest.emailAddresses.join(',');
  },
  methods : {
      emailHandler(){
        let emailArr = this.emails.split(',')
        let arr = [];
        if(this.emails === ''){
            this.isValid = null;
            this.$sessionStore.commit('setEmails', []);
            return 
        };
        for(let i=0;i < emailArr.length; i++){
            let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
            if(!reg.test(emailArr[i].trim())){
               this.$sessionStore.commit('setEmails', []);
               this.isValid = false;
               return console.log('failed reg test')      
            }
            else{
                arr.push(emailArr[i].trim())
                this.isValid = true;
            }
        }
        this.$sessionStore.commit('setEmails', arr);
      }
  },
}
</script>
<style lang="scss" scoped>
::v-deep input {
    width: 75%;
    margin-top: 1rem;
}
</style>

