<template>
  <div class="confirmation-container">
    <div class="row" style="margin-top:200px">
      <div class="col text-center">
        <div class="row">
          <div class="col">
            <b-icon icon="check-circle" scale="4" variant="success"></b-icon>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col">
            <h1>Thank you for your payment</h1>
            <p v-for="line_item in purchases">{{ formatPurchase(line_item) }}</p>
            <b-button href="/settings">
              Continue
            </b-button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      purchases: [],
    }
  },
  mounted() {
    this.getCheckoutSession();
  },
  methods: {
    async getCheckoutSession() {
      let cs = this.$route.query.cs;
      const thing = await this.refreshToken();
      this.err = "";
      let headers = this.getHeaders();
      headers.headers['Cache-Control'] = 'no-cache';
      headers.headers['Pragma'] ='no-cache';
      headers.headers['Expires'] ='0';
      let prices = [this.creditOptionSelected];
      try {
        const res = await this.$http.post("/v2/credit-confirmation", {cs:cs} ,headers);
        this.purchases = res.data.checkout_data.line_items.data;
        //this.$router.go(res.data.session_url);
        //window.location.href = res.data.session_url;
      } catch (err) {
        console.log(err);
        // TODO show error to user
        this.err="Error";
      }
    },
    formatPurchase(line_item) {
      let quantity = line_item.price.metadata["Product Quantity"];
      let credit_type = line_item.price.metadata["Product Type"].replace("Pinnacle ", "");
      let str = `You have purchased ${quantity} ${credit_type} credits.`;
      return str;
    },
  }
}
</script>