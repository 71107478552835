<template>
  <div>
    <router-view></router-view>
    <MaintenanceModal />
  </div>
</template>

<script>
import MaintenanceModal from "@/components/partials/MaintenanceModal";

export default {
  name: "PublicLayout",
  components: {
    MaintenanceModal,
  },
};
</script>

<style>
</style>