export default {
  state: {
    permissions: [],
    roles: [],
    company: {},
    data: {
      company_id: "",
      id: "",
      email: "",
      expired: false
    }
  },
  mutations: {
    setPermissions(state, payload) {
      const roles = [];
      const permissions = [];
      //console.log(payload)
      for (const role of payload) {
        roles.push({
          id: role.id,
          name: role.name
        });
        for (const permission of role.permissions) {
          permissions.push(permission);
        }
        
      }
      state.roles = roles;
      state.permissions = permissions;
    },
    setCompany(state,payload) {
      state.company = payload;
    },
    setData(state,payload) {
      state.data.company_id = payload.company_id;
      state.data.id = payload.id;
      state.data.email = payload.email;
    },
    setExpired(state, payload) {
      state.data.expired = payload;
    }
  },
};
