<template>
  <div class="create-page-container">
    <b-alert v-model="hasError" class="error-alert" variant="danger">
      <div class="error-container">
        <p>{{ errorMsg || genericError }}</p>
      </div>
    </b-alert>
    <div v-show="showStickyMenu" :class="{ 'animate-sticky-menu': showStickyMenu }" class="sticky-menu">
      <b-button class="back-button button" size="md" @click="goBack">
        {{ backTitle }}
      </b-button>
      <div class="text-container">
        <p class="step-text" v-if="step">Step {{ step | numberToWord }} <span v-if="numSteps">of {{ numSteps |
            numberToWord
        }}</span></p>
        <h1>{{ title || "Format" }}</h1>
      </div>
      <b-button :disabled="!validateStep(namespace, validatorStep) || isSubmitting" class="button" size="md"
        @click="goNext" :class="{ 'submit-button': $route.meta.submit }" variant="primary">
        {{ nextTitle }}
      </b-button>
    </div>
    <div class="top-section">
      <b-button class="back-button button" size="md" @click="goBack">
        {{ backTitle }}
      </b-button>
      <div class="text-container">
        <p class="step-text" v-if="step">Step {{ step | numberToWord }} <span v-if="numSteps">of {{ numSteps |
            numberToWord
        }}</span></p>
        <h1>{{ title || "Format" }}</h1>
      </div>
      <b-button :disabled="!validateStep(namespace, validatorStep) || isSubmitting"
        v-observe-visibility="nextButtonHidden" class="button" size="md" @click="goNext"
        :class="{ 'submit-button': $route.meta.submit }" variant="primary">
        {{ nextTitle }}
      </b-button>
    </div>
    <div class="page-section">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import Validator from '../../../services/validation/validator.js';
import RouteGuard from 'pinnacle-lib/mixins/RouteGuard';

const Breakpoints = [
  { value: 1000000000, str: "Billion" },
  { value: 1000000, str: "Million" },
  { value: 1000, str: "Thousand" },
  { value: 100, str: "Hundred" },
  { value: 90, str: "Ninety" },
  { value: 80, str: "Eighty" },
  { value: 70, str: "Seventy" },
  { value: 60, str: "Sixty" },
  { value: 50, str: "Fifty" },
  { value: 40, str: "Forty" },
  { value: 30, str: "Thirty" },
  { value: 20, str: "Twenty" },
  { value: 19, str: "Nineteen" },
  { value: 18, str: "Eighteen" },
  { value: 17, str: "Seventeen" },
  { value: 16, str: "Sixteen" },
  { value: 15, str: "Fifteen" },
  { value: 14, str: "Fourteen" },
  { value: 13, str: "Thirteen" },
  { value: 12, str: "Twelve" },
  { value: 11, str: "Eleven" },
  { value: 10, str: "Ten" },
  { value: 9, str: "Nine" },
  { value: 8, str: "Eight" },
  { value: 7, str: "Seven" },
  { value: 6, str: "Six" },
  { value: 5, str: "Five" },
  { value: 4, str: "Four" },
  { value: 3, str: "Three" },
  { value: 2, str: "Two" },
  { value: 1, str: "One" }
];
export default {
  mixins: [Validator, RouteGuard],
  data() {
    return {
      genericError: "Uh oh, something went wrong on our end. Please try again, and contact us if the problem persists.",
      showStickyMenu: false,
    };
  },
  watch: {
    '$route'(to, from) {
      document.getElementById('home-page').scrollTo(0, 0);
    }
  },
  filters: {
    numberToWord(number) {
      let result = '';
      for (let n of Breakpoints) {
        if (number >= n.value) {
          if (number <= 99) {
            result += n.str;
            number -= n.value;
            if (number > 0) result += ' ';
          } else {
            var t = Math.floor(number / n.value);
            // console.log(t);
            var d = number % n.value;
            if (d > 0) {
              return this.numberToWord(t) + ' ' + n.str + ' ' + this.numberToWord(d);
            } else {
              return this.numberToWord(t) + ' ' + n.str;
            }
          }
        }
      }
      return result;
    }
  },
  computed: {
    namespace() {
      if (this.$route.name.includes('report')) return 'reportValidator';
      return 'datasetValidator';
    },
    validatorStep() {
      if (this.$route.meta.root) return 0;
      return this.step;
    },
    step() {
      return this.$route.meta.step;
    },
    numSteps() {
      const siblings = this.getAllSiblings(this.$route.name);
      // Subtract itself
      if (siblings !== undefined) return siblings.length;
      return undefined;
    },
    title() {
      return this.$route.meta.title;
    },
    backTitle() {
      if (this.$route.meta.root) return 'Cancel';
      return 'Back';
    },
    nextTitle() {
      if (this.isSubmitting) return 'Submitting';
      if (this.$route.meta.submit) return 'Submit';
      return 'Next';
    }
  },
  methods: {
    getAllSiblings(name, routes = this.$router.options.routes) {
      for (let route of routes) {
        if (route.name === name) return routes;

        if (!('children' in route) || route.children.length === 0) continue;
        const siblings = this.getAllSiblings(name, route.children);

        if (siblings !== undefined) return siblings;
      }
    },
    getChildren(name, routes = this.$router.options.routes) {
      for (let route of routes) {
        if (route.name == name) return route.children;
        if (!('children' in route) || route.children.length == 0) continue;
        const children = this.getChildren(name, route.children);
        if (children !== undefined) return children;
      }
    },
    getNextStep() {
      if (this.$route.meta.root) {
        const children = this.getChildren(this.$route.name);
        return children !== undefined && children.length > 0 ? children[0] : undefined;
      }
      const siblings = this.getAllSiblings(this.$route.name);
      if (siblings === undefined) return undefined;

      for (let i = 0; i < siblings.length; i++) {
        const cur = siblings[i];
        if (cur.name === this.$route.name && siblings.length >= i + 1) return siblings[i + 1];
      }
    },
    goNext() {
      const nextStep = this.getNextStep();
      
      if (nextStep !== undefined && !nextStep.redirect) {
        let route = Object.assign({query: {}}, nextStep);
        if (nextStep.meta && nextStep.meta.query !== undefined) route.query = nextStep.meta.query;
        if (this.$route && this.$route.query && this.$route.query.clone !== undefined) route.query.clone = this.$route.query.clone;
        this.$router.push(route);
        return;
      }

      // Must be the last step, submit
      this.submit();
      return;
    },
    goBack() {
      if (this.backTitle !== 'Cancel') {
        this.$router.back();
        return;
      }

      // Cancel
      this.$sessionStore.commit('resetJobRequest');

      if (this.$route.name.includes('report')) {
        this.$router.push({ name: 'home-reports' });
        return;
      }
      this.$router.push({ name: 'home-datasets' });
      return;
    },
    async submit() {
      const res = await this.submitStep(this.namespace);
      this.postSubmitStep(this.namespace, res);
    },
    nextButtonHidden(visible) {
      if (!visible) {
        this.showStickyMenu = true;
        return;
      }
      this.showStickyMenu = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes fadeInOpacity {
  0% {
    top: -116px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

::v-deep .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef !important;
}

.error-alert {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding-left: 8rem;
  padding-right: 8rem;
  z-index:100;
}

.back-button {
  color: #6E84A3;
  background-color: #EDF2F9;
}

.sticky-menu {
  position: sticky;
  top: -300px;
  left: calc(80px + 8rem);
  right: 8rem;
  display: flex;
  flex-direction: row;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
  background-color: #F9FAFC;
  opacity: 0;
  border-bottom: 1px solid #E3EBF6;
  z-index: 99;
}

.animate-sticky-menu {
  opacity: 1;
  top: 0;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.35s;
}

.button {
  // background-color: #edf2f9; 
  // color: #6E84A3;
  font-weight: 500;
  border-width: 0px;
  padding: 0.75rem 2rem;
}

.submit-button {
  background-color: $blue;
  color: white;
}

.create-page-container {
  position: relative;
  width: 100%;
  padding-left: 8rem;
  padding-right: 8rem;
  margin: auto;
  min-height: 100%;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: $bg-color;

  .text-container {
    text-align: center;

    .step-text {
      color: #858EA7;
    }
  }

  ::v-deep h1,
  ::v-deep p {
    margin: 0;
  }

  .top-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 2rem;
    padding-top: 6rem;
    justify-content: space-between;
    align-items: center;

  }

  .page-section {
    width: 100%;
    height: 100%;
    flex: 1;
    background-color: white;
    border: 1px solid #E3EBF6;
  }

}

::v-deep .section+.section {
  border-top: 1px solid #F7F8F9;
}

::v-deep .section {
  padding: 2.5rem;
}


::v-deep .graytext {
  color: lighten(gray, 10%);
}

::v-deep .subtext {
  color: lighten(gray, 10%);
  font-size: 14px;
}

::v-deep .required {
  color: #f7617b;
  font-size: 11px;
  margin-bottom: 0em;
}

::v-deep h2 {
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  margin-bottom: 1rem;
}

::v-deep h3 {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  margin-bottom: 1rem;
}
</style>