<template>
  <div class="report-options-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

.report-options-container {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #E3EBF6;
  background-color: #f9fafc;
  padding: 2rem;
}

</style>