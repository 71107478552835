
const initialState = {
  searchTerm: '',
  status: {
    text: 'Filter By Status',
    filter: ""
  },
  reload: false
};


export default {
  state: Object.assign({}, initialState),
  mutations: {
    setDataFeedSearchTerm: (state, searchTerm) => {
      state.searchTerm = searchTerm;
    },
    setDataFeedStatus: (state, status) => {
      state.status = status;
    },
    setDataFeedReload: (state, reload) => {
      state.reload = reload;
    }
  },
};
