export function emitWithPromise({bus, event, callbackEvent, timeout=5000}, ...args) {

  return new Promise((resolve, reject) => {
    bus.$on(callbackEvent, (..._args) => {
      bus.$off(callbackEvent);
      resolve(..._args);
    });
    bus.$emit(event, ...args);
    setTimeout(() => {
      bus.$off(callbackEvent);
      reject({message: 'Timed out.'});
    }, timeout);
  });

}