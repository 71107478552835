var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items.length > 0)?_c('div',{staticClass:"typeahead-container"},[_c('b-card',{staticClass:"typeahead-card"},[_c('ul',{staticClass:"selectedAutosuggest",attrs:{"role":"listbox","aria-label":"Autosuggestions"}},[_vm._l((_vm.items.slice(0, 5)),function(suggestion,idx){return [(typeof suggestion === 'string')?[_c('li',{key:`${suggestion}-${idx}`,attrs:{"aria-posinset":idx,"aria-controls":`recent_term_${idx}`},on:{"click":function($event){return _vm.$emit('searchRecentTerm', suggestion)}}},[_c('div',{staticClass:"suggestion-container"},[_c('img',{staticClass:"icon",attrs:{"src":require('pinnacle-lib/assets/icon/clock.svg')}}),_c('p',[_vm._v(_vm._s(suggestion))])])])]:('place_id' in suggestion)?[_c('li',{key:suggestion.place_id,attrs:{"aria-posinset":idx,"aria-controls":`place_id_${idx}`},on:{"click":function($event){return _vm.$emit('searchSuggestion', suggestion)}}},[_c('div',{staticClass:"suggestion-container"},[_c('img',{staticClass:"icon",attrs:{"src":require('pinnacle-lib/assets/icon/search-icon.svg')}}),_c('p',[_vm._v(_vm._s(suggestion.description))])])])]:(suggestion.type === 'category')?[_c('li',{key:idx,attrs:{"aria-posinset":idx,"aria-controls":`category_${idx}`},on:{"click":function($event){return _vm.$emit('searchSuggestion', suggestion)}}},[_c('div',{staticClass:"suggestion-container"},[_c('img',{staticClass:"icon",attrs:{"src":suggestion.recent
                    ? require('pinnacle-lib/assets/icon/clock.svg')
                    : require('pinnacle-lib/assets/icon/search-icon.svg')}}),_c('p',[_vm._v(_vm._s(suggestion.value))]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm._f("category_field_filter")(suggestion.field)))+" ")])])])]:(suggestion.type === 'geography')?[_c('li',{key:idx,attrs:{"aria-posinset":idx,"aria-controls":`geography_${idx}`},on:{"click":function($event){return _vm.$emit('searchSuggestion', suggestion)}}},[_c('div',{staticClass:"suggestion-container"},[_c('img',{staticClass:"icon",attrs:{"src":suggestion.recent
                    ? require('pinnacle-lib/assets/icon/clock.svg')
                    : require('pinnacle-lib/assets/icon/search-icon.svg')}}),_c('p',[_vm._v(" "+_vm._s(_vm._f("geography_value_filter")(suggestion))+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(suggestion.field.split("_")[0]))+" ")])])])]:(!('type' in suggestion))?[_c('li',{key:idx,attrs:{"aria-posinset":idx,"aria-controls":`address_${idx}`},on:{"click":function($event){return _vm.$emit('viewDetail', suggestion, true)}}},[_c('div',{staticClass:"suggestion-container"},[_c('img',{staticClass:"icon",attrs:{"src":suggestion.recent
                    ? require('pinnacle-lib/assets/icon/clock.svg')
                    : require('pinnacle-lib/assets/icon/location-marker.svg')}}),_c('p',[_vm._v(_vm._s(suggestion.name))]),_c('p',{staticClass:"description"},[_vm._v(_vm._s(suggestion.address))])])])]:_vm._e()]})],2),(_vm.logo !== undefined)?_c('div',{staticClass:"logo-container"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logo}})]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }