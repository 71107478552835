import numeral from "numeral";
import moment from "moment";

export const getLineGraphOptions = (args) => ({
  scales: {
    yAxes: [
      {
        gridLines: {
          // type: "linear",
          // color: "#283E59",
          // zeroLineColor: "#283E59",
          display: true,
        },
        offsetGridLines: true,
        ticks: {
          maxTicksLimit: 6,
          stepSize: 1,
          callback: function(label) {
            if (/\s/.test(label)) {
              return label.split(" ");
            }else{
              return label;
            }
          }  
        },
      },
    ],
    xAxes: [
      
      {
        type: "time",
        time:       {
            // format: "LLL dd",
            unit: args.timeFrame,
            displayFormats: {
              month: 'MMM YYYY',
              week: 'MMM DD',
              day: 'MMM DD',
              year: 'YYYY',
              hour: 'HH'
          },
          tooltipFormat: args.timeFrame === 'month' ? 'MMM YYYY' : args.timeFrame === 'week' ? 'MMM DD' : args.timeFrame === 'day' ? 'MMM DD' : args.timeFrame === 'hour' ? 'HH:MM:SS' : 'YYYY',
        },
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: args.labelStringXAxes,
          position: "center",
          fontStyle: "bold"

        },
        ticks: {
          stepSize: 1,
          maxTicksLimit: 6,
          callback: function(label) {
            if (args.timeFrame === 'week') {
              const endOfWeek = moment(label, "MMM DD").add(6, "days").format("MMM DD");
              label += ` - ${endOfWeek}`;
            }
            return label;
          }
        },
      },
    ],
    responsive: true,
    maintainAspectRatio: false,
  },
  title: {
    display: true,
    text: args.labelStringYAxes,
    position: "left",
    fontStyle: "bold"
  },
  legend: {
    labels: {
      filter: (item) =>
        // Logic to remove a particular legend item goes here
        item.text !== "Median",
    },
    display: false,
  },
    //to remove min and max from tooltip
  
    tooltips: {
      filter: function(tooltipItem, data) { 
          var label = data['datasets'][tooltipItem['datasetIndex']]['label'];
          if (args.timeFrame === 'week') {
            tooltipItem.label += ` - ${moment(tooltipItem.label, "MMM DD").add(6, "days").format('MMM DD')}`;
          }
          if (label == "Min" || label == "Max") {
            return false;
          } else {
            return true;
          }
        },
        ...(args.tooltipSuffix && {
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                if(data['datasets'][tooltipItem['datasetIndex']]['label'] !== 'Avg')
                  return data['datasets'][tooltipItem['datasetIndex']]['label'] + ' ' + data['datasets'][0]['data'][tooltipItem['index']] + args.tooltipSuffix;
                else{
                  return data['datasets'][tooltipItem['datasetIndex']]['label'] + ' ' + data['datasets'][1]['data'][tooltipItem['index']] + args.tooltipSuffix;
                }
              },
            },
          },
        }),
      },
  // ...(args.tooltipSuffix && {
  //   tooltips: {
  //     callbacks: {
  //       label: function(tooltipItem, data) {
  //         if(data['datasets'][tooltipItem['datasetIndex']]['label'] !== 'Avg')
  //           return data['datasets'][tooltipItem['datasetIndex']]['label'] + ' ' + data['datasets'][0]['data'][tooltipItem['index']] + args.tooltipSuffix;
  //         else{
  //           return data['datasets'][tooltipItem['datasetIndex']]['label'] + ' ' + data['datasets'][1]['data'][tooltipItem['index']] + args.tooltipSuffix;
  //         }
  //       },
  //     },
  //   },
  // }),
  maintainAspectRatio: false,
  responsive: true,
});

export const getBarGraphOptions = (args) => {
  return {
    scales: {
      yAxes: [
        {
          gridLines: {
            display: args.hideYAxes === true ? false : true,
          },
          ticks: {
            maxTicksLimit: 6,
            stepSize: 1,
            callback: function(label) {
              if (/\s/.test(label)) {
                return label.split(" ");
              }else{
                return label;
              }
            }  
            // callback: function(label) {
            //   if (/\s/.test(label)) {
            //     return label.split(/(.{20})/).filter((O) => O);
            //   }
            //   return label;
            // },
          },
          ...(args.barThickness && {
//            minBarThickness: args.barThickness,
//            maxBarThickness: 28,
          }),
        },
      ],
      xAxes: [
        {
          offset: true,
          type: "time",
          time:       {
              // format: "LLL dd",
              unit: args.timeFrame,
              displayFormats: {
                month: 'MMM YYYY',
                week: 'MMM DD',
                day: 'MMM DD',
                year: 'YYYY',
                hour: 'HH'
            },
            tooltipFormat: args.timeFrame === 'month' ? 'MMM YYYY' : args.timeFrame === 'week' ? 'MMM DD' : args.timeFrame === 'day' ? 'MMM DD' : args.timeFrame === 'hour' ? 'HH:MM:SS' : 'YYYY',
          },
          gridLines: {
            display: args.hideXAxes === true ? false : true,
          },
          ticks: {
            stepSize: 1,
            maxTicksLimit: 6,
            max: args.max,
            callback: function(label) {
              if (args.timeFrame === 'week') {
                const endOfWeek = moment(label, "MMM DD").add(6, "days").format("MMM DD");
                label += ` - ${endOfWeek}`;
              }
              return label;
            }
          },
          // ticks: {
          //   maxTicksLimit: 6,
          //   display: args.hideXAxes === true ? false : true,
          //   callback: function(value) {
          //     const num = numeral(value).format("0,0");
          //     return `${num}${window.ticksPercentage ? "%" : ""}`;
          //   },
          // },
          //categoryPercentage: 0.3,
          //barPercentage: 1,
          scaleLabel: {
            display: true,
            labelString: args.labelStringXAxes || "",
            position: "center",
            fontStyle: "bold"
          },
        },
      ],
    },
    title: {
      display: true,
      text: args.labelStringYAxes,
      position: "left",
      fontStyle: 'bold'
    },
    legend: {
      labels: {
        filter: (item) => item.text !== "Median",
      },
    },
    tooltips: {
      filter: function(tooltipItem, data) {
        var label = data['datasets'][tooltipItem['datasetIndex']]['label'];
        if (args.timeFrame === 'week') {
          tooltipItem.label += ` - ${moment(tooltipItem.label, "MMM DD").add(6, "days").format('MMM DD')}`;
        }
        if (label == "Min" || label == "Max") {
          return false;
        } else {
          return true;
        }
      },
    },    
    ...(!args.hideMedian && {
      lineAtIndex: 50,
    }),
    ...(args.barThickness && {
      tooltips: false,
    }),
    ...(args.tooltipSuffix && {
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            if(data['datasets'][tooltipItem['datasetIndex']]['label'] !== 'Avg')
              return data['datasets'][tooltipItem['datasetIndex']]['label'] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + args.tooltipSuffix;
            else{
            return data['datasets'][tooltipItem['datasetIndex']]['label'] + ': ' + data['datasets'][1]['data'][tooltipItem['index']] + args.tooltipSuffix;
            }
          },
          
        },
      },
    }),

    layout: {
      padding: args.layoutPadding,
    },

    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 5,
  };
};

export const getBarGraphOptionsNoTime = (args) => {
  return {
    scales: {
      yAxes: [
        {
          gridLines: {
            display: args.hideYAxes === true ? false : true,
          },
          ticks: {
            maxTicksLimit: 6,
            stepSize: 1,
            // callback: function(label) {
            //   if (/\s/.test(label)) {
            //     return label.split(" ");
            //   }else{
            //     return label;
            //   }
            // }  
            callback: function(label) {
                // if (label.length > 20){
                  if (/\s/.test(label)) {
                  // return label.slice(0,20) + "...";
                  return label.split(/(.{20})/).filter((O) => O);
                } else {
                  return label;
                }
          }  
          },
          ...(args.barThickness && {
//            minBarThickness: args.barThickness,
//            maxBarThickness: 28,
          }),
        },
      ],
      xAxes: [
        {
          // categoryPercentage: 1.0,
          // barPercentage: 1.0,
          gridLines: {
            display: args.hideXAxes === true ? false : true,
          },
          ticks: {
            stepSize: 1,
            maxTicksLimit: 6,
            max: args.max,
          },
          // ticks: {
          //   maxTicksLimit: 6,
          //   display: args.hideXAxes === true ? false : true,
          //   callback: function(value) {
          //     const num = numeral(value).format("0,0");
          //     return `${num}${window.ticksPercentage ? "%" : ""}`;
          //   },
          // },
          //categoryPercentage: 0.3,
          //barPercentage: 1,
          scaleLabel: {
            display: true,
            labelString: args.labelStringXAxes || "",
            position: "center",
            fontStyle: "bold"
          },
        },
      ],
    },
    title: {
      display: true,
      text: args.labelStringYAxes,
      position: "left",
      fontStyle: 'bold'
    },
    legend: {
      labels: {
        filter: (item) => item.text !== "Median",
      },
    },
    ...(!args.hideMedian && {
      lineAtIndex: 50,
    }),
    ...(args.barThickness && {
      tooltips: false,
    }),
    ...(args.tooltipSuffix && {
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            if(data['datasets'][tooltipItem['datasetIndex']]['label'] !== 'Avg')
              return data['datasets'][tooltipItem['datasetIndex']]['label'] + ' ' + data['datasets'][tooltipItem['datasetIndex']]['data'][tooltipItem['index']] + args.tooltipSuffix;
            else{
            return data['datasets'][tooltipItem['datasetIndex']]['label'] + ' ' + data['datasets'][1]['data'][tooltipItem['index']] + args.tooltipSuffix;
            }
          },
          
        },
      },
    }),

    layout: {
      padding: args.layoutPadding,
    },

    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 9,
  };
};

export const getLineGraphOptionsNoTime = (args) => ({
  scales: {
    yAxes: [
      {
        gridLines: {
          // type: "linear",
          // color: "#283E59",
          // zeroLineColor: "#283E59",
          display: true,
        },
        offsetGridLines: true,
        ticks: {
          maxTicksLimit: 6,
          stepSize: 1,
          callback: function(label) {
            if (/\s/.test(label)) {
              return label.split(" ");
            }else{
              return label;
            }
          }  
        },
      },
    ],
    xAxes: [
      
      {
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: args.labelStringXAxes,
          position: "center",
          fontStyle: "bold"
        },
        ticks: {
          stepSize: 1,
          maxTicksLimit: 6,
        },
      },
    ],
    responsive: true,
    maintainAspectRatio: false,
  },
  title: {
    display: true,
    text: args.labelStringYAxes,
    position: "left",
    fontStyle: "bold"
  },
  legend: {
    labels: {
      filter: (item) =>
        // Logic to remove a particular legend item goes here
        item.text !== "Median",
    },
    display: false,
  },
  // ...(args.tooltipSuffix && {
  //   tooltips: {
  //     callbacks: {
  //       label: function(tooltipItem, data) {
  //         if(data['datasets'][tooltipItem['datasetIndex']]['label'] !== 'Avg')
  //           return data['datasets'][tooltipItem['datasetIndex']]['label'] + ': ' + data['datasets'][tooltipItem['datasetIndex']]['data'][tooltipItem['index']] + args.tooltipSuffix;
  //         else{
  //           return data['datasets'][tooltipItem['datasetIndex']]['label'] + ': ' + data['datasets'][1]['data'][tooltipItem['index']] + args.tooltipSuffix;
  //         }
  //       },
  //     },
  //   },
  // }),

  
    //to remove min and max from tooltip
  
    tooltips: {
      filter: function(tooltipItem, data) { 
          var label = data['datasets'][tooltipItem['datasetIndex']]['label'];
          if (label == "Min" || label == "Max") {
            return false;
          } else {
            return true;
          }
        },
        ...(args.tooltipSuffix && {
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                if(data['datasets'][tooltipItem['datasetIndex']]['label'] !== 'Avg')
                  return data['datasets'][tooltipItem['datasetIndex']]['label'] + ' ' + data['datasets'][0]['data'][tooltipItem['index']] + args.tooltipSuffix;
                else{
                  return data['datasets'][tooltipItem['datasetIndex']]['label'] + ' ' + data['datasets'][1]['data'][tooltipItem['index']] + args.tooltipSuffix;
                }
              },
            },
          },
        }),
      },
  maintainAspectRatio: false,
  responsive: true,
});

export const momentDateFormat = "MM/DD/YYYY";
export const momentParseDateFormats = ["MM/DD/YYYY", "MM/DD/YY", moment.ISO_8601];