<template>
  <div>
    <SelectFormat v-if="$route.name==='create-dataset'"></SelectFormat>

  <router-view></router-view>
  </div>
</template>

<script>
import SelectFormat from './SelectFormat.vue';

export default {
  components: {SelectFormat}
}
</script>

<style lang="scss" scoped></style>