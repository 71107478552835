<template>
  <div>
    <div class="section">
      <CreateHeader :useRouteMeta="true"/>
    </div>
    <div class="section">
      <PolygonChooser :allowMultiple="$route.name.includes('report') ? false : true" />
    </div>
    <!-- Hiding Large Polygons Toggle with the Large Jobs Implementation -->
    <div v-if="false && canUseLargePolygons" class="section">
      <AllowLargePolygons v-if="canUseLargePolygons" />
    </div>
  </div>
</template>

<script>
import CreateHeader from "./CreateHeader.vue";
import PolygonChooser from '../../../dataExplorer/PolygonChooser.vue';
import AllowLargePolygons from "../../../dataExplorer/reportOptions/AllowLargePolygons.vue";
export default {
  components: { PolygonChooser, CreateHeader, AllowLargePolygons },
  computed: {
  canUseLargePolygons() {
      return this.$persistingStore.state.user.company.roles.find(
        (item) => item.name === "DataAPI Allow Large Polygons In CreateJob"
      ) || this.hasPermission("Super Admin", this.$persistingStore.state.user.permissions);
    }
  }
}
</script>

<style lang="scss" scoped></style>