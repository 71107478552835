export default {
  data() {
    return {
      _rootComponent: null,
      _rootComponentId: null,
      _familyMapping: {},
      _openSubmenuStack: [],
      _ignoreMapping: {},
    };
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      const { componentId } = bvEvent;
      const hasCorrectPath = this.hasCorrectPath(bvEvent.target);
      const isInMapping = componentId in this.$data._familyMapping;

      if (!hasCorrectPath) return;

      if (isInMapping) {

        const component = this.$data._familyMapping[componentId];
        component.ref = bvEvent.vueTarget;

        if (component.level > this.$data._openSubmenuStack.length) {
          this.$data._openSubmenuStack.push(component.ref);
          return;
        }
        else if (component.level <= this.$data._openSubmenuStack.length) {
          const diff = this.$data._openSubmenuStack.length - component.level + 1;
          for (let i = 0; i < diff; i++) {
            const closed = this.$data._openSubmenuStack.pop();
            this.$data._ignoreMapping[closed.id] = true;
            closed.hide();
          }
          this.$data._openSubmenuStack.push(component.ref);
          return;
        }
        bvEvent.preventDefault();
      }

    });

    this.$root.$on('bv::dropdown::hide', bvEvent => {

      const { componentId } = bvEvent;
      const hasCorrectPath = this.hasCorrectPath(bvEvent.target);
      const isInMapping = componentId in this.$data._familyMapping;

      if (!hasCorrectPath) return;


      if (isInMapping) {

        const component = this.$data._familyMapping[componentId];
        component.ref = bvEvent.vueTarget;

        if (this.$data._ignoreMapping[componentId]) {
          delete this.$data._ignoreMapping[componentId];
          return;
        }

        if (component.level === this.$data._openSubmenuStack.length) {
          this.$data._openSubmenuStack.pop();
          return;
        }
        bvEvent.preventDefault();
      }
    });

    document.addEventListener('click', this.checkClick);

  },
  destroyed() {
    this.$root.$off('bv::dropdown::hide');
    this.$root.$off('bv::dropdown::show');
    document.removeEventListener('click', this.checkClick);
  },
  methods: {
    /**
     * Must be called on mounted() to construct a dropdown tree.
     * @param {*} rootComponentRef The root dropdown ref.
     */
    registerMultiDropdown(rootComponentRef) {

      if (!rootComponentRef.id) {
        console.warn('Could not register multi dropdown, ID was not defined!', rootComponentRef.$vnode.data.ref);
        return;
      }
      this.$data._rootComponent = rootComponentRef;
      this.$data._rootComponentId = this.$data._rootComponent.id;

      const scanChildren = (parent, level) => {
        parent.$children.forEach(child => {
          if (child.$options._componentTag === 'b-dropdown' || child.$options._componentTag === 'b-dd') {
            if (!parent.id) {
              console.warn('No parent ID found while registering multi dropdown', parent);
              return;
            }
            if (!child.id) {
              console.warn('No child ID found while registering multi dropdown', child);
              return;
            }
            if (!(parent.id in this.$data._familyMapping)) this.$data._familyMapping[parent.id] = {children: [], level, ref: parent};
            if (!(child.id in this.$data._familyMapping)) this.$data._familyMapping[child.id] = {children: [], level: level + 1, ref: child, parent};
            this.$data._familyMapping[parent.id].children.push(child.id);
            scanChildren(child, level + 1);
          }
        });
      };

      scanChildren(this.$data._rootComponent, 1);
    },
    /**
     * Clears all the dropdowns.
     */
    clearDropdowns(msg) {
      const stack = this.$data._openSubmenuStack;

      const clearDropdown = (dropdown) => {
        this.$data._ignoreMapping[dropdown.id] = true;
        dropdown.hide(true);
      }

      while (stack.length > 0) {
        const dropdown = stack.pop();
        clearDropdown(dropdown);
      }
      this.$data._rootComponent.hide();
      this.$data._openSubmenuStack = [];

    },
    /**
     * Checks if the passed dropdown is a child of the root dropdown.
     * @param {*} el The dropdown element.
     * @returns {boolean} Returns true if the dropdown element is a child of root.
     */
    hasCorrectPath(el) {
      if (el.id === this.$data._rootComponentId) return true;
      let current = null;
      while (current === null || current.parentElement) {
        current = current === null ? el.parentElement : current.parentElement;
        if (current.id === this.$data._rootComponentId) return true;
      }
      return false;
    },
    /**
     * Clears the dropdowns if a dropdown item is selected or a dropdown element was not clicked.
     * @param {*} evt The click event.
     */
    checkClick(evt) {
      let didClickDropdown = false;
      if (evt.target.tagName === 'A' && evt.target.className.includes('dropdown-item')) {
        const correctPath = evt.target.id === this.$data._rootComponentId || evt.path.some(node => (node.id === this.$data._rootComponentId));
        if (correctPath) this.clearDropdowns('dropdown item clicked');
        return;
      }
      for (const parentId in this.$data._familyMapping) {
        const { children } = this.$data._familyMapping[parentId];
        if (parentId === evt.target.id || children.some(childId => (childId === evt.target.id))) {
          didClickDropdown = true;
          break;
        }
      }
      if (!didClickDropdown && this.$data._openSubmenuStack.length > 0) this.clearDropdowns('clearing all dropdowns, nothing clicked');
    }
  }
}