export default class MapManager extends EventTarget {
  constructor(mapInstance) {
    super();
    this.map = mapInstance;
  }

  on(eventName, handler, options=null) {
    this.addEventListener(eventName, handler, options);
  }

  off(eventName, handler, options=null) {
    this.removeEventListener(eventName, handler, options);
  }

  emit(eventName, options=null) {
    this.dispatchEvent(new CustomEvent(eventName, {'detail': options}));
  }
}