<template>
  <ChartCard id="org" :isLoading="isLoading" :isLoadingToLocation="isLoadingToLocation">
    <template #icon>
      <img :src="require('pinnacle-lib/assets/icon/origin-clock.svg')" alt="stopwatch" />
    </template>
    <template #title>
      <h2>Visitor Home Locations</h2>
      <p>Top areas visitors live</p>
    </template>
    <template #dropdown>
      <b-dropdown
        id="origin-options"
        ref="originOptions"
        style="margin-right: -20px"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        data-html2canvas-ignore="true"
      >
        <template #button-content>
          <img :src="require('pinnacle-lib/assets/icon/elip.svg')" alt="foot" />
        </template>
        <li>
          <b-dropdown id="export-submenu" class="dropdown-item" variant="link">
            <template #button-content> Export </template>
            <b-dropdown-item @click="_saveChart()"
              >Download Image</b-dropdown-item
            >
            <b-dropdown-item
              @click="saveData('Visitor Home Locations', 'origin')"
              >Download Data</b-dropdown-item
            >
          </b-dropdown>
        </li>
        <li>
          <b-dropdown
            id="geo-type-submenu"
            class="dropdown-item"
            variant="link"
          >
            <template #button-content> Geographic Level </template>
            <b-dropdown-item
              @click="
                selectedGeographyOption = 'municipality';
                onGeographyChange();
              "
              >Market</b-dropdown-item
            >
            <b-dropdown-item
              @click="
                selectedGeographyOption = 'postal';
                onGeographyChange();
              "
              >Postal Code</b-dropdown-item
            >
            <b-dropdown-item
              @click="
                selectedGeographyOption = 'province';
                onGeographyChange();
              "
              >Province</b-dropdown-item
            >
          </b-dropdown>
        </li>
      </b-dropdown>
    </template>
    <template #name-card>
      <name-card
        chart-type="origin"
        v-bind:insufficient-data-ids="insufficientDataIds"
      ></name-card>
    </template>
    <template #charts-container>
      <div
        class="originD"
        id="horizontalBarChart"
        v-bind:style="isLoading ? 'z-index: -99' : 'z-index: 1'"
      >
        <HorizontalBarChart
          ref="originHorizontalBarChart"
          :chart-data="origin.bar.data"
          :options="origin.bar.option"
          style="min-height: 150px"
          :class="`${isLoading ? 'opacity-25' : 'opacity-100'}`"
        />
        <div style="position: absolute; top: 35%; left: 50%"></div>

        <span class="originLabelStringXAxes">{{
          origin.bar.labelStringXAxes
        }}</span>
      </div>

      <div class="originD showContainer">
        <button
          class="showMore"
          v-if="!show"
          v-on:click="upHandler"
          style="margin-right: 5px"
        >
          <img :src="upFlag ? upDark : up" />
        </button>
        <button class="showMore" v-if="!show" v-on:click="downHandler">
          <img :src="downFlag ? downDark : down" />
        </button>
      </div>
      <div v-if="showWarn" class="warning-container">
        <div class="warning">Data unavailable for the selected dates</div>
      </div>
    </template>
  </ChartCard>
</template>
<script>
import ChartCard from "./chartCard.vue";
import HorizontalBarChart from "./HorizontalBarChart";
import NameCard from "../partials/NameCard.vue";
import { bus } from "@/main";
import { getBarGraphOptionsNoTime } from "@/config/chart";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import MultiDropdownMixin from "pinnacle-lib/mixins/multiDropdown.js";
import ChartsDownloadMixin from "../../services/charts/download.js";
import ChartUtilMixin from "../../services/charts/chartUtil.js";

const upD = require('pinnacle-lib/assets/icon/up_dark.svg');
const dowD = require('pinnacle-lib/assets/icon/down_dark.svg');
const upL = require('pinnacle-lib/assets/icon/up_light.svg');
const dowL = require('pinnacle-lib/assets/icon/down_light.svg');

export default {
  components: {
    HorizontalBarChart,
    NameCard,
    ChartCard,
  },
  mixins: [MultiDropdownMixin, ChartsDownloadMixin, ChartUtilMixin],
  data() {
    return {
      attempt: false,
      upFlag: false,
      downFlag: false,
      page: 0,
      pages: [0],
      newArr: [],
      up: upL,
      down: dowL,
      upDark: upD,
      downDark: dowD,
      show: false,
      noData: false,
      geographyOptions: [
        { value: "municipality", text: "Market" },
        { value: "postal", text: "Postal code" },
        { value: "province", text: "Province" },
      ],
      origin: {
        bar: {
          data: null,
          option: null,
          labelStringXAxes: "",
        },
      },
      polygon: 0,
      originEntries: {},
      selectedConversionOption: "percentage",
      conversionOptions: [
        { value: "percentage", text: "Percentage" },
        { value: "count", text: "Count" },
      ],
      selectedGeographyOption: "municipality",
      isLoading: true,
      insufficientDataIds: [],
      selection: "",
      showWarn: false,
    };
  },
  props: {
    filterString: {
      type: String,
    },
    id: {
      type: Array,
    },
  },
  methods: {
    async _saveChart() {
      this.$bvModal.hide("my-modal");
      this.$sessionStore.commit("setDownloading", true);
      this.$bvModal.show("downloading");
      let formatP = "png";
      let zip = new JSZip();
      let tempAdd =
        this.$route.path.split("/")[1] === "preview"
          ? this.$sessionStore.state.study.previews
          : this.$sessionStore.state.study.locations;
      let canvas1 = await html2canvas(document.querySelector("#org")).then(
        (canvas) => {
          return canvas;
        }
      );
      if (document.querySelector("#button0")) {
        let base0 = document.querySelector("#button0");
        await base0.click();
        let org0 = await html2canvas(document.querySelector("#org")).then(
          (canvas) => {
            return canvas;
          }
        );
        await org0.toBlob(function (blob) {
          zip.file(
            `Visitor Home Locations-${tempAdd[0].name}-${tempAdd[0].address}.${formatP}`,
            blob
          );
        }, `image/${formatP}`);
      }
      if (document.querySelector("#button1")) {
        let base1 = document.querySelector("#button1");
        await base1.click();
        console.log("clicked");
        let org1 = await html2canvas(document.querySelector("#org")).then(
          (canvas) => {
            return canvas;
          }
        );
        await org1.toBlob(function (blob) {
          zip.file(
            `Visitor Home Locations-${tempAdd[1].name}-${tempAdd[1].address}.${formatP}`,
            blob
          );
        }, `image/${formatP}`);
      }
      if (document.querySelector("#button2")) {
        let base2 = document.querySelector("#button2");
        await base2.click();
        let org2 = await html2canvas(document.querySelector("#org")).then(
          (canvas) => {
            return canvas;
          }
        );
        await org2.toBlob(function (blob) {
          zip.file(
            `Visitor Home Locations-${tempAdd[2].name}-${tempAdd[2].address}.${formatP}`,
            blob
          );
        }, `image/${formatP}`);
      }
      if (document.querySelector("#button3")) {
        let base3 = document.querySelector("#button3");
        await base3.click();
        let org3 = await html2canvas(document.querySelector("#org")).then(
          (canvas) => {
            return canvas;
          }
        );
        await org3.toBlob(function (blob) {
          zip.file(
            `Visitor Home Locations-${tempAdd[3].name}-${tempAdd[3].address}.${formatP}`,
            blob
          );
        }, `image/${formatP}`);
      }
      if (document.querySelector("#button4")) {
        let base4 = document.querySelector("#button4");
        await base4.click();
        let org4 = await html2canvas(document.querySelector("#org")).then(
          (canvas) => {
            return canvas;
          }
        );
        await org4.toBlob(function (blob) {
          zip.file(
            `Visitor Home Locations--${tempAdd[4].name}-${tempAdd[4].address}.${formatP}`,
            blob
          );
        }, `image/${formatP}`);
      }
      let that = this.$sessionStore;
      await canvas1.toBlob(function (blob) {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          that.commit("setDownloading", false);
          saveAs(content, "Visitor Home Locations.zip");
        });
      }, "image/jpeg");
    },
    saveCSV() {
      const rows = [["Place\tRank\tMarket\tPercent"]];
      let tempAdd =
        this.$route.path.split("/")[1] === "preview"
          ? this.$sessionStore.state.study.previews
          : this.$sessionStore.state.study.locations;
      let tempRow = [];
      for (let j = 0; j < this.id.length; j++) {
        tempRow.push(
          this.originEntries[this.id[j]].map((o, index) => [
            `${tempAdd[j].name} ${tempAdd[j].address}\t${
              index + 1
            }\t${o.name.replace(/,/gi, "")}\t${o.percent}\r\n`,
          ])
        );
      }
      let rew = rows.concat(tempRow);
      let csvContent = "";
      rew.forEach(function (rowArray) {
        let row = rowArray;
        csvContent += row + "\r\n";
      });
      this.$sessionStore.commit(
        "setOriginChart",
        csvContent.replace(/,/gi, "")
      );
    },
    upHandler() {
      if (this.upFlag === true) {
        this.page = this.page - 1;
        if (this.page === 0) {
          this.upFlag = false;
        }
        if (this.page >= this.pages.length - 1) {
          this.downFlag = false;
        }
        this.pageChange();
      }
    },
    downHandler() {
      if (this.downFlag == true) {
        this.page = this.page + 1;
        if (this.page >= this.pages.length - 2) {
          this.downFlag = false;
        }
        if (this.page !== 0) {
          this.upFlag = true;
        }
        //console.log(this.downFlag);
        this.pageChange();
      }
    },
    pageChange() {
      //console.log("page changing");
      //console.log(this.page);
      // console.log(this.selection)
      this.updateOrigin(this.selection);
    },
    updateOrigin(polygon) {
      //console.log(this.originEntries[polygon]);
      const originData = Object.entries(this.originEntries[polygon] || {}).map(
        (entry) => {
          const { name, percent, value } = entry[1];
          return [name, { percent, value }];
        }
      );
      //console.log(originData);
      const isPercent = this.selectedConversionOption === "percentage";
      const isMarket = this.selectedGeographyOption === "municipality";
      const isPost = this.selectedGeographyOption === "postal";
      const isProv = this.selectedGeographyOption === "province";
      let title =
        this.selectedGeographyOption === "municipality"
          ? "Market"
          : this.selectedGeographyOption === "postal"
          ? "Postal Code"
          : this.selectedGeographyOption === "province"
          ? "Province"
          : "Error";
      this.newArr = [];
      let i,
        j,
        temp,
        split = 6;
      for (i = 0, j = originData.length; i < j; i += split) {
        temp = originData.slice(i, i + split);
        this.newArr.push(temp);
      }
      this.pages = [...Array(this.newArr.length).keys()];
      if (this.pages.length >= 2 && this.page < this.pages.length - 1) {
        this.downFlag = true;
      } else if (this.pages.length === 1) {
        this.upFlag = false;
        this.downFlag = false;
      } else {
        this.downFlag = false;
      }
      window.ticksPercentage = isPercent;
      if (this.newArr.length == 0) {
        return;
      }
      const { color } = this.chartColors;
      this.origin = {
        bar: {
          data: {
            labels: this.newArr[this.page].map((o) => o[0]),
            datasets: [
              {
                label: "Visits",
                data: isPercent
                  ? this.newArr[this.page].map((o) => o[1].percent)
                  : this.newArr[this.page].map((o) => o[1].value),
                backgroundColor: color[this.name.indexOf(this.polygon)],
                borderRadius: 2,
                barThickness: 26,
                maxBarThickness: 28,
                datalabels: {
                  anchor: "end",
                  align: "right",
                  offset: 10,
                  formatter: function (value) {
                    // const num = numeral(value).format("0,00");
                    return `${value}${isPercent ? "%" : ""}`;
                  },
                },
              },
            ],
          },
          option: getBarGraphOptionsNoTime({
            labelStringYAxes: title,
            barThickness: 26,
            categoryPercentage: 0.2,
            hideMedian: true,
            layoutPadding: {
              right: 50,
            },
            max: 100,
            labelStringXAxes: isPercent
              ? "Percent of Visitors"
              : "Count of Visitors",
          }),
        },
      };
      this.saveCSV();
      // Re-Render Chart
      if (this.isLoading === true) {
        this.isLoading = false;
        setTimeout(() => {
          this.$refs.originHorizontalBarChart.renderChart(
            this.$refs.originHorizontalBarChart.chartData,
            this.$refs.originHorizontalBarChart.options
          );
        }, 1);
      }
    },
    onGeographyChange() {
      if (this.showWarn) {
        return;
      }
      this.isLoading = true;
      this.upFlag = false;
      this.page = 0;
      this.requestData().then((res) => {
        //console.log(res);
        this.setData(res);
      });
    },
    polygonChange() {
      this.upFlag = false;
      this.page = 0;
      this.selection = this.id[this.name.indexOf(this.polygon)];
      this.updateOrigin(this.selection);
    },
    loadChart() {
      this.requestData().then((res) => {
        //console.log(res);
        this.setData(res);
      });
    },
    setData(res) {
      const [originEntries] = res;
      this.updateOrigin(this.id[this.name.indexOf(this.polygon)]);
    },
    async requestData() {
      this.showWarn = false;
      const extraParams = {
        "geography_level": this.selectedGeographyOption
      };
      const promises = [this.getChartData(`v2/origin-market/${this.$route.params.ids}`, extraParams)];
      const responses = await Promise.allSettled(promises);
      // Check Insufficient Data
      let insufficientDataIds =
        responses[0].value.data.meta_data.insufficientDataIds;
      let scope = this;
      if (insufficientDataIds) {
        this.insufficientDataIds = insufficientDataIds;
      }
      const dataArray = Object.entries(responses[0].value.data);
      if (this.insufficientDataIds.length === dataArray.length - 1) {
        this.showWarn = true;
        this.isLoading = false;
        return;
      }
      let idWithData = -1;
      for (var i = 0; i < this.id.length; i++) {
        if (
          this.disabledIds[this.id[i]] === undefined &&
          !this.insufficientDataIds.includes(this.id[i])
        ) {
          idWithData = i;
          break;
        }
      }
      if (idWithData !== -1) {
        this.polygon = this.name[idWithData];
        this.selection = this.id[idWithData];
      }
      let origin = responses[0].value.data;
      // let max = day.aggregation_data.max_line;
      // let min = day.aggregation_data.min_line;
      this.originEntries = origin;
      //console.log(this.originEntries);
      return [this.originEntries];
    },
  },
  mounted() {
    this.registerMultiDropdown(this.$refs.originOptions);
    //this.loadChart();  // Wait until filters event before populating chart
    bus.$on("filters", (res) => {
      //this.filterString = res;
      this.isLoading = true;
      if (this.isLoadingToLocation === false) {
        this.loadChart();
      } else {
        this.attempt = true;
      }
    });
    bus.$on("onClick", (res) => {
      this.polygon = res.selected;
      this.polygonChange();
    });
    bus.$on("locationsListChanged", (res) => {
      let newIndex = this.id.indexOf(res.locationId);
      let ids = this.id;
      if (!res.selected || this.noData) {
        if (this.name[newIndex] === this.polygon) {
          for (let i = 0; i <= this.id.length + 1; i++) {
            if (i === this.id.length) {
              this.noData = true;
              this.isLoading = true;
            }
            let id = ids[i];
            if (
              id &&
              this.disabledIds[ids[i]] === undefined &&
              !this.insufficientDataIds.includes(ids[i])
            ) {
              this.polygon = this.name[i];
              this.polygonChange();
              return;
            }
          }
        }
      }
    });
  },
  computed: {
    name() {
      return this.location.map(
        (value, index) => `${index + 1}. ${value.shortenedName}`
      );
    },
    location() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split("/")[1] === "preview"
        ? this.$sessionStore.state.study.previews
        : this.$sessionStore.state.study.locations;
    },
    isLoadingToLocation() {
      return this.$sessionStore.state.study.loading;
    },
    disabledIds() {
      return this.$sessionStore.state.study.disabledIds;
    },
  },
  watch: {
    isLoadingToLocation() {
      if (!this.isLoadingToLocation && this.attempt) {
        let idWithData = -1;
        for (var i = 0; i < this.id.lenghth; i++) {
          if (!this.insufficientDataIds.includes(ids[i])) {
            idWithData = i;
            break;
          }
        }
        if (idWithData !== -1) {
          this.polygon = this.name[idWithData];
          this.selection = this.id[idWithData];
        }
        this.requestData().then((res) => {
          this.setData(res);
        });
      }
    },
  },
  // beforeDestroy() {
  //   this.unwatch();
  // },
};
</script>

<style lang="scss" scoped>
@import "./chartStyles.scss";
</style>