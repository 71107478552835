import schema from './defaults.js';
import createGeoJSON from '../../utils/createGeojson.js';
import VPService from './vpService.js';
import { parseZip } from 'shpjs';
import * as toGeoJson from '@mapbox/togeojson';
import { p2pReports } from '../../components/dataExplorer/reportTypes.js';
import * as dayjs from 'dayjs'
import * as duration from 'dayjs/plugin/duration';
dayjs.extend(duration);


export default {
  mixins: [VPService],
  data() {
    return {
      polygonMetaData: {
        errors: [],
        polygons: [],
        selectedLocationMethod: null,
        selectedLocationMethodLabel: null,
      },
    }
  },
  methods: {
    _updateSelectedReports(newId, oldId) {
      if (Array.isArray(newId)) {
        for (const id of oldId) this.selectedReportIds.delete(id);
        for (const id of newId) this.selectedReportIds.add(id);
      } else {
        this.selectedReportIds.delete(oldId);
        this.selectedReportIds.add(newId);
      }
    },
    _removeNulls(params) {
      for (const key in params) {
        if (params[key] === null || params[key] === "" || (Array.isArray(params[key]) && params[key].length === 0)) {
          console.log(`Removing ${key}`);
          delete params[key];
        } else if (typeof params[key] === "object") {
          this._removeNulls(params[key]);
        }
      }
    },
    _checkTimeframe(params) {
      if (params.didReportTypes !== undefined && params.didReportTypes.length === 1 && params.didReportTypes[0] === 'RESIDENT_WORKER_REPORT') {
        if (params.pipReportType === '' && params.ftReportType === '' && params.p2pReportType === '' && params.locationReportType === '') {
          delete params.startDateTime;
          delete params.endDateTime;
          return;
        }
      }
    },
    _setExternalDefaults(params) {
      if (!params.pipHotspotScreening) params.pipHotspotScreening = {};
      params.pipHotspotScreening.enableHotspotScreening = true;
      // params.unhashedDeviceIds = false;
      // params.allowLargePolygons = false;
      // params.screenOutliers = true;
      params.reportOutputOptions.preciseLatLon = false;
      // if (this.isReportIdSelected('GENERATOR_REPORT')) {
      //   params.generatorSecondsBefore = 604800;
      // }
    },
    _setDefaults(params) {
      for (const rootField in schema) {
        const metadata = schema[rootField];
        if (this.isReportIdSelected(metadata.reportId) || metadata.required) {
          if (metadata.value !== undefined && params[rootField] === undefined) {
            console.log(`Provisioning ${rootField}`);
            params[rootField] = metadata.value;
          }
          else if (metadata.value === undefined) {
            console.log(`Provisioning ${rootField}`);
            params[rootField] = Object.assign({}, metadata.defaults, params[rootField]);
          }
        } else if (!this.isReportIdSelected(metadata.reportId) && metadata.deleteFields !== undefined) {
          for (const field of metadata.deleteFields) {
            console.log(`Removing ${field}`);
            delete params[field];
          }
        }
      }
      if (params.p2pReportType !== "PATHING_X_CONTEXT_REPORT" && params.p2pReportType !== "PATHING_X_CONTEXT_ONLY_REPORT") {
        delete params.contextPoiGeojson;
      }
      if (params.clusterReportOptions !== undefined) {
        // Taken from addp2pParams in Vista
        params.clusterReportOptions.pointsThreshold = params.clusterReportOptions.minDevices * 2;
      }
      if (params.p2pReportType === '' && params.locationReportOptions) {
        params.secondsBefore = params.locationReportOptions.ogsSecondsBefore;
        params.secondsAfter = params.locationReportOptions.ogsSecondsAfter;
      }
      if (params.dwellTimeReportOptions !== undefined) {
        const { dwellSecondsBefore, dwellSecondsAfter } = params.dwellTimeReportOptions;
        if (dwellSecondsBefore !== undefined && dwellSecondsAfter !== undefined) {
          // Change to secondsBefore and secondsAfter
          params.locationReportOptions.ogsSecondsBefore = dwellSecondsBefore;
          params.locationReportOptions.ogsSecondsAfter = dwellSecondsAfter;
          delete params.dwellTimeReportOptions.dwellSecondsBefore;
          delete params.dwellTimeReportOptions.dwellSecondsAfter;
        }
        const p2pReport = params.p2pReportType;
        if (p2pReport !== '') params.dwellTimeReportOptions.dwellTimeReportInputType = p2pReport;
        else params.dwellTimeReportOptions.dwellTimeReportInputType = 'PATHINGX';
      }
      const url = process.env.VUE_APP_VISTA_CALLBACK_URL || 'https://vista-stage.um.co/api/trigger_data_api_email';
      params.completionCallbackUrl = url;
    },
    _cleanup(params) {
      this._checkTimeframe(params);
      this._setExternalDefaults(params);
      this._setDefaults(params);
      this._removeNulls(params);

      delete params.template; // This is from card reports
      
      if (this.jobRequest.includeDataSources !== undefined) {
        delete params.dataSourceGroup;
      }
      if (this.jobRequest.locationReportType !== "OGS") {
        delete params.minDevices;
      }
    },
    /**
     * Fetches the job details from the Data API.
     * @param {number} jobId The job ID.
     */
    async loadJobDetails(jobId) {
      let headers = this.getHeaders();
      headers.headers["Cache-Control"] = "no-cache";
      headers.headers["Pragma"] = "no-cache";
      headers.headers["Expires"] = "0";

      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();

        const { data } = await this.$http.get(
          `${process.env.VUE_APP_PINNACLE_API}/v2/data-api/stage/getJobStatus?jobId=${jobId}&includeRequest=true&includeChildCompanies=${this.includeChildCompanies}`,
          {
            ...headers
          }
        );
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    isReportIdSelected(id) {
      if (Array.isArray(id)) {
        for (const reportId of id) {
          if (this.selectedReportIds.has(reportId)) return true;
        }
        return false;
      }
      return this.selectedReportIds.has(id);
    },

    multiPolygonFix(geojson) {
      console.log('Convert Polygons to MultiPolygons');
      if (geojson && geojson.features) {
        geojson.features.forEach((feature, index, arr) => {
          if (feature.geometry.type == "Polygon") {
            console.log('Polygon Converted'); 
            feature.geometry.type = "MultiPolygon";
            feature.geometry.coordinates = [feature.geometry.coordinates]
          }
        });
      }
      return geojson; 
    },


    geometry(_) {
      var area = 0, i;
      switch (_.type) {
          case 'Polygon':
              return this.polygonArea(_.coordinates);
          case 'MultiPolygon':
              for (i = 0; i < _.coordinates.length; i++) {
                  area += this.polygonArea(_.coordinates[i]);
              }
              return area;
          case 'Point':
          case 'MultiPoint':
          case 'LineString':
          case 'MultiLineString':
              return 0;
          case 'GeometryCollection':
              for (i = 0; i < _.geometries.length; i++) {
                  area += this.geometry(_.geometries[i]);
              }
              return area;
      }
    },
    
    polygonArea(coords) {
        var area = 0;
        if (coords && coords.length > 0) {
            area += Math.abs(this.ringArea(coords[0]));
            for (var i = 1; i < coords.length; i++) {
                area -= Math.abs(this.ringArea(coords[i]));
            }
        }
        return area;
    },
    
    /**
     * Calculate the approximate area of the polygon were it projected onto
     *     the earth.  Note that this area will be positive if ring is oriented
     *     clockwise, otherwise it will be negative.
     *
     * Reference:
     * Robert. G. Chamberlain and William H. Duquette, "Some Algorithms for
     *     Polygons on a Sphere", JPL Publication 07-03, Jet Propulsion
     *     Laboratory, Pasadena, CA, June 2007 http://trs-new.jpl.nasa.gov/dspace/handle/2014/40409
     *
     * Returns:
     * {float} The approximate signed geodesic area of the polygon in square
     *     meters.
     */
    
    ringArea(coords) {
        var p1, p2, p3, lowerIndex, middleIndex, upperIndex, i,
        area = 0,
        coordsLength = coords.length;
        var wgs84_RADIUS = 6378137
    
        if (coordsLength > 2) {
            for (i = 0; i < coordsLength; i++) {
                if (i === coordsLength - 2) {// i = N-2
                    lowerIndex = coordsLength - 2;
                    middleIndex = coordsLength -1;
                    upperIndex = 0;
                } else if (i === coordsLength - 1) {// i = N-1
                    lowerIndex = coordsLength - 1;
                    middleIndex = 0;
                    upperIndex = 1;
                } else { // i = 0 to N-3
                    lowerIndex = i;
                    middleIndex = i+1;
                    upperIndex = i+2;
                }
                p1 = coords[lowerIndex];
                p2 = coords[middleIndex];
                p3 = coords[upperIndex];
                area += ( this.rad(p3[0]) - this.rad(p1[0]) ) * Math.sin( this.rad(p2[1]) );
            }
    
            area = area * wgs84_RADIUS * wgs84_RADIUS / 2;
        }
    
        return area;
    },
    
    rad(_) {
        return _ * Math.PI / 180;
    },

    calculateJobSize(parsedGeojson) {
      let features = this.collectFeaturesFromGeoJSON(parsedGeojson);
      this.calculateAreas(features);
      this.calculateInterval();
      
    
    },
    calculateArea(features) {
      let total_area = 0;
      let max_area = 0;
      features.forEach((feature) => {
        let feature_area = this.geometry(feature.geometry);
        if (feature_area > max_area) {
          max_area = feature_area;
        }
        total_area += feature_area;
      });
      this.polygonMetaData.TotalArea = total_area;
      this.polygonMetaData.MaxArea = max_area;
    },
    calculateInterval() {
      console.log(this.startDateTime, this.endDateTime);
      let startDate = dayjs(this.startDateTime);
      let endDate = dayjs(this.endDateTime);
      let diff = endDate.diff(startDate, 'years', true);
      console.log(diff);
      this.polygonMetaData.TimeframeInterval = diff;
    },
    calculateAreaXInterval(parsedGeojson) {
      let features = this.collectFeaturesFromGeoJSON(parsedGeojson);
      this.polygonMetaData.GeoJSON = parsedGeojson;
      this.polygonMetaData.NumPolygons = features.length;
      this.calculateArea(features);
      this.calculateInterval();
      this.polygonMetaData.AreaXInterval = this.polygonMetaData.TotalArea * this.polygonMetaData.TimeframeInterval;

      this.polygonMetaData.ValidInterval = 20*1000000 / this.polygonMetaData.TotalArea;
      this.polygonMetaData.IntervalDiff = this.polygonMetaData.TimeframeInterval - this.polygonMetaData.ValidInterval;
                
      this.polygonMetaData.ValidArea = 20*1000000 / this.polygonMetaData.TimeframeInterval;
      this.polygonMetaData.AreaDiff = this.polygonMetaData.TotalArea - this.polygonMetaData.ValidArea;      

      this.checkLargeJob();
    },
    checkLargeJob() {
      // Things to Check
      // Does company have Allow Large Polygons permission
      // Set jobParams.allowLargePolygons as true automatically if allowed
      if (this.canUseLargePolygons) {
        this.allowLargePolygons = true;
      }

      this.polygonMetaData.largeJobReason = null;
      this.isLargeJob = false;
      //console.log(this.$persistingStore.state.user.permissions);
      let canCreateLargeJobs = false;
      this.$persistingStore.state.user.permissions.forEach((p) => {
        if (p.name == "Allow Large Jobs") {
          canCreateLargeJobs = true;
        }
      })
      if (canCreateLargeJobs) {
        this.isLargeJob = false;
        return false;
      }
      if (!this.canUseLargePolygons) {
        // In this case use the 5m single polygon check first
        if (this.polygonMetaData.MaxArea >= 5*1000000) {
          this.polygonMetaData.largeJobReason = "sqft";
          this.isLargeJob = false;
          return false;
        }
        if (this.polygonMetaData.AreaXInterval >= 20*1000000) {
          this.polygonMetaData.largeJobReason = "sqft-years";
          this.isLargeJob = true;
          return true;
        }
      } else {
        if (this.polygonMetaData.AreaXInterval >= 20*1000000) {
          this.polygonMetaData.largeJobReason = "sqft-years";
          this.isLargeJob = true;
          return true;
        }
      }
      
      return false;
    },
    collectFeaturesFromGeoJSON(parsedGeojson) {
      let polygons = [];
      if (parsedGeojson.type == "Feature") {
        polygons.push(parsedGeojson);
      }
      if (parsedGeojson.type == "FeatureCollection") {
        polygons = parsedGeojson.features
      }
      return polygons;
    },    
    async parseGeojson() {
      if (this.selectedLocationMethod == 'select') {
        let parsedGeojson = {};
        if (this.selectedPolygons !== undefined && this.selectedPolygons.length > 0) {
          parsedGeojson = createGeoJSON(this.selectedPolygons);
          return parsedGeojson;
        } else if (this.selectAllParams !== undefined) {
          try {
            const data = await this.getFilteredPolygons({ overrideParams: this.selectAllParams });
            let excluded = [];
            if (this.excludedPolygons !== undefined && this.excludedPolygons.length > 0) excluded = this.excludedPolygons.map(polygon => polygon.id);
            const polygons = data.records.filter(polygon => { return !excluded.includes(polygon.id) });
            parsedGeojson = createGeoJSON(polygons);
            return parsedGeojson;
          } catch (err) {
            console.log(err);
            throw new Error('Could not fetch select all polygons.');
          }
        }
      }
      if (this.selectedLocationMethod == 'geojson') {
        if (this.polygonInputOptions && this.polygonInputOptions.polygonFormat == "GEOJSON") {
          try {
            let parsedGeojson = JSON.parse(this.polygonString);
            return parsedGeojson;
          } catch(e) {
            console.log(e);
          }
        }
        if (this.polygonInputOptions && this.polygonInputOptions.polygonFormat == "KML") {
          let dom = (new DOMParser()).parseFromString(this.polygonString, 'text/xml');
          let parsedGeojson = toGeoJson.kml(dom);
          return parsedGeojson;
        }
        if (this.polygonInputOptions && this.polygonInputOptions.polygonFormat == "ESRI_SHAPEFILE_ZIP") {
          if (this.attachedFile && this.attachedFile.arrayBuffer) {
            let file_buffer = await this.attachedFile.arrayBuffer();
            let parsedGeojson = await parseZip(file_buffer);
            return parsedGeojson;
          }
        }
      }
      if (this.selectedLocationMethod == 'draw') {
        let parsedGeojson = this.drawnPolygonGeoJSON;
        return parsedGeojson;
      }

      return parsedGeojson;
    },
  	fixPropertiesName(parsedGeojson) {
  		// If the geojson is a FeatureCollection then make sure each feature has a properties.name, properties.id, or id.  Otherwise assign one
  		if (parsedGeojson.type == "FeatureCollection") {
  			parsedGeojson.features.forEach((feature, i) => {
  				// create a properties object if its missing
  				if (!feature.properties) {
  					feature.properties = {};
  				}
  				// check for properties.name
  				if (feature.properties && feature.properties.name) {
  					return;
  				}
  				// check for properties.id
  				if (feature.properties && feature.properties.id) {
  					feature.properties.name = feature.properties.id;
  					return;
  				}
  				// check for id
  				if (feature.id) {
  					feature.properties.name = feature.id;
  					return;
  				}
          // check for properties.Name
          if (feature.properties && feature.properties.Name) {
            feature.properties.name = feature.properties.Name;
            delete feature.properties.Name;
            return;
          }
  				// If no name/id found default properties.name to be location_(i+1)
  				feature.properties.name = "location_"+(i+1);
  			});
  		}
  		return parsedGeojson;
  	},
    
    /**
     * Parses the polygon selection and formats the params for Data API.
     * @param {*} params 
     */
    async parsePolygonSelection(params) {
      if (params.selectedLocationMethod === 'select') {
        params.polygonInputOptions.polygonFormat = 'GEOJSON';
        delete params.polygonInputOptions.polygonNameAliasElement;
        delete params.attachedFile;
        if (params.selectedPolygons !== undefined && params.selectedPolygons.length > 0) {
          params.polygonInputOptions.geojson = createGeoJSON(params.selectedPolygons);
        } else if (params.selectAllParams !== undefined) {
          try {
            const data = await this.getFilteredPolygons({ overrideParams: params.selectAllParams });
            let excluded = [];
            if (params.excludedPolygons !== undefined && params.excludedPolygons.length > 0) excluded = params.excludedPolygons.map(polygon => polygon.id);
            const polygons = data.records.filter(polygon => { return !excluded.includes(polygon.id) });
            params.polygonInputOptions.geojson = createGeoJSON(polygons);
          } catch (err) {
            console.log(err);
            throw new Error('Could not fetch select all polygons.');
          }
        }
        params.polygonInputOptions.geojson = this.multiPolygonFix(params.polygonInputOptions.geojson);
      } else if (params.selectedLocationMethod === 'draw') {
        params.polygonInputOptions.polygonFormat = 'GEOJSON';
        delete params.polygonInputOptions.polygonNameAliasElement;
        delete params.attachedFile;
        if (params.drawnPolygonGeoJSON === undefined) throw new Error('Could not find drawn polygon');
        params.polygonInputOptions.geojson = params.drawnPolygonGeoJSON;
      } else if (params.selectedLocationMethod === 'geojson') {
        if (params.polygonInputOptions.polygonFormat !== 'ESRI_SHAPEFILE_ZIP') {
          delete params.attachedFile;
        }
        if (params.polygonString !== undefined) {
          let fieldname = params.polygonInputOptions.polygonFormat === 'GEOJSON' ? 'geojson' : 'kml';
          if (fieldname === 'geojson' && typeof params.polygonString === 'string') params.polygonString = JSON.parse(params.polygonString);
          params.polygonInputOptions[fieldname] = params.polygonString;
          if (fieldname === 'geojson') {
            params.polygonInputOptions[fieldname] = this.multiPolygonFix(params.polygonInputOptions[fieldname]);
          }
        }
      }

      delete params.selectedPolygons;
      delete params.excludedPolygons;
      delete params.selectAllParams;
      delete params.polygonString;
      delete params.drawnPolygonGeoJSON;
      delete params.selectedLocationMethod;
    },
    async requestJob() {
      const params = Object.assign({}, this.jobRequest);
      this._cleanup(params);
      await this.parsePolygonSelection(params);
      console.log('REPORT SUBMITTED', params);

      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const config = this.getHeaders();
        let response;

        if (this.jobRequest.isLargeJob) {
          console.log('largeJob Submitter');
          delete params.attachedFile;
          let parsedGeojson = await this.parseGeojson();
          parsedGeojson = this.fixPropertiesName(parsedGeojson);
          this.calculateAreaXInterval(parsedGeojson);
          params.parsedGeojson = this.polygonMetaData.GeoJSON;
          params.NumPolygons = this.polygonMetaData.NumPolygons;
          params.TotalArea = this.polygonMetaData.TotalArea;
          params.TimeframeInterval = this.polygonMetaData.TimeframeInterval;
          console.log('polygonMetaData', this.polygonMetaData);
          console.log('params', params);
          response = await this.$http.post('/v2/dag-api/createJob', params, config);
          console.log('DATA API RESPONSE: ', response);
        } else if (params.attachedFile !== undefined) {
          const formData = new FormData();
          formData.append('polygonFile', params.attachedFile);
          delete params.attachedFile;
          formData.append('jsonRequest', JSON.stringify(params));
          config.headers['Content-Type'] = 'multipart/form-data';
          response = await this.$http.post('/v2/data-api/stage/createJobWithFile', formData, config);
          console.log('DATA API RESPONSE: ', response);
        } else {
          delete params.attachedFile;
          response = await this.$http.post('/v2/data-api/stage/createJob', params, config);
          console.log('DATA API RESPONSE: ', response);
        }
        return response.data;
      } catch (raw) {
        console.log(raw);
        if ('error' in raw) return {succeeded: false, error: raw.error};
        if (raw && raw.response && raw.response.data && raw.response.data.error) {
          return {succeeded: false, error: raw.response.data.error}  
        }
        return {succeeded: false, error: raw};
      }
    }
  },
  computed: {
    jobRequest() {
      return this.$sessionStore.state.jobRequest;
    },
    selectedReportIds() {
      const reportIds = new Set();
      if (this.jobRequest.pipReportType) reportIds.add(this.jobRequest.pipReportType);
      if (this.jobRequest.ftReportType) reportIds.add(this.jobRequest.ftReportType);
      if (this.jobRequest.didReportType) reportIds.add(this.jobRequest.didReportType);
      for (const reportType of this.jobRequest.didReportTypes) reportIds.add(reportType);
      if (this.jobRequest.p2pReportType) reportIds.add(this.jobRequest.p2pReportType);
      if (this.jobRequest.locationReportType) reportIds.add(this.jobRequest.locationReportType);
      return reportIds;
    }
  },
}