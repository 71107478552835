
const initialSearchState = {
  searchState: {
    results: [],
    term: "",
    geographies: [],
    categories: [],
    currentPage: 1,
    hasMoreLocations: false,
    geo: {
      term: ''
    },
    submitted: false
  },
  query: {},
  showNearbyPlaces: false,
  selectedSuggestion: undefined,
  liveSearch: true,
  searchVisualization: 'PlacesCluster',
  mapMoved: false,
  needsUpdate: true
};


export default {
  state: Object.assign({}, initialSearchState),
  mutations: {
    setSearchState: (state, payload) => {
      state.searchState = payload;
    },
    setQueryParams: (state, payload) => {
      state.query = payload;
    },
    setSearchDefault: (state) =>{
      Object.assign(state, initialSearchState);
    },
    clearSearchState: (state) => {
      Object.assign(state, initialSearchState);
    },
    setShowNearbyPlaces: (state, showNearbyPlaces) => {
      state.showNearbyPlaces = showNearbyPlaces;
    },
    setSelectedSuggestion: (state, suggestion) => {
      state.selectedSuggestion = suggestion;
    },
    setLiveSearch: (state, liveSearch) => {
      state.liveSearch = liveSearch;
    },
    setSearchVisualization: (state, searchVisualization) => {
      state.searchVisualization = searchVisualization;
    },
    setMapMoved: (state, mapMoved) => {
      state.mapMoved = mapMoved;
    },
    setNeedsUpdate: (state, needsUpdate) => {
      state.needsUpdate = needsUpdate;
    }
  },
};
