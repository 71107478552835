var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isToggled)?_c('div',{staticClass:"left-nav-lightbox",on:{"click":_vm.toggleLeftNav}}):_vm._e(),_c('nav',{staticClass:"navbar navbar-vertical fixed-start navbar-expand-md navbar-light sidebar-collapsed",class:{ 'sidebar-expanded': _vm.isToggled }},[_c('div',{staticClass:"container-fluid"},[_c('a',{staticClass:"navbar-brand",on:{"click":() => {_vm.navigate({name: 'home-datasets', path: '/home/datasets', meta: { appName: 'pinnacle' }})}}},[_c('span',[_c('inline-svg',{attrs:{"src":require('../../assets/icon/pinnacle-sidebar-logo.svg')}})],1)]),_c('div',{staticClass:"collapse navbar-collapse"},[_c('div',[_c('hr',{staticClass:"navbar-divider"}),_c('ul',{staticClass:"navbar-container-list navbar-nav",class:{
              'd-flex': !_vm.isToggled,
              'align-items-center': !_vm.isToggled,
            }},[(_vm.canSee('home'))?_c('NavItem',{attrs:{"isToggled":_vm.isToggled,"currentRoute":_vm.currentRoute.includes('/home'),"title":"Home","container":".navbar-container-list","navigate":() => {_vm.navigate({name: 'home-feeds', path: '/home/feeds', meta: { appName: 'pinnacle' }})},"icon":require('../../assets/icon/home-unselected.svg'),"iconSelected":require('../../assets/icon/home-selected.svg')}}):_vm._e(),(_vm.canSee('explore'))?_c('NavItem',{attrs:{"isToggled":_vm.isToggled,"currentRoute":_vm.currentRoute.includes('/explore') || _vm.currentRoute.includes('/study') || _vm.currentRoute.includes('/preview'),"title":"Places","container":".navbar-container-list","navigate":() => {_vm.navigate({name: 'explore', path: '/explore', meta: { appName: 'pinnacle' }})},"icon":require('../../assets/icon/places-pin-unselected.svg'),"iconSelected":require('../../assets/icon/places-pin-selected.svg')}}):_vm._e()],1),_c('hr',{staticClass:"navbar-divider"}),_c('ul',{staticClass:"help-links navbar-nav",class:{
              'd-flex': !_vm.isToggled,
              'align-items-center': !_vm.isToggled,
            }},[_c('NavItem',{attrs:{"isToggled":_vm.isToggled,"title":"Documentation","container":".help-links","navigate":() => {_vm.navigate({name: 'documentation', path: '/documentation', meta: { appName: 'pinnacle' }})},"icon":require('../../assets/icon/documentation.svg'),"iconSelected":require('../../assets/icon/documentation-selected.svg')}},[_c('img',{staticClass:"external-link-img",attrs:{"src":require("../../assets/icon/external-link.svg")}})]),_c('NavItem',{attrs:{"isToggled":_vm.isToggled,"currentRoute":_vm.currentRoute.includes('/customer-support'),"title":"Customer Support","container":".help-links","navigate":() => {_vm.navigate({name: 'customer-support', path: '/customer-support', meta: { appName: 'pinnacle' }})},"icon":require('../../assets/icon/customer-support.svg'),"iconSelected":require('../../assets/icon/customer-support-selected.svg')}}),_c('NavItem',{attrs:{"isToggled":_vm.isToggled,"currentRoute":_vm.currentRoute.includes('/settings'),"title":"Settings","container":".help-links","navigate":() => {_vm.navigate({name: 'settings', path: '/settings', meta: { appName: 'pinnacle' }})},"icon":require('../../assets/icon/settings.svg'),"iconSelected":require('../../assets/icon/settings-selected.svg')}})],1)]),_c('div',{staticClass:"navbar-user d-none d-md-flex",attrs:{"id":"sidebar-user"}},[_c('div',[(!_vm.isToggled)?_c('img',{staticClass:"avatar-img cursor-pointer",attrs:{"src":require("../../assets/icon/open.svg"),"alt":"Expand sidebar"},on:{"click":_vm.toggleLeftNav}}):_vm._e(),(_vm.isToggled)?_c('img',{staticClass:"avatar-img cursor-pointer",attrs:{"src":require("../../assets/icon/collapse.svg"),"alt":"Collapse sidebar"},on:{"click":_vm.toggleLeftNav}}):_vm._e()])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }