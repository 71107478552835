<template>
  <div class="layer-icon" :style="getStyle()">
    <img v-if="!loading" :src="icon" :alt="alt" />
    <div class="lottie-container" v-if="loading">
      <vue-lottie-player
        autoplay
        loop
        mode="normal"
        :animationData="require('pinnacle-lib/assets/lottie/layer-loader.json')"
        width="30px"
        height="30px"
        name="layer-loader"
      />
    </div>
  </div>
</template>

<script>
import VueLottiePlayer from "vue-lottie-player";
export default {
  name: "LayerIcon",
  props: ["icon", "loading", "alt", "width", "height", "margin"],
  components: {
    VueLottiePlayer,
  },
  methods: {
    getStyle() {
      const dimensions = `width: ${this.width ? this.width : "48px"}; height: ${
        this.height ? this.height : "48px"
      }; margin: ${this.margin ? this.margin : '0 20px 0 0'};`; 
      return dimensions;
    },
  },
};
</script>

<style lang="scss" scoped>
.layer-icon {
  background: rgba(44, 123, 229, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lottie-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
