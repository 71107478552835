<template>
  <div>
    <div class="section">
      <CreateHeader :useRouteMeta="true" />
    </div>
    <div class="section">
      <Timeframe :disableDayparts="$route.name.includes('report')" />
    </div>
  </div>
</template>

<script>
import CreateHeader from "./CreateHeader.vue";
import Timeframe from "../../../dataExplorer/Timeframe.vue";

export default {
  components: { CreateHeader, Timeframe }
}
</script>

<style lang="scss" scoped>

</style>