
import MapManager from "@/services/map/mapManager";
import HoverManager from "@/services/map/hoverManager";
import LayerManager from "@/services/map/layerManager";
import SourceManager from "@/services/map/sourceManager";
import { VisualizationType, getTypeById, isCommunityLayerById } from "@/services/map/types";
import { createMarker } from "@/services/map/markers";
import { provisionAvatar } from "@/utils/avatars";
import { sessionStore } from '@/store/index.js';
import axios from 'axios';

var updateCLMarkers_timeout = null;
function updateCLMarkers(id, clickHandler, omitLocations, force=false) {
  clearTimeout(updateCLMarkers_timeout);
  updateCLMarkers_timeout = setTimeout(updateCLMarkersDebounced.bind(this, id, clickHandler, omitLocations, force), 500);
}

function updateCLMarkersDebounced(id, clickHandler, omitLocations, force=false) {
  let vis_type = getTypeById(id);
  this.clRenderCount = 0;

  const keep = {};
  const features = this.map.querySourceFeatures(id, {sourceLayer: 'geojsonLayer', validate: false});

  let locations = [];
  if (typeof omitLocations !== 'undefined') {
    locations = sessionStore.state.study[omitLocations];
  }

  for (const feature of features) {
    if (!feature.properties.cluster && !feature.properties.hidden) {

      if (locations.length > 0 && locations.find(loc => loc.id === feature.properties.id) !== undefined) {
        continue;
      }
      
      keep[feature.properties.id] = true;

      if (feature.properties.id in this.clMarkerStorage) {
        this.clMarkerStorage[feature.properties.id].addTo(this.map);
        continue;
      }

      const center = feature.geometry.coordinates;
      const { url, type } = provisionAvatar(feature.properties);
      let marker_config = {
        map: this.map,
        center,
        iconURL: url,
        iconType: type,
        address: feature.properties.address,
        label: feature.properties.name,
        loc: feature.properties,
        click_handler: clickHandler,
        iconSize: "18px",
        
        // comparisonLocations: locations,
      };
      if (vis_type.name == VisualizationType.PLACES_CLUSTER.name) {
        marker_config.iconBGColor = "#2C7BE5";
      }
      
      const marker = createMarker(marker_config);
      marker.addTo(this.map);
      this.clMarkerStorage[feature.properties.id] = marker;
    }
  }

  if (sessionStore.state.search.liveSearch) {
//    bus.$emit('getLocations', closest_results, false, 1, false, false, true);
  } else if (!sessionStore.state.search.mapMoved) {
//    bus.$emit('getLocations', closest_results, false, 1, false, false, true);
  }

  for (const key in this.clMarkerStorage) {
    if (!keep[key]) {
      this.clMarkerStorage[key].remove();
    }
  }

}

export class MapService extends MapManager {

  constructor(mapInstance) {
    super(mapInstance);
    mapInstance.on('idle', () => this._processAction());
    this.queuedActions = [];
    this.sourceMgr = new SourceManager(mapInstance, this);
    this.hoverMgr = new HoverManager(mapInstance, this);
    this.layerMgr = new LayerManager(mapInstance, this);
    this.visualizations = new Set();
    this.clMarkerStorage = {};
    this.clRenderListener = null;
    this.clMarkerClickHandler = null;
    this.errored = {};
  }

  async _processAction() {
    if (this.queuedActions.length === 0) return;
    const action = this.queuedActions.shift();
    try {
      const res = await this[action.method](...action.args);
      action.resolve(res);
    } catch (err) {
      console.log({err});
      action.reject({message: 'Could not process action.', err});
    }
    return;
  }

  _queueAction({method, resolve, reject}, ...args) {
    this.queuedActions.push({method, resolve, reject, args});
  }

  async addTileVisualization({ filters, id, clickHandler, omitLocations }) {

    if (!this.map.loaded()) {
      const promise = new Promise((resolve, reject) => {
        this._queueAction({
          method: 'addTileVisualization',
          resolve,
          reject
        }, {filters, id, clickHandler, omitLocations});
      });
      return await promise;
    }
    this.visualizations.add(id);

    try {
      const data = await this.sourceMgr.addTileSource({ id, filters }, true);
      const type = getTypeById(id);

      if (data === undefined) {
        this.emit('cancelvisualization', { id });
        return;
      }

      const layerData = this.layerMgr.createLayerData({ id, filters });

      if (type.hasPopup) {
        this.hoverMgr.addHoverPopup(id, filters.api_params);
      }

      // Add render listener for layer
      if (type.name === VisualizationType.CL.name) {
        this.clMarkerClickHandler = clickHandler;
        this.clRenderListener = updateCLMarkers.bind(this, id, clickHandler, omitLocations);
        this.map.on('render', this.clRenderListener);
      }
      if (type.name === VisualizationType.PLACES_CLUSTER.name) {
        this.clMarkerClickHandler = clickHandler;
        this.clRenderListener = updateCLMarkers.bind(this, id, clickHandler, omitLocations);
        this.map.on('render', this.clRenderListener);
      }
      

      if (layerData.layers) {
        for (const layer of layerData.layers) {
          await this.layerMgr.addLayer(layer, true, id);
        }
      } else await this.layerMgr.addLayer(layerData.layer, true);

      if (this.clRenderListener) this.clRenderListener(id, omitLocations); // Initial call

      this.emit('addvisualization', { id, zoom: this.map.getZoom() });
    } catch (err) {
      throw this.handleError(id, err, filters.visualization);
    }
  }

  async addBatchVisualization({ filters, locations, id, bounds }) {

    if (!this.map.loaded()) {
      const promise = new Promise((resolve, reject) => {
        this._queueAction({
          method: 'addBatchVisualization',
          resolve,
          reject
        }, {filters, id, locations, bounds});
      });
      return await promise;
    }

    this.visualizations.add(id);

    try {

      const data = await this.sourceMgr.addBatchSource({ id, filters, locations, bounds }, true);
      const type = getTypeById(id);

      if (data === undefined) {
        this.emit('cancelvisualization', { id });
        return;
      }

      const layerData = this.layerMgr.createLayerData({ id, filters, batch: true });

      if (type.hasPopup) {
        this.hoverMgr.addHoverPopup(id, filters.api_params, locations);
      }

      await this.layerMgr.addLayer(layerData.layer, true);
      
      if (this.shouldZoom() && bounds !== undefined) this.map.fitBounds(bounds, { padding: 250, maxZoom: 14 });

      this.emit("addvisualization", { id, zoom: this.map.getZoom() });

    } catch (err) {
      throw this.handleError(id, err, filters.visualization);
    }
  }

  async addVisualization({ filters, location, id, bounds }) {
    if (!this.map.loaded()) {
    const promise = new Promise((resolve, reject) => {
      this._queueAction({
        method: 'addVisualization',
        resolve,
        reject
      }, {filters, id, location, bounds});
    });
    return await promise;
  }

    this.visualizations.add(id);
    //console.log('this.visualizations.add(id);', id);

    try {
      const type = getTypeById(id);
      let overwrite = false;
      if (type && type.prefix == "routes") {
        overwrite = true; 
      }
      const data = await this.sourceMgr.addSource({ id, filters, location, bounds }, overwrite);      

      if (data === undefined) {
        this.emit('cancelvisualization', { id });
        return; // Request cancelled
      }

      const layerData = this.layerMgr.createLayerData({ id, filters, colorRamp: location.colorRamp });

      if (type.hasPopup) {
        this.hoverMgr.addHoverPopup(id, filters.api_params);
      }

      await this.layerMgr.addLayer(layerData.layer, true);

      if (this.shouldZoom() && bounds !== undefined) this.map.fitBounds(bounds, { padding: 250, maxZoom: 14 });

      this.emit("addvisualization", { id, zoom: this.map.getZoom() });

    } catch (err) {
      throw this.handleError(id, err, filters.visualization);
    }

    return;
  }

  shouldZoom() {
    const visualizations = Array.from(this.visualizations);
    const hasWH = visualizations.filter(id => { return id.includes('wh_') }).length > 0;
    const hasRoutes = visualizations.filter(id => { return id.includes('routes_') }).length > 0;
    return (hasWH && !hasRoutes) || (hasRoutes && !hasWH);
  }

  handleError(id, err, visualization) {
    console.log(err);
    this.visualizations.delete(id);
    this.emit('addvisualizationerror', { id });
    this.errored[id] = err;
    if ('code' in err && err.code < 0) {
      const passedError = new Error(err.err);
      passedError.type = visualization;
      passedError.id = id;
      return passedError;
    }
    const defaultError = new Error(`Error fetching ${visualization} data for: ${id}`);
    defaultError.type = visualization;
    defaultError.id = id;
    return defaultError;
  }

  isLoaded(id) {
    return this.layerMgr.isLayerLoaded(id) && this.sourceMgr.isSourceLoaded(id) && this.visualizations.has(id);
  }

  _remove(id) {

    const type = getTypeById(id);

    if (type.hasPopup) {
      this.hoverMgr.removeHoverPopup(id);
    }

    if (type.name === VisualizationType.CL.name || type.name === VisualizationType.PLACES_CLUSTER.name) {
      this.map.off('render', this.clRenderListener);
      this.clRenderListener = null;
      this.clMarkerClickHandler = null;
      for (const key in this.clMarkerStorage) {
        this.clMarkerStorage[key].remove();
      }
      this.clMarkerStorage = {};
    }
    
    this.layerMgr.removeLayer(id);
    this.sourceMgr.removeSource(id);

    this.visualizations.delete(id);
  }

  async removeVisualization({ id }) {

    if (!this.map.loaded()) {
      const promise = new Promise((resolve, reject) => {
        this._queueAction({
          method: 'removeVisualization',
          resolve,
          reject
        }, {id});
      });
      return await promise;
    }

    return new Promise((resolve) => {

      const canCancel = id in this.sourceMgr.dataService.cancelTokens;
      
      if (!this.visualizations.has(id) && canCancel) {
        //console.log('not in set of visualizations', id);
        this.sourceMgr.dataService.cancelRequest(id);
        this._remove(id);
        return resolve(true);
      } else if (!this.isLoaded(id) && !canCancel && !isCommunityLayerById(id)) {

        if (this.errored[id]) {
          console.log('Tried to remove errored visualization');
          delete this.errored[id];
          return resolve(true);
        }

        console.log('Queued remove: true ' + id);

        const helper = (evt) => {
          if (evt.detail.id === id) {
            this._remove(id);
            this.off('addvisualization', helper);
            this.off('addvisualizationerror', errorHelper);
            return resolve(true);
          }
        };

        const errorHelper = (evt) => {
          console.log('errorHelper', evt);
          if (evt.detail.id === id) {
            delete this.errored[id];
            this.off('addvisualization', helper);
            this.off('addvisualizationerror', errorHelper);
            return resolve(true);
          }
        };

        this.on('addvisualizationerror', errorHelper);
        this.on('addvisualization', helper);

      } else {
        //console.log('this.sourceMgr.dataService.cancelRequest', id);
        this.sourceMgr.dataService.cancelRequest(id);
        this._remove(id);
        return resolve(true);
      }

    });

  }
  
  async hideVisualization({ id }) {
    if (!this.map.loaded()) {
      const promise = new Promise((resolve, reject) => {
        this._queueAction({
          method: 'hideVisualization',
          resolve,
          reject
        }, {id});
      });
      return await promise;
    }

    return new Promise((resolve) => {
  
      this.layerMgr.hideLayer(id);

    });    
    
  }
  async showVisualization({ id }) {
    if (!this.map.loaded()) {
      const promise = new Promise((resolve, reject) => {
        this._queueAction({
          method: 'hideVisualization',
          resolve,
          reject
        }, {id});
      });
      return await promise;
    }

    return new Promise((resolve) => {
  
      this.layerMgr.showLayer(id);

    });    
    
  }

  async updateVisualization({ id, ids, location, locations, filters, bounds, noZoom }) {
    await this.clearVisualizations(filters.prefix);
    if (ids) {
      for (const i in locations) {
        let id = ids[i];
        let location = locations[i];
        await this.addVisualization({ id, location, filters, bounds, noZoom });  
      }
    } else {
      await this.addVisualization({ id, location, filters, bounds, noZoom });
    }
  }

  async updateBatchVisualization({ id, locations, filters, bounds, noZoom }) {
    await this.clearVisualizations(filters.prefix);
    await this.addBatchVisualization({ id, locations, filters, bounds, noZoom });
  }

  async updateTileVisualization({ locations, filters, id, clickHandler, omitLocations }) {
    await this.clearVisualizations(filters.prefix);
    await this.addTileVisualization({ locations, filters, id, clickHandler, omitLocations });
  }

  async clearVisualizations(prefix = undefined) {
    // Clears visualizations with the given prefix, leave empty for all
    if (prefix && prefix.prefix) prefix = prefix.prefix;
    for (const id of this.visualizations) {
      if (prefix !== undefined) {
        const targetPrefix = id.split('_')[0];
        if (targetPrefix === prefix) await this.removeVisualization({ id });
      } else {
        await this.removeVisualization({ id });
      }
    }
    return;
  }

  async changeMapStyle(evt) {

    if (!this.map.loaded()) {
      const promise = new Promise((resolve, reject) => {
        this._queueAction({
          method: 'changeMapStyle',
          resolve,
          reject
        }, evt);
      });
      return await promise;
    }

    const selectedLayer = evt.mapID;
    const currentStyle = this.map.getStyle();
    console.log("SELECTED:   ", selectedLayer);

    const { data: newStyle } = await axios.get(`https://api.mapbox.com/styles/v1/nearco/${selectedLayer}?access_token=${process.env.VUE_APP_MAP_ACCESS_TOKEN}`);
    newStyle.sources = Object.assign({}, currentStyle.sources, newStyle.sources); // Copy sources
    let labelIdx = newStyle.layers.findIndex(el => { return el.id === 'waterway-label' });
    if (labelIdx === -1) labelIdx = newStyle.layers.length;

    const appLayers = currentStyle.layers.filter(el => {
      return el.source &&
        el.source != 'mapbox://mapbox.satellite' &&
        el.source != 'mapbox' &&
        el.source != 'composite'
    });

    newStyle.layers = [
      ...newStyle.layers.slice(0, labelIdx),
      ...appLayers,
      ...newStyle.layers.slice(labelIdx, -1),
    ];
    this.map.setStyle(newStyle);

  }

}