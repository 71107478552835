<template>
  <div>
    <ReportOptions>
      <TimePeriod :id="'dwell-time-report-options'" @timePeriodSeconds="setTimePeriodSeconds" :presetSecondsBefore="
        $sessionStore.state.jobRequest.locationReportOptions.ogsSecondsBefore
      " :presetSecondsAfter="
  $sessionStore.state.jobRequest.locationReportOptions.ogsSecondsAfter
" />
    </ReportOptions>
    <ReportOptions>
      <ToggleOption v-if="false" label="Include Full JSON">
        <b-checkbox v-model="dwellTimeReportOptions.generateJsonFile" switch></b-checkbox>
      </ToggleOption>

      <div class="calculator-field mt-4">
        <p>Minimum Dwell Time</p>
        <SecondsCalculator @submitTotalSeconds="setSeconds" :buttonPressCheck="buttonPressCheck"
          :buttonInput="buttonInput" :role="'dwellMinTime'" id="input=dwellMinTime"
          :presetSeconds="dwellTimeReportOptions.dwellMinTime" />
        <p class="subtext">
          In seconds, the minimum amount of time a visitor must be seen in a
          location in order to be considered in the dwell time calculation for a
          location.
        </p>
      </div>

      <div class="calculator-field mt-4">
        <p>Visit Separation Time</p>
        <SecondsCalculator @submitTotalSeconds="setSeconds" :buttonPressCheck="buttonPressCheck"
          :buttonInput="buttonInput" :role="'dwellSepTime'" id="input=dwellSepTime"
          :presetSeconds="dwellTimeReportOptions.visitSeparationTime" />
        <p class="subtext">
          In seconds, the amount of time between visits to be considered
          separate visits. Default is one day.
        </p>
      </div>

      <div class="calculator-field mt-4">
        <p>Max Outer Dwell Time</p>
        <SecondsCalculator @submitTotalSeconds="setSeconds" :buttonPressCheck="buttonPressCheck"
          :buttonInput="buttonInput" :role="'dwellMaxTime'" id="input=dwellMaxTime"
          :presetSeconds="dwellTimeReportOptions.outerWindowMaxTime" />
        <p class="subtext">
          Maximum time in seconds before or after a visit to consider for the
          outer dwell estimation. Default is 30 min.
        </p>
      </div>

      <p class="mt-4">For this dataset, employees are defined as</p>
      <b-form inline>
        A visitor dwelling for longer than
        <b-form-input :state="isValidNumber(dwellTimeReportOptions.employeeMinDwellTime)"
          id="inline-form-input-employeeMinDwellTime" class="mb-2 mr-sm-2 mb-sm-0 ml-sm-2"
          v-model="dwellTimeReportOptions.employeeMinDwellTime" @input="setEmployeeDef()"></b-form-input>
        seconds or which is seen more than
        <b-form-input :state="isValidNumber(dwellTimeReportOptions.employeeMinDays)"
          id="inline-form-input-employeeMinDays" class="mb-2 mr-sm-2 mb-sm-0 ml-sm-2"
          v-model="dwellTimeReportOptions.employeeMinDays" @input="setEmployeeDef()"></b-form-input>
        days
      </b-form>

      <ToggleOption class="toggle" label="Include Visitor-Level Data">
        <b-checkbox @change="setDeviceDwell()" v-model="dwellTimeReportOptions.deviceLevelDwell" switch></b-checkbox>
      </ToggleOption>
    </ReportOptions>
  </div>
</template>
<script>
import ReportOptions from "../shared/ReportOptions.vue";
import ToggleOption from "../shared/ToggleOption.vue";
import TimePeriod from "../TimePeriod.vue";
import SecondsCalculator from "../SecondsCalculator.vue";

export default {
  components: { ReportOptions, ToggleOption, TimePeriod, SecondsCalculator },
  data() {
    return {
      dwellTimeReportOptions: {
        generateJsonFile: false,
        dwellMinTime:
          this.$sessionStore.state.jobRequest.dwellTimeReportOptions
            .dwellMinTime,
        visitSeparationTime:
          this.$sessionStore.state.jobRequest.dwellTimeReportOptions
            .visitSeparationTime,
        outerWindowMaxTime:
          this.$sessionStore.state.jobRequest.dwellTimeReportOptions
            .outerWindowMaxTime,
        employeeMinDwellTime:
          this.$sessionStore.state.jobRequest.dwellTimeReportOptions
            .employeeMinDwellTime,
        employeeMinDays:
          this.$sessionStore.state.jobRequest.dwellTimeReportOptions
            .employeeMinDays,
        deviceLevelDwell:
          this.$sessionStore.state.jobRequest.dwellTimeReportOptions
            .deviceLevelDwell,
      },
      buttonInput: 0,
      buttonPressCheck: 0,
    };
  },
  watch: {},
  methods: {
    setTimePeriodSeconds(timePeriod) {
      // sets both screen date times based on inputs from time period component
      this.screenMinDateTime = timePeriod.beforeSeconds;
      this.screenMaxDateTime = timePeriod.afterSeconds;
      let timeObj = {
        dwellSecondsBefore: this.screenMinDateTime,
        dwellSecondsAfter: this.screenMaxDateTime,
      };
      this.$sessionStore.commit("setDwellTime", timeObj);
    },
    setSeconds(totalSeconds, role) {
      // listens for submitTotalSeconds event and updates the before/after seconds value here
      if (!isNaN(parseInt(totalSeconds))) {
        switch (role) {
          case "before":
            this.beforeSeconds = parseInt(totalSeconds);
            break;
          case "after":
            this.afterSeconds = parseInt(totalSeconds);
            break;
          case "dwellMinTime":
            this.$sessionStore.commit(
              "setMinDwellTime",
              parseInt(totalSeconds)
            );
            break;
          case "dwellSepTime":
            this.$sessionStore.commit(
              "setDwellSepTime",
              parseInt(totalSeconds)
            );
            break;
          case "dwellMaxTime":
            this.$sessionStore.commit(
              "setMaxDwellTime",
              parseInt(totalSeconds)
            );
            break;
          default:
            console.log("No role");
        }
      } else {
        console.log("is NaN");
      }
    },
    buttonPress(seconds) {
      // updates seconds before and after with button press amount
      this.buttonPressCheck++; // increments to change buttonPressCheck prop in Seconds Calculator so that watcher on that component always fires with button press
      this.buttonInput = seconds;
    },
    setEmployeeDef() {
      const { employeeMinDays, employeeMinDwellTime } = this.dwellTimeReportOptions;
      if (!this.isValidNumber(employeeMinDays) || !this.isValidNumber(employeeMinDwellTime)) {
        this.$sessionStore.commit("deleteEmployeeDef")
        return;
      }
      let employObj = {
        employeeMinDays: parseInt(employeeMinDays),
        employeeMinDwellTime: parseInt(employeeMinDwellTime),
      };
      this.$sessionStore.commit("setEmployeeDef", employObj);

    },
    isValidNumber(num) {
      try {
        return parseInt(num) > 0;
      } catch {
        return false;
      }
    },
    setDeviceDwell() {
      this.$sessionStore.commit(
        "setDeviceDwell",
        this.dwellTimeReportOptions.deviceLevelDwell
      );
    },
  },
  mounted() {
    let timeObj = {
      dwellSecondsBefore:
        this.$sessionStore.state.jobRequest.locationReportOptions
          .ogsSecondsBefore,
      dwellSecondsAfter:
        this.$sessionStore.state.jobRequest.locationReportOptions
          .ogsSecondsAfter,
    };
    this.$sessionStore.commit("setDwellTime", timeObj);
  },
  computed: {
    seconds() {
      // links beforeSeconds and afterSeconds so that watcher can emit event if either value changes
      const { beforeSeconds, afterSeconds } = this;
      return {
        beforeSeconds,
        afterSeconds,
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  margin-top: 20px;
  width: 25%;
}
</style>

