<template>
  <OptionsPanel>
    <h2>Set Additional Parameters</h2>
    <template v-if="hasPermission('Unhashed Device Ids In CreateJobRequest', $persistingStore.state.user.permissions)">
      <RadioButtonList @change="updateDeviceIdFormat" :options="hashOptions" :default="hashOptions[0]"
        ref="deviceIdComponent" label="Device IDs"
        subtext="Dataset will include either hashed device IDs or unhashed device IDs. Please note that we can only provide hashed device IDs to partners.">
      </RadioButtonList>
    </template>
    <ToggleOption label="Include Column Headers">
      <b-checkbox v-model="includeHeaders" switch></b-checkbox>
    </ToggleOption>
    <ToggleOption label="Use Friendly Dates"
      subtext="Datasets include Unix Timestamp by default. Turning on Friendly Dates will include year, date, and time, in UTC or Local Timezones.">
      <b-checkbox v-model="useFriendlyDates" switch></b-checkbox>
      <template v-if="useFriendlyDates" v-slot:right-pane>
        <b-form-select v-model="selectedDateType" :options="friendlyDatesTypes"></b-form-select>
      </template>
    </ToggleOption>
    <ToggleOption
      v-if="hasPermission('Include Source Column in CreateJobRequest', $persistingStore.state.user.permissions)"
      label="Include 'Data Source' Column"
      subtext="This will include the data source for each row in the datasets that support this feature">
      <b-checkbox v-model="includeSourceColumn" switch></b-checkbox>
    </ToggleOption>
    <ToggleOption label="Split File"
      subtext="Large files can be difficult to open in Excel. Setting this to true will split files that are over 1,000,000 lines. This only applies to tsv/csv files.">
      <b-checkbox v-model="splitFile" switch></b-checkbox>
    </ToggleOption>
    <template v-if="isReportIdSelected(shapefileReports)">
      <ToggleOption label="Receive ESRI-compatible output"
        subtext="Turn on ESRI Output to receive an ESRI compatible file with the dataset output. This is available for a growing subset of datasets containing lat-long information.">
        <b-checkbox v-model="includeEsriShapefile" switch></b-checkbox>
      </ToggleOption>
    </template>
  </OptionsPanel>
</template>
<script>
import OptionsPanel from "./shared/OptionsPanel.vue";
import ToggleOption from "./shared/ToggleOption.vue";
import RadioButtonList from "./shared/RadioButtonList.vue";
import JobService from "@/services/dataExplorer/jobService.js";

export default {
  components: { OptionsPanel, ToggleOption, RadioButtonList },
  mixins: [JobService],
  data() {
    return {
      selectedDateType: 'Local',
      useFriendlyDates: true,
      deviceIdFormat: 'Hashed'
    };
  },
  mounted() {
    if (this.jobRequest.reportOutputOptions.unhashedDeviceIds !== undefined && this.hasPermission('Unhashed Device IDs in CreateJobRequest', this.$persistingStore.state.user.permissions)) {
      this.$refs.deviceIdComponent.setSelected(this.jobRequest.reportOutputOptions.unhashedDeviceIds ? 'Unhashed' : 'Hashed');
    }
    if (this.jobRequest.reportOutputOptions.friendlyDatesType !== undefined) {
      this.selectedDateType = this.friendlyDatesTypes.find(type => type.toLowerCase() === this.jobRequest.reportOutputOptions.friendlyDatesType.toLowerCase());
      this.useFriendlyDates = true;
    } else this.useFriendlyDates = false;
  },
  watch: {
    useFriendlyDates(value) {
      if (value) {
        this.$sessionStore.commit("setFriendlyDatesType", this.selectedDateType.toUpperCase());
      } else {
        this.$sessionStore.commit("setFriendlyDatesType", undefined);
      }
    },
    selectedDateType(value) {
      this.$sessionStore.commit("setFriendlyDatesType", value.toUpperCase());
    }
  },
  computed: {
    shapefileReports() {
      return [
        'PIN_REPORT',
        'PATHING_X_REPORT',
        'PATHING_X_CONTEXT_REPORT',
        'PATHING_X_CONTEXT_ONLY_REPORT',
        'PRE_POST_REPORT',
        'PRE_POST_SAMPLED_REPORT',
        'GENERATOR_REPORT',
        'CEL_CDL_DISTANCE_REPORT',
        'EXPANDED_CEL_CDL_REPORT',
        'OGS',
      ];
    },
    hashOptions() {
      return ['Hashed', 'Unhashed'];
    },
    friendlyDatesTypes() {
      return ['UTC', 'Local'];
    },
    includeSourceColumn: {
      get() {
        return this.jobRequest.includeSourceColumn;
      },
      set(value) {
        this.$sessionStore.commit("setIncludeSourceColumn", value);
      }
    },
    includeHeaders: {
      get() {
        return this.jobRequest.reportOutputOptions.includeHeaders;
      },
      set(value) {
        this.$sessionStore.commit("setIncludeHeaders", value);
      },
    },
    splitFile: {
      get() {
        return this.jobRequest.reportOutputOptions.splitFileLineCount !== undefined;
      },
      set(value) {
        this.$sessionStore.commit("setSplitFile", value);
      }
    },
    includeEsriShapefile: {
      get() {
        return this.jobRequest.reportOutputOptions.includeEsriShapefile !== undefined;
      },
      set(value) {
        this.$sessionStore.commit("setIncludeEsriShapefile", value);
      }
    }
  },
  methods: {
    updateDeviceIdFormat(val) {
      this.$sessionStore.commit('setDeviceIdFormat', val === 'Unhashed');
    }
  },
};
</script>

