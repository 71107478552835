import { bus } from '@/main';
import { syncState } from '@/utils/syncState.js';

export const SearchMixin = {
  computed: {
    ...syncState({
      suggestion: 'state.search.selectedSuggestion|setSelectedSuggestion',
      showNearbyPlaces: "state.search.showNearbyPlaces|setShowNearbyPlaces",
      needsUpdate: 'state.search.needsUpdate|setNeedsUpdate'
    }, { context: this })
  },
  mounted() {
    this.setQueryParams(this.query).then(() => {
      //console.log(this.$route);
      if (this.$route.query.return_results) {
        if (this.needsUpdate) this.emitSearch();
      } else {
        bus.$emit("focusSearchLocations", true); 
      }
    });
  },
  methods: {
    /**
     * Converts a suggestion to searchParams.
     * @param {*} suggestion 
     * @returns {Object} Returns the search params, which you can use with emit functions.
     */
    getParams(suggestion) {
      const searchParams = {};
      if (suggestion && suggestion.type === 'category' && 'value' in suggestion) {
        searchParams.filter = [];
        const { field, value } = suggestion;
        searchParams.filter.push({ field, value });
      }

      if (this.search.term !== '' && (!suggestion || suggestion.type !== 'category')) {
        searchParams.search_term = this.search.term;
      }
      if (this.search.geo.term !== '' && this.search.geo.term !== 'Map Area') {
        searchParams.geo_term = this.search.geo.term;
      } else if (suggestion && 'place_id' in suggestion) {
        searchParams.place_id = suggestion.place_id;
      }
      return searchParams;
    },
    /**
     * Enables the Nearby Places layer using the filter params sent in.
     * If you pass a geo_term in, it will be converted to place_id then coordinates via Google.
     * If you don't pass in any search params, they will be inherited from the route query params.
     * @param {*} searchParams 
     * @param {string} [searchParams.search_term] The brand/industry name filter.
     * @param {string} [searchParams.geo_term] The geography/address filter.
     * @param {string} [searchParams.place_id] The Google place Id.
     * @param {*} opts 
     * @returns {Promise<boolean>} Returns true for promise completion.
     * @throws
     */
    async emitSearch(searchParams, opts = {}) {
      //console.log('emitSearch');
      if (this.showNearbyPlaces) this.showNearbyPlaces = false;

      let { bbox } = opts;
      this.ui.activeElement = undefined;

      let params = searchParams;
      if (!params) params = { ...this.$route.query };
      
      if (!this.search.geo.term) {
        this.search.geo.term = "Map Area";
      }

      if (params.search_term === '') {
        delete params.search_term;
        if (this.search.geo.term == "Map Area") {
          this.search.geo.term = '';
        }
      }
      if (params.geo_term === '' || params.geo_term === 'Map Area') delete params.geo_term;
      if (params.geo_term !== undefined && !('place_id' in params)) {
        this.typeahead.googlePlaces = await this.getGooglePlaces(params.geo_term);
        const ggl = this.typeahead.googlePlaces[0];
        if (ggl) {
          delete params.geo_term;
          this.search.geo.term = ggl.description;
          params.place_id = ggl.place_id;
        }
      }
      
      let skipBBox = false;
      if ('place_id' in params) {

        // Move the map first
        const coords = await this.getCoordinates(params.place_id);
        if (!this.map) await this.getMapInstance();
        setTimeout(() => {
          this.map.flyTo({
            center: [coords.lng(), coords.lat()],
            speed: 4.8,
            zoom: 12
          });
        }, 100);
        delete params.place_id;
        skipBBox = true;
        this.map.once("flyend", () => {
          bus.$emit("searchItBBox", params);
        });

        if (
          !this.recentGeoSearches.includes(this.search.geo.term) &&
          this.search.geo.term !== "" &&
          this.search.geo.term !== 'Map Area'
        ) {
          this.recentGeoSearches = [this.search.geo.term].concat(
            this.recentGeoSearches
          );
        }

//        if (!this.liveSearch || bbox) {
//          // Replace max_bbox with the new viewport
//          params.max_bbox = await this.getBounds();
//        }

      }

      try {
        this.search.results = [];
        bus.$emit("clearMarkers");

//        if (!this.liveSearch || bbox) {
//          if (params.max_bbox === undefined) {
//            if (this.$route.query.max_bbox !== undefined) {
//              params.max_bbox = this.$route.query.max_bbox;
//            } else params.max_bbox = await this.getBounds();
//          }
//        }

        if (Object.keys(params).length > 0) params.return_results = true;

        this.setQueryParams(params).then(
          () => {
            this.showNearbyPlaces = true;
            this.search.submitted = true;
            this.ui.loading.search = true;
            this.ui.loading.autocomplete = false;
            if (!jQuery.isEmptyObject(params)) {
              if (!skipBBox) {
                params.expand = 1;
                bus.$emit("searchItBBox", params);
              }
              bus.$emit("focusSearchLocations", true);
            } else {
              this.search.submitted = false;
              this.ui.loading.search = false;
            }
            this.clearTypeahead();
          }
        );
      } catch (err) {
        throw err;
      }
      
      //this.emitSearchBbox(searchParams);

      return true;
    },
    /**
     * Uses emitSearch() so it's almost identical.
     * Will set the geo input field to Map Area, and search with a maximum bounding box.
     * @param {*} searchParams 
     * @param {string} [searchParams.search_term] The brand/industry name filter.
     * @param {string} [searchParams.geo_term] The geography/address filter.
     * @param {string} [searchParams.place_id] The Google place Id.
     * @returns {Promise<boolean>} Returns true for promise completion.
     * @throws
     */
    async emitSearchBbox(searchParams) {
      //console.log('emitSearchBbox');
      try {

        let params = searchParams;
        if (!params) params = {...this.$route.query};

       this.search.geo.term = "Map Area";
       //params.max_bbox = await this.getBounds();

       //return await this.emitSearch(params, {bbox: true});
       bus.$emit("searchItBBox", params);
     } catch (err) {
       console.log(err);
       throw err;
     }
    },
    /**
     * Searches based on a string and a type (category, geography).
     * @param {string} term The search term.
     * @param {string} type The search type, "category" or "geography".
     * @returns {Promise<boolean>} Returns true for promise completion.
     */
    async emitSearchRecentTerm(term, type) {
      const params = {};
      if (typeof term === 'string') {
        if (type === 'geography') {
          this.search.geo.term = term;
          params.geo_term = term;
          if (this.search.term !== '') params.search_term = this.search.term;
        } else if (type === 'category') {
          this.search.term = term;
          params.search_term = term;
          if (this.search.geo.term !== '' && this.search.geo.term !== 'Map Area') params.geo_term = this.search.geo.term;
        }
      }
      return this.emitSearch(params, { bbox: false });
    },
    /**
     * Searches based on an API suggestion.
     * @param {*} suggestion The API suggestion object.
     * @param {*} inputType The source input type, either 'category' or 'geography'.
     * @returns {Promise<boolean>} Returns true for promise completion.
     */
    async emitSearchSuggestion(suggestion, inputType = undefined) {
      //console.log('emitSearchSuggestion', suggestion, inputType);
      if (this.suggestion) this.suggestion = undefined;
      if (typeof suggestion === 'string') return this.emitSearchRecentTerm(suggestion, inputType);

      const params = this.getParams(suggestion);
      if (this.search.term === '' && suggestion.type !== 'category') params.moveOnly = true;

      const suggestionCopy = Object.assign({}, suggestion);
      suggestionCopy.recent = true;

      if (suggestion.type === 'geography' || "place_id" in suggestion) {
        //console.log(JSON.stringify(suggestion));
        this.search.geo.term = suggestion.value ?? suggestion.description;
        params.geo_term = this.search.geo.term;
        
        //console.log(this.search.geo.term);
        this.moveRequested = true;
        
        if (this.recentGeographies.find(el => el.value === suggestion.value) === undefined) {
          this.recentGeographies = [suggestionCopy, ...this.recentGeographies];
        }

        if (this.search.term !== '') params.search_term = this.search.term;

        return this.emitSearch(params, { bbox: false });
      } else if (suggestion.type === 'category') {
        // Brand or industry (category or subsector)
        this.search.term = suggestion.value;

        if (this.recentCategories.find(el => el.value === suggestion.value) === undefined) {
          this.recentCategories = [suggestionCopy, ...this.recentCategories];
        }

        if (this.search.geo.term !== '' && this.search.geo.term !== 'Map Area') params.geo_term = this.search.geo.term;

        return this.emitSearch(params, { bbox: false });
      }
    },
    /**
     * Asynchronously resets the nearby places layer.
     * @returns {Promise<boolean>} Returns true for promise completion.
     */
    resetSearchLayer() {
      console.log('resetSearchLayer');
//      this.showNearbyPlaces = false;
//      return new Promise((resolve) => {
//        setTimeout(() => {
//          this.showNearbyPlaces = true;
//          resolve(true);
//        }, 1);
//      });
    },
  }
};