<template>
  <div class="popularity-container">
    <InsightsContainer 
      title="Popularity"
      :icon="require('pinnacle-lib/assets/icon/map-info-large.svg')"
      :tooltip="tooltip"
      :tooltip2="tooltip2"
      insight="gt"
      :selection="gt"
      insightFamily="ci"
      :toggleSelectedLayer="toggleSelectedLayer" 
    >
      <div class="popularity-content">
        <div class="color-pill-container">
          <label>Key</label>
          <color-pill
            class="color-pill"
            name="color-pill"
            :colors="['#2C7BE5', '#FF1900']"
          ></color-pill>
          <div class="color-pill-labels">
            <label for="color-pill">Popularity Decline</label>
            <label for="color-pill">Popularity Growth</label>
          </div>
        </div>
        <div class="time-period">
          <label>Time Period</label>
          <select
          @change="updateSelectedLayer(gt)"
          name="month_options"
          v-model="gt.api_params.months"
          >
            <template v-for="option in month_options">
              <option :value="option.value" :key="option.value">
                {{ option.label }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </InsightsContainer>
  </div>
</template>

<script>
import InsightsContainer from "@/components/maps/InsightsContainer";
import ColorPill from "@/components/maps/ColorPill";

export default {
  components: {
    InsightsContainer,
    ColorPill
  },
  props: ['toggleSelectedLayer', 'updateSelectedLayer'],
  data() {
    return {
      tooltip: "Overall growth of device activity in each area during the chosen look-back period",
      tooltip2: "The popularity layer is only available in the U.S. Please pan to the U.S. to view the layer" 
    }
  },
  computed: {
    month_options() {
      return [ 
        { label: "6 months", value: "6" },
        { label: "12 months", value: "12" },
        { label: "18 months", value: "18" }
      ]
    },
    gt: {
      get() {
        return this.$sessionStore.state.studyMap.ci.gt;
      },
      set(value) {
        this.$sessionStore.commit('setCIGrowthTrends', value);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.popularity-container {

  .popularity-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center; 

    .color-pill-container {
      padding: 16px 24px 12px 24px;
      border-bottom: 1px solid $border-color;

      label {
        font-size: 14px;
        color: #858EA7;
      }
      .color-pill {
        width: 100%;
        height: 28px;
        border-radius: 4px;
      }
      .color-pill-labels {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
      }
    }

    .time-period {
      display: flex;
      flex-direction: column;
      padding: 16px 24px;

      label {
        font-size: 14px;
        color: #858EA7;
      }
      select {
        padding: 10px 16px;
        border: 1px solid #CFD3DC;
        border-radius: 4px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: url('pinnacle-lib/assets/icon/arrow-down-select.svg');
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) center;
        background-size: 10px;
      }
    }
  }
}
</style>