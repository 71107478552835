<template>
  <div>
    <div class="section">
      <CreateHeader title="Choose your Insights"
        :description="`Pinnacle Data insights draw from high-quality data and are developed by ${ Constants.company_name }'s team of data scientists. You may only request one dataset from each category except for the Visitor/Behavioral category.`" />
      
      <LargeJobWarning type="Datasets" @calculationDone="onCalculationDone" />
    </div>
    <div class="section" v-if="!calculatingJobSize">
      <SelectReportTypes />
    </div>
  </div>
</template>

<script>
import CreateHeader from './CreateHeader.vue';
import LargeJobWarning from '@/components/dataExplorer/shared/LargeJobWarning.vue';
import SelectReportTypes from '../../../dataExplorer/SelectReportTypes.vue';
import { ConstantsService } from "@/services/constants.js";
export default {
  mixins: [ConstantsService],
  components: { CreateHeader, LargeJobWarning, SelectReportTypes },
  data() {
    return {
      calculatingJobSize: false,
      longCalculation: false,
    }
  },
  methods: {
    onCalculationDone() {
      this.calculatingJobSize = false;
    }
  },
  async created() {
    this.calculatingJobSize = true;
    this.longCalculation = false;
    setTimeout(() => this.longCalculation = true, 1000);
    
  },
  computed: {
    canUseLargePolygons() {
      return this.$persistingStore.state.user.company.roles.find(
        (item) => item.name === "DataAPI Allow Large Polygons In CreateJob"
      ) || this.hasPermission("Super Admin", this.$persistingStore.state.user.permissions);
    },
  }
}
</script>

<style lang="scss" scoped>
.large-job-alert {
  margin-top:15px;
  background-color:#fff3cd;
  border-color: #fff3cd;
  p {
    margin-bottom:15px;
  }
  .large-job-example {
    font-size:14px;
  }
  .large-job-metrics {
    font-size:14px;
    p {
      margin-bottom:0;
    }
  }  
}

</style>