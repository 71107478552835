<template>
  <div>
    <div class="switchContainer">
      <div class="text-container">
        <p>Include Scatter Plot</p>
        <p class="subtext">
          Include a visualization that highlights what kind of social media
          behaviors are most prevalent in the market and what kind of social
          media behaviors are most unique to oberseved visitors.
        </p>
      </div>
      <b-checkbox
        value="true"
        v-model="scatterPlot"
        switch
        class="scatterToggle"
        @change="switchHandler()"
      ></b-checkbox>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      scatterPlot:
        this.$sessionStore.state.jobRequest.geoSocialAffinityReportOptions
          .includeScatterPlot,
    };
  },
  methods: {
    switchHandler() {
      this.$sessionStore.commit(
        "setGeoSocialAffinityReportOptions",
        this.scatterPlot
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.switchContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .text-container { width: 50%; }
}
.scatterToggle {
    margin-left: 2rem;
}
</style>