/**
 * An enumeration of environments.
 */
export const ENVIRONMENTS = {
  local: 0,
  test: 1,
  dev: 2,
  stage: 3,
  prod: 4
};

/**
 * Maps application name to origin.
 */
export const ORIGINS = {
  pinnacle: ['http://localhost:8080', 'https://pinnacle-test.near.com', 'https://pinnacle-dev.near.com', 'https://pinnacle-stage.near.com', 'https://pinnacle.near.com'],
  feedFactory: ['http://localhost:9090']
};

/**
 * Fetches the current environment enum based on the window origin.
 * @returns The environment index.
 */
export function getEnvironment() {
  if (window.location.origin.includes('localhost')) return ENVIRONMENTS.local;
  if (window.location.origin.includes('-test')) return ENVIRONMENTS.test;
  if (window.location.origin.includes('-dev')) return ENVIRONMENTS.dev;
  if (window.location.origin.includes('-stage')) return ENVIRONMENTS.stage;
  return ENVIRONMENTS.prod;
}

/**
 * Fetches the current environment key.
 * @returns The environment key.
 */
export function getEnvironmentKey() {
  const env = getEnvironment();
  return Object.keys(ENVIRONMENTS).find(key => ENVIRONMENTS[key] === env);
}

/**
 * Fetches the origin target location based on the target appName and the environment enum.
 * @param {string} appName The target appName, accepts either "pinnacle" or "feedFactory".
 * @param {number} environment The environment index (use the computed enums).
 * @returns {string} The window location origin string from the target application.
 * @throws {Error} Throws an error when an invalid appName parameter is passed.
 */
export function getOrigin(appName, environment) {
  if (!(appName in ORIGINS)) throw new Error('Invalid appName passed to getOrigin(appName, environment).');
  if (!(environment in ORIGINS[appName])) {
    return ORIGINS[appName][ORIGINS[appName].length - 1];
  }
  return ORIGINS[appName][environment];
}