

function hexToRGB(hex) {
  return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
              ,(m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16));
};

export default function colorExpression(colorRamp, mode="property") {
  switch (mode) {
    case "interpolate":
      return colorRamp.map((d, i) => {
        if (i === 0) {
          if (d.color.includes("#")) {
            const rgb = hexToRGB(d.color);
            return [d.value, `rgba(${rgb.join(", ")}, 0)`]
          }
        }
        return [d.value, d.color];
      });
    default:
      return colorRamp.map((d) => [d.value, d.color]);
  }
};