export const ciInitialState = {
  localDropdown: {
    pd: false,
    gt: false,
  },
  status: {
    pd: false,
    gt: false,
  },
  pd: {
    prefix: "pd",
    visualization: "PulseDaypart",
    api_params: {
      day_of_week: ",Mon,Tue,Wed,Thu,Fri,Sun,Sat",
      time_of_day:
        ",00:00,01:00,02:00,03:00,04:00,05:00,06:00,07:00,08:00,09:00,10:00,11:00,12:00,13:00,14:00,15:00,16:00,17:00,18:00,19:00,20:00,21:00,22:00,23:00",
      geography_level: "Census",
      quarter: "all",
    },
  },
  gt: {
    prefix: "gt",
    visualization: "GrowthTrends",
    api_params: {
      months: "6",
      geography_level: "Census",
    },
  },
};

export const piInitialState = {
  status: {
    wh: false,
    cl: true,
    routes: false,
  },
  wh: {
    prefix: "wh",
    visualization: "Hexagon",
    api_params: {
      visitor_info: "Home",
      geography_level: "Census",
      resolution: 8,
    },
  },
  cl: {
    prefix: "cl",
    visualization: "CompetitiveLandscape",
    api_params: {
      filter: [],
    },
  },
  routes: {
    prefix: "routes",
    visualization: "Routes",
    api_params: {
      
    }
  },
};

export const uiState = {
  loading: {
    wh: {},
    cl: {},
    routes: {},
    pd: {},
    gt: {},
  }
};

export const studyMap = {
  state: {
    ci: Object.assign({}, ciInitialState),
    pi: Object.assign({}, piInitialState),
    ui: Object.assign({}, uiState)
  },
  mutations: {
    setPI: (state, payload) => (state.pi = payload),
    setCI: (state, payload) => (state.ci = payload),
    setUI: (state, payload) => (state.ui = payload),
    setPIStatus(state, value) {
      state.pi.status = value;
    },
    setWHStatus(state, value) {
      state.pi.status.wh = value;
    },
    setCLStatus(state, value) {
      state.pi.status.cl = value;
    },
    setROUTESStatus(state, value) {
      state.pi.status.routes = value;
    },
    setPIWorkHome(state, value) {
      state.pi.wh = value;
    },
    setPICompetitiveLandscape(state, value) {
      state.pi.cl = value;
    },
    setCLFilters(state, value) {
      state.pi.cl.api_params.filter = value;
    },
    setPIRoutes(state, value) {
      state.pi.routes = value;
    },
    setCILocalDropdown(state, value) {
      state.ci.localDropdown = value;
    },
    setCIStatus(state, value) {
      state.ci.status = value;
    },
    setPDStatus(state, value) {
      state.ci.status.pd = value;
    },
    setGTStatus(state, value) {
      state.ci.status.gt = value;
    },
    setCIPulseDaypart(state, value) {
      state.ci.pd = value;
    },
    setPDQuarter(state, value) {
      state.ci.pd.api_params.quarter = value;
    },
    setTODDay(state, value) {
      state.ci.pd.api_params.day_of_week = value;
    },
    setTODTime(state, value) {
      state.ci.pd.api_params.time_of_day = value;
    },
    setCIGrowthTrends(state, value) {
      state.ci.gt = value;
    }
  },
};
