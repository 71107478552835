<template>
  <div class="datasets-table-container">
    <DataTable>
      <thead>
        <tr>
          <th id="job-id-header">Job ID</th>
          <th id="name-header">Name</th>
          <th id="status-header">Status</th>
          <th id="datasets-header">Datasets</th>
          <th id="request-date-header">Request Date</th>
          <th id="company-header" v-if="isSuperAdmin">Company</th>
          <th id="blank-header"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="job in jobs" :key="job.jobId" :class="{ 'highlight-item': submitted === job.jobId }">
          <td class="action-items-col">
            <span>{{ job.jobId }}</span>
          </td>
          <td class="name-col">
            <IconWrapper :src="require('pinnacle-lib/assets/icon/view_list.svg')"></IconWrapper>
            <p class="name">{{ job.reportName }}</p></td>
          <td class="status-badge-container">
            <StatusBadge :text="getFriendlyStatusName(job.jobStatus)" :variant="STATUS_VARIANT[job.jobStatus]">
            </StatusBadge>
          </td>
          <td>
            <a v-for="report in job.reportResults" :key="job.jobId + '_' + report.reportType" :href="report.reportUrl">
              {{ getFriendlyReportTypeById(report.reportType) }}
              <br />
            </a>
          </td>
          <td>{{ job.submittedDate }}</td>
          <td v-if="isSuperAdmin">{{ job.companyName }}</td>
          <td class="options-dropdown-col">
            <b-dropdown right class="options-dropdown" variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <img alt="datasets-options" :src="require('pinnacle-lib/assets/icon/more-vertical.svg')" />
              </template>
              <b-dropdown-item @click="cloneJob(job)">Clone</b-dropdown-item>
            </b-dropdown>
          </td>
        </tr>
      </tbody>
    </DataTable>
    <div v-if="loading" class="text-center w-100 my-2" style="color: #6e84a3">
      <WorldLoader></WorldLoader>
    </div>
    <div v-if="!loading" class="text-center w-100 my-2">
      <b-button v-if="hasMore" @click="loadMore()" class="m-md-3"
        style="background-color: #edf2f9; color: #6e84a3; border-width: 0px" size="md">
        Load More
      </b-button>
      <b-button v-if="!hasMore" :disabled="true" class="m-md-3"
        style="background-color: #edf2f9; color: #6e84a3; border-width: 0px" size="md">
        End of results
      </b-button>
    </div>
    <!-- Clone modal -->
    <LoadingModal :visible="clone !== undefined">
      <template #title>
        <h2>Cloning your Job: <span class="title">"{{ clone ? clone.reportName : 'Undefined' }}"</span></h2>
      </template>
    </LoadingModal>
    <b-modal id="large-job-submitted-modal" class="large-job-submitted-modal" size="md" centered hide-header scrollable :visible="large_job_submitted_modal">
      Your Dataset has been submitted.<br /> 
      You will be notified via email when this job has completed.
      <template #modal-footer>
        <div class="w-100 text-center">
          <b-button
            variant="primary"
            @click="closeLargeJobSubmittedModal()">
            OK
          </b-button>
        </div>
      </template>
    </b-modal>
</div>
</template>

<script>
import { DataTable, GetMixin, StatusBadge } from "pinnacle-lib";
import { Reports, p2pReports, CECDLocationReports } from "../../dataExplorer/reportTypes.js";
import { syncState } from "../../../utils/syncState";
import jobService from "../../../services/dataExplorer/jobService";
import LoadingModal from "./LoadingModal.vue";
import IconWrapper from "./create/IconWrapper.vue";
import WorldLoader from "./WorldLoader.vue";
import RouteGuard from "pinnacle-lib/mixins/RouteGuard";

export const DAPI_STATUS = {
  All: "",
  Completed: "COMPLETED",
  "In Progress": "IN_PROGRESS",
  "Processing": "PROCESSING_USER_FILE",
  Submitted: "SUBMITTED",
  Failed: "FAILED",
  Scheduled: "SCHEDULED",
};
const STATUS_VARIANT = {
  COMPLETED: "success",
  SUBMITTED: "pending",
  FAILED: "error",
  SCHEDULED: "disabled",
  IN_PROGRESS: "pending",
  PROCESSING_USER_FILE: "pending",
};
const PAGE_SIZE = 25;

export default {
  mixins: [GetMixin, jobService, RouteGuard],
  components: { DataTable, StatusBadge, LoadingModal, IconWrapper, WorldLoader },
  data() {
    return {
      jobs: [],
      filters: {
        status: {
          text: "Filter By Status",
          filter: "",
        },
        searchTerm: "",
      },
      debounce: {
        search: null,
      },
      clone: undefined,
      nextJobIdStart: null,
      loading: false,
      cloneLoading: false,
      loadJobController: new AbortController(),
      hasMore: false,
      pagination_links: [],
      DAPI_STATUS,
      PAGE_SIZE,
      STATUS_VARIANT,
      large_job_submitted_modal: false,
    };
  },
  filters: {
    reportToDataset(str) {
      return str.replace("REPORT", "DATASET");
    },
  },
  created() {
    this.loadJobs();
    console.log(this.$route.query['large-job-submitted']);
    if (this.$route.query['large-job-submitted']) {
      //this.$bvModal.show('large-job-submitted-modal');
      this.large_job_submitted_modal = true;
    }
  },
  watch: {
    searchTerm() {
      this.loadJobs();
    },
    status() {
      this.loadJobs();
    },
    shouldReload(reload) {
      if (reload) {
        this.loadJobs();
        this.shouldReload = false;
      }
    }
  },
  computed: {
    /**
     * The job ID of the recently submitted job (from query params).
     */
    submitted() {
      return this.$route.query.submitted;
    },
    /**
     * @return {boolean} Whether to include child companies or not.
     */
    includeChildCompanies() {
      const permissions = ["Super Admin", "All Job Access", "Child Job Access"];
      const hasPermission = this.hasPermissions(
        permissions,
        this.$persistingStore.state.user.permissions,
        false
      );
      return (
        hasPermission &&
        this.$persistingStore.state.user.company.parent === undefined
      );
    },
    ...syncState({
      searchTerm: 'state.datasetFilters.searchTerm',
      status: 'state.datasetFilters.status',
      shouldReload: "state.datasetFilters.reload|setDataFeedReload"
    }, { context: this, defaultStore: '$store' }),
    permissions() {
      return this.$persistingStore.state.user.permissions;
    },
    hasDatasetPermissions() {
      return this.hasPermission('Pinnacle Bulk Export Access', this.permissions, false);
    },
    isSuperAdmin() {
      return this.hasPermission('Super Admin', this.permissions);
    },
  },
  methods: {
    /**
     * Loads Data API jobs and updates the component state.
     */
    async loadJobs() {
      this.jobs = [];
      this.pagination_links = [];
      this.nextJobIdStart = null;
      this.loading = true;
      if (this.loadJobController !== undefined) {
        this.loadJobController.abort();
      }
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        let urlStr = `${process.env.VUE_APP_PINNACLE_API}/v2/data-api/stage/getJobStatusBatch?resultCount=${this.PAGE_SIZE}&includeChildCompanies=${this.includeChildCompanies}&searchTerm=${this.searchTerm}&searchStatus=${this.status.filter}`;
        
        this.loadJobController = new AbortController();
        let data = await this.getFromURL(urlStr, { signal: this.loadJobController.signal });

        this.jobs = data.jobStatuses;

        if (this.jobs.length < data.totalJobCount) {
          this.hasMore = true;
          const urlParams = new URLSearchParams(data.nextResults);
          this.nextJobIdStart = urlParams.get("jobIdStart");
        } else {
          this.hasMore = false;
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        if (this.loadJobController !== undefined && this.loadJobController.signal.aborted) {
          this.loading = true;
          return;
        }
        this.loading = false;
      } finally {
        this.loadJobController = undefined;
      }
    },
    /**
     * Loads the next page of data jobs.
     */
    async loadMore() {
      this.loading = true;
      if (this.loadJobController !== undefined) {
        this.loadJobController.abort();
      }
      try {
        let urlStr = `${process.env.VUE_APP_PINNACLE_API}/v2/data-api/stage/getJobStatusBatch?resultCount=${this.PAGE_SIZE}&includeChildCompanies=${this.includeChildCompanies}&searchTerm=${this.searchTerm}&searchStatus=${this.status.filter}&jobIdStart=${this.nextJobIdStart}`;
        
        this.loadJobController = new AbortController();
        let data = await this.getFromURL(urlStr, { signal: this.loadJobController.signal });
        this.jobs = this.jobs.concat(data.jobStatuses);

        if (this.jobs.length < data.totalJobCount) {
          this.hasMore = true;
          const urlParams = new URLSearchParams(data.nextResults);
          this.nextJobIdStart = urlParams.get("jobIdStart");
        } else {
          this.hasMore = false;
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        if (this.loadJobController !== undefined && this.loadJobController.signal.aborted) {
          this.loading = true;
          return;
        }
        this.loading = false;
      } finally {
        this.loadJobController = undefined;
      }
    },
    /**
     * Clones a job.
     * @param {*} job
     */
    async cloneJob(job) {
      this.clone = job;
      this.cloneLoading = true;
      
      const jobDetails = await this.loadJobDetails(job.jobId);
      if (jobDetails !== undefined) {

        this.$sessionStore.commit('recreate', jobDetails.createJobRequest);
        console.log(jobDetails.createJobRequest)
        this.$router.push({ name: "create-dataset-places", query: { clone: true } });
        return;

      }
      this.cloneLoading = false;
    },
    /**
     * Updates the local filter state and loads new jobs.
     * @param {*} text
     */
    statusFilterChanged(text) {
      this.filters.status.text = text;
      this.filters.status.filter = DAPI_STATUS[text];
      this.loadJobs();
    },
    /**
     * Returns the key of the job status (the friendly name).
     */
    getFriendlyStatusName(jobStatus) {
      // Get the key by value of DAPI_STATUS
      return Object.keys(DAPI_STATUS).filter(
        (status) => DAPI_STATUS[status] === jobStatus
      )[0];
    },
    /**
     * Returns the dataset friendly name by ID.
     * @param {*} datasetId
     */
    getFriendlyReportTypeById(datasetId) {
      const pathingReport = p2pReports.find((report) => report.id === 'PATHING_REPORT');
      const baseCELCDLReport = CECDLocationReports.find((report) => report.id === 'BASE_CEL_CDL_REPORT');
      if (datasetId in pathingReport.formats) {
        const format = pathingReport.formats[datasetId];
        return format.name;
      } else if (datasetId in baseCELCDLReport.formats) {
        const format = baseCELCDLReport.formats[datasetId];
        return format.name;
      } else {
        const dataset = Reports.find((report) => report.id === datasetId);
        if (dataset !== undefined) {
          return dataset.name;
        }
      }
      return datasetId;
    },
    /**
     * Removes the submitted field from the route query params.
     */
    clearJobResponseURLParams() {
      setTimeout(() => {
        const query = Object.assign({}, this.$route.query);
        delete query.submitted;
        this.$router.replace({ query });
      }, 100);
    },
    handleModalHide(evt) {
      evt.preventDefault();
    },
    closeLargeJobSubmittedModal(evt) {
      this.large_job_submitted_modal = false;
      this.$bvModal.hide('large-job-submitted-modal');
      setTimeout(() => {
        this.$router.replace({ query: {} });
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.name-col {
  display: flex;
  align-items: center;
}

.name {
  margin-left: 14px;
  margin-bottom: 0;
}
.options-dropdown-col {
  text-align: right;
}
</style>

<style>
#large-job-submitted-modal {
  .modal-body {
    padding-top:15px !important;
    padding-bottom:0 !important;
  }
}
</style>