<template>
  <ReportOptions>
    <ScatterPlot />
  </ReportOptions>
</template>
<script>

import ReportOptions from '../shared/ReportOptions.vue';
import ScatterPlot from '../ScatterPlot.vue';
export default {
  components: { ReportOptions, ScatterPlot },
  watch: {
}
}
</script>

<style lang="scss" scoped>

</style>