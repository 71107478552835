
import { getPermissions } from "../utils/permissionsUtil.js";
import { saveUser } from "../utils/user.js";
const axios = require('axios');

async function provisionUser(http) {
  const permissions = await getPermissions(http);
  if (permissions.success) {
    saveUser(permissions);
    return true;
  } else {
    localStorage.removeItem("accessToken");
    return "/login";
  }
}

export default {
  beforeMount() {
    const expired = JSON.parse(localStorage.getItem("_e"));
    const permissions = JSON.parse(localStorage.getItem("_p"));
    const company = JSON.parse(localStorage.getItem("_c"));
    const userData = JSON.parse(localStorage.getItem("_ud"));
    if (!userData) {
      localStorage.removeItem('accessToken');
      this.$router.push('/logout');
    }

    this.$persistingStore.commit("setExpired", expired);
    this.$persistingStore.commit("setPermissions", permissions);
    this.$persistingStore.commit("setCompany", company);
    this.$persistingStore.commit("setData", userData);

    const obj = JSON.parse(localStorage.getItem("ajs_user_traits"));
    if (obj !== null && "id" in obj) {
      this.$persistingStore.commit("setData", {company_id: obj.company_id, id: obj.id, email: obj.email});
    }

    if (this.$route.meta.routeGuard === undefined || this.$route.meta.routeGuard.length === 0) return;
    if (!this.hasPermissions(this.$route.meta.routeGuard, this.$persistingStore.state.user.permissions, false)) {
      this.$router.push({path: '/'});
    }
  },
  async beforeRouteEnter(_, __, next) {
    let lastUpdated = localStorage.getItem("_lu");
    if (lastUpdated === null) {
      localStorage.setItem("_lu", Math.round(+new Date().getTime() / 1000).toString());
      provisionUser(axios);
    }
    lastUpdated = Number.parseInt(lastUpdated);
    if (Math.round(+new Date().getTime() / 1000) - lastUpdated > 60) {
      provisionUser(axios);
      localStorage.setItem("_lu", Math.round(+new Date().getTime() / 1000).toString());
    }
    next();
  }
}