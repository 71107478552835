<template>
  <ReportOptions>
    <LocationAffinityOptions></LocationAffinityOptions>
  </ReportOptions>
</template>
<script>

import ReportOptions from '../shared/ReportOptions.vue';
import LocationAffinityOptions from '../LocationAffinityOptions.vue';

export default {
  components: { ReportOptions, LocationAffinityOptions },
  watch: {
}
}
</script>

<style lang="scss" scoped>

</style>