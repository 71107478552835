import moment from "moment";

/**
 * Calculates time labels in between a given range, interval, and input/output formats.
 * @param {*} start The start time.
 * @param {*} end The end time.
 * @param {*} interval The interval (time subtracted from 00:00:00).
 * @param {*} inputFormat The input format (default: HH:mm:ss)
 * @param {*} outputFormat The output format (default: HHa)
 * @returns {Array} Returns an array of time label strings.
 */
export default function getTimeLabels(start, end, interval, inputFormat="HH:mm:ss", outputFormat="ha") {
  const mInterval = moment(interval, inputFormat) - moment().startOf("day");
  const times = [];
  const startTime = moment(start, inputFormat);
  const endTime = moment(end, inputFormat);

  if (startTime >= endTime) {
    return [];
  }

  while (startTime < endTime + mInterval) {
    const format = startTime.format(outputFormat);
    times.push(format);
    startTime.add(mInterval, "ms");
  }
  return times;
}