<template>
  <div class="polygon-container">
    <h2>Polygons</h2>
    <div class="toolbar">
      <p>{{ numSelected }} polygon{{ numSelected === 1 ? '' : 's' }} selected</p>
      <div v-if="allowMultiple" :class="{ 'hide': !display }">
        <template v-if="!selectAll">
          <a class="select-a" @click="toggleSelectAll(true)">Select all {{ pagination.records }} polygons</a>
        </template>
        <template v-else>
          <a class="select-a" @click="toggleSelectAll(false)">Clear select all</a>
        </template>
      </div>
    </div>
    <div :class="{ 'hide': !display }">

      <b-table hover :items="items" :fields="columns">
        <template v-slot:cell(selected)="row">
          <b-checkbox :disabled="storedSelectedPolygons.length > 0 && !allowMultiple && !isChecked(row.item)"
            :checked="isChecked(row.item)" @change="togglePolygon($event, row.item)"></b-checkbox>
        </template>
        <template #head(selected)>
          <template v-if="selectAll">
            Exclude
          </template>
          <template v-else>
            <b-checkbox :checked="tableSelected" @change="togglePolygons"></b-checkbox>
          </template>
        </template>
      </b-table>
      <b-table v-if="storedSelectedPolygons.length > 0 && items.length < 1" hover :items="storedSelectedPolygons" :fields="columns">
        <template v-slot:cell(selected)="row">
          <b-checkbox :disabled="storedSelectedPolygons.length > 0 && !allowMultiple && !isChecked(row.item)"
            :checked="isChecked(row.item)" @change="togglePolygon($event, row.item)"></b-checkbox>
        </template>
        <template #head(selected)>
          <template v-if="selectAll">
            Exclude
          </template>
          <template v-else>
            <b-checkbox :checked="tableSelected" @change="togglePolygons"></b-checkbox>
          </template>
        </template>
      </b-table>
    </div>
    <div v-if="items.length > 0" class="pagination-container">
      <b-pagination class="pagination-el" @change="changePage" v-model="currentPage" :total-rows="pagination.records"
        :per-page="25"></b-pagination>
    </div>

  </div>
</template>
<script>

import { syncState } from '../../utils/syncState.js';
export default {
  props: ['polygons', 'pagination', 'display', 'allowMultiple'],
  data() {
    return {
      selectAll: false,
      currentPage: 1
    };
  },
  methods: {
    toggleSelectAll(val) {
      if (val) {
        this.selectAll = true;
        this.storedSelectedPolygons = [];
      } else {
        this.selectAll = false;
        this.storedExcludedPolygons = [];
      }
      this.$emit('updateStore', { selectAll: this.selectAll, selectedPolygons: this.storedSelectedPolygons, excludedPolygons: this.storedExcludedPolygons });
    },
    togglePolygon(evt, polygon) {
      if (this.selectAll) {
        if (evt) {
          this.storedExcludedPolygons = [...this.storedExcludedPolygons, polygon];
        }
        else this.storedExcludedPolygons = this.excludedPolygons.filter(cmp => cmp.id !== polygon.id);
      } else {
        if (evt) {
          this.storedSelectedPolygons = [...this.storedSelectedPolygons, polygon];
        }
        else {
          this.storedSelectedPolygons = this.storedSelectedPolygons.filter(cmp => cmp.id !== polygon.id);
        }

      }
      this.$emit('updateStore', { selectAll: this.selectAll, excludedPolygons: this.storedExcludedPolygons, selectedPolygons: this.storedSelectedPolygons });
    },
    togglePolygons(evt) {
      if (evt) {
        this.storedSelectedPolygons = this.storedSelectedPolygons.concat(this.polygons);
        this.storedSelectedPolygons = Array.from(new Set(this.storedSelectedPolygons));
      } else {
        this.storedSelectedPolygons = this.storedSelectedPolygons.filter((polygon) => {
          return !this.polygons.includes(polygon);
        });
      }
      this.$emit('updateStore', { selectAll: this.selectAll, selectedPolygons: this.storedSelectedPolygons, excludedPolygons: this.storedExcludedPolygons });
    },
    isChecked(polygon) {
      if (this.selectAll) {
        return this.storedExcludedPolygons.some(cmp => {
          return cmp.id === polygon.id;
        });
      }
      return this.storedSelectedPolygons.some(cmp => {
        return cmp.id === polygon.id;
      });
    },
    changePage(page) {
      this.$emit('changePage', page);
    },
    resetSelectAll() {
      this.storedExcludedPolygons = [];
      this.selectAll = false;
      this.currentPage = 1;
    },
    clearSelections() {
      this.storedSelectedPolygons = [];
      this.storedExcludedPolygons = [];
      this.selectAll = false;
      this.currentPage = 1;
    }
  },
  computed: {
    ...syncState({
      storedSelectedPolygons: 'state.jobRequest.selectedPolygons|setSelectedPolygons', // readonly
      storedExcludedPolygons: 'state.jobRequest.excludedPolygons|setExcludedPolygons',
      storedSelectAllParams: 'state.jobRequest.selectAllParams.setSelectAllParams'
    }, {context: this, defaultStore: '$sessionStore'}),
    tableSelected() {
      const selected = new Set(this.storedSelectedPolygons.map(polygon => polygon.id));
      return this.polygons.every(polygon => selected.has(polygon.id));
    },
    numSelected() {
      if (this.selectAll) {
        return this.pagination.records - this.storedExcludedPolygons.length;
      }
      return this.storedSelectedPolygons.length;
    },
    items() {
      return this.polygons.map(polygon => {
        return { ...polygon, categories: polygon.polygon_categories.map(cat => cat.name).join(', ') };
      });
    },
    tableSelectedItems() {
      return this.storedSelectedPolygons.map(polygon => {
        return {...polygon, categories: polygon.polygon_categories.map(cat => cat.name).join(', ')};
      });
    },
    columns() {
      return [
        { key: 'selected' },
        { key: 'name' },
        { key: 'address' },
        { key: 'id' },
        { key: 'categories' }
      ];
    }
  }
}
</script>
<style lang="scss" scoped>
.polygon-container {
  width: 100%;

  .toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    p {
      color: #4A577C;
    }

    .select-a {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .pagination-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.hide {
  display: none;
}
</style>