<template>
  <div class="icon-wrapper">
    <img :src="src" :alt="alt || ''" />
  </div>
</template>

<script>
  export default {
    props: ['src', 'alt']
  }
</script>

<style lang="scss" scoped>
.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 4px;
  background: #E9F2FC;
}
</style>