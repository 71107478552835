<template>
  <div class="customer-wrap">
    <h2>Customer Support</h2>

    <div class="hubspot"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "5155414",
          formId: "5f2b9c29-4e5a-49b8-95be-6e14d74f54e5",
          target: ".hubspot",
        });
      }
    });
  },
};
</script>


<style scoped lang='scss'>
.customer-wrap {
  padding: 2rem 3rem;
  padding-top: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 20%;

  h2 {
    font-family: "Cerebri Sans Bold";
    font-size: 28px;
    margin-bottom: 50px;
  }

  .hbspt-form {
    width: 100%;
    height: 90vh;
  }

  .hubspot {
    width: 50%;
  }
}
</style>