<template>
  <div class="routes-container">
    <InsightsContainer
      title="Routes"
      :icon="require('pinnacle-lib/assets/icon/map-routes-large.svg')"
      :tooltip="tooltip"
      insight="routes"
      :selection="routes"
      insightFamily="pi"
      :toggleSelectedLayer="toggleSelectedLayer"
    >
      <div class="routes-content">
        <label>Key</label>
        <color-pill-routes
          class="color-pill"
          name="color-pill"
          :colors="['#4cf1d3', '#4cf1d3', '#ffff00','#FFBF00', '#ff0000']"
        ></color-pill-routes>
        <div class="color-pill-labels">
          <label for="color-pill">Less</label>
          <label for="color-pill">More</label>
        </div>
      </div>
    </InsightsContainer>
  </div>
</template>

<script>
import InsightsContainer from "@/components/maps/InsightsContainer.vue";
import ColorPillRoutes from "@/components/maps/ColorPillRoutes";

export default {
  components: {
    InsightsContainer,
    ColorPillRoutes
  },
  props: ['toggleSelectedLayer'],
  data() {
    return {
      tooltip: 'The paths visitors take to and from a place'
    }
  },
  computed: {
    routes: {
      get() {
        return this.$sessionStore.state.studyMap.pi.routes;
      },
      set(value) {
        this.$sessionStore.commit('setPIRoutes', value);
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.routes-container {
  
  .routes-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 24px;

    .color-pill {
      width: 100%;
      height: 28px;
      border-radius: 4px;
    }
    .color-pill-labels {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;

      label {
        font-size: 14px;
        color: #858EA7;
      }
    }
  }
}

</style>