<template>
  <div class="divider"></div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.divider {
  height: 1px;
  width: 100%;
  background: $border-color;
}
</style>