<template>
    <span>
        <iframe title="" id="project-iframe" v-if="bool === true" style="height:100%;width:100%;border:0" :src="hash"></iframe>
        <div v-if="bool === false"> 
            <div class="denied">
                <h1>Access denied</h1>
                <p>This Project share url is not active.</P>
            </div>
        </div>
    </span>
</template>

<script>
window.cardSystemDownloadData = false;
window.cardSystemExportPDF = false;
window.cardSystemEmbed = false;

export default {
  name: "ShareLayout",
  data() {
    return {
        hash: '',
        bool: null,
        report: {},
        cards_base_url: "https://cards.ubermedia.com",
    }
  },
  methods: {
    async getLink(){
        try{
            const data = await await this.$http.get(`${process.env.VUE_APP_PINNACLE_API}/v2/get-share-project/${this.$route.params.hash}`);
            if(data.status === 200){
                this.report = data.data;
                this.hash = `${this.cards_base_url}/${this.report.project_hash}/`
                let urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has('c')) {
                    this.hash = this.hash + urlParams.get('c');
                }
                
                this.bool = true;
            }
        }
        catch(err){
            this.bool = false;
        }
    },
    onIframeMessage(event) {
        //console.log(event)
        // if (event.data.message == 'refresh_page') {
        //     location.reload();
        // return;
        // }
        if (event.data && event.data.message == "pdf_export") {
            this.openExportPDFModal(this.report);
        }
    },
   /**
     * Opens the export as PDF modal for a given report.
     * @param {*} report 
     */
     openExportPDFModal(report) {
      const clone = { id: report.id, name: report.name };
      this.$router.push({
        query: {
          exportPDF: btoa(JSON.stringify(clone))
        }
      });
    },
  },
  mounted() {
    window.cardSystemExportPDF = false;
    // let host = window.location.host;
    // if (host.indexOf("pinnacle.near.com") == -1) {
    //     this.cards_base_url = "https://cards.ubermedia.com"
    // }
    // if (host.indexOf("localhost") !== -1) {
    //     this.cards_base_url = "http://uber.ravefire.com:3000"
    // }
    this.getLink();
    window.addEventListener("message", this.onIframeMessage, false);
    setTimeout(() => {
        var head = document.head;
        var script = document.createElement("script");
        script.type = "text/javascript";
        let baseUrl = this.cards_base_url;
        script.src = baseUrl+"/js/CardRouting.js";
        head.appendChild(script);        
    }, 1000);
  }
};
</script>

<style>
    .denied{
        margin-left: 35%;
        margin-top: 5%;
    }
</style>