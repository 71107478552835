const Filters = [
  { id: 'sector-filter', param: 'polygon_category.id', name: 'Sector' },
  { id: 'category-filter', param: 'polygon_category.id', name: 'Subsector & Category' },
  { id: 'country-filter', param: 'country_criterion_id', name: 'Country', criterionKey: 'cy' },
  { id: 'state-filter', param: 'state_criterion_id', name: 'Province (State for US)', criterionKey: 'st' },
  { id: 'dma-filter', param: 'dma_criterion_id', name: 'Municipality (DMA for US)', criterionKey: 'dma' },
];


export default {
  created() {
    this.Filters = Filters;
  },
  methods: {
    setSearchParams(term) {
      const numFilters = this.Filters.length;
      if (!this._paramList) this._paramList = {};
      if (term !== "") {
        this._paramList[`ands[${numFilters}].ors[0].or`] = `name~=${term}`;
        this._paramList[`ands[${numFilters}].ors[1].or`] = `address~=${term}`;
        this._paramList[`ands[${numFilters}].ors[2].or`] = `id~=${term}`;
      }
      this._setDefaultParams();
    },
    clearSearchParams() {
      if (!this._paramList) return;
      const numFilters = this.Filters.length;
      delete this._paramList[`ands[${numFilters}].ors[0].or`];
      delete this._paramList[`ands[${numFilters}].ors[1].or`];
      delete this._paramList[`ands[${numFilters}].ors[2].or`];
    },
    clearAllParams() {
      this._paramList = {};
      this._selectedFilters = {};
    },
    _setDefaultParams() {
      if (!this._paramList) this._paramList = {};
      this._paramList[`ands[8].and`] = 'type.name!=basic';
      this._paramList[`ands[9].and`] = 'type.name!=Skipped';
      this._paramList['include_polygon_category_children'] = true;
      this._paramList['isInActive'] = true;
    },
    _setFilterParams() {
      if (!this._paramList) this._paramList = {};
      this.Filters.forEach((filter, idx) => {
        if (this._selectedFilters[filter.id] !== undefined && this._selectedFilters[filter.id].length)
          this._paramList[`ands[${idx}].and`] = `${filter.param}=${this._selectedFilters[filter.id].join('|')}`;
        else delete this._paramList[`ands[${idx}].and`];
      });
      this._setDefaultParams();
    },
    setOrderBy(field, asc) {
      if (field !== 'name' && field !== 'address') throw new Error('Invalid order by field');
      this._paramList['order_by[0]'] = `${field}:${asc ? 'asc' : 'desc'}`;
    },
    setFilter(filter, ids) {
      if (!this._selectedFilters) this._selectedFilters = {};
      this._selectedFilters[filter.id] = ids;
      this._setFilterParams();
    },
    hasUserParams() {
      return this._selectedFilters !== undefined && Object.keys(this._selectedFilters).length > 0 && (Object.values(this._selectedFilters).some(ids => ids.length > 0));
    },
    hasSearchParams() {
      if (!this._paramList)  return false;
      const numFilters = this.Filters.length;
      
      return `ands[${numFilters}].ors[0].or` in this._paramList;
    },
    async getFilteredPolygons({page, page_size, overrideParams}) {
      let params;
      if (overrideParams !== undefined) params = overrideParams;
      else {
        params = {...this._paramList};
        if (page) params.page = page;
        if (page_size) params.page_size = page_size;
      }
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const headers = this.getHeaders();
        const response = await this.$http({
          method: 'GET',
          params,
          ...headers,
          url: `${process.env.VUE_APP_PINNACLE_API}/v2/vista-polygons/polygons`
        });
        return response.data;
      } catch (axiosError) {
        throw axiosError;
      }
    },
    async searchCriteria(filter, query) {
      let path;
      const params = {};

      if (filter.param === 'polygon_category.id') {
        path = '/polygon-categories';
      } else {
        path = '/criteria';
        params['where[0]'] = `type.key=${filter.criterionKey}`;
      }
      if (query.name !== undefined) params[`where[${Object.keys(params).length}]`] = `name${query.operator !== undefined ? query.operator : '~='}${query.name}`;
      if (query.key !== undefined) params[`where[${Object.keys(params).length}]`] = `key${query.operator !== undefined ? query.operator : '='}${query.key}`;
      if (query.page_size) params.page_size = query.page_size;
      params[`where[${Object.keys(params).length}]`] = 'active=1';
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const headers = this.getHeaders();
        const response = await this.$http({
          method: 'GET',
          ...headers,
          params,
          url: `${process.env.VUE_APP_PINNACLE_API}/v2/vista-polygons${path}`
        });
        return response.data.records.slice();
      } catch (axiosError) {
        throw axiosError;
      }
    }
  }
}