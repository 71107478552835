<template>
  <div>
    <button 
      v-for="item in Object.keys(dayButtons)" 
      :key="item.label" 
      :class="dayButtons[item] ? 'dayBut selected' : 'dayBut unselected'" 
      @click="select(item); toggle()"
    >
      {{ item }}
    </button>
    <!-- <p>
      Shortcuts:
      <template v-for="option in day_of_week_options">
        <a
          @click="shortcut(option.label, option.values)"
          class="shortcut"
          :value="option.values"
          :key="option.label"
        >
          {{ option.label }}
        </a>
      </template>
    </p> -->
  </div>
</template>

<script>
import { bus } from "@/main";

const labelMapping = {
  'Mon': 'Mon',
  'Tue': 'Tue',
  'Wed': 'Wed',
  'Thu': 'Thu',
  'Fri': 'Fri',
  'Sat': 'Sat',
  'Sun': 'Sun'
};

export default {
  name: "tod-day-grid",
  props: ['checked', 'selection', 'toggleSelectedLayer'],
  data() {
    return {
      dayButtons: {
        Mon: true,
        Tue: true,
        Wed: true,
        Thu: true,
        Fri: true,
        Sat: true,
        Sun: true,
      },
    };
  },
  mounted() {
    bus.$off("dayButtonsOn");
    bus.$on("dayButtonsOn", () => {
      for (const day in this.dayButtons) {
        this.dayButtons[day] = true;
      }
      this.$sessionStore.commit("setTODDay", this.selected);
    });

    bus.$off("dayButtonsOff");
    bus.$on("dayButtonsOff", () => {
      for (const day in this.dayButtons) {
        this.dayButtons[day] = false;
      }
      this.$sessionStore.commit("setTODDay", this.selected);
    });
  },
  computed: {
    day_of_week_options() {
      return [
        {label: 'All', values: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']},
        {label: 'Weekday', values: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']},
        {label: 'Weekend', values: ['Sat', 'Sun']}
      ];
    },
    selected() {
      const selectedArr = Object.keys(this.dayButtons)
        .filter((key) => this.dayButtons[key])
        .map((key) => labelMapping[key]);
      return selectedArr.join(",");
    },
    daysOfWeekActive() {
      return this.$sessionStore.state.studyMap.ci.pd.api_params.day_of_week.length > 0;
    },
    status() {
      return this.$sessionStore.state.studyMap.ci.status.pd;
    }
  },
  methods: {
    toggle() {
      for (const button in this.dayButtons) {
        if (this.dayButtons[button]) {
          this.$emit("toggleOn");
        }
      }
    },
    select(item) {
      this.dayButtons[item] = !this.dayButtons[item];
      this.$emit("newSelection", this.selected);
    },
    // shortcut(label, values) {
    //   const shouldClear = values.every((val) => this.dayButtons[val]);
    //   if (shouldClear) {
    //     values.forEach((val) => (this.dayButtons[val] = false));
    //   } else {
    //     values.forEach((val) => (this.dayButtons[val] = true));
    //   }
    //   this.$emit("newSelection", this.selected);
    // },
  },
  watch: {
    daysOfWeekActive() {
      this.toggleSelectedLayer(this.status, this.selection);
    }
  }
}
</script>

<style lang="scss" scoped>

.dayBut {
  margin-bottom: 10px;
  text-align: center;
  line-height: 16px;
  margin-right: 2px;
  height: 32px;
  width: 48px;
  border: 1px solid #e3ebf6;
  outline: none;
}

.unselected {
  background-color: #ffffff;
  color: #6e84a3;
}

.selected {
  background-color: #2c7be5;
  color: #ffffff !important;
}

.shortcut {
  text-decoration: underline;
  margin-right: 2px;
  cursor: pointer;
  color: #6e84a3;
}
</style>