<template>
  <div class="toggle-option-container">
    <div class="toggle-option">
      <div class="text">
        <p>{{ label }}</p>
        <p class="subtext">{{ subtext }}</p>
      </div>
      <slot></slot>
    </div>
    <div class="right-pane">
      <slot name="right-pane"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ['label', 'subtext']
}
</script>
<style lang="scss" scoped>

.toggle-option-container:not(:last-child) > .toggle-option {

  border-bottom: 0.5px solid #e3ebf6;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.toggle-option-container {

  display: flex;
  width: 100%;

  .toggle-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;

    .text {
      p {
        margin: 0;
      }
    }
  }

  .right-pane {
    flex: 1;
    margin-left: 2rem;
  }

}
</style>