import { render, staticRenderFns } from "./LayerSidebar.vue?vue&type=template&id=38080d82&scoped=true&"
import script from "./LayerSidebar.vue?vue&type=script&lang=js&"
export * from "./LayerSidebar.vue?vue&type=script&lang=js&"
import style0 from "./LayerSidebar.vue?vue&type=style&index=0&id=38080d82&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38080d82",
  null
  
)

export default component.exports