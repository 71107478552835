import Defaults from "../../../services/dataExplorer/defaults";

let initialState = {
  uploadInput: {
    json: '',
    kml: '',
    file: '',
    esriField: '',
    selection: 'GEOJSON'
  },
  template: undefined,
  // Timeframe options
  startDateTime: "",
  endDateTime: "",
  minDevices: 38,
  // contextPoiGeojson: {},
  // dayParts: undefined,
  // jobRunStartDateTime: undefined,

  // Report output options
  reportOutputOptions: {
    includeHeaders: true,
    friendlyDatesType: "LOCAL",
    reportFilename: "",
    reportName: "",
    includeFullGeo: false,
    // unhashedDeviceIds: false, // Default for externals
    // preciseLatLon: false // Default for externals
  },

  // Screen Data options
  dataSourceGroup: "ALL_WITH_DELAYED",
  // screenOutliers: true,
  // maxScreenMph: 50,

  // Polygon input options
  polygonInputOptions: {
    polygonFormat: "GEOJSON",
    polygonNameAliasElement: undefined
  },

  // Location report options
  locationReportOptions: Defaults.locationReportOptions.defaults,
  dwellTimeReportOptions: Defaults.dwellTimeReportOptions.defaults,
  clusterReportOptions: Defaults.clusterReportOptions.defaults,
  locationAffinityReportOptions: Defaults.locationAffinityReportOptions.defaults,
  estimatedVisits2Options: Defaults.estimatedVisits2Options.defaults,
  // PIP options
  // pipHotspotScreening: {
  //   enableHotspotScreening: true
  // },

  generatorSecondsBefore: 604800,
  allowLargePolygons: false, // Default for externals
  // screenOutliers: true, // Default for externals
  geoSocialAffinityReportOptions: Defaults.geoSocialAffinityReportOptions.defaults,
  // Report type
  pipReportType: "",
  ftReportType: "",
  didReportTypes: [],
  p2pReportType: "",
  locationReportType: "",
  polygonString: '',
  attachedFile: undefined,
  selectedPolygons: [],
  excludedPolygons: [],
  selectAllParams: undefined,

  emailAddresses: [],

  //Selected Location method
  selectedLocationMethod: 'select',

  drawnPolygonGeoJSON: undefined,
  secondsBefore: undefined,
  secondsAfter: undefined,

  isLargeJob: false
};
initialState = Object.freeze(initialState);

function getDefaultState() {
  return Object.assign({}, initialState);
}

export default {
  state: getDefaultState(),
  mutations: {
    setUploadInput(state, value) {
      state.uploadInput = value;
    },
    setReportName(state, reportName) {
      const reportFilename = reportName.replace(/[^\w-]+/g, "_");
      state.reportOutputOptions.reportName = reportName;
      state.reportOutputOptions.reportFilename = reportFilename;
    },
    setTimeframe(state, { startDateTime, endDateTime }) {
      state.startDateTime = startDateTime;
      state.endDateTime = endDateTime;
    },
    setTimePeriod(state, { secondsBefore, secondsAfter }) {
      state.secondsBefore = secondsBefore;
      state.secondsAfter = secondsAfter;
    },
    setJobRunStartDateTime(state, jobRunStartDateTime) {
      state.jobRunStartDateTime = jobRunStartDateTime;
    },
    setDayParts(state, dayParts) {
      state.dayParts = dayParts;
    },
    setIncludeHeaders(state, includeHeaders) {
      if (!includeHeaders) delete state.reportOutputOptions.includeHeaders;
      state.reportOutputOptions.includeHeaders = includeHeaders;
    },
    setIncludeSourceColumn(state, includeSourceColumn) {
      if (!includeSourceColumn) delete state.includeSourceColumn;
      state.includeSourceColumn = includeSourceColumn;
    },
    setReportOutputOptions(state, options) {
      state.reportOutputOptions = options;
    },
    setDeviceIdFormat(state, unhashed) {
      state.reportOutputOptions.unhashedDeviceIds = unhashed;
    },
    setFriendlyDatesType(state, dateType) {
      state.reportOutputOptions.friendlyDatesType = dateType;
    },
    setSplitFile(state, value) {
      if (value) state.reportOutputOptions.splitFileLineCount = 1000000;
      else state.reportOutputOptions.splitFileLineCount = undefined;
    },
    setIncludeEsriShapefile(state, value) {
      if (value) state.reportOutputOptions.includeEsriShapefile = true;
      else state.reportOutputOptions.includeEsriShapefile = undefined;
    },
    // Screen data options
    setDataSourceGroup(state, dataSourceGroup) {
      state.dataSourceGroup = dataSourceGroup;
    },
    setScreenOutliers(state, screenOutliers) {
      if (!screenOutliers) state.screenOutliers = undefined;
      state.screenOutliers = screenOutliers;
    },
    setMaxScreenMPH(state, maxScreenMph) {
      state.maxScreenMph = maxScreenMph;
    },
    setPolygonInputOptions(state, value) {
      state.polygonInputOptions = value;
    },
    setEmails(state, emails) {
      state.emailAddresses = emails;
    },
    setPIPReportType(state, reportType) {
      state.pipReportType = reportType;
    },
    setFTReportType(state, reportType) {
      state.ftReportType = reportType;
    },
    setDIDReportTypes(state, reportTypes) {
      state.didReportTypes = reportTypes;
    },
    setP2PReportType(state, reportType) {
      state.p2pReportType = reportType;
    },
    setLocationReportType(state, reportType) {
      state.locationReportType = reportType;
    },
    setGeneratorSecondsBefore(state, generatorSecondsBefore) {
      state.generatorSecondsBefore = generatorSecondsBefore;
    },
    setIncludeSummary(state, includeSummary) {
      if (!state.celCdlDistanceReportOptions) state.celCdlDistanceReportOptions = {};
      state.celCdlDistanceReportOptions.includeSummary = includeSummary;
    },
    setClusterReportOptions(state, value) {
      state.clusterReportOptions = value;
    },
    setDeviceHistoryTimeframe(state, { screenMinDateTime, screenMaxDateTime }) {
      if (!state.pathToPurchaseReportOptions) state.pathToPurchaseReportOptions = {};
      state.pathToPurchaseReportOptions.screenMinDateTime = screenMinDateTime;
      state.pathToPurchaseReportOptions.screenMaxDateTime = screenMaxDateTime;
    },
    setLocationAffinityReportOptions(state, value) {
      state.locationAffinityReportOptions = value;
    },
    setLocationAffinityRegions(state, regions) {
      if (!state.locationAffinityReportOptions) state.locationAffinityReportOptions = {};
      if (!state.locationAffinityReportOptions.regions) state.locationAffinityReportOptions.regions = [];
      state.locationAffinityReportOptions.regions = regions;
    },
    setLocationAffinityRegionType(state, type) {
      if (!state.locationAffinityReportOptions) state.locationAffinityReportOptions = {};
      state.locationAffinityReportOptions.locationAffinityRegionType = type;
    },
    deleteLocationAffinityRegions(state) {
      if (state.locationAffinityReportOptions) state.locationAffinityReportOptions.regions = undefined;
    },
    setEstimatedVisitsDwellTime(state, dwellTime) {
      if (!state.estimatedVisits2Options) state.estimatedVisits2Options = {};
      state.estimatedVisits2Options.dwellTime = dwellTime;
    },
    setEstimatedVisitsCategory(state, category) {
      if (!state.estimatedVisits2Options) state.estimatedVisits2Options = {};
      state.estimatedVisits2Options.category = category;
    },
    setCELCDLReportType(state, type) {
      state.didReportTypes = state.didReportTypes.filter(id => !id.includes('CEL_CDL'));
      state.didReportTypes.push(type);
    },
    setGeoSocialAffinityReportOptions(state, includeScatterPlot) {
      if (!state.geoSocialAffinityReportOptions) state.geoSocialAffinityReportOptions = {};
      state.geoSocialAffinityReportOptions.includeScatterPlot = includeScatterPlot;
    },
    setPolygonString(state, string) {
      state.polygonString = string;
    },
    setAttachedFile(state, file) {
      state.attachedFile = file;
    },
    setOGSDensity(state, value) {
      state.locationReportOptions.densityMode = value.densityMode;
      state.locationReportOptions.densityMinDevicesMedium = value.densityMinDevicesMedium;
      state.locationReportOptions.densityMinDevicesHigh = value.densityMinDevicesHigh;
      state.locationReportOptions.densityMinSquareMilesMedium = value.densityMinSquareMilesMedium;
    },
    setOGSOptions(state, value) {
      state.locationReportOptions.ogsReportInputTypes = value;
    },
    setOGSTime(state, value) {
      state.locationReportOptions.ogsSecondsBefore = value.ogsSecondsBefore;
      state.locationReportOptions.ogsSecondsAfter = value.ogsSecondsAfter;
    },
    setDwellTime(state, value){
      state.dwellTimeReportOptions.dwellSecondsBefore = value.dwellSecondsBefore;
      state.dwellTimeReportOptions.dwellSecondsAfter = value.dwellSecondsAfter;
    },
    setOGSWeight(state, value) {
      state.locationReportOptions.p2pEqualWeight = value;
    },
    setOGSMaxRetainted(state, value) {
      state.locationReportOptions.maxRetained = value;
    },
    setOGSMinDevice(state, value) {
      state.minDevices = value;
    },
    setMinDwellTime(state, value) {
      state.dwellTimeReportOptions.dwellMinTime = value;
    },
    setMaxDwellTime(state, value) {
      state.dwellTimeReportOptions.outerWindowMaxTime = value;
    },
    setDwellSepTime(state, value) {
      state.dwellTimeReportOptions.visitSeparationTime = value;
    },
    setEmployeeDef(state, value) {
      state.dwellTimeReportOptions.employeeMinDwellTime = value.employeeMinDwellTime;
      state.dwellTimeReportOptions.employeeMinDays = value.employeeMinDays;
    },
    deleteEmployeeDef(state) {
      state.dwellTimeReportOptions.employeeMinDwellTime = undefined;
      state.dwellTimeReportOptions.employeeMinDays = undefined;
    },
    setDeviceDwell(state, value) {
      state.dwellTimeReportOptions.deviceLevelDwell = value;
    },
    setSelectedPolygons(state, polygons) {
      state.selectedPolygons = polygons;
    },
    setExcludedPolygons(state, polygons) {
      state.excludedPolygons = polygons;
    },
    setSelectAllParams(state, params) {
      state.selectAllParams = params;
    },
    setDrawnPolygonGeoJSON(state, value) {
      state.drawnPolygonGeoJSON = value;
    },
    setReportTemplate(state, template) {
      state.template = template;
    },
    setIsLargeJob(state, value) {
      state.isLargeJob = value;
    },
    recreateReport(state, value) {
      state.reportOutputOptions.reportName = value.name;
      state.template = value.template;
      state.startDateTime = value.startDateTime;
      state.endDateTime = value.endDateTime;
      state.polygonInputOptions = value.polygonInputOptions;

      // Add the geojson
      state.uploadInput.json = JSON.stringify(value.polygonInputOptions.geojson, null, '  ');
    },
    recreate(state, jobRequest) {
      const defaultState = getDefaultState();
      for (const key in state) {
        if (!(key in defaultState)) state[key] = undefined;
      }
      Object.assign(state, defaultState);
      Object.assign(state, jobRequest);
      if (state.didReportType !== undefined) {
        state.didReportTypes.push(jobRequest.didReportType);
        delete state.didReportType;
        if (state.didReportTypes.length > 0) {
          // Remove duplicates (IDK why some recreate requests have duplicate report IDs)
          state.didReportTypes = Array.from(new Set(state.didReportTypes));
        }
      }
      // Hardcoded to remove device history reports (for now)
      if (state.p2pReportType !== undefined && state.p2pReportType.includes('DEVICE_HISTORY')) {
        state.p2pReportType = '';
      }
      if (state.includeDataSources !== undefined) state.includeDataSources = undefined;

      // Add the geojson
      state.uploadInput.json = JSON.stringify(jobRequest.polygonInputOptions.geojson, null, '  ');

    },
    resetJobRequest(state) {
      const defaultState = getDefaultState();
      for (const key in state) {
        if (!(key in defaultState)) state[key] = undefined;
      }
      Object.assign(state, defaultState);
    },
    setLocationSelectMethod(state, method){
      state.selectedLocationMethod = method;
    },
    resetPolygonInput(state){
      state.polygonString = '';
      state.attachedFile = undefined;
      state.selectedPolygons = undefined;
      state.selectAllParams = undefined;
    },
    setLargePoly(state, value){
      state.allowLargePolygons = value;
    },
    setPOIJSON(state, value){
      state.contextPoiGeojson = value;
    },
    setDrawName(state,value){
      state.drawnPolygonGeoJSON.properties.name = value;
    }
  },
};
