export function validatePlaceSelection() {
  switch (this.selectedLocationMethod) {
    case 'select':
      return validatePolygonChooser.call(this);
    case 'geojson':
      return validateUpload.call(this);
    case 'draw':
      return validateDrawnPolygon.call(this);
    default:
      break;
  }
}

export function validatePolygonChooser() {
  const selectAll = this.selectAllParams !== undefined;
  if (selectAll) return this.excludedPolygons.length < this.selectAllParams.page_size;
  return this.selectedPolygons.length > 0;
}

export function validateUpload() {
  switch (this.uploadInput.selection) {
    case 'GEOJSON':
    case 'KML':
      return this.polygonString !== '';
    case 'ESRI_SHAPEFILE_ZIP':
      return this.attachedFile !== undefined;
    default:
      return false;
  }
}

export function validateDrawnPolygon() {
  return this.drawnPolygonGeoJSON !== undefined;
}