<template>
  <div class="header-container">
    <!-- <div class="text-container">
            Comparison Insights
        </div> -->
    <ComparisonInfo></ComparisonInfo>
    <div class="button-container">
      <button class="opened-button">Opened</button>
      <button @click="copyLink" class="copy-button">
        <img :src="require('pinnacle-lib/assets/icon/link-icon.svg')" />
        Copy Link
      </button>
    </div>
    <div class="selection">
      <p class="description">Contents</p>
      <!-- <p class="edit">Edit</p> -->
    </div>
  </div>
</template>
<script>
import { bus } from "@/main";
import ComparisonInfo from "@/components/partials/ComparisonInfo.vue";

export default {
  components: {
    ComparisonInfo,
  },
  data() {
    return {
      // number: 1
    };
  },
  methods: {
    copyLink() {
      const clipboardInput = document.createElement("input");
      const url = window.location.href;
      document.body.appendChild(clipboardInput);
      clipboardInput.value = url;
      clipboardInput.select();
      document.execCommand("copy");
      document.body.removeChild(clipboardInput);

      bus.$emit("copyLinkAlert");
    },
  },
};
</script>
<style lang="scss" scoped>
.text-container {
  height: 55px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #e3ebf6;
  background: #ffffff;
  color: #6e84a3;
  font-size: 14px;
  padding-top: 15px;
  padding-left: 24px;
  font-weight: bold;
  line-height: 24px;
}
.header-container {
  background: #ffffff;
  width: 100%;
}
.button-container {
  // margin: 16px 24px;
  padding: 0 1.5rem 0 1.5rem;
  display: flex;
  flex-direction: row;
  text-align: center;
}
.opened-button {
  width: 60%;
  height: 46px;
  border-radius: 4px;
  border-width: 0;
  margin-bottom: 16px;
  background: #6e84a3;
  color: #ffffff;
}
.copy-button {
  width: 40%;
  border-radius: 4px;
  border-width: 0;
  margin-bottom: 16px;
  margin-left: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #edf2f9;
  color: #6e84a3;
}
.selection {
  display: flex;
  background: #f9fafc;
  padding: 18px 24px;
  justify-content: space-between;
  flex-direction: row;
}
.description {
  margin-bottom: 0px;
  color: #6e84a3;
  font-weight: 700;
  font-size: 14px;
}
.edit {
  margin-bottom: 0px;
  font-size: 14px;
  color: #6e84a3;
  font-weight: normal;
}
.max {
  font-weight: normal;
}
</style>