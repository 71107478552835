<template>
  <div class="pinnacle-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PContainer'
}
</script>

<style lang="scss" scoped>
.pinnacle-container {
  margin: 2em;
  flex-direction: column;
}
</style>