<template>
  <div class="search-container">
    <template>
      <div v-show="isInitialState">
        <div class="initial-container">
          <div class="initial-header">
            <h1>Places</h1>
            <p>
              Instant place-based insights powered by {{ Constants.company_name }}'s Data Intelligence
              Platform.
            </p>
            <p>
              Learn more about our place library.
            </p>
          </div>
        </div>
      </div>
      <div v-show="!isInitialState">
        <SearchAreaButton
          :shouldRender="!liveSearch && renderSearchAreaButton"
          @searchArea="onSearchArea"
        ></SearchAreaButton>
        <PseudoBreadcrumbs
          @closeButton="resetToInitial()"
          staticTitle="Search"
        ></PseudoBreadcrumbs>
        <LiveSearchToggle :disabled="search.loading" :shouldRender="liveSearch || !renderSearchAreaButton"></LiveSearchToggle>
      </div>
      
      <SearchVisualizationControl :disabled="search.loading" :shouldRender="true"></SearchVisualizationControl>
      <div class="search-topbar">
        <div class="searchbar-container">

          <div class="searchbar-input-container">
            <b class="input-prefix">Find</b>
            <b-input
              @input="onSearchInput"
              @keyup.native.enter="onEnter('category')"
              @blur="search.submitted = true"
              @focus="clearTypeahead('near')"
              v-model="search.term"
              class="searchbar"
              :class="{
                'searchbar-with-typeahead': hasTypeahead('category'),
              }"
              type="text"
              placeholder="Places, Brands, Industries"
              ref="searchInput"
              id="searchInput"
              autocomplete="off"
              v-click-outside="focusChanged"
            >
            </b-input>
            <Typeahead
              @viewDetail="viewDetail"
              @searchSuggestion="emitSearchSuggestion"
              @searchRecentTerm="emitSearchRecentTerm($event, 'category')"
              :items="searchSuggestions"
              v-click-outside="clearTypeahead"
            ></Typeahead>
            <template v-if="search.term === ''">
              <img
                class="input-postfix"
                :src="require('pinnacle-lib/assets/icon/location-marker.svg')"
              />
            </template>
            <template v-else>
              <img
                @click="resetFindInput"
                class="input-postfix close-postfix"
                :src="require('pinnacle-lib/assets/icon/close-icon.svg')"
                id="near-close"
              />
            </template>
          </div>

          <div class="searchbar-input-container">
            <b class="input-prefix">Near</b>
            <b-input
              @input="onGeoInput"
              @keyup.native.enter="onEnter('geography')"
              @blur="search.submitted = true"
              @focus="clearTypeahead('find')"
              v-model="search.geo.term"
              class="searchbar"
              :class="{
                'searchbar-with-typeahead': hasTypeahead('geography'),
              }"
              type="text"
              placeholder="Address, City, Province, Postal Code..."
              ref="geoInput"
              id="geoInput"
              autocomplete="off"
              v-click-outside="focusChanged"
              role="combobox"
              :aria-expanded="geoSuggestions.length > 0"
              aria-autocomplete="list"
              aria-control="geoInput"
            ></b-input>
            <Typeahead
              @viewDetail="viewDetail"
              @searchSuggestion="emitSearchSuggestion"
              @searchRecentTerm="emitSearchRecentTerm($event, 'geography')"
              :logo="
                require('pinnacle-lib/assets/img/powered_by_google_on_white_hdpi.png')
              "
              :items="geoSuggestions"
              v-click-outside="clearTypeahead"
            ></Typeahead>
            <template v-if="search.geo.term === ''">
              <img
                id="map-icon"
                class="input-postfix"
                :src="require('pinnacle-lib/assets/icon/map-grey.svg')"
              />
            </template>
            <template v-else>
              <img
                @click="resetGeoInput"
                class="input-postfix close-postfix"
                :src="require('pinnacle-lib/assets/icon/close-icon.svg')"
                id="find-close"
              />
            </template>
          </div>

          
        </div>
      </div>
      <template v-if="!isInitialState">
        <div class="search-toolbar">
          <div class="toolbar-header">
            <span class="searchText ">Search Results</span>
          </div>
          <div class="toolbar-action">
            <!-- <span @click="clearSearch()">Clear Search</span> -->
          </div>
        </div>
        <div
          class="results-container"
          :class="{ 'flex-center': isInitialState }"
        >
          <template v-if="isInitialState">
            <div class="empty-search-msg">
              <img :src="require('pinnacle-lib/assets/icon/search-icon.svg')" />
              <h1>Search for a place</h1>
              <h2>or geographic area</h2>
            </div>
          </template>

          <template v-else>
            <div class="search-results" @scroll="checkScroll">
              <!-- MESSAGE WHEN NO SEARCH TERM AND NO RESULTS -->
              <div
                class="no-result-container"
                v-if="
                  search.term === '' &&
                  resultsEmpty &&
                  !ui.loading.autocomplete &&
                  !ui.loading.search &&
                  routeEmpty
                "
              >
                <div class="empty-search-msg">
                  <img :src="require('pinnacle-lib/assets/icon/search-icon.svg')" />
                  <h1>Search for a place</h1>
                  <h2>or geographic area</h2>
                </div>
              </div>

              <!-- MESSAGE WHEN THERE ARE NO SEARCH RESULTS --->
              <div
                class="no-result-container"
                v-if="
                  resultsEmpty &&
                  !ui.loading.autocomplete &&
                  !ui.loading.search &&
                  !routeEmpty
                "
              >
                <div class="empty-search-msg">
                  <img :src="require('pinnacle-lib/assets/icon/alert-circle-outlined.svg')" />
                  <h1>No results found</h1>
                  <div class="empty-search-info-msg">
                    <h2>Pinnacle does not have any instant insights for this place. You can: use Pinnacle Data for this site, run an insights report using Vista Insights and <a style="text-decoration: underline;" href="/customer-support">drop us a note</a> asking us to add this place to Pinnacle Places.</h2>
                  </div>
                </div>
              </div>

              <template v-if="!resultsEmpty || !autocompleteEmpty">
                <SearchResults
                  :results="search.results"
                  @viewDetail="viewDetail"
                  @mouseEnter="hoverSearchResult"
                  @mouseLeave="hoverSearchResult"
                  @loadMoreLocations="submitLoadMore"
                  :searchResultHighlighted="ui.searchResultHighlighted"
                ></SearchResults>
              </template>
            </div>
          </template>
        </div>
      </template>
      <!-- end if not isInitialState -->
    </template>
  </div>
</template>
<script>
import SearchResults from "@/components/search/SearchResults";
import SearchAreaButton from "@/components/search/SearchAreaButton";
import LiveSearchToggle from '@/components/search/LiveSearchToggle.vue';
import SearchVisualizationControl from '@/components/search/SearchVisualizationControl.vue';
// import StudyResultsBar from "@/components/partials/StudyResultsBar";
import { bus } from "@/main";
// import point from "turf-point";
// import distance from "@turf/distance";
import Typeahead from "@/components/search/Typeahead";
// import ComparisonBanner from "@/components/search/ComparisonBanner";
import { compareTwoStrings } from "string-similarity";
import PseudoBreadcrumbs from "@/components/partials/PseudoBreadcrumbs.vue";
import { CommonSearchMixin } from "@/services/search/common.js";
import { syncState } from '@/utils/syncState.js';
import { ConstantsService } from "@/services/constants.js";

const supportedCountries = ["USA", "Canada", "Australia", "New Zealand"];

export default {
  mixins: [CommonSearchMixin, ConstantsService],
  components: {
    SearchResults,
    SearchAreaButton,
    LiveSearchToggle,
    SearchVisualizationControl,
    Typeahead,
    PseudoBreadcrumbs,
  },
  data() {
    return {
      activeElement: undefined,
      typeahead: {
        categories: [],
        geographies: [],
        googlePlaces: [],
        results: [],
        termCategory: "",
        termGeo: "",
        debounce: null
      },
      selectedLocation: null,
      //mapMoved: false,
      fittingBounds: false,
      moveRequested: false,
      searchResultHighlighted: null,
      isDragging: false,
      loading: {
        search: false,
        autocomplete: false,
      },
      google: undefined,
      map: undefined,
      searchInputIds: ['location-marker', 'searchInput', 'find-close'],
      geoInputIds: ['map-icon', 'geoInput', 'near-close'],
      focusedElement: null,
      selectedTerm: null,
      selectedGeo: null,
      busEvents: [],
    };
  },
  initMap() {
    if (!this.google) {
      this.google = new google.maps.places.AutocompleteService();
    }
  },
  created() {
    document.addEventListener("focusin", this.focusChanged);
    bus.$off("getAutocomplete");
    bus.$on("getAutocomplete", (evt) => {
      if (!evt) return;
      if (this.search.submitted) return;

      // Do not show results if the search term has changed
      if (
        evt.type === "geography" &&
        this.search.geo.term !== this.typeahead.termGeo
      )
        return;
      if (
        evt.type === "category" &&
        this.search.term !== this.typeahead.termCategory
      )
        return;

      if (evt.type === "geography") {
        // Commented out for PIN-1062
        // if (this.typeahead.termGeo.includes(",")) {
        //   const city = this.typeahead.termGeo
        //     .split(",")[0]
        //     .trim()
        //     .toLowerCase();
        //   const province = this.typeahead.termGeo
        //     .split(",")[1]
        //     .trim()
        //     .toLowerCase();
        //   // TODO: Bandaid solution for PIN-1026
        //   this.typeahead.geographies = evt.geographies.sort((a, b) => {
        //     if (a.field === "city" && b.field === "city") {
        //       const aCityString = a.value.toLowerCase();
        //       const bCityString = b.value.toLowerCase();
        //       const aProvinceString = a.province.toLowerCase();
        //       const bProvinceString = b.province.toLowerCase();
        //       const aSimilarity =
        //         compareTwoStrings(aCityString, city) +
        //         compareTwoStrings(aProvinceString, province);
        //       const bSimilarity =
        //         compareTwoStrings(bCityString, city) +
        //         compareTwoStrings(bProvinceString, province);
        //       return aSimilarity > bSimilarity ? -1 : 1;
        //     }
        //     return 0;
        //   });
        // } else {
        //   this.typeahead.geographies = evt.geographies
        //     .concat(this.typeahead.geographies)
        //     .filter(
        //       (geo, idx, arr) =>
        //         arr.findIndex((geo2) => geo2.id === geo.id) === idx
        //     );
        // }
      } else {
        this.typeahead.categories = evt.categories;
        // .concat(this.typeahead.categories)
        // .filter(
        //   (cat, idx, arr) =>
        //     arr.findIndex((cat2) => cat2.id === cat.id) === idx
        // );
        this.typeahead.results = evt.records;
        // .concat(this.typeahead.results)
        // .filter(
        //   (result, idx, arr) =>
        //     arr.findIndex((result2) => result2.id === result.id) === idx
        // );
      }
      this.loading.autocomplete = false;
    });

//    bus.$off("getLocations");
//    bus.$on(
//      "getLocations",
//      (locations, hasMoreLocations, page, concat, moveOnly, foundLocation) => {
//        if (!concat) this.search.results = locations;
//        else {
//          const currentIds = this.search.results.map((result) => result.id);
//          this.search.results = [
//            ...this.search.results,
//            ...locations.filter((loc) => {
//              return !currentIds.includes(loc.id);
//            }),
//          ];
//        }
//
//        this.loading.search = false;
//        //this.mapMoved = false;
//        this.search.hasMoreLocations = hasMoreLocations;
//        this.search.currentPage = page;
//
//        if (locations.length > 0 && this.search.term !== '') {
//          // Save the search term
//          if (
//            !this.recentLocationSearches.includes(this.search.term) &&
//            this.search.term !== ""
//          ) {
//            this.recentLocationSearches = [this.search.term].concat(
//              this.recentLocationSearches
//            );
//          }
//        }
//
//        if (foundLocation) {
//          if (
//            !this.recentGeoSearches.includes(this.search.geo.term) &&
//            this.search.geo.term !== ""
//          ) {
//            this.recentGeoSearches = [this.search.geo.term].concat(
//              this.recentGeoSearches
//            );
//          }
//        }
//      }
//    );

//    this.busEvents.push("mapIconClicked");
//    bus.$on("mapIconClicked", (evt) => {
//      this.viewDetail(evt);
//    });

//    bus.$off("mapIconMouseenter");
//    bus.$on("mapIconMouseenter", (evt) => {
//      this.hoverSearchResult({ locationId: evt, state: true });
//    });

//    bus.$off("mapIconMouseleave");
//    bus.$on("mapIconMouseleave", (evt) => {
//      this.hoverSearchResult({ locationId: evt, state: false });
//    });

//    bus.$off("fitBounds");
//    bus.$on("fitBounds", () => {
//      this.fittingBounds = true;
//    });

//    bus.$off("searchMapLoaded");
//    bus.$on("searchMapLoaded", (map) => {
//      this.map = map;
//    });


  },
  mounted() {
    this.setQueryParams(this.query);

    //bus.$emit("focusSearchLocations");

    //auto-suggest

    bus.$on("focusedChanged", (focused) => {
      this.focusedElement = focused;
    })

    bus.$on("closeSuggestions", () => {
    this.focusChanged();
    })

    this.busEvents.push("mapMoved");
    bus.$on("mapMoved", () => {
      if (this.fittingBounds) this.fittingBounds = false;
      else if (this.moveRequested) this.moveRequested = false;
      else {
        //this.mapMoved = true;
        // Remove the geography fields from search
        // this.search.geo.term = "";
        if ("filter" in this.$route.query) {
          const params = Object.assign({}, this.$route.query);
          const filter = JSON.parse(params.filter).filter((obj) => {
            return obj.field === "category" || obj.field === "subsector";
          });
          params.filter = JSON.stringify(filter);
          delete params.type;
          this.setQueryParams(params);
        }
        

      }
    });
    
    this.busEvents.push("mapMovedByUser");
    bus.$on("mapMovedByUser", () => {
      // livesearch
      if (this.liveSearch) {
        this.onSearchArea(); 
      }
    });

  },
  beforeDestroy() {
    
    this.busEvents.forEach(event_name => bus.$off(event_name));
    
    document.removeEventListener("focusin", this.focusChanged);
  },
  watch: {
    $route(to, from) {
      this.$sessionStore.commit("setQueryParams", this.$route.query);
    },

   focusedElement(){ 
     if(this.searchSuggestions.length > 0 && this.focusedElement >= 0){
       if(typeof this.searchSuggestions[this.focusedElement] === "object"){
        let suggestionObj = this.searchSuggestions[this.focusedElement].value;
         this.selectedTerm = suggestionObj;       
       } else {
         this.selectedTerm = this.searchSuggestions[this.focusedElement];
       }
     } else if (this.geoSuggestions.length > 0 && this.focusedElement >= 0){
      if(typeof this.geoSuggestions[this.focusedElement] === "object"){
        let geoObj = this.geoSuggestions[this.focusedElement].description;
         this.selectedGeo = geoObj;       
       } else {
         this.selectedGeo = this.geoSuggestions[this.focusedElement];
       }
     };
     this.focusedElement = -1;
   },
    isInitialState(val) {
      if (val) {
        bus.$emit("focusSearchLocations", true);
      }
    }
  },
  computed: {
    studyLocations() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split("/")[1] === "preview"
        ? this.$sessionStore.state.study.previews
        : this.$sessionStore.state.study.locations;
    },
    ...syncState({
      search: 'state.search.searchState|setSearchState',
      query: 'state.search.query',
      liveSearch: "state.search.liveSearch",
    }, {context: this, defaultStore: '$sessionStore'}),
    ...syncState({
      recentGeographies: 'state.recentSearches.geographies|setRecentGeographies',
      recentResults: 'state.recentSearches.results|setRecentResults',
      recentCategories: 'state.recentSearches.categories|setRecentCategories',
      recentLocationSearches: 'state.recentSearches.terms.location|setRecentLocationSearches',
      recentGeoSearches: 'state.recentSearches.terms.geo|setRecentGeoSearches',
    }, {context: this, defaultStore: '$persistingStore'}),
    autocompleteEmpty() {
      return (
        this.typeahead.geographies.length === 0 &&
        this.typeahead.categories.length === 0
      );
    },
    resultsEmpty() {
      return this.search.results.length === 0;
    },
    routeEmpty() {
      return Object.keys(this.$route.query).length === 0;
    },
    renderSearchAreaButton() {
      return (
        ("filter" in this.$route.query || "search_term" in this.$route.query) &&
        this.mapMoved
      );
    },
    studyEmpty() {
      return this.studyLocations.length === 0;
    },
    isInitialState() {
      return (
        this.resultsEmpty &&
        this.autocompleteEmpty &&
        this.search.term === "" &&
        this.search.geo.term === "" &&
        this.studyEmpty
      );
    },
    geoSuggestions() {
      if (
        this.search.geo.term === "" &&
        this.activeElement !== undefined &&
        // this.geoInputIds.includes(this.activeElement.id)
        this.activeElement.id === "geoInput"
      ) {
        return this.recentGeoSearches;
      } else if (
        this.search.geo.term !== "" &&
        this.activeElement !== undefined &&
        // this.geoInputIds.includes(this.activeElement.id)
        this.activeElement.id === "geoInput"
      ) {
        // Filter and sort recent searches
        const recents = this.recentGeoSearches
          .filter((term) => {
            return term
              .toLowerCase()
              .includes(this.search.geo.term.toLowerCase());
          })
          .sort((a, b) => {
            // Sort based on no. of matching letters
            const aDiff = Math.abs(this.search.geo.term.length - a.length);
            const bDiff = Math.abs(this.search.geo.term.length - b.length);
            if (aDiff < bDiff) return -1;
            return 1;
          });

        // Remove conflicting google places
        const places = this.typeahead.googlePlaces.filter((place) => {
          return !recents.includes(place.description);
        });

        return recents.concat(places.concat(this.typeahead.geographies));
      }
      return [];
    },
    searchSuggestions() {
      if (
        this.search.term === "" &&
        this.activeElement !== undefined &&
        this.searchInputIds.includes(this.activeElement.id)
      ) {
        return this.recentLocationSearches;
      } else if (
        this.search.term !== "" &&
        this.activeElement !== undefined &&
        this.searchInputIds.includes(this.activeElement.id)
      ) {
        const recents = this.recentLocationSearches
          .filter((term) => {
            return term.toLowerCase().includes(this.search.term.toLowerCase());
          })
          .sort((a, b) => {
            const aDiff = Math.abs(this.search.term.length - a.length);
            const bDiff = Math.abs(this.search.term.length - b.length);
            if (aDiff < bDiff) return -1;
            return 1;
          });

        const categories = this.typeahead.categories.filter((cat) => {
          const name = cat.value ?? cat.shortenedName;
          return !recents.includes(name);
        });

        return recents
          .concat(categories)
          .concat(this.typeahead.results)
          .map((el) => {
            if (typeof el === 'string') return el;
            const val = el.value ?? el.shortenedName;
            el.score = compareTwoStrings(
              this.search.term.toLowerCase().trim(),
              val.toLowerCase().trim()
            );
            return el;
          })
          .filter((el) => {
            if (typeof el === 'string') return true;
            return el.score > 0;
          })
          .sort((a, b) => {
            if (typeof a === 'string' && typeof b !== 'string') return -1;
            if (typeof b === 'string' && typeof a !== 'string') return 1;
            if (typeof a ==='string' && typeof b === 'string') return 0;
            let aVal = a.value ?? a.shortenedName;
            let bVal = b.value ?? b.shortenedName;
            const aScore = compareTwoStrings(
              this.search.term.toLowerCase().trim(),
              aVal.toLowerCase().trim()
            );
            const bScore = compareTwoStrings(
              this.search.term.toLowerCase().trim(),
              bVal.toLowerCase().trim()
            );
            return bScore - aScore;
          });
      }
      return [];
    },
  },
  methods: {
    async getAutocomplete(input) {
      // return [];
      try {
        if (!this.google) {
          this.google = new google.maps.places.AutocompleteService();
        }
        let geoInfo = {};
        if (this.map !== undefined) {
          const coords = this.map.getCenter();
          const location = new google.maps.LatLng(coords);
          geoInfo = { location, radius: 50000 };
        }
        const res = await this.google.getPlacePredictions({
          input,
          ...geoInfo,
        });
        return res.predictions.filter((pred) => {
          return (
            pred.types.includes("geocode") &&
            supportedCountries.includes(pred.terms.slice(-1)[0].value)
          );
        });
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    hasTypeahead(type) {
      const check =
        type === "category" ? this.searchSuggestions : this.geoSuggestions;
      return check.length > 0;
    },
    clearTypeahead(type = undefined) {
      setTimeout(() => {
        if (type !== undefined) {
          if (type === "find") {
            this.typeahead.categories = [];
            this.typeahead.results = [];
          } else {
            this.typeahead.geographies = [];
            this.typeahead.googlePlaces = [];
          }
        } else {
          this.typeahead.categories = [];
          this.typeahead.geographies = [];
          this.typeahead.results = [];
          this.typeahead.googlePlaces = [];
        }
      }, 0);
      bus.$emit("clearFocused");
      // this.typeahead.addresses = [];
    },
    submitAutocomplete(term, type) {
      if (this.typeahead.debounce) {
        clearTimeout(this.typeahead.debounce);
      }

      if (type === "Geo") {
        this.getAutocomplete(term).then(
          (predictions) => (this.typeahead.googlePlaces = predictions)
        );
      }

      this.typeahead.debounce = setTimeout(() => {
        this.typeahead[`term${type}`] = term;
        this.loading.autocomplete = true;

        bus.$emit(`autocomplete${type}`, term);
      }, 75);
    },
    focusChanged(evt) {
      if (!evt) this.activeElement = undefined;
      else this.activeElement = evt.target;
    },
    async submitSearch(searchParams) {
      //autosuggest enter key logic 
      if(this.selectedTerm || this.selectedGeo) {
        if(this.selectedTerm) {
          searchParams.search_term = this.selectedTerm;
          this.search.term = this.selectedTerm;
        };
        if(this.selectedGeo) {
          searchParams.geo_term = this.selectedGeo;
          this.search.geo.term = this.selectedGeo;
        };
        this.selectedTerm = null;
        this.selectedGeo = null;
      }

      this.activeElement = undefined;
      let search_params = searchParams;
      if (!search_params) search_params = { ...this.$route.query };

      if (search_params.search_term === "") delete search_params.search_term;
      if (
        search_params.geo_term === "" ||
        search_params.geo_term === "Map Area"
      )
        delete search_params.geo_term;
      else if (
        this.typeahead.googlePlaces.length > 0 &&
        !("place_id" in search_params)
      ) {
        this.typeahead.googlePlaces = await this.getAutocomplete(search_params.geo_term);
        const ggl = this.typeahead.googlePlaces[0];
        delete search_params.geo_term;
        search_params.place_id = ggl.place_id;
      }

      if (Object.keys(search_params).length === 0) return;
      this.search.submitted = true;

      if (
        search_params.search_term &&
        !search_params.geo_term &&
        !search_params.place_id
      ) {
        // console.log("using search bbox");
        delete search_params.geo_term;
        this.submitSearchBbox(search_params);
        return;
      } else if (
        !search_params.search_term &&
        (search_params.geo_term || search_params.place_id)
      ) {
        search_params.moveOnly = true;
        this.moveRequested = true;
      }

      this.loading.search = true;
      this.loading.autocomplete = false;
      this.setQueryParams(search_params);
      bus.$emit("searchIt", search_params);
      this.clearTypeahead();



    },
    searchRecentTerm(term, type) {
      const searchParams = {};
      if (type === "geography") {
        this.search.geo.term = term;
        searchParams.geo_term = term;
        if (this.search.term !== "")
          searchParams.search_term = this.search.term;
        this.submitSearch(searchParams);
      } else {
        this.search.term = term;
        searchParams.search_term = term;
        if (this.search.geo.term !== "")
          searchParams.geo_term = this.search.geo.term;
        this.submitSearch(searchParams);
      }
    },
    submitSearchBbox(searchParams, searchThisArea = false) {
      this.activeElement = undefined;
      let search_params = searchParams;
      if (!search_params) search_params = { ...this.$route.query };
      delete search_params.geo_term;
      this.setQueryParams(search_params);
      this.clearTypeahead();
      this.loading.search = true;
      this.loading.autocomplete = false;
      search_params.expand = searchThisArea ? 0 : 5000;
      this.search.geo.term = "Map Area";
      bus.$emit("searchItBBox", search_params);
    },
    submitLoadMore() {
      if (this.search.hasMoreLocations) {
        const search = { ...this.$route.query };
        if (Object.keys(search).length > 0) {
          bus.$emit("loadMoreLocations", {
            search,
            page: this.search.currentPage + 1,
          });
        }
      }
    },
//    setQueryParams(searchParams) {
//      let needsUpdating = false;
//      if (
//        Object.keys(searchParams).length !==
//        Object.keys(this.$route.query).length
//      )
//        needsUpdating = true;
//      else {
//        for (const key in searchParams) {
//          if (!(key in this.$route.query)) {
//            needsUpdating = true;
//            break;
//          } else {
//            if (searchParams[key] !== this.$route.query[key]) {
//              needsUpdating = true;
//              break;
//            }
//          }
//        }
//      }
//      if (needsUpdating) {
//        this.$router.push({
//          path: "/explore/search",
//          query: { ...searchParams },
//        });
//      }
//    },
    searchSuggestion(suggestion) {
      const searchParams = this.getFilterParams(suggestion, suggestion.type === 'category' ? suggestion.value : this.search.term);
      if (this.search.term === "" && suggestion.type !== "category")
        searchParams.moveOnly = true;

      const suggestionCopy = Object.assign({}, suggestion);
      suggestionCopy.recent = true;
      if (suggestion.type === "geography" || "place_id" in suggestion) {
        this.search.geo.term = suggestion.value ?? suggestion.description;
        searchParams.geo_term = suggestion.value ?? suggestion.description;
        if ("place_id" in suggestion) searchParams.place_id = suggestion.place_id;
        this.submitSearch(searchParams);
        if (
          this.recentGeographies.find((el) => el.value === suggestion.value) ===
          undefined
        ) {
          this.recentGeographies = [suggestionCopy].concat(
            this.recentGeographies
          );
        }
        this.moveRequested = true;
      } else if (suggestion.type === "category") {
        // Category type
        this.search.term = suggestion.value;
        if (
          this.recentCategories.find((el) => el.value === suggestion.value) ===
          undefined
        ) {
          this.recentCategories = [suggestionCopy].concat(
            this.recentCategories
          );
        }
        if (searchParams.search_term) {
          this.submitSearch(searchParams);
        } else {
          this.submitSearchBbox(searchParams);
        }
      }
    },
    getFilterParams(suggestion) {
      let searchParams = {};
      if (suggestion.type === 'category' && 'value' in suggestion) {
        searchParams.search_term = suggestion.value;
      } else if (this.search.term !== '' && suggestion.type !== 'category') {
        // Provision the search term
        searchParams.search_term = this.search.term;
      }
      if (this.search.geo.term !== "") {
        searchParams.geo_term = this.search.geo.term;
      } else if ("place_id" in suggestion) {
        searchParams.place_id = suggestion.place_id;
      }
      searchParams.expand = 1;
      return searchParams;
    },
    resetToInitial() {
      const query = {};
      this.$router.push({ path: "/explore/search", query });
      this.search.term = "";
      this.search.geo.term = "";
      this.search.results = [];
      this.typeahead.categories = [];
      this.typeahead.geographies = [];
      this.typeahead.googlePlaces = [];
      bus.$emit("clearMarkers");
      //this.mapMoved = false;
      this.$sessionStore.commit("setLocations", []);
    },
    onSearchInput() {
      this.search.submitted = false;
      this.activeElement = document.getElementById('searchInput');
      if (this.search.term === "") return;
      this.submitAutocomplete(this.search.term, "Category");
    },
    onGeoInput() {
      this.search.submitted = false;
      this.activeElement = document.getElementById('geoInput');
      if (this.search.geo.term === "") return;
      this.submitAutocomplete(this.search.geo.term, "Geo");
    },
    viewDetail(result, single_marker) {
      if (typeof single_marker !== "undefined" && single_marker) {
        bus.$emit("repopulateMarkers", { results: [result] });
      }
      this.$sessionStore.commit("set1Preview", result);
      bus.$emit("fitMarker", result.centroid.coordinates);
      const encodedPlace = btoa(JSON.stringify(result));
      const query = { place: encodedPlace };
      if (this.recentResults.find((el) => el.id === result.id) === undefined) {
        const recentResult = Object.assign({}, result);
        recentResult.recent = true;
        this.recentResults = [recentResult].concat(this.recentResults);
      };
      this.$router.push({
        path: "/explore/details",
        query,
      });
    },
    returnToSearch() {
      this.selectedLocation = null;
      this.searchResultHighlighted = null;
      $(".location-pin").removeClass("result-highlight");
    },
    hoverSearchResult({ locationId, state }) {
      if (!$) return;
      $(".location-pin").removeClass("result-highlight");
      if (state) {
        this.searchResultHighlighted = locationId;

      // only adding result-highligt to locations that are not in the studyLocations 
      if (this.studyLocations.some((loc) => loc.id === locationId )){
      } else {   
        $(".location-pin-" + locationId).addClass("result-highlight");
      }

      } else {
        this.searchResultHighlighted = null;
      }
    },
    checkScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.submitLoadMore();
      }
    },
  },
};
</script>
<style lang="scss" scoped>

p {
  width: fit-content;
}
.no-result-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  border-right: 1px solid $border-color;
  background: white;

  .initial-container {
    background: white;
    //height: 100%;
    //width: 100%;

    .initial-header {
      margin: 32px 24px;
      h1 {
        color: rgba(74, 87, 124, 1);
      }

      p {
        color: rgba(133, 142, 167, 1);
      }
    }
  }

  .search-topbar {
    display: flex;
    width: 100%;
    background-color: white;

    .searchbar-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px;

      .searchbar-input-container {
        width: 100%;
        height: 100%;
        position: relative;

        .input-prefix {
          position: absolute;
          font-weight: 450;
          color: rgba(110, 132, 163, 1);
          margin-top: 9px;
          margin-left: 13px;
        }

        .input-postfix {
          position: absolute;
          top: 8px;
          right: 8px;
        }

        .close-postfix {
          top: 14px;
          right: 14px;
          cursor: pointer;
        }

        #map-icon {
          top: 12px;
          right: 9px;
        }

        .searchbar {
          width: 100%;
          padding: 1rem;
          background: rgba(249, 250, 252, 1);
          transition: background-color 0.75s ease-in-out;
          padding-left: 55px;
          padding-right: 36px;
          border: none;
        }

        .searchbar-with-typeahead {
          background-color: white;
          border: 1px solid #edf2f9;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        // .searchbar + .searchbar {
        //   margin-top: 1em;
        // }
      }

      .searchbar-input-container + .searchbar-input-container {
        margin-top: 16px;
      }
    }
  }

  .search-toolbar {
    background: rgba(249, 250, 252, 1);
    border-right: 1px solid $border-color;
    color: rgba(110, 132, 163, 1);
    // flex: 1;
    height: 56px;

    display: flex;
    .toolbar-header {
      width: 50%;
      font-weight: 450;
      padding-left: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .toolbar-action {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 16px;
      span {
        font-weight: 100;
        cursor: pointer;
      }
    }
  }

  .results-container {
    background: rgba(249, 250, 252, 1);
    display: flex;
    flex: 1;
    overflow-y: auto;
    width: 100%;

    .empty-search-msg {
      .empty-search-info-msg {
        margin: auto;
        width: 80%;
      }
      text-align: center;
      // color: #dae1ec;
      color: #c9d3e3;

      img {
        margin-bottom: 18px;
        height: 40px;
        width: 40px;
        opacity: 0.6;
      }
      h1,
      h2 {
        font-weight: normal;
      }
      h2 {
        margin-top: -10px;
        font-size: 18px;
      }
      h1 {
        font-size: 24px;
      }
    }

    ::-webkit-scrollbar {
      width: 0px;
    }

    .search-results {
      height: 100%;
      width: 100%;
      overflow-y: auto;
    }
  }
}

.flex-center {
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1700px) {
  .search-container .search-topbar .btn-container {
    width: 80px;
  }
}
</style>
