<template>

<div class="search-visualization-control-container">
  <button
    class="btn cluster-btn"
    v-if="shouldRender"
  >
    <b-form-group label="Search Visualization" v-slot="{ ariaDescribedby }">
      <b-form-radio v-model="searchVisualization" :aria-describedby="ariaDescribedby" name="searchVisualization-radios" value="PlacesCluster">Cluster</b-form-radio>
      <b-form-radio v-model="searchVisualization" :aria-describedby="ariaDescribedby" name="searchVisualization-radios" value="PlacesChoropleth">Geography</b-form-radio>
    </b-form-group>

  </button>
</div>

</template>

<script>
import { syncState } from '@/utils/syncState.js';


export default {

  props: ['shouldRender', 'disabled'],
//  data() {
//    return {
//      choroplethVisualization: false,
//      searchVisualization: 'PLACES_CLUSTER',
//    }
//  },
  computed: {
    ...syncState({ searchVisualization: 'state.search.searchVisualization|setSearchVisualization' }, {context: this})
  },
  methods: {
    searchArea() {
      this.$emit('searchArea');
    }
  }

}

</script>

<style lang="scss" scoped>

.search-visualization-control-container {
  position: fixed;
  top: 8.5vh;
  right: 2.5vh;
  z-index: 1;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%) !important;

  .checkbox {
    display: inline;
  }

  .cluster-btn {
    text-align:left;
    background-color: white;
    border-radius: 6px !important;
    border: 1px solid #e3ebf6 !important;
    .form-group {
      margin-bottom:0.25rem; 
    }
  }

}

</style>