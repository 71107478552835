<template>
   <div class='modalD'  >
   <h3 style='text-align: center;' > Download {{!$route.path.includes('map') ?  'Chart' : "Map"}} Insights </h3>
   <p class='greyText' v-if="!$route.path.includes('map')" >Download images of and/or the underlying data behind the charts in a ZIP file. You can select the downloads you want through the menu below.</p>
   <p class='greyText' v-else-if="isSafari" >Safari is not a supported browser for map export, please use Chrome or Firefox instead.</p>
   <p class='greyText' v-else >Download an image of the map in the PNG format.</p>     
   <div class='downloadText' v-if="!isSafari || !$route.path.includes('map')">  
      <span style='margin-left: 10px;' @click="log()">
        Download Format
      </span>
   </div>        
   <div class='selectRow' v-if="!isSafari || !$route.path.includes('map')">
      <span style='display: flex; flex-direction: row;'>
        <img :src="require('pinnacle-lib/assets/icon/smImg.svg')" alt='images' />
        <p class='selectName'> Images </p>
      </span>
      <b-form-checkbox size="lg" v-model="image" v-if="!$route.path.includes('map')" ></b-form-checkbox>
   </div> 
   <div class='selectRow' v-if="!isSafari || !$route.path.includes('map')">
      <span style='display: flex; flex-direction: row;'>  
        <img :src="require('pinnacle-lib/assets/icon/smFile.svg')" v-if="!$route.path.includes('map')"  alt='data'/>
        <p class='selectName' v-if="!$route.path.includes('map')" > Data </p>
      </span>
      <b-form-checkbox  v-if="!$route.path.includes('map')" size="lg" v-model="data" ></b-form-checkbox>
      <p class='greyText' v-if="$route.path.includes('map')">Please note that Map Insights image exports will display all active map layers in a single image.</p>
   </div>
   <div class='downloadText' v-if="!isSafari || !$route.path.includes('map')">
      <span style='margin-left: 10px;'>  
        Insight Contents
      </span>
   </div> 
   <div class='selectRow' v-if="!$route.path.includes('map')">
      <span style='display: flex; flex-direction: row;'>
        <img :src="require('pinnacle-lib/assets/icon/smEST.svg')" alt='Estimated Visits Over Time'/>
        <p class='selectName'> Estimated Visits Over Time </p>
      </span>
      <b-form-checkbox size="lg" v-model="selected.est" ></b-form-checkbox>
   </div> 
   <div class='selectRow' v-if="!$route.path.includes('map')">
      <span style='display: flex; flex-direction: row;'>  
        <img :src="require('pinnacle-lib/assets/icon/smTod.svg')" alt='Visits by Time of Day'/>
        <p class='selectName'> Visits by Time of Day </p>
      </span>
      <b-form-checkbox size="lg" v-model="selected.day" ></b-form-checkbox>
   </div>
   <div class='selectRow' v-if="!$route.path.includes('map')">
      <span style='display: flex; flex-direction: row;'>
        <img :src="require('pinnacle-lib/assets/icon/smDow.svg')" alt='Visits by Day of Week'/>
        <p class='selectName'> Visits by Day of Week </p>
      </span>
      <b-form-checkbox size="lg" v-model="selected.week" ></b-form-checkbox>
   </div> 
   <div class='selectRow' v-if="!$route.path.includes('map')">
      <span style='display: flex; flex-direction: row;'>  
        <img :src="require('pinnacle-lib/assets/icon/smDw.svg')" alt='Time Spent in Place' />
        <p class='selectName'> Time Spent in Place </p>
      </span>
      <b-form-checkbox size="lg" v-model="selected.dwell" ></b-form-checkbox>
   </div>
   <div class='selectRow' v-if="!$route.path.includes('map')">
      <span style='display: flex; flex-direction: row;'>
        <img :src="require('pinnacle-lib/assets/icon/smHm.svg')" alt='Visitor Home Locations'/>
        <p class='selectName'> Visitor Home Locations  </p>
      </span>
      <b-form-checkbox size="lg" v-model="selected.origin" ></b-form-checkbox>
   </div>    
   <div class='selectRow' v-if="$route.path.includes('map') && status.cl && !isSafari">
      <span style='display: flex; flex-direction: row;'>
        <vue-lottie-player
          autoplay
          loop
          mode="normal"
          :animationData="require('pinnacle-lib/assets/lottie/layer-loader.json')"
          width="30px"
          height="30px"
          name="layer-loader"
          v-if='Object.keys(loading.cl).length > 0'
        />
        <img  v-else :src="require('pinnacle-lib/assets/icon/smNP.svg')" alt='Nearby Places' />
        <p class='selectName'> Nearby Places </p>
      </span>

   </div> 
   <div class='selectRow' v-if="$route.path.includes('map') && status.routes && !isSafari">
      <span style='display: flex; flex-direction: row;'>  
        <vue-lottie-player
          autoplay
          loop
          mode="normal"
          :animationData="require('pinnacle-lib/assets/lottie/layer-loader.json')"
          width="30px"
          height="30px"
          name="layer-loader"
          v-if='Object.keys(loading.routes).length > 0'
        />
        <img  v-else :src="require('pinnacle-lib/assets/icon/smRoute.svg')" alt='Routes'/>
        <p class='selectName'> Routes </p>
      </span>

   </div>
   <div class='selectRow' v-if="$route.path.includes('map') && status.wh && !isSafari">
      <span style='display: flex; flex-direction: row;'>
        <vue-lottie-player
          autoplay
          loop
          mode="normal"
          :animationData="require('pinnacle-lib/assets/lottie/layer-loader.json')"
          width="30px"
          height="30px"
          name="layer-loader"
          v-if='Object.keys(loading.wh).length > 0'
        />
        <img v-else :src="require('pinnacle-lib/assets/icon/smWH.svg')" alt='Home and Work' />
        <p class='selectName'> Home and Work </p>
      </span>

   </div> 
   <div class='selectRow' v-if="$route.path.includes('map') && statusCi.pd && !isSafari">
      <span style='display: flex; flex-direction: row;'>  
        <vue-lottie-player
          autoplay
          loop
          mode="normal"
          :animationData="require('pinnacle-lib/assets/lottie/layer-loader.json')"
          width="30px"
          height="30px"
          name="layer-loader"
          v-if='Object.keys(loading.pd).length > 0'
        />
        <img v-else :src="require('pinnacle-lib/assets/icon/smAct.svg')" alt='Activity'/>
        <p class='selectName'> Activity </p>
      </span>

   </div>
   <div class='selectRow' v-if="$route.path.includes('map') && statusCi.gt && !isSafari">
      <span style='display: flex; flex-direction: row;'>
        <vue-lottie-player
          autoplay
          loop
          mode="normal"
          :animationData="require('pinnacle-lib/assets/lottie/layer-loader.json')"
          width="30px"
          height="30px"
          name="layer-loader"
          v-if='Object.keys(loading.gt).length > 0'
        />
        <img v-else :src="require('pinnacle-lib/assets/icon/smPop.svg')" alt='Popularity' />
        <p class='selectName'> Popularity  </p>
      </span>

   </div>    
   <div class="buttonCoral">
    <button @click='$bvModal.hide("my-modal")' class='greyB'>Cancel </button>
    <button :disabled='image || data ? selected.est || selected.day || selected.week || selected.dwell || selected.origin ? false : true : true' @click='saveChart()' class='blueB' v-if="!$route.path.includes('map')">Download </button>
    <button @click='saveMap()' class='blueB' v-if="$route.path.includes('map') && !isSafari">Download </button>
    <button @click='$bvModal.hide("my-modal")' class='blueB' v-if="$route.path.includes('map') && isSafari">OK </button>
   </div>
  </div> 
</template>
<script>
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import VueLottiePlayer from "vue-lottie-player";
  export default {
     components: {
       VueLottiePlayer,
     },
     data() {
       return{
         image: true,
         data: true,
         selected: {
          est: true,
          day: true,
          week: true,
          dwell: true,
          origin: true
        },
       }
     },
     methods: {
           log(){
            console.log(this.isSafari)
           },
           async saveMap(){
             this.$bvModal.hide("my-modal")
             this.$sessionStore.commit("setDownloading", true );
             this.$bvModal.show("downloading")
             const filter = (node)=>{
                const reviseClasses = ['keyHide'];
                const exclusionClasses = ['satelite-view', "mapboxgl-user-location-dot", 'mapboxgl-ctrl-bottom-right', 'insightsBtn', 'alert-container', "topbar-nav-container", "details-container", "container-fluid", "scroll", "absolute-container", "right-panel-datepicker", "closeInsightsBtn"]; 
                if(node.classList === undefined){
                  return node;
                }
                if(node.classList.contains('keyHide'))
                {
                  let temp = node;
                  temp.className = 'key';
                  return temp;
                }
                return !exclusionClasses.some(classname=>node.classList.contains(classname));
              }
             //gotta rescrope the store
             let that = this.$sessionStore;
             htmlToImage.toPng(document.querySelector(".content-container"), {filter: filter ,cacheBust: true})
              .then(function (blob) {
                let target = document.querySelector(".key");
                target.className = 'keyHide';
                that.commit("setDownloading", false );
                saveAs(blob, 'map.png');
             });
           },
           async saveChart(){
            this.$bvModal.hide("my-modal")
            this.$sessionStore.commit("setDownloading", true );
            this.$bvModal.show("downloading")
            //rescope format (needed because tobBlob() function cannot access vue object)
            let formatD = this.data ? 'csv' : ''
            let formatP = this.image ? 'png' : ''
            let zip = new JSZip();
            let canvas1 =  await html2canvas(document.querySelector("#est")).then(canvas => {
                  return canvas;
              });
            if(formatD === 'tsv' || formatD === 'csv'){
              let csv1 = this.$sessionStore.state.study.chart.est;
              let csv2 = this.$sessionStore.state.study.chart.day;
              let csv3 = this.$sessionStore.state.study.chart.week;
              let csv4 = this.$sessionStore.state.study.chart.dwell;
              let csv5 = this.$sessionStore.state.study.chart.origin;
              if(formatD === 'csv' ){
                csv1 = this.$sessionStore.state.study.chart.est.replace(/\t/gi,',');
                csv2 = this.$sessionStore.state.study.chart.day.replace(/\t/gi,',');
                csv3 = this.$sessionStore.state.study.chart.week.replace(/\t/gi,',');
                csv4 = this.$sessionStore.state.study.chart.dwell.replace(/\t/gi,',');
                csv5 = this.$sessionStore.state.study.chart.origin.replace(/\t/gi,',');
              }
              
             
              //convert canvas elements to blob and add them to the zip
              if(this.selected.est){
                  zip.file(`Estimated Visits Over Time.${formatD}`, csv1);        
              }
              if(this.selected.day){
                  zip.file(`Visits by Time of Day.${formatD}`, csv2);
              }
              if(this.selected.week){
                  zip.file(`Visits by Day of Week.${formatD}`, csv3);
              }
              if(this.selected.dwell){
                  zip.file(`Time Spent in Place.${formatD}`, csv4);
              }
              if(this.selected.origin){
                  zip.file(`Visitor Home Locations.${formatD}`, csv5);       
              }
              //Call the first canvas element again, because the zip function MUST occur within toBlob()
              // zip.generateAsync({type:"blob"}).then(function(content) {
              //     saveAs(content, "charts.zip");
              // });  
            }
            if(formatP === 'png' || formatP === 'jpeg'){
              //Target the dom to create what we will use as the canvas
              let canvas1 =  await html2canvas(document.querySelector("#est")).then(canvas => {
                  return canvas;
              });
              let canvas2 =  await html2canvas(document.querySelector("#day")).then(canvas => {
                  return canvas;
              });
              let canvas3 =  await html2canvas(document.querySelector("#week")).then(canvas => {
                  return canvas;
              });
              let canvas4 =  await html2canvas(document.querySelector("#dwell")).then(canvas => {
                  return canvas;
              });
              let canvas5 =  await html2canvas(document.querySelector("#org")).then(canvas => {
                  return canvas;
              });
              
              
              //convert canvas elements to blob and add them to the zip
              if(this.selected.est){
                await canvas1.toBlob(function (blob) {
                  zip.file(`Estimated Visits Over Time.${formatP}`, blob);
                },`image/${formatP}`);
              }
              if(this.selected.day){
                await canvas2.toBlob(function (blob) {
                  zip.file(`Visits by Time of Day.${formatP}`, blob);
                },`image/${formatP}`);
              }
              if(this.selected.week){
                await canvas3.toBlob(function (blob) {
                  zip.file(`Visits by Day of Week.${formatP}`, blob);
                },`image/${formatP}`);
              }
              if(this.selected.dwell){
                await canvas4.toBlob(function (blob) {
                  zip.file(`Time Spent in Place.${formatP}`, blob);
                },`image/${formatP}`);
              }
              if(this.selected.origin){
                let tempAdd =  this.$route.path.split('/')[1] === 'preview' ? this.$sessionStore.state.study.previews : this.$sessionStore.state.study.locations;
                if(document.querySelector("#button0")){
                  let base0 = document.querySelector("#button0");
                  await base0.click();
                  let org0 =  await html2canvas(document.querySelector("#org")).then(canvas => {
                    return canvas;
                  });
                  await org0.toBlob(function (blob) {
                    zip.file(`Visitor Home Locations-${tempAdd[0].name}-${tempAdd[0].address}.${formatP}`, blob);
                  },`image/${formatP}`);
                }
                if(document.querySelector("#button1")){
                  let base1 = document.querySelector("#button1");
                  await base1.click();
                  console.log('clicked')
                  let org1 =  await html2canvas(document.querySelector("#org")).then(canvas => {
                    return canvas;
                  });
                  await org1.toBlob(function (blob) {
                    zip.file(`Visitor Home Locations-${tempAdd[1].name}-${tempAdd[1].address}.${formatP}`, blob);
                  },`image/${formatP}`);
                }
                if(document.querySelector("#button2")){
                  let base2 = document.querySelector("#button2");
                  await base2.click();
                  let org2 =  await html2canvas(document.querySelector("#org")).then(canvas => {
                    return canvas;
                  });
                  await org2.toBlob(function (blob) {
                    zip.file(`Visitor Home Locations-${tempAdd[2].name}-${tempAdd[2].address}.${formatP}`, blob);
                  },`image/${formatP}`);
                }
                if(document.querySelector("#button3")){
                  let base3 = document.querySelector("#button3");
                  await base3.click();
                  let org3 =  await html2canvas(document.querySelector("#org")).then(canvas => {
                    return canvas;
                  });
                  await org3.toBlob(function (blob) {
                    zip.file(`Visitor Home Locations-${tempAdd[3].name}-${tempAdd[3].address}.${formatP}`, blob);
                  },`image/${formatP}`);
                }
                if(document.querySelector("#button4")){
                  let base4 = document.querySelector("#button4");
                  await base4.click();
                  let org4 =  await html2canvas(document.querySelector("#org")).then(canvas => {
                    return canvas;
                  });
                  await org4.toBlob(function (blob) {
                    zip.file(`Visitor Home Locations-${tempAdd[4].name}-${tempAdd[4].address}.${formatP}`, blob);
                  },`image/${formatP}`);
                }
                
              }
              //Call the first canvas element again, because the zip function MUST occur within toBlob()
            }
            let that = this.$sessionStore;
            await canvas1.toBlob(function (blob) {
                zip.generateAsync({type:"blob"}).then(function(content) {
                  that.commit("setDownloading", false );
                  saveAs(content, "charts.zip");                 
                });  
              },'image/jpeg');
          }
     },
     computed: {
       status() {
        return this.$sessionStore.state.studyMap.pi.status;
        },
       statusCi() {
        return this.$sessionStore.state.studyMap.ci.status;
        },
       loading: {
          get() {
            return this.$sessionStore.state.studyMap.ui.loading;
          },
          set(val) {
            this.$sessionStore.state.studyMap.ui.loading = val;
          }
        },
        isSafari() {
          return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') == -1 &&
               navigator.userAgent.indexOf('FxiOS') == -1;
        }
     }
  }
</script>
<style lang="scss" scoped>
  .modalD{
    
  }
  .downloadText{
    background: #F9FAFC;
    padding: 24px 16px 24px;
    margin-right: -24px;
    margin-left: -24px;
    color: #6E84A3;
    font-weight: 700;
    font-size: 14px;  
  }
  .selectRow{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .selectName{
    color: #12263F;
    font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
    margin-left: 24px;
  }
  .buttonCoral{
    margin-top: 26px;
  }
  .greyB{
    width: 48%;
    border-radius: 4px;
    border-width: 0;
    margin-bottom: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #EDF2F9;
    color: #6E84A3;
  }
  .blueB{
    width: 48%;
    border-radius: 4px;
    border-width: 0;
    margin-bottom: 16px;
    margin-left: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #2C7BE5;
    color:  #FFFFFF;
  }
  .greyText{
    font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.03em;

    color: #6E84A3;
  }
  
</style>