import { syncState } from '../../utils/syncState.js';
import { validatePlaceSelection } from './individual/places.js';
import { validateTimeframe } from './individual/timeframe.js';
import { validateReportDetails } from './individual/reportDetails.js';
import CardUtil from '../cards/cardUtil.js';

const reportValidator = {
  validators: [{
    validate: () => (true)
  },
  {
    validate: function() {
      return validatePlaceSelection.call(this);
    }
  },
  {
    validate: function() {
      return validateTimeframe.call(this);
    }
  },
  {
    validate: function() {
      return validateReportDetails.call(this);
    }
  }],
  submitter: async function() {
    this.isSubmitting = true;
    try {
      const data = await this.requestReport();
      if (data.succeeded) {
        return data;
      }
      if ('error' in data) return {succeeded: false, error: data.error};
      return {succeeded: false, error: data};
    } catch (raw) {
      if ('error' in raw) return {succeeded: false, error: raw.error};
      return {succeeded: false, error: raw};
    } finally {
      this.isSubmitting = false;
    }
  },
  postSubmitter: function(response) {
    if (response.succeeded) {
      this.hasError = false;
      analytics.track('Run Pinnacle Report');

      this.$router.push({name: 'home-reports', query: {submitted: response.id}});
      this.$sessionStore.commit('resetJobRequest');
      console.log(response);
      return;
    }
    this.hasError = true;
    if (response && response.error && response.error.response && response.error.response.data && response.error.response.data.message !== undefined) {
      this.errorMsg = response.error.response.data.message;
    }
    if (response && response.error && response.error.friendlyMessage !== undefined) {
      this.errorMsg = response.error.friendlyMessage;
    }
  }
};

export default {
  mixins: [CardUtil],
  data() {
    return {
      reportValidator
    };
  },
  watch: {
    isSubmitting() {
      if (this.isSubmitting) this.hasError = false;
    }
  },
  computed: {
    ...syncState({
      selectedLocationMethod: 'state.jobRequest.selectedLocationMethod',
      selectedPolygons: 'state.jobRequest.selectedPolygons',
      excludedPolygons: 'state.jobRequest.excludedPolygons',
      selectAllParams: 'state.jobRequest.selectAllParams',
      polygonInputOptions: 'state.jobRequest.polygonInputOptions',
      uploadInput: 'state.jobRequest.uploadInput',
      polygonString: 'state.jobRequest.polygonString',
      attachedFile: 'state.jobRequest.attachedFile',
      drawnPolygonGeoJSON: 'state.jobRequest.drawnPolygonGeoJSON',
      startDateTime: 'state.jobRequest.startDateTime',
      endDateTime: 'state.jobRequest.endDateTime',
      jobName: 'state.jobRequest.reportOutputOptions.reportName',
      template: 'state.jobRequest.template',
      isLargeJob: "state.jobRequest.isLargeJob|setIsLargeJob"
    }, { context: this, defaultStore: '$sessionStore' })
  }
}