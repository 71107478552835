import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import auth from "./modules/default/auth";
import loader from "./modules/default/loader";
import map from "./modules/session/map";
import alert from "./modules/default/alert"
import dataAgreement from "./modules/session/dataAgreement"
import study from "./modules/session/study";
import search from "./modules/session/search";
import jobRequest from "./modules/session/jobRequest";
import user from "./modules/local/user";
import crumbs from "./modules/session/crumbs";
import recentSearches from "./modules/local/recentSearches";
import {studyMap} from "./modules/session/studyMap";
import datasetFilters from "./modules/default/datasetFilters";
import reportFilters from "./modules/default/reportFilters";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage
});

const store = new Vuex.Store({
  modules: {
    auth,
    loader,
    alert,
    datasetFilters,
    reportFilters
  },
});

export const persistingStore = new Vuex.Store({
  modules: {
    user,
    recentSearches
  },
  plugins: [vuexLocal.plugin],
});

export const sessionStore = new Vuex.Store({
  modules: {
    study,
    search,
    jobRequest,
    studyMap,
    crumbs,
    map,
    dataAgreement,    
  },
  plugins: [vuexSession.plugin]
});

export default store;
