<template>
  <OptionsPanel>
    <h2>Screen Data</h2>
    <RadioButtonList ref="dataSourceComponent" label="Data Source Group" @change="updateDataSourceGroup"
      :options="dataSourceOptions" :default="dataSourceOptions[0]"></RadioButtonList>

    <ToggleOption v-if="dataSourceGroup !== 'Bid Stream'" label="Include Delayed Data">
      <b-checkbox v-model="includeDelayedData" @change="updateDataSourceGroup(dataSourceGroup)" switch></b-checkbox>
    </ToggleOption>
    <ToggleOption label="Screen for Outliers">
      <b-checkbox v-model="screenOutliers" @change="toggleScreenForOutliers" switch></b-checkbox>
    </ToggleOption>
    <ToggleOption label="Set Max Screen MPH" subtext="Must be greater than 0">
      <b-checkbox v-model="useMaxScreenMPH" switch></b-checkbox>
      <template v-slot:right-pane v-if="useMaxScreenMPH">
        <div class="max-screen-container">
          <b-input class="max-screen-input" type="number" v-model="maxScreenMPH"
            :state="isMaxScreenMPHValid(maxScreenMPH)"></b-input>
          <label class="subtext">MPH</label>
        </div>
      </template>
    </ToggleOption>
    <label class="subtext">
      "Screen for Outliers" and "Set Max Screen MPH" should be used for all Path-To-Purchase Datasets.
    </label>
  </OptionsPanel>
</template>
<script>
import OptionsPanel from "./shared/OptionsPanel.vue";
import ToggleOption from "./shared/ToggleOption.vue";
import RadioButtonList from "./shared/RadioButtonList.vue";
import JobService from "@/services/dataExplorer/jobService.js";

const dataSourceMap = {
  'All': 'ALL',
  'Bid Stream': 'RTB',
  'SDK only': 'SDK'
};

export default {
  components: { OptionsPanel, ToggleOption, RadioButtonList },
  mixins: [JobService],
  data() {
    return {
      useMaxScreenMPH: false,
      maxScreenMPH: 50,
      includeDelayedData: true,
      dataSourceGroup: 'All',
      screenOutliers: false
    };
  },
  mounted() {
    // Recreate mode
    if (this.jobRequest.maxScreenMph !== undefined) {
      this.useMaxScreenMPH = true;
      this.maxScreenMPH = this.jobRequest.maxScreenMph;
    }
    if (this.jobRequest.screenOutliers !== undefined) {
      this.screenOutliers = this.jobRequest.screenOutliers;
      this.toggleScreenForOutliers(this.jobRequest.screenOutliers);
    }
    if (this.jobRequest.dataSourceGroup !== undefined) {
      this.includeDelayedData = this.jobRequest.dataSourceGroup.includes('_WITH_DELAYED');
      const group = this.getDataSourceGroup(this.jobRequest.dataSourceGroup);
      this.$refs.dataSourceComponent.setSelected(group);
    }
  },
  watch: {
    maxScreenMPH(val) {
      this.$sessionStore.commit('setMaxScreenMPH', val);
    },
    useMaxScreenMPH(val) {
      if (!val) this.$sessionStore.commit('setMaxScreenMPH', undefined);
      else this.$sessionStore.commit('setMaxScreenMPH', this.maxScreenMPH);
    }
  },
  computed: {
    dataSourceOptions() {
      return ['All', 'Bid Stream', 'SDK only'];
    }
  },
  methods: {
    getDataSourceGroup(value) {
      const split = value.split('_');
      switch (split[0]) {
        case 'ALL': return 'All';
        case 'RTB': return 'Bid Stream';
        case 'SDK': return 'SDK only'
      }
    },
    getDelayedPostfix(dataSourceGroup, includeDelayed) {
      if (dataSourceGroup === 'Bid Stream') return '';
      return includeDelayed ? '_WITH_DELAYED' : '_WITHOUT_DELAYED';
    },
    toggleScreenForOutliers(val) {
      if (val && !this.useMaxScreenMPH) this.useMaxScreenMPH = true;
      else if (!val && this.useMaxScreenMPH) this.useMaxScreenMPH = false;
      this.$sessionStore.commit('setScreenOutliers', val);
    },
    isMaxScreenMPHValid(val) {
      return Number.parseInt(val) > 0;
    },
    updateDataSourceGroup(val) {
      this.dataSourceGroup = val;
      this.$sessionStore.commit('setDataSourceGroup', `${dataSourceMap[val]}${this.getDelayedPostfix(val, this.includeDelayedData)}`);
    }
  },
};
</script>
<style lang="scss" scoped>
.max-screen-container {
  display: flex;
  align-items: center;

  .max-screen-input {
    width: 6rem;
  }

  label {
    margin: 0;
    margin-left: 1rem;
  }

}
</style>

