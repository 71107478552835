<template>
  <ReportOptions style="margin-top: 1em;">
    <div d-flex class="outputFormat">
      <p>Output Format</p>
      <p class="subtext mb-2">Specify the data to include in the output of the dataset</p>

      <div d-flex class="reportFormatList">
        <b-form-radio-group id="radio-group" v-model="selected">
          <div class="expandedStandardReport">
            <b-form-radio value="EXPANDED_CEL_CDL_REPORT">Expanded Standard Dataset</b-form-radio>
            <div class="description">
              <p>Includes:</p>
              <li>— All Available Geographic Context for 170 countries</li>
              <li>
                — Timestamp of the first visit to a location from each CEL or CDL
                for the device
              </li>
              <li>
                — Count of days device visited the location for each CEL or CDL
              </li>
              <li>
                — Summary of key geographics driving traffic and distance traveled
              </li>
            </div>
          </div>

          <div class="expandedDetailedReport">
            <b-form-radio value="EXPANDED_CEL_CDL_DETAILED_REPORT">Expanded Detailed Dataset</b-form-radio>
            <div class="description">
              <p>Includes:</p>
              <li>— All Available Geographic Context for 170 countries</li>
              <li>
                — Timestamp of the first visit to a location from each CEL or CDL
                for the device
              </li>
              <li>
                — Summary of key geographics driving traffic and distance traveled
              </li>
            </div>
          </div>

          <div class="legacyDistanceReport" v-if="!isLargeJob">
            <b-form-radio value="CEL_CDL_DISTANCE_REPORT">Legacy Distance Dataset</b-form-radio>
            <div class="description">
              <p>Includes:</p>
              <li>— Limited Geographic context for 62 countries</li>
              <li>
                — Only the most recent CEL or CDL of the visitors to the location
              </li>
              <li>
                — Optional summary of key geographics driving traffic and distance
                traveled
              </li>
              <b-checkbox v-model="includeSummary" class="includeSummary" switch>Include Summary</b-checkbox>
            </div>
          </div>

          <div class="legacyLocationReport" v-if="!isLargeJob">
            <b-form-radio value="CEL_CDL_LOCATION_REPORT">Legacy Location Dataset</b-form-radio>
            <div class="description">
              <p>Includes:</p>
              <li>
                — Only the most recent CEL or CDL of the visitors to the location
              </li>
              <li style="color: red">
                — This dataset is scheduled to be sunset in the immediate future,
                please migrate to using one of our other more modern and improved
                datasets
              </li>
            </div>
          </div>
        </b-form-radio-group>
      </div>
    </div>
  </ReportOptions>
</template>
<script>
import ReportOptions from "../shared/ReportOptions.vue";
import { syncState } from "../../../utils/syncState";
import { bus } from "@/main";

export default {
  data() {
    return {
      selected: "EXPANDED_CEL_CDL_REPORT",
      includeSummary: false
    };
  },
  components: { ReportOptions },
  mounted() {
    let celCdlReportType;
    if (this.$sessionStore.state.jobRequest.didReportTypes !== undefined) {
      celCdlReportType = this.$sessionStore.state.jobRequest.didReportTypes.filter(id => id.includes('CEL_CDL'))[0];
    } else if (this.$sessionStore.state.jobRequest.didReportType !== undefined && this.$sessionStore.state.jobRequest.didReportType.includes('CEL_CDL')) {
      celCdlReportType = this.$sessionStore.state.jobRequest.didReportType;
    }
    if (this.$sessionStore.state.jobRequest.celCdlDistanceReportOptions !== undefined && this.$sessionStore.state.jobRequest.celCdlDistanceReportOptions.includeSummary !== undefined) {
      this.includeSummary = this.$sessionStore.state.jobRequest.celCdlDistanceReportOptions.includeSummary;
    }
    if (celCdlReportType !== undefined) this.selected = celCdlReportType;
  },
  computed: {
    ...syncState({
      isLargeJob: "state.jobRequest.isLargeJob|setIsLargeJob"
    }, { context: this, defaultStore: '$sessionStore' })    
  },
  watch: {
    selected() {
      let reportSelected = this.selected;
      let selectedCELCDLReport = reportSelected.split(' ').join('_');
      this.$sessionStore.commit('setCELCDLReportType', selectedCELCDLReport);
      bus.$emit("selectedReportOption", reportSelected);

    },
    includeSummary(value) {
      this.$sessionStore.commit(
        "setIncludeSummary",
        value
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.outputFormat {
  padding-top: 1em;
}

.reportFormatList {
  display: flex;
  flex-direction: column !important;

  li {
    list-style: none;
  }

  .expandedDetailedReport,
  .legacyDistanceReport,
  .legacyLocationReport {
    margin-top: 15px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  margin-left: 1.7em;

  p {
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 5px;
    font-weight: 600;
  }

  li {
    font-size: 14px;
    font-weight: 400;
    color: #92929d;
    margin-top: 3px;
  }

  .includeSummary {
    font-size: 14px;
    margin-top: 6px;
    margin-left: 1.5em;
  }
}

.button-container {
  display: flex;
  flex-direction: row;
}
</style>