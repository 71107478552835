<template>
  <div>Logging out...</div>
</template>

<script>
export default {
  created() {
    this.logout();
  },
  async logout() {
    const headers = this.getHeaders();
    this.$store.commit("setAlert", false);
    this.$sessionStore.commit("clearMapState");
    this.$sessionStore.commit("clearSearchState");
    try {
      if (this.tokenNeedsRefresh()) await this.refreshToken();
      await this.$http.post("/v1/logout", {}, headers);
    } catch (err) {
      console.log({ err });
      localStorage.setItem("accessToken", "");
      localStorage.setItem("refreshToken", "");
      localStorage.removeItem("vuex");
      this.$sessionStore.commit("setSearchDefault");
      this.$sessionStore.commit("setStudyDefault");
      this.$router.push("/login");
    }

    localStorage.setItem("accessToken", "");
    localStorage.setItem("refreshToken", "");
    localStorage.removeItem("vuex");
    this.$sessionStore.commit("setSearchDefault");
    this.$sessionStore.commit("setStudyDefault");
    this.$router.push("/login");
  },
};
</script>