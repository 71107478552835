<template>
  <b-btn @click="clicked" :class="classes" class="short-button" :variant="variant || 'primary'"><slot></slot></b-btn>
</template>

<script>
export default {
  props: ['variant', 'title', 'classes'],
  methods: {
    clicked(evt) {
      this.$emit('click', evt);
    }
  }
}
</script>

<style lang="scss" scoped>
.short-button {
  height: 30vh;
  margin-right: 3rem;
}
</style>