<template>
  <div id="map" :class="`basemap-${baseMap}`">
    <div class="alert-container">
      <b-alert
        v-for="msg in alert.error"
        :key="msg"
        :show="alert.error.length > 0"
        variant="danger"
        dismissible
        fade
        @dismissed="alertDismissed(msg, 'error')"
        >{{ msg }}</b-alert
      >
      <b-alert
        :show="alert.info !== undefined"
        variant="info"
        dismissible
        fade
        @dismissed="alertDismissed(alert.info, 'info')"
        class="info-alert"
        ><span style="display: inline-block; margin-right: 0.5rem"
          ><CopyLinkBell /></span
        >{{ alert.info }}</b-alert
      >
    </div>
    <div ref="mapComponent"></div>
    <basemaps></basemaps>
  </div>
</template>

<script>
import numeral from "numeral";
// import { uniqBy, debounce } from "lodash";
import qs from "qs";
import axios from "axios";
import { createMarker } from "@/services/map/markers";
import ThreeDimensionControl from "@/services/map/controls/threeDimensionControl";
import Basemaps from "@/components/partials/Basemaps.vue";
import { bus } from "@/main";
import { MapService } from "@/services/map/mapService";
import { syncState } from "@/utils/syncState.js";

import { getTypeByName, getTypeById, createCommunityId } from "@/services/map/types";

export default {
  components: {
    Basemaps,
  },
  props: {
    delayMapLoadTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      map: undefined,
      mapService: undefined,

      locations: [],
      page_size: 20,
      baseMap: "default",
      autosuggestCancelToken: null,
      alert: {
        info: undefined,
        error: [],
      },
      searchVisualizationID: null,
      actionQueue: [],
      openedLocation: null,
      busEvents: [],
    };
  },
  computed: {
    ...syncState(
      {
        search: "state.search.searchState",
        mapState: "state.map",
        comparisonLocations: "state.study.locations",
        liveSearch: "state.search.liveSearch",
        searchVisualization: "state.search.searchVisualization",
        selectedBrand: "state.search.selectedBrand",
        mapMoved: "state.search.mapMoved|setMapMoved",
        needsUpdate: "state.search.needsUpdate|setNeedsUpdate",
      },
      { context: this }
    ),
    placeLink() {
      if (this.$route.name === "search-details") {
        return this.$route.query.place;
      }
      return null;
    },
  },
  mounted() {
    if (this.delayMapLoadTime) {
      setTimeout(this.loadMap, this.delayMapLoadTime);
    } else {
      this.loadMap();
    }

    this.busEvents.push("focusComparisonLocations");
    bus.$on("focusComparisonLocations", () => {
      this.focusComparisonLocations();
    });

    this.busEvents.push("focusSearchLocations");
    bus.$on("focusSearchLocations", (forceRefresh) => {
      this.focusSearchLocations(forceRefresh);
    });

    this.busEvents.push("focusPlace");
    bus.$on("focusPlace", (place) => {
      this.focusPlace(place);
    });
    if (this.comparisonLocations.length > 0) this.focusComparisonLocations();
  },
  beforeDestroy() {
    this.busEvents.forEach(event_name => bus.$off(event_name));
    
    const { lng, lat } = this.map.getCenter();
    this.$sessionStore.commit("setMapCenter", [lng, lat]);
    this.$sessionStore.commit("setMapZoom", this.map.getZoom());
    this.map = undefined;
    this.mapService = undefined;
    this.needsUpdate = true;
  },
  watch: {
    searchVisualization(newValue) {
        this.setSearchVisualizationLayer(true);  
    },
    comparisonLocations(newLocations, oldLocations) {
      if (newLocations.length === 0) return;
      else if (newLocations.length < oldLocations.length) {
        const removed = oldLocations.filter((loc) => {
          return (
            newLocations.find((loc2) => {
              return loc2.id === loc.id;
            }) === undefined
          );
        })[0];
        document
          .querySelectorAll(
            `.location-pin-${removed.id}`
          ) /* find all classes named marker */
          .forEach((item) => {
            item.remove();
          });
        if (this.$route.name !== "comparison-details") {
          this.populateMoreMarkers([removed], this.map);
        }
      }
    },
    placeLink() {
      $(".location-pin").removeClass("location-pin-selected");
      $(".location-pin").removeClass("location-pin-selected-search");
      if (this.$route.query.place) {
        this.openedLocation = JSON.parse(atob(this.$route.query.place));
        $(".location-pin-" + this.openedLocation.id).removeClass(
          "result-highlight"
        );
        $(".location-pin-" + this.openedLocation.id).addClass(
          "location-pin-selected"
        );
        $(".location-pin-" + this.openedLocation.id).addClass(
          "location-pin-selected-search"
        );
      }
    },
  },
  methods: {
    async processAction() {
      if (this.actionQueue.length === 0) return;
      const action = this.actionQueue.shift();
      try {
        const res = await this[action.method](...action.args);
        action.resolve(res);
      } catch (err) {
        console.log({ err });
        action.reject({ message: "Could not process action.", err });
      }
      return;
    },
    queueAction({ method, resolve, reject }, ...args) {
      this.actionQueue.push({ method, resolve, reject, args });
    },
    updateMetadata(locations) {
      return locations.map((r) => ({
        ...r,
        // shortenedName: r.name.split(",")[0].slice(0, 20),
        shortenedName:
          r.name.length >= 23 ? r.name.substring(0, 23) + "..." : r.name,
        uptrend: r.estimated_visitor < r.past_estimated_visitor,
        estimated_visitor: numeral(r.estimated_visitor).format("0,0"),
      }));
    },
    async loadMoreLocations(params, center, page, map, retry) {
      if(params.search_term){
        params.search_term = params.search_term.replace('&', ' ');
      }
      console.log('loadMoreLocations');
      if (params) {
        const headers = this.getHeaders();
        let search_params = { ...params };
        search_params["page_size"] = this.page_size;
        search_params["page"] = page;
        search_params["center"] = center;
        //let search_query = qs.stringify(search_params);

        let bounds = map.getBounds();
        let tl = bounds.getNorthWest();
        let br = bounds.getSouthEast();
        //tl_lon, tl_lat, br_lon, br_lat
        let api_url = `/v1/locations-bbox/${tl.lng},${tl.lat},${br.lng},${br.lat}`;
        try {
          const { data } = await this.$http.get(api_url, {
            ...headers,
            params: { ...search_params },
          });

          //        const { data } = await this.$http.get(
          //          `/v1/locations-nearest/${lng},${lat}?search_term=${search}&page_size=${this.page_size}&page=${page}&radius=50mi`
          //        );
          if (data) {
            const results = this.updateMetadata(data.records);
            this.locations = [...this.locations, ...results];
            window.mapLocations = this.locations;
            //this.populateMoreMarkers(results, map);
            //const hasMoreLocations = !!results.length;
            const hasMoreLocations =
              data.pagination.pages > data.pagination.page;
            //this.fitBounds(this.locations, map);
            bus.$emit(
              "getLocations",
              this.locations,
              hasMoreLocations,
              data.pagination.page,
              true
            );
          }
        } catch (err) {
          if (
            !err.response.data.success &&
            !err.response.data.statusCode &&
            !retry
          ) {
            console.log("refresh route");
            this.refreshToken().then((res) => {
              if (res.success) {
                //this is no longer recursive thanks to head-recursion
                this.loadMoreLocations(params, center, page, map, true);
              }
            });
          } else {
            this.err = err.response.data.errors[0].message;
            console.log({ err });
          }
        }
      }
    },
    async autocompleteMap(search, center, map, type, retry) {
      const headers = this.getHeaders();
      if (search) {
        if (this.autosuggestCancelToken) {
          this.autosuggestCancelToken.cancel(
            "Operation canceled due to new request."
          );
        }

        this.autosuggestCancelToken = axios.CancelToken.source();
        try {
          const { lng, lat } = center;
          const termName = type === "category" ? "search_term" : "geo_term";
          let search_encoded = encodeURIComponent(search);
          const { data } = await axios.get(
            `${process.env.VUE_APP_PINNACLE_API}/v1/autosuggest/${lng},${lat}?${termName}=${search_encoded}&page_size=10&page=1&radius=50mi`,
            { ...headers, cancelToken: this.autosuggestCancelToken.token }
          );

          if (data) {
            // if (type === 'category') {
            //   this.locations = this.updateMetadata(data?.records);
            //   this.populateMarkers(this.locations, map);
            // }
            data.records = this.updateMetadata(data.records);
            data.type = type;
            bus.$emit("getAutocomplete", data);
          }
        } catch (err) {
          if (err.message.includes("Operation canceled")) return;
          if (err.response && err.response.data && err.response.data.message) {
            // err.response.data.errors.forEach((element) => {
            console.log(err.response.data);
            if (
              !err.response.data.success &&
              !err.response.data.statusCode &&
              !retry
            ) {
              console.log("refresh route");
              this.refreshToken().then((res) => {
                if (res.success) {
                  //this can be re-enginerred to not be recursive, but I added a return in the other function just in case
                  this.autocompleteMap(search, center, map, true);
                }
              });
            } else {
              this.err = err.response.data.errors[0].message;
              console.log({ err });
            }
          } else {
            console.log(err);
            //bus.$emit("getAutocomplete", null);
          }
        }
      } else {
        bus.$emit("getAutocomplete", null);
      }
    },
    searchMap: async function (params, center, map, retry) {
      console.log('searchMap');
      document
        .querySelectorAll(".marker") /* find all classes named marker */
        .forEach((item) => {
          item.remove();
        });
      const headers = this.getHeaders();
      let popup = document.getElementsByClassName("mapboxgl-popup");
      if (popup.length) {
        popup[0].remove();
      }
      if (params) {
        if (window.mapLocations) window.mapLocations = undefined;
        let search_params = { ...params };
        search_params["page_size"] = this.page_size;
        search_params["page"] = 1;
        let search_query = qs.stringify(search_params);
        let api_url = `/v1/locations?${search_query}`;
        try {
          const res = await this.$http.get(api_url, headers);
          if (!res || !res.data) {
            bus.$emit("getLocations", [], false, 1);
          }

          const { data } = res;
          if (data) {
            if (params.moveOnly) {
              // Hardcoded for PIN-1396
              // data.records = data.records.slice(0, 3);
              // this.locations = this.updateMetadata(data?.records);
              // this.populateMarkers(this.locations, map);
              bus.$emit(
                "getLocations",
                [],
                false,
                1,
                false,
                true,
                data.center !== undefined
              );
            } else {
              this.locations = this.updateMetadata(data?.records);
              this.populateMarkers(this.locations);
              const hasMoreLocations =
                data.pagination.pages > data.pagination.page;

              bus.$emit(
                "getLocations",
                this.locations,
                hasMoreLocations,
                data.pagination.page,
                false,
                false,
                data.center !== undefined
              );
            }

            this.fitBounds(data.records, map, data.center, data.bounds);
          }
        } catch (err) {
          if (
            err.response &&
            "data" in err.response &&
            "message" in err.response.data
          ) {
            // err.response.data.errors.forEach((element) => {
            if (
              !err.response.data.success &&
              !err.response.data.statusCode &&
              !retry
            ) {
              console.log("refresh route");
              this.refreshToken().then((res) => {
                if (res.success) {
                  //this is no longer recursive thanks to head-recursion
                  this.searchMap(params, center, map, true);
                }
              });
            } else {
              this.err = err.response.data.errors[0].message;
              console.log({ err });
            }
            // })
          } else {
            this.locations = [];
            const hasMoreLocations = false;
            bus.$emit("getLocations", this.locations, hasMoreLocations);
          }
        }
      }
    },
    searchMapBBox: async function (params, center, map, retry) {
      if(params.search_term){
        params.search_term = params.search_term.replace('&', ' ');
      }
      document
        .querySelectorAll(".marker") /* find all classes named marker */
        .forEach((item) => {
          item.remove();
        });
      const headers = this.getHeaders();
      let popup = document.getElementsByClassName("mapboxgl-popup");
      if (popup.length) {
        popup[0].remove();
      }

      if (!params) {
        params = {};
      }
      let search_params = { ...params };

      if (window.mapLocations) window.mapLocations = undefined;

      search_params["page_size"] = this.page_size;
      search_params["page"] = 1;
      search_params["center"] = center;
      // let search_query = qs.stringify(search_params);

      let bounds = map.getBounds();
      let tl = bounds.getNorthWest();
      let br = bounds.getSouthEast();
      //tl_lon, tl_lat, br_lon, br_lat
      let api_url = `/v1/locations-bbox/${tl.lng},${tl.lat},${br.lng},${br.lat}`;
      try {
        const { data } = await this.$http.get(api_url, {
          ...headers,
          params: { ...search_params },
        });

        if (data) {
          this.locations = this.updateMetadata(data?.records);
          //this.populateMarkers(this.locations, map);
          if (data?.pagination && data?.pagination.expanded) {
            this.fitBounds(this.locations, map);
          }
          const hasMoreLocations = data.pagination.pages > data.pagination.page;
          bus.$emit(
            "getLocations",
            this.locations,
            hasMoreLocations,
            data.pagination.page
          );
        }
      } catch (err) {
        if (err.response && "data" in err.response) {
          if (
            !err.response.data.success &&
            !err.response.data.statusCode &&
            !retry
          ) {
            console.log("refresh route");
            this.refreshToken().then((res) => {
              if (res.success) {
                //this is no longer recursive thanks to head-recursion
                this.searchMapBBox(params, center, map, true);
              }
            });
          } else {
            this.err = err.response.data.errors[0].message;
            console.log({ err });
          }
        }
      }
    },
    searchMapDefault: async function (center, map, retry) {
      console.log('searchMapDefault');
      document
        .querySelectorAll(".marker") /* find all classes named marker */
        .forEach((item) => {
          item.remove();
        });
      const headers = this.getHeaders();
      let popup = document.getElementsByClassName("mapboxgl-popup");
      if (popup.length) {
        popup[0].remove();
      }

      if (window.mapLocations) window.mapLocations = undefined;

      let bounds = map.getBounds();
      let tl = bounds.getNorthWest();
      let br = bounds.getSouthEast();
      //tl_lon, tl_lat, br_lon, br_lat

      try {
        const { data } = await this.$http.get(
          `/v1/locations-bbox/${tl.lng},${tl.lat},${br.lng},${br.lat}?page_size=${this.page_size}`,
          headers
        );
        if (data) {
          this.locations = this.updateMetadata(data?.records);
          this.populateMarkers(this.locations);
          const hasMoreLocations = data.pagination.pages > data.pagination.page;
          bus.$emit(
            "getLocations",
            this.locations,
            hasMoreLocations,
            data.pagination.page
          );
        }
      } catch (err) {
        if (
          !err.response.data.success &&
          !err.response.data.statusCode &&
          !retry
        ) {
          console.log("refresh route");
          this.refreshToken().then((res) => {
            if (res.success) {
              //this is no longer recursive thanks to head-recursion
              this.searchMapDefault(center, map, true);
            }
          });
        } else {
          this.err = err.response.data.errors[0].message;
          console.log({ err });
        }
      }
    },
    clearMarkers() {
      document
        .querySelectorAll(".location-pin") /* find all classes named marker */
        .forEach((item) => {
          item.remove();
        });

      const popup = document.getElementsByClassName("mapboxgl-popup");
      if (popup.length) {
        popup[0].remove();
      }
    },
    focusComparisonLocations(hideSearchLayer=false, moveScreen=true) {
      // sets the markers just for comparison locations
      this.populateMarkers(this.comparisonLocations);
      if (hideSearchLayer) this.setSearchVisualizationLayer(false);

      // calculate new bounds around the study locations and zoom in
      // to fit all location pins
      const centerPoints = this.comparisonLocations.map((loc) => {
        return loc.centroid.coordinates;
      });
      const bounds = new mapboxgl.LngLatBounds();
      centerPoints.forEach((point) => {
        bounds.extend(point);
      });

      if (Object.keys(bounds).length === 0) return;

      if (moveScreen) this.map.fitBounds(bounds, { padding: 100 });
    },
    focusSearchLocations(forceRefresh) {
      this.focusComparisonLocations(false, false);
      this.setSearchVisualizationLayer(true, forceRefresh);

      //this.map.fitBounds(bounds, { padding: 100 });
    },
    focusPlace(place) {
      console.log('focusPlace', place);
      console.log(this.map);
      this.setSearchVisualizationLayer(false);
      this.populateMarkers([place, ...this.comparisonLocations.filter(loc => {
        return loc.id !== place.id;
      })], true);
      
      this.map.flyTo({
        center: place.centroid.coordinates
      });
    },
    populateMoreMarkers(locations = [], map) {
      /*let popup = document.getElementsByClassName("mapboxgl-popup");*/
      // add markers to map
      locations.forEach((loc) => {
        createMarker({
          map: this.map,
          center: loc.centroid.coordinates,
          iconURL:
            loc.avatar_subsector === null ||
            (loc.avatar_subsector !== null &&
              (loc.avatar_subsector === "None" ||
                !loc.avatar_subsector.endsWith("svg")))
              ? loc.avatar_sector
              : loc.avatar_subsector,
          iconSize: "18px",
          iconBGColor: "#2C7BE5",
          address: loc.address,
          label: loc.name,
          click_handler: function (result) {
            bus.$emit("mapIconClicked", result);
          },
          mouseenter_handler: function (result) {
            bus.$emit("mapIconMouseenter", result);
          },
          mouseleave_handler: function (result) {
            bus.$emit("mapIconMouseleave", result);
          },
          loc: loc,
        });
      });
    },
    populateMarkers(locations = [], focused=false) {
      this.clearMarkers();

      // add markers to map
      try {
        locations.forEach((loc, idx) => {
          const config = {
            map: this.map,
            center: loc.centroid.coordinates,
            iconURL:
              loc.avatar_subsector === null ||
              (loc.avatar_subsector !== null &&
                (loc.avatar_subsector === "None" ||
                  !loc.avatar_subsector.endsWith("svg")))
                ? loc.avatar_sector
                : loc.avatar_subsector,
            iconSize: "18px",
            iconBGColor: "#2C7BE5",
            address: loc.address,
            label: loc.name,
            click_handler: function (result) {
              bus.$emit("mapIconClicked", result);
            },
            mouseenter_handler: function (result) {
              bus.$emit("mapIconMouseenter", result);
            },
            mouseleave_handler: function (result) {
              bus.$emit("mapIconMouseleave", result);
            },
            loc: loc,
            // comparisonLocations: this.comparisonLocations
          };

          if (focused && idx === 0) {
            config.classString = "location-pin-selected location-pin-selected-search";
          }
          // make a marker for each feature and add it to the map
          createMarker(config);
        });
      } catch (err) {
        console.log(err);
      }
    },
    fitBounds(locations = [], map, center, bounds) {
      if (locations.length === 0) {
        if (!bounds) {
          console.log("Fitting w/ Google Center");
          map.flyTo({ center, padding: 250, maxZoom: 12 });
        }

        if (!center) return;

        console.log("Fitting w/ Google Bounds");
        const { northeast, southwest } = bounds;
        map.fitBounds(
          [
            [southwest.lng, southwest.lat],
            [northeast.lng, northeast.lat],
          ],
          { center }
        );
      } else {
        console.log("Fitting w/ Locations");

        const bounds = new mapboxgl.LngLatBounds();

        if (locations.length > 0) {
          locations.forEach((loc) => {
            bounds.extend(JSON.parse(JSON.stringify(loc.centroid.coordinates)));
          });

          bus.$emit("fitBounds", this.map);

          map.fitBounds(bounds, { padding: 250, maxZoom: 12 });
        }
      }
    },
    getMapStats(map) {
      const { lng, lat } = map.getCenter();
      this.$sessionStore.commit("setMapCenter", [lng, lat]);
      this.$sessionStore.commit("setMapZoom", map.getZoom());
      bus.$emit("mapMoved", this.map);
    },
    async setSearchVisualizationLayer(visible, forceRefresh = false) {
//      console.log('setSearchVisualizationLayer');
//      console.log('visible', visible);
//      console.log('forceRefresh', forceRefresh);
      if (!this.map || !this.mapService) {
        return await new Promise((resolve, reject) => {
          this.queueAction(
            { method: "setSearchVisualizationLayer", resolve, reject },
            visible, forceRefresh
          );
        });
      }
      if (visible) {
        if (this.searchVisualizationID !== null) {
          // check if different searchVisualization
          let typeById = getTypeById(this.searchVisualizationID);
          //console.log(typeById);
          if (typeById.name == this.searchVisualization && !forceRefresh) {
            return;
          } else {
            //console.log('removeVisualization', this.searchVisualizationID);
            await this.mapService.removeVisualization({ id: this.searchVisualizationID });
//            this.searchVisualizationID = null;
//            // If removing old visualization, give a little extra time before adding new visualization
//            setTimeout(function() {
//              this.setSearchVisualizationLayer(visible, forceRefresh);
//            }.bind(this), 100);
//            return;
          }
        }
        
        
        //const type = getTypeByName("PlacesCluster");
        //const type = getTypeByName("PlacesChoropleth");
        
        const type = getTypeByName(this.searchVisualization);
        //console.log(type);
        this.searchVisualizationID = createCommunityId(type);
        //console.log("searchVisualizationID", this.searchVisualizationID);

        const api_params = { ...this.$route.query };
        //console.log(JSON.stringify(api_params));
        try {
          await this.mapService.addTileVisualization({
            filters: {
              api_params,
              prefix: type.prefix,
              visualization: type.name,
            },
            id: this.searchVisualizationID,
            clickHandler: (loc) => {
              bus.$emit("mapIconClicked", loc);
            },
          });
        } catch (err) {
          console.log(err);
          this.searchVisualizationID = null;
        }
      } else {
        if (this.searchVisualizationID === null) return;
        await this.mapService.removeVisualization({ id: this.searchVisualizationID });
        this.searchVisualizationID = null;
      }
    },
    async getUserLocation() {
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error("Geolocation is not available."));
        }

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    async loadMap() {
      if (typeof mapboxgl != "object") {
        setTimeout(this.loadMap, 1000);
        return;
      }

      mapboxgl.accessToken = process.env.VUE_APP_MAP_ACCESS_TOKEN;
      let center = this.mapState.center ?? [-78.6393462, 35.7761857];
      let zoom = this.mapState.zoom ?? 10;
      const last_center = window.sessionStorage.getItem("explore_map_center");
      const last_zoom = window.sessionStorage.getItem("explore_map_zoom");

      if (last_center && last_zoom) {
        center = JSON.parse(last_center);
        zoom = JSON.parse(last_zoom);
      } else {
        try {
          let user_location = await this.getUserLocation();
          center = [
            user_location.coords.longitude,
            user_location.coords.latitude,
          ];
          // console.log('center from html5', center);
        } catch (e) {
          const { data } = await this.$http.get(`/v1/whereami`);
          if (data.longitude) {
            center = [data.longitude, data.latitude];
            // console.log('center from ip', center);
          } else {
            // console.log('center from default', center);
          }
        }
      }

      let mapData = window.localStorage.getItem("lastSelectedMap");
      let mapID = "cku4lf4wi1qn217pq9kh4w3ol";
      if (mapData) {
        mapData = JSON.parse(mapData);
        mapID = mapData.mapID;
      }
      const map = new mapboxgl.Map({
        container: this.$refs.mapComponent,
        style: `mapbox://styles/nearco/${mapID}`,
        attributionControl: false,
        center,
        zoom,
        transformRequest: (url, resourceType) => {
          if (resourceType === "Tile" && url.indexOf("mapbox") === -1) {
            if (this.tokenNeedsRefresh()) {
              this.refreshToken().then(() => {
                const { headers } = this.getHeaders();
                return {
                  url,
                  headers,
                };
              });
            } else {
              const { headers } = this.getHeaders();
              return {
                url,
                headers,
              };
            }
          }
        },
      });

      map.on("load", () => {
        bus.$emit("searchMapLoaded", map);
      });

      map.on("idle", () => {
        this.processAction();
      });

      const nav = new mapboxgl.NavigationControl({ showCompass: false });
      map.addControl(nav, "bottom-right");

      const scale = new mapboxgl.ScaleControl({
        maxWidth: 260,
        unit: "imperial",
      });
      map.addControl(scale);

      const geolocate = new mapboxgl.GeolocateControl();
      geolocate.on("error", (error) => {
        if (error.PERMISSION_DENIED) {
          this.setAlert(
            "Please share your location via your browser and/or system settings to use the my location button."
          );
        }
      });
      map.addControl(geolocate, "bottom-right");

      const threeDimensionControl = new ThreeDimensionControl();
      map.addControl(threeDimensionControl, "bottom-right");

      const attribution = new mapboxgl.AttributionControl({
        compact: false
      });
      map.addControl(attribution, "bottom-left");

      this.map = map;
      this.mapService = new MapService(map);

      const { getMapStats } = this;

      map.on("movestart", () => (this.mapMoved = true));

      map.on("moveend", ({ originalEvent }) => {
        window.sessionStorage.setItem(
          "explore_map_center",
          JSON.stringify(map.getCenter())
        );
        window.sessionStorage.setItem(
          "explore_map_zoom",
          JSON.stringify(map.getZoom())
        );
        getMapStats(map);
        if (originalEvent) {
          map.fire('usermoveend');
        } else {
          map.fire('flyend');
        }        
      });
      
      map.on("usermoveend", () => {
        //console.log('user moved map');
        bus.$emit("mapMovedByUser", this.map);
      });
      map.on("flyend", () => {
        //console.log('on flyend');
      });

      // map.on("dragend", function () {
      //   getMapStats(map);
      // });

      // map.on("zoomend", function () {
      //   getMapStats(map);
      // });

      // map.on("boxzoomend", function () {
      //   getMapStats(map);
      // });

      // map.on("rotateend", function () {
      //   getMapStats(map);
      // });

      bus.$off("autocompleteGeo");
      bus.$on("autocompleteGeo", (search) => {
        this.autocompleteMap(search, map.getCenter(), map, "geography");
      });
      bus.$off("autocompleteCategory");
      bus.$on("autocompleteCategory", (search) => {
        this.autocompleteMap(search, map.getCenter(), map, "category");
      });
      bus.$off("searchIt");
      bus.$on("searchIt", (search) => {
        this.searchMap(search, map.getCenter(), map);
      });
      bus.$off("searchItDefault");
      bus.$on("searchItDefault", () => {
        this.searchMapDefault(map.getCenter(), map);
      });
      bus.$off("searchItBBox");
      bus.$on("searchItBBox", (search) => {
        this.searchMapBBox(search, map.getCenter(), map);
      });
      bus.$off("loadMoreLocations");
      bus.$on("loadMoreLocations", ({ search, page }) => {
        this.loadMoreLocations(search, map.getCenter(), page, map);
      });

      bus.$off("repopulateMarkers");
      bus.$on("repopulateMarkers", ({ results }) => {
        const allLocations = [...results];
        if (this.locations.length > 0) {
          allLocations.push(...this.locations);
        }
        this.populateMarkers(allLocations);
      });

      bus.$off("clearMarkers");
      bus.$on("clearMarkers", () => {
        this.clearMarkers();
      });

      bus.$off("moveMap");
      bus.$on("moveMap", (center, zoom) => {
        console.log({
          center,
          zoom: zoom === undefined ? map.getZoom() : zoom,
        });
        map.flyTo({
          center,
          zoom: zoom === undefined ? map.getZoom() : zoom,
        });
      });

      bus.$off("fitMarker");
      bus.$on("fitMarker", (coords) => {
        let visibleOnMap = map.getBounds().contains(coords);
        if (!visibleOnMap) {
          map.flyTo({ center: coords });
        }
      });

      bus.$off("newBaseMapLayerSelected");
      bus.$on("newBaseMapLayerSelected", (evt) => {
        this.changeMapStyle(evt);
      });

      bus.$off("getSearchMapInstance");
      bus.$on("getSearchMapInstance", () => {
        bus.$emit("returnSearchMapInstance", this.map);
      });

      // Reload old map state
      // If a place is selected add back the pin
      // If its a comparison, add back comparison location pins
      // Else load the Search Visualization
      if (this.$route.name === "comparison-details") {
        this.focusComparisonLocations(true);
      } else if (this.$route.name === "search-details" && this.$route.query.place !== undefined) {
        // console.log(this.$route);
        const place = JSON.parse(atob(this.$route.query.place));
        if (!place) {
          this.$router.push({path: '/explore/search'});
          return;
        }
        this.focusPlace(place);
      }   
    },

    async changeMapStyle(evt) {
      const selectedLayer = evt.mapID;
      const currentStyle = this.map.getStyle();
      console.log("SELECTED:   ", selectedLayer);

      const { data: newStyle } = await axios.get(
        `https://api.mapbox.com/styles/v1/nearco/${selectedLayer}?access_token=${process.env.VUE_APP_MAP_ACCESS_TOKEN}`
      );
      newStyle.sources = Object.assign(
        {},
        currentStyle.sources,
        newStyle.sources
      ); // Copy sources
      let labelIdx = newStyle.layers.findIndex((el) => {
        return el.id === "waterway-label";
      });
      if (labelIdx === -1) labelIdx = newStyle.layers.length;

      const appLayers = currentStyle.layers.filter((el) => {
        return (
          el.source &&
          el.source != "mapbox://mapbox.satellite" &&
          el.source != "mapbox" &&
          el.source != "composite"
        );
      });

      newStyle.layers = [
        ...newStyle.layers.slice(0, labelIdx),
        ...appLayers,
        ...newStyle.layers.slice(labelIdx, -1),
      ];
      this.map.setStyle(newStyle);
    },
    setAlert(msg, type = "error") {
      if (type === "error") this.alert[type].push(msg);
      else this.alert[type] = msg;
    },
    alertDismissed(msg, type = "error") {
      if (type === "error")
        this.alert[type] = this.alert[type].filter((_msg) => _msg !== msg);
      else this.alert[type] = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
#modal-center___BV_modal_content_ {
  max-width: 110% !important;
  min-width: 110% !important;
}

.alert {
  margin-bottom: 0.5rem !important;
}

.info-alert {
  background-color: $blue;
}

#map {
  // min-height: calc(100vh - 195px);
  // max-height: 100vh;
  // max-width: calc(100% - 125px);
  height: 100%;
  width: 100%;
  position: relative;

  .alert-container {
    position: absolute;
    z-index: 3;
    left: 1vw;
    right: 1vw;
    top: 1vw;
  }
}

.modal-dialog .modal-header .close {
  margin: -1.2rem -1.5rem -1.5rem auto;
}
.margin-dialog h5 {
  margin-bottom: -3px;
}
</style>
