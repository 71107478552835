import { saveAs } from "file-saver"
import html2canvas from "html2canvas";

export default {
  methods: {
    /**
     * Saves the chart data.
     * @param {string} filename The output filename.
     * @param {string} selector The store field selector.
     */
    async saveData(filename, selector) {
      this.$bvModal.hide("my-modal");
      this.$sessionStore.commit("setDownloading", true);
      this.$bvModal.show("downloading");
      let data =
        "data:text/csv;charset=utf-8," +
        this.$sessionStore.state.study.chart[selector].replace(/\t/gi, ",");
      this.$sessionStore.commit("setDownloading", false);
      saveAs(data, `${filename}.csv`);
    },
    /**
     * Saves the chart png.
     * @param {*} filename The output filename.
     * @param {*} chartSelector The selector of the chart element.
     */
    async saveChart(filename, chartSelector) {
      this.$bvModal.hide("my-modal");
      this.$sessionStore.commit("setDownloading", true);
      this.$bvModal.show("downloading");
      let canvas2 = await html2canvas(document.querySelector(chartSelector)).then(
        (canvas) => {
          return canvas;
        }
      );
      let that = this.$sessionStore;
      await canvas2.toBlob(function (blob) {
        that.commit("setDownloading", false);
        saveAs(blob, `${filename}.png`);
      }, `image/png`);
    }
  }
}