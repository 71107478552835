import LeftNav from './components/universal/LeftNav.vue';
import TopNav from './components/universal/TopNav.vue';
import PSelection from './components/partials/ui/PSelection.vue';
import PCard from './components/partials/ui/PCard.vue';
import PContainer from './components/partials/layout/PContainer.vue';
import PageDivider from './components/partials/layout/PageDivider.vue';
import DataTable from './components/partials/ui/DataTable.vue';
import PageTabs from './components/partials/ui/buttons/PageTabs.vue';
import ShortButton from './components/partials/ui/buttons/ShortButton.vue';
import StatusBadge from './components/partials/ui/StatusBadge.vue';
import PermissionsMixin from './mixins/permissions.js';
import EnvironmentsMixin from './mixins/environments.js';
import GetMixin from "./mixins/get.js";

function PinnacleComponents(options={}) {
  const plugin = {
    options,
    install(app) {
      app.component('left-nav', LeftNav);
      app.component('top-nav', TopNav);
      app.component('p-card', PCard);
      app.component('p-container', PContainer);
      app.component('p-selection', PSelection);
      app.component('p-short-button', ShortButton);
      app.component('page-divider', PageDivider)
      app.component('page-tabs', PageTabs);
      app.component('data-table', DataTable);
      app.component('status-badge', StatusBadge);
    }
  };
  return plugin;
}

function PinnaclePermissions(options={}) {
  const plugin = {
    options,
    install(app) {
      app.mixin(PermissionsMixin);
    }
  }
  return plugin;
}

function PinnacleEnvironments(options={}) {
  const plugin = {
    options,
    install(app) {
      app.mixin(EnvironmentsMixin);
    }
  };
  return plugin;
}

export {
  // Components
  LeftNav, TopNav, PCard, PContainer, PSelection, ShortButton,
  PageDivider, PageTabs, DataTable, StatusBadge,

  // Plugins
  PinnacleComponents, PinnaclePermissions, PinnacleEnvironments, 

  // Mixins
  GetMixin
};