<template>
  <div>
  <ReportOptions>

    <p class="smalltext required">* Required</p>
    <h2>Industry</h2>
    <div class="input-container">
      <b-form-select :state="isCategoryValid(category)" v-model="category" :options="filtered_subsectors">
       <template v-slot:first>
        <option value="" disabled>- Select Industry -</option>
       </template>      
     </b-form-select>
    </div>


    <p class="smalltext graytext">Choose the industry that most closely matches the places for which you are requesting the dataset.</p>
    <p :class="validDates ? 'd-none' : 'warning'">Estimated Visits datasets are only valid for intervals later than January 1, 2019.</p>
    
  </ReportOptions>
  <ReportOptions>

    <div>
    <p class="smalltext required mt-4">* Required</p>
    <p>Average Dwell Time</p>
    <div class="input-container">
      <b-input :state="isValid(dwellTime)" v-model="dwellTime" type="number"></b-input>
      <label>min</label>
    </div>
    <p class="smalltext graytext">Enter the average time (in minutes) that visitors stay in the study location. The could be found via a Vista Dwell Time dataset, internet research such as Google Places, or anecdotal information. Depending on the localized panel behavior, changes in this value may have minimal or moderate impact on the overall estimated visits.</p>
    <p :class="validDates ? 'd-none' : 'warning'">Estimated Visits datasets are only valid for intervals later than January 1, 2019.</p>
    </div>
  </ReportOptions>

  </div>
</template>
<script>

import ReportOptions from '../shared/ReportOptions.vue';
import { bus } from "@/main";
export default {
  components: { ReportOptions },
  mounted() {

    console.log(this.$sessionStore.state.jobRequest)
    
    const subsectors = localStorage.getItem("subsectors");
    if (subsectors) {
      this.subsectors = JSON.parse(subsectors);
    } else {
      this.fetchSubsectors();  
    }
    
  },
  watch: {
    dwellTime(val) {
      if (this.isValid(val)) {
        this.$sessionStore.commit('setEstimatedVisitsDwellTime', val);
        return;
      }
      this.$sessionStore.commit('setEstimatedVisitsDwellTime', undefined);
    },
    category(val) {
      this.$sessionStore.commit('setEstimatedVisitsCategory', val);
    },
    validDates(val) {
      if (val) bus.$emit('reportTypeErrorOff'); 
      else bus.$emit('reportTypeErrorOn', 'Estimated Visits datasets are only valid for intervals later than January 1, 2019.');
    }
  },
  data() {
    return {
      dwellTime: this.$sessionStore.state.jobRequest.estimatedVisits2Options.dwellTime,
      category: this.$sessionStore.state.jobRequest.estimatedVisits2Options.category,
      subsectors: [],
    };
  },
  methods: {
    isCategoryValid(val) {
      return val != "";
    },
    isValid(val) {
      try {
        return Number.parseInt(val) > 0;
      } catch {
        return false;
      }
    },
    async fetchSubsectors() {
      const headers = this.getHeaders();
      if (this.tokenNeedsRefresh()) await this.refreshToken();
      let url = `${process.env.VUE_APP_PINNACLE_API}/v1/categories`;
      const subsectors = await this.$http.get(url, {
        ...headers,
        params: { field: "subsector", collection: "PPOI" },
      });

      this.subsectors = subsectors.data.records.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      window.localStorage.setItem(
        "subsectors",
        JSON.stringify(this.subsectors)
      );
    },
  },
  beforeDestroy() {
    bus.$emit('reportTypeErrorOff');
  },
  computed: {
    validDates() {
      const startDateTime = new Date(this.$sessionStore.state.jobRequest.startDateTime);
      const firstDate = new Date("2019-01-01 00:00:00");
      return startDateTime >= firstDate;
    },
    filtered_subsectors() {

      if (this.subsectors) {
        return this.subsectors.map(x => { return {value: x.name, text: x.name}});
      } else {
        return [];
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.input-container {
  display: flex;
  width: 50%;
  margin: 1rem 0;

  ::v-deep input {
    width: 6rem;
  }

  align-items: center;

  label {
    margin-left: 1rem;
  }
}

p {
  margin: 0;
}

.smalltext {
  font-size: 12px;
}

.warning {
  color: #f7617b;
  font-size: 11px;
  margin-bottom: 0em;
}
</style>