import { bus } from '@/main';

const supportedCountries = ["USA", "Canada", "Australia", "New Zealand"];

export const AutocompleteMixin = {
  data() {
    return {
      google: undefined,
      geocoder: undefined,

      typeahead: {
        categories: [],
        geographies: [],
        googlePlaces: [],
        results: [],
        termCategory: "",
        termGeo: "",
        debounce: null,
      },
    };
  },
  computed: {
    autocompleteEmpty() {
      return (
        this.typeahead.googlePlaces.length === 0 &&
        this.typeahead.categories.length === 0
      );
    }
  },
  created() {
    
    bus.$on('getAutocomplete', (evt) => {
      if (!evt) return;
      if (this.search.submitted) return;

      // Do not show results if the search term has changed
      if (
        evt.type === "geography" &&
        this.search.geo.term !== this.typeahead.termGeo
      )
        return;
      if (
        evt.type === "category" &&
        this.search.term !== this.typeahead.termCategory
      )
        return;

      if (evt.type !== "geography") {
        this.typeahead.categories = evt.categories;
        this.typeahead.results = evt.records;
      }
      this.ui.loading.autocomplete = false;
    });

  },
  beforeDestroy() {

    bus.$off('getAutocomplete');

  },
  methods: {
    /**
     * Emits autocomplete event, type can be 'Geo' or 'Category'
     * @param {*} term 
     * @param {*} type 
     */
    emitAutocomplete(term, type) {
      if (this.typeahead.debounce) {
        clearTimeout(this.typeahead.debounce);
      }

      if (type === "Geo") {
        this.getGooglePlaces(term).then(
          (predictions) => (this.typeahead.googlePlaces = predictions)
        );
      }

      this.typeahead.debounce = setTimeout(() => {
        this.typeahead[`term${type}`] = term;
        this.ui.loading.autocomplete = true;

        bus.$emit(`autocomplete${type}`, term);
      }, 75);
    },
    /**
     * Fetches the closest place from the Google Places API.
     * @param {string} input 
     * @returns 
     */
    async getGooglePlaces(input) {
      try {
        if (!this.google) {
          this.google = new google.maps.places.AutocompleteService();
        }
        let geoInfo = {};
        if (this.map === undefined) await this.getMapInstance();
        const coords = this.map.getCenter();
        const location = new google.maps.LatLng(coords);
        geoInfo = { location, radius: 50000 };
        const res = await this.google.getPlacePredictions({
          input,
          ...geoInfo,
        });
        return res.predictions.filter((pred) => {
          return (
            pred.types.includes("geocode") &&
            supportedCountries.includes(pred.terms.slice(-1)[0].value)
          );
        }).map(pred => {
          pred.type = 'geography';
          return pred;
        });
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    async getCoordinates(placeId) {
      try {
        if (!this.geocoder) this.geocoder = new google.maps.Geocoder();
        const response = await this.geocoder.geocode({
          placeId
        });
        if ('results' in response && response.results.length > 0) return response.results[0].geometry.location;
        return null;
      } catch (err) {
        console.log({err});
        throw err;
      }
    },
  }
};