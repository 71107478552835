<template>

<div class="live-search-container">
  <button
    class="btn live-search-btn"
    v-if="shouldRender"
  >
    <b-checkbox
      class="checkbox"
      v-model="liveSearch"
      :disabled="disabled"
    ></b-checkbox>
    <span> Search as map moves </span>

  </button>
</div>

</template>

<script>
import { syncState } from '@/utils/syncState.js';


export default {

  props: ['shouldRender', 'disabled'],
  computed: {
    ...syncState({ liveSearch: 'state.search.liveSearch|setLiveSearch' }, {context: this})
  },
  methods: {
    searchArea() {
      this.$emit('searchArea');
    }
  }

}

</script>

<style lang="scss" scoped>

.live-search-container {
  position: fixed;
  top: 2.5vh;
  right: 2.5vh;
  z-index: 1;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%) !important;

  .checkbox {
    display: inline;
  }

  .live-search-btn {
    background-color: white;
    border-radius: 6px !important;
    border: 1px solid #e3ebf6 !important;
  }

}

</style>