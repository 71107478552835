<template>
  <PlaceDetails :selectedLocation="studyLocations[index]">
    <template v-slot:study-icon>
      <div
        class="brand-img brand-avatar brand-avatar-64"
        :style="getImgContainerStyle(studyLocations[index])"
        :class="className"
        id="target"
        @mouseover="hoverHandler(true)"
        @mouseleave="hoverHandler(false)"
        @click="toggleLocation(!bool, studyLocations[index])"
      >
        <img
          :src="getCategoryAvatar(studyLocations[index])"
          :style="getImgStyle(studyLocations[index])"
        />
        <img
            :class="{'eye' : !hover || !bool}"
            :src="require('pinnacle-lib/assets/icon/white-eye.svg')"
           
          />     
        <img
            :class="{'eye' : !hover || bool}"
            :src="require('pinnacle-lib/assets/icon/white-no-eye.svg')"
            
          />
        <b-tooltip  target="target" triggers="hover" placement="right" boundary='parent' animation=false interactive=false >
            {{bool ? 'Hide Insights' : 'Show Insights' }}
        </b-tooltip>
      </div>
    </template>
    <template v-slot:study-checkbox>
      <img
        style="cursor: pointer"
        class="placesCheckboxes"
        :src="require('pinnacle-lib/assets/icon/play-check.svg')"
        id="remove-target-"
        @click="removeLocation($event, studyLocations[index])"
      />
      <b-tooltip target="remove-target-" triggers="hover" placement="top-right">
        Remove from comparison
      </b-tooltip>
    </template>
    <!-- BUTTONS -->
    <template v-slot:buttons>
      <template>
        <div class="button-container">
          <button
            class="secondary-button"
            @click="openNewInsight(studyLocations[index])"
          >
            Open Insights
          </button>
        </div>
      </template>
    </template>
  </PlaceDetails>
</template>
<script>
import PlaceDetails from "@/components/partials/PlaceDetails";
import { bus } from "@/main";

export default {
  components: {
    PlaceDetails,
  },
  data() {
    return {
      bool: true,
      hover: false,
    };
  },
  computed: {
    className() {
      if (this.bool) {
        let num = parseInt(this.index) + 1;
        return "colorBord" + num;
      } else {
        return "unSelected";
      }
    },
    boolean() {
      if (this.$route.params.index.split(",")[1] === "true") {
        return true;
      } else {
        return false;
      }
    },
    index() {
      return this.$route.params.index.split(",")[0];
    },
    studyLocations() {
      // return this.$route.path.split('/')[1] === 'preview' ? this.$sessionStore.state.study.previews : this.$sessionStore.state.study.locations;
      return this.$sessionStore.state.study.locations;
    },
    routeName() {
      return this.$route.name;
    },
    disabledIds() {
      return this.$sessionStore.state.study.disabledIds;
    },
  },
  created() {
    this.bool = this.boolean;
  },
  methods: {
    hoverHandler(state) {
      this.hover = state;
    },
    toggleLocation(val, location) {
      // if (this.bool == "false") {
      //   this.bool = true;
      // } else {
      //   this.bool = false;
      // }
      this.bool = val;
      // this.checked = !this.checked;
      const disabledIds = Object.assign({}, this.disabledIds);

      if (!val) disabledIds[location.id] = true;
      else delete disabledIds[location.id];
      this.$sessionStore.commit("setDisabledIds", disabledIds);

      // a function to emit selectedLocation to Charts to remove it
      bus.$emit("locationsListChanged", {
        selected: val,
        locationId: location.id,
      });
    },
    openNewInsight(location) {
      this.$router.push({ path: `/preview/${location.id}/charts` });
    },
    removeLocation(val, location) {
      this.addButtonShow = !this.addButtonShow;
      const newLocations = this.studyLocations.filter(
        (loc) => loc.id !== location.id
      );

      const oldIds = this.$route.params.ids.split(",");

      this.$router.push({
        params: { ids: oldIds.filter((id) => id != location.id).join(",") },
        name: "study-charts",
      });

      this.$sessionStore.commit("setLocations", newLocations);

      if (this.studyLocations.length === 0) {
        this.$router.push({ path: "/explore" });
        return;
      }

      //adding location.reload becasue charts dont have re-render functionality yet
      window.location.reload();

      //to update charts
      // bus.$emit("locationsListChanged", {
      //   selected: val,
      //   locationId: location.id,
      // });

      bus.$emit("updateMapMarkersOn");
    },
  },
};
</script>
<style lang="scss" scoped>
@function select($index) {
  @return nth($location-colors, $index);
}

// @mixin add-border($argument) {
//   $string: unquote($argument);

//   &--#{$string} {
//     position: absolute;
//     right: 30px;
//      ::v-deep
//       .custom-control-label::after {
//       border: select($argument) solid 2px !important;
//       top: 0.3125rem;
//       left: -1.875rem;
//       width: 1.25rem;
//       height: 1.25rem;
//       border-radius: 0.3rem;

//     }
//   }
// }
// .placesCheckboxes{
//     @include add-border(0)
//     @include add-border(1)
//     @include add-border(2)
//     @include add-border(4)
//     @include add-color(5)
// }

.placesCheckboxes1 {
  ::v-deep .custom-control-label::after {
    border: select(1) solid 2px !important;
    top: 0.3125rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.3rem;
  }
}
.placesCheckboxes1 {
  ::v-deep > .custom-control-input:checked ~ .custom-control-label::before {
    background-color: select(1) !important;
    border-color: select(1) !important;
  }
}
.placesCheckboxes2 {
  ::v-deep .custom-control-label::after {
    border: select(2) solid 2px !important;
    top: 0.3125rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.3rem;
  }
}
.placesCheckboxes2 {
  ::v-deep > .custom-control-input:checked ~ .custom-control-label::before {
    background-color: select(2) !important;
    border-color: select(2) !important;
  }
}
.placesCheckboxes3 {
  ::v-deep .custom-control-label::after {
    border: select(3) solid 2px !important;
    top: 0.3125rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.3rem;
  }
}
.placesCheckboxes3 {
  ::v-deep > .custom-control-input:checked ~ .custom-control-label::before {
    background-color: select(3) !important;
    border-color: select(3) !important;
  }
}
.placesCheckboxes4 {
  ::v-deep .custom-control-label::after {
    border: select(4) solid 2px !important;
    top: 0.3125rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.3rem;
  }
}
.placesCheckboxes4 {
  ::v-deep > .custom-control-input:checked ~ .custom-control-label::before {
    background-color: select(4) !important;
    border-color: select(4) !important;
  }
}
.placesCheckboxes5 {
  ::v-deep .custom-control-label::after {
    border: select(5) solid 2px !important;
    top: 0.3125rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.3rem;
  }
}
.placesCheckboxes5 {
  ::v-deep > .custom-control-input:checked ~ .custom-control-label::before {
    background-color: select(5) !important;
    border-color: select(5) !important;
  }
}
// @for $i from 1 through length($location-colors) {
//   $color: nth($location-colors, $i);
//   .detailsCardLocation:nth-child(#{$i}) {
//     ::v-deep
//       .placesCheckboxes
//       > .custom-control-input:checked
//       ~ .custom-control-label::before {
//       background-color: $color !important;
//       border-color: $color !important;
//     }
//   }
// }
// @for $i from 1 through length($location-colors) {
//   $color: nth($location-colors, $i);
//   .detailsCardLocation:nth-child(#{$i}) {
//     ::v-deep
//       .custom-control-label::after {
//       border: $color solid 2px !important;
//       top: 0.3125rem;
//       left: -1.875rem;
//       width: 1.25rem;
//       height: 1.25rem;
//       border-radius: 0.3rem;
//     }
//   }
// }

.button-container {
  width: 100%;
  // padding: 0 2rem 2rem 2rem;
  padding: 0 1.5rem 16px 1.5rem;
  display: flex;
  justify-content: space-between;
  .secondary-button {
    width: 98%;
    border-radius: 4px;
    border-width: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #2c7be5;
    color: #ffffff;
  }
  .primary-button {
    width: 49%;
    border-radius: 4px;
    border-width: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #edf2f9;
    color: #6e84a3;
  }

  .secondary-button:disabled {
    background-color: lightgray;
  }
  .primary-button-long {
    width: 100%;
    border-radius: 4px;
    border-width: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #edf2f9;
    color: #6e84a3;
  }
}
.colorBord1 {
  border: 4px solid select(1) !important;
  &:hover {
    background: rgba(22, 34, 68, 0.5) !important;
  }
}
.colorBord2 {
  border: 4px solid select(2) !important;
  &:hover {
    background: rgba(22, 34, 68, 0.5) !important;
  }
}
.colorBord3 {
  border: 4px solid select(3) !important;
  &:hover {
    background: rgba(22, 34, 68, 0.5) !important;
  }
}
.colorBord4 {
  border: 4px solid select(4) !important;
  &:hover {
    background: rgba(22, 34, 68, 0.5) !important;
  }
}
.colorBord5 {
  border: 4px solid select(5) !important;
  &:hover {
    background: rgba(22, 34, 68, 0.5) !important;
  }
}
.eye {
  display: none;
}
.unSelected {
  border: 4px solid #e3ebf6 !important;
  &:hover {
    background: rgba(22, 34, 68, 0.5) !important;
  }
}
.hoverable {
  background: rgba(22, 34, 68, 0.5) !important;
}
</style>