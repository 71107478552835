export default function createGeoJSON(polygons) {
  let geojson = { type: 'FeatureCollection', features: [] };

  polygons.forEach((polygon) => {
    let categories = [];
    if (polygon.polygon_categories !== undefined) categories = polygon.polygon_categories.map(cat => cat['name']);
    let latlong = JSON.parse(polygon.centroid).coordinates;

    let feature = {
      type: 'Feature',
      id: `${polygon.name}|${polygon.id}`,
      properties: {
        polygon_id: polygon.id,
        name: polygon.name,
        latitude: latlong[1],
        longitude: latlong[0],
        categories: categories,
        address: polygon.address
      },
      geometry: JSON.parse(polygon.geometry.coordinates)
    };
    geojson.features.push(feature);
  });

  return geojson;
}