<template>
  <ReportOptions>
    <p>Generator Seconds Before</p>
    <SecondsCalculator @submitTotalSeconds="setSeconds" :role="'generator-report'" id="input=generator-report"
      ref="secondsComponent" />
    <p class="subtext">
      This value, specific to the Generator Dataset, is much like Seconds Before & Seconds After above. This value is in
      seconds; please use the calculator tool to find the appropriate value. Any whole number greater than or equal to 0
      is accepted.
    </p>
  </ReportOptions>
</template>
<script>
import ReportOptions from "../shared/ReportOptions.vue";
import SecondsCalculator from "../SecondsCalculator.vue";
export default {
  components: { ReportOptions, SecondsCalculator },
  watch: {},
  mounted() {
    const { generatorSecondsBefore } = this.$sessionStore.state.jobRequest;
    if (generatorSecondsBefore !== undefined) {
      this.$refs.secondsComponent.setSubmitValue(generatorSecondsBefore);
    }
  },
  methods: {
    setSeconds(seconds) {
      try {
        const int = Number.parseInt(seconds) > 0;
        this.$sessionStore.commit('setGeneratorSecondsBefore', int);
      } catch {
        this.$sessionStore.commit('setGeneratorSecondsBefore', undefined);
      }
    },
  },
  beforeDestroy() {
    this.$sessionStore.commit('setGeneratorSecondsBefore', undefined);
  }
};
</script>

<style lang="scss" scoped>

</style>