<template>
  <div>
    <div class="satelite-view">
      <div class="sateliteIcons">
        <img
          class="sateliteViewImage"
          :src="thumbnail"
          alt="Satelite view"
          @click="quickSwitch"
        />

        <div class="mapIcon">
          <img
            :src="require('pinnacle-lib/assets/icon/map-icon.svg')"
            alt="Map icon"
            @click="modalShow = !modalShow"
          />
        </div>
      </div>
      <!-- to close Satelite Icons -->
    </div>
    <b-modal
      v-model="modalShow"
      id="modal-center"
      centered
      title="Basemaps"
      hide-footer
    >
      <basemaps-modal @mapUpdated="mapUpdated"></basemaps-modal>
    </b-modal>
  </div>
</template>

<script>

import {bus} from '@/main';
import BasemapsModal from '@/components/partials/BasemapsModal.vue';
import Key from '@/components/partials/Key.vue';

const lightImg = require('pinnacle-lib/assets/icon/basemap-light.svg');
const satelliteImg = require('pinnacle-lib/assets/icon/basemap-satellite.svg');
const streetImg = require('pinnacle-lib/assets/icon/basemap-streets.svg');
const darkImg = require('pinnacle-lib/assets/icon/basemap-dark.svg');
const pirateImg = require('pinnacle-lib/assets/icon/basemap-pirate.svg');

function getThumbnail(selected) {
  switch (selected) {
    case 1:
      return lightImg;
    case 2:
      return satelliteImg;
    case 3:
      return streetImg;
    case 4:
      return darkImg;
    case 5:
      return pirateImg;
    default:
      return satelliteImg;
  }
}

export default {
  components: {
    BasemapsModal,
    Key
  },
  data() {
    return {
      modalShow: false,
      thumbnail: lightImg,
      selected: 1,
      lastSelected: 2
    }
  },
  created() {
    let mapData = window.localStorage.getItem('lastSelectedMap');
    if (mapData) {
      mapData = JSON.parse(mapData);
      if ('lastSelected' in mapData) {
        this.selected = mapData.selected;
        this.lastSelected = mapData.lastSelected;
        this.thumbnail = getThumbnail(mapData.lastSelected);
      }
    } else {
      this.thumbnail = getThumbnail(this.lastSelected);
    }
  },
  methods: {
    mapUpdated({selected, lastSelected}) {
      this.selected = selected;
      this.lastSelected = lastSelected;
      const metadata = this.getMetadata();
      window.localStorage.setItem('lastSelectedMap', JSON.stringify(metadata));

      bus.$emit("newBaseMapLayerSelected", metadata);
      this.thumbnail = getThumbnail(metadata.lastSelected);
    },
    quickSwitch() {
      this.mapUpdated({selected: this.lastSelected, lastSelected: this.selected});
    },
    getMetadata() {
      let mapID;
      let baseMap;
      switch (this.selected) {
        case 1:
          // Light
          mapID = "cku4lf4wi1qn217pq9kh4w3ol";
          baseMap = "light";
          break;
        case 2:
          // Satellite
          mapID = "cku4lv16g1r3t17pjmah9fkqk";
          baseMap = "satellite";
          break;
        case 3:
          // Streets
          mapID = "cku4lw4091qyr18mxdbexkmte";
          baseMap = "streets";
          break;
        case 4:
          // Dark
          mapID = "cku4lwijx1qzy17pku57bjmz0";
          baseMap = "dark";
          break;
        case 5:
          // Pirate
          mapID = "cku4i0uy40x5s19qjrb9lu1e4";
          baseMap = "pirate";
          break;
        default:
          mapID = "cku4lf4wi1qn217pq9kh4w3ol";
          baseMap = "default";
          break;
      }
      return {mapID, baseMap, selected: this.selected, lastSelected: this.lastSelected};
    }
  },
}
</script>

<style lang="scss" scoped>
.satelite-view {
  position: absolute;
  bottom: 50px;
  left: 40px;
  background: #fff;
  padding: 5px;
  width: 114px;
  height: 105px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  img {
    height: 100%;
    width: 100%;
  }
}

.sateliteIcons {
  position: relative;
  top: 0;
  left: 0;
}

.sateliteViewImage {
  position: relative;
  top: 0;
  left: 0;
}

.mapIcon {
  position: absolute;
  top: 47px;
  left: -5px;
  height: 56px;
  width: 53px;
  border-radius: 0px 6px;
  img {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
::v-deep .modal-backdrop {
  box-shadow: inset 0 0 2000px hwb(0deg 100% 0% / 50%) !important;
  background-color: rgb(22 34 68 / 50%) !important;
  backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
}
</style>