<template>
  <div>
    <div class="section">
      <CreateHeader title="Specify details for your Datasets"
        description="Please provide a name for your delivery. You may customize the values of the other settings or leave them at their defaults." />
    </div>
    <div class="section">
      <div class="job-name">
        <p class="required">*Required</p>
        <h2 class="input-label">Name</h2>
        <b-form-input
          v-model="rawName"
          class="job-name-input"
          placeholder="Name is required"
          :state="isNameStateValid"
        ></b-form-input>
        <p class="subtext">
          Limit 75 characters. Invalid text: |\:();{}*?/'"
        </p>
      </div>
    </div>
    <div class="section" v-if="isLargeJob">
      <b-alert show>
        This job will be processed by a separate workflow that is not currently visible in the user interface.<br />You will be notified via email when the job is complete.
      </b-alert>
    </div>
    <div class="section" v-if="!isLargeJob">
      <ScreenData />
    </div>
    <div class="section" v-if="!isLargeJob">
      <AdditionalParams />
    </div>
    <div class="section" v-if="!isLargeJob">
      <Notifications />
    </div>

  </div>
</template>

<script>
import CreateHeader from './CreateHeader.vue';
import ScreenData from '../../../dataExplorer/ScreenData.vue';
import AdditionalParams from '../../../dataExplorer/AdditionalParams.vue';
import Notifications from '../../../dataExplorer/Notifications.vue';
import { syncState } from '../../../../utils/syncState';

export default {
  components: { CreateHeader, ScreenData, AdditionalParams, Notifications },
  data() {
    return {
      rawName: this.$sessionStore.state.jobRequest.reportOutputOptions.reportName
    };
  },
  beforeMount() {
    if (!this.$route.query.clone) {
      this.rawName = '';
    }
  },
  computed: {
    ...syncState({
      jobName: 'state.jobRequest.reportOutputOptions.reportName|setReportName',
      isLargeJob: "state.jobRequest.isLargeJob|setIsLargeJob"
    }, {context: this, defaultStore: '$sessionStore'}),
    isNameStateValid() {
      if (this.rawName === '') return false;
      var format = /[<>|:()&;{}*'"\/?]/;
      if (format.test(this.rawName)) {
        return false;
      } else if (this.rawName.length > 200) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    rawName() {
      if (this.isNameStateValid) this.jobName = this.rawName;
      else this.jobName = '';
    }
  }
}
</script>

<style lang="scss" scoped>
.job-name {
  width: 25%;
}
</style>