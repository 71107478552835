<template>
  <div class="main-toggle-content">
    <div>
      <ul class="insight-list borderless" id="placeChildAccordion">
        <li>
          <div class="d-flex align-items-center">
            <button
              class="
                d-flex
                align-items-center
                justify-content-between
                toggle-btn
                collapsed
              "
              data-toggle="collapse"
              data-target="#landscape"
              aria-expanded="true"
              aria-controls="landscape"
            >
              <div class="details" @click="selectInsight('nerbyPlaces')">
                <LayerIcon
                  :icon="require('pinnacle-lib/assets/icon/nearByPlaces.svg')"
                  alt="nearby-places-icon"
                  :loading="Object.keys(loading.cl).length > 0"
                  :margin="selectedMargin"
                ></LayerIcon>
                Nearby Places
                <div class="insights-arrow">
                  <img
                    :src="
                      require('pinnacle-lib/assets/icon/insights-arrow.svg')
                    "
                    alt="right-arrow"
                  />
                </div>
              </div>
            </button>
            <b-form-checkbox
              id="cl-checkbox"
              name="cl-checkbox"
              class="checkbox"
              v-model="status.cl"
              @change="toggleSelectedLayer($event, cl)"
              size="lg"
            />
          </div>
          <div
            id="landscape"
            class="toggle-content collapse active-border"
            data-parent="#placeChildAccordion"
          ></div>
        </li>

        <li>
          <div class="d-flex align-items-center">
            <button
              class="
                d-flex
                align-items-center
                justify-content-between
                toggle-btn
                collapsed
              "
              data-toggle="collapse"
              data-target="#routes"
              aria-expanded="true"
              aria-controls="routes"
            >
              <div class="details" @click="selectInsight('routes')">
                <LayerIcon
                  :icon="
                    require('pinnacle-lib/assets/icon/map-routes-large.svg')
                  "
                  alt="insight-icon"
                  :loading="Object.keys(loading.routes).length > 0"
                ></LayerIcon>
                Routes
                <div class="insights-arrow">
                  <img
                    :src="
                      require('pinnacle-lib/assets/icon/insights-arrow.svg')
                    "
                    alt="map-info"
                  />
                </div>
              </div>
            </button>
            <b-form-checkbox
              id="routes-checkbox"
              name="routes-checkbox"
              class="checkbox"
              v-model="status.routes"
              @change="toggleSelectedLayer($event, routes)"
              size="lg"
            />
          </div>
          <div
            id="routes"
            class="toggle-content collapse active-border"
            data-parent="#placeChildAccordion"
          ></div>
        </li>

        <li>
          <div class="d-flex align-items-center">
            <button
              class="
                d-flex
                align-items-center
                justify-content-between
                toggle-btn
                collapsed
              "
              data-toggle="collapse"
              data-target="#work"
              aria-expanded="true"
              aria-controls="work"
            >
              <div class="details" @click="selectInsight('wh')">
                <LayerIcon
                  :icon="require('pinnacle-lib/assets/icon/map-work-large.svg')"
                  alt="insight-icon"
                  :loading="Object.keys(loading.wh).length > 0"
                ></LayerIcon>
                Home and Work
                <div class="insights-arrow">
                  <img
                    :src="
                      require('pinnacle-lib/assets/icon/insights-arrow.svg')
                    "
                    alt="map-info"
                  />
                </div>
              </div>
            </button>
            <b-form-checkbox
              id="workhome-checkbox"
              name="workhome-checkbox"
              class="checkbox"
              @change="toggleSelectedLayer($event, wh)"
              v-model="status.wh"
              size="lg"
            />
          </div>
          <div
            id="work"
            class="toggle-content collapse active-border"
            data-parent="#placeChildAccordion"
          ></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import LayerIcon from "@/components/maps/LayerIcon.vue";
import { GeoType, VisualizationType } from "@/services/map/types";

export default {
  components: {
    LayerIcon,
  },
  props: [
    "dropdown",
    "toggleSelectedLayer",
    "updateSelectedLayer",
    "loading",
    "locations",
  ],
  data() {
    return {
      subsectors: [],
      clFiltersInitialized: false,
      search: {
        cl: "",
      },
      tags: {
        cl: [],
      },
      selectedMargin: "16px 20px 16px 0px",
    };
  },
  computed: {
    geography_levels() {
      return Object.values(GeoType);
    },
    filtered_subsectors() {
      return this.subsectors
        .filter((subsector) => {
          return subsector.name
            .toLowerCase()
            .includes(this.search.cl.toLowerCase());
        })
        .slice(0, 10);
    },
    wh_visualizations() {
      return Object.values(VisualizationType)
        .filter((val) => val.prefix === "wh")
        .map((val) => val.name);
    },
    status: {
      get() {
        return this.$sessionStore.state.studyMap.pi.status;
      },
      set(value) {
        this.$sessionStore.commit("setPIStatus", value);
      },
    },
    wh: {
      get() {
        return this.$sessionStore.state.studyMap.pi.wh;
      },
      set(value) {
        this.$sessionStore.commit("setPIWorkHome", value);
      },
    },
    cl: {
      get() {
        return this.$sessionStore.state.studyMap.pi.cl;
      },
      set(value) {
        this.$sessionStore.commit("setPICompetitiveLandscape", value);
      },
    },
    routes: {
      get() {
        return this.$sessionStore.state.studyMap.pi.routes;
      },
      set(value) {
        this.$sessionStore.commit("setPIRoutes", value);
      },
    },
  },
  methods: {
    selectInsight(insight) {
      this.$emit("insightSelected", insight);
    },
    loadLayers() {
      for (const key of Object.keys(this.status)) {
        if (this.status[key]) this.toggleSelectedLayer(true, this[key]);
      }
    },
  },
};
</script>

<style lang="scss">
.main-toggle-content {
  background-color: white;
  ul {
    height: 240px;
  }
  li {
    padding: 12px !important;
  }
  .details {
    color: #12263f;
    font-weight: 600;
    font-size: 14px;
  }
}
.cl-dropdown {
  ul {
    width: 100% !important;
    position: absolute;
  }
}

#cl-search {
  border: 1px solid #e3ebf6 !important;
  border-radius: 0 !important;
}

.cl-form-tags {
  padding: 0 !important;
  border: none !important;
}

.cl-tag {
  span {
    background-color: $blue !important;
    color: white !important;
    border-radius: 0;

    * {
      color: white !important;
    }
  }
}

.cl-dropdown {
  border-radius: 0;
  padding: 0;

  button {
    border-radius: 0 !important;
  }

  ul {
    width: 100% !important;
    border-radius: 0 !important;
  }
}

.cl-dropdown.show {
  color: white !important;
}

.insights-arrow {
  position: relative;
  min-width: 10px;
  margin-left: 10px;
}
</style>