
<template>
  <div class="crumb-container">
    <div class="crumb-trail">
      <template v-if="staticTitle === undefined">
        <a class="crumb-text" @click="closeButton()">{{ origin.title }}</a>
        <div class="caret-container"><span> > </span></div>
        <a class="crumb-text">{{ currentTitle }}</a>
      </template>
      <template v-else>
        <p class="crumb-text">{{ staticTitle }}</p>
      </template>
    </div>
      <CloseButton 
        v-if="!noClose"
        @closeButton="closeButton()" 
        noBorder="true"
      ></CloseButton>
  </div>
</template>

<script>
import CloseButton from "@/components/partials/CloseButton";
import moment from "moment";

export default {
  props: ['staticTitle', 'origin', 'currentTitle', 'noClose'],
  components: {
    CloseButton
  },
  methods: {
    closeButton() {
    //to reset dates 
    // let dateRange = {
    //   start_date: moment()
    //     .subtract(1, "year")
    //     .subtract(2, "days")
    //     .format("MM/DD/YYYY"),
    //   end_date: moment().subtract(2, "days").format("MM/DD/YYYY"),
    // };
    // this.$sessionStore.commit("setDateRange", dateRange);

      this.$sessionStore.commit("popCrumb");
      if (this.origin !== undefined && this.origin.title === "Search") 
        this.$router.push({path: '/explore/search'});
      else if (this.$route.name === 'preview-charts' || this.$route.name === 'preview-map' && this.origin.title === "Comparison") 
        this.$router.push({
          path: `/study/${this.locationIds.join(',')}/charts`,
        });
      else if (this.origin !== undefined) 
        this.$router.push(this.origin);
      else this.$emit('closeButton');
    }
  },
  computed: {
    locationIds() {
      const locations = this.$sessionStore.state.study.locations;
      const idArray = locations.map(loc => loc.id);
      return idArray;
    }
  }
}
</script>

<style lang="scss" scoped>
.crumb-container {
  background-color: white;
  display: flex;
  border-bottom: 1px solid $border-color;
  padding: 1rem 24px;
  height: 55px;
  width: 100%;

  .crumb-trail {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-self: center;
    align-self: center;
    width: 75%;

    span {
      font-size: 8px;
      color: rgba(110, 132, 163, 1);
      position: relative;
      bottom: 2px;
    }

    .crumb-text {
      color: rgba(110, 132, 163, 1);
      font-size: 14px;
      font-weight: 450;
    }

    .caret-container {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 2px 3px 0px 3px;
    }

    p {
      margin: 0;
    }

    a {
      cursor: pointer;
    }
  }
}
</style>