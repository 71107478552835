export default {
  state: {
    center: null,
    zoom: null
  },
  mutations: {
    setMapCenter: (state, payload) => (state.center = payload),
    setMapZoom: (state, payload) => (state.zoom = payload),
    clearMapState: (state) => {
      state.center = null;
      state.zoom = null;
    }
  },
};
