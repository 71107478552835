import DatasetValidator from './dataset.js';
import ReportValidator from './report.js';

export default {
  mixins: [ DatasetValidator, ReportValidator ],
  data() {
    return {
      isSubmitting: false,
      hasError: false,
      errorMsg: ''
    };
  },
  methods: {
    validateStep(namespace, step) {
      if (!this[namespace] || !this[namespace].validators || !(step in this[namespace].validators)) {
        console.error(`No validators found for ${namespace}, step ${step}!`);
        return true;
      }
      const validator = this[namespace].validators[step];
      return validator.validate.call(this);
    },
    async submitStep(namespace) {
      if (!this[namespace] || !this[namespace].submitter) {
        console.error(`No submitter found for ${namespace}`);
        return true;
      }
      const submitter = this[namespace].submitter;
      return await submitter.call(this);
    },
    async postSubmitStep(namespace, submitResponse) {
      if (!this[namespace] || !this[namespace].postSubmitter) {
        console.error(`No post-submitter found for ${namespace}`);
        return true;
      }
      const postSubmitter = this[namespace].postSubmitter;
      return await postSubmitter.call(this, submitResponse);
    }
  }
}