import { getEnvironment, getOrigin } from "../utils/environmentsUtil";

export default {
  methods: {
    async getFromURL(url, config = {}, attempt = 0, MAX_ATTEMPTS = 5) {
      try {

        if (this.tokenNeedsRefresh()) await this.refreshToken();
        
        let headers = this.getHeaders();
        headers.headers['Cache-Control'] = 'no-cache';
        headers.headers['Pragma'] = 'no-cache';
        headers.headers['Expires'] = '0';

        const { data } = await this.$http.get(url, { ...headers, ...config });
        return data;
      } catch (err) {

        if (err.response && err.response.data && err.response.data.message) {
          console.log(err.response.data);
          if (!err.response.data.success && !err.response.data.statusCode) {
            console.log("refresh route");
            this.refreshToken().then((res) => {
              if (res.success && attempt < MAX_ATTEMPTS) {
                attempt++;
                return fetch(url, attempt);
              }
              window.location.replace(getOrigin("pinnacle", getEnvironment()) + "/logout");
            });
          } else {
            this.err = err.response.data.errors[0].message;
            console.log({ err });
          }
        } else {
          console.log(err);
        }
        throw err;
      }
    }
  }
}