import { syncState } from "@/utils/syncState.js";
import distance from "@turf/distance";
import { bus } from "@/main";
import { compareTwoStrings } from "string-similarity";
import { SearchMixin } from './search.js';
import { AutocompleteMixin } from "./autocomplete.js";
import { emitWithPromise } from '@/utils/emitWithPromise';

export const CommonSearchMixin = {
  mixins: [AutocompleteMixin, SearchMixin],
  data() {
    return {
      selectedLocation: null,
      fittingBounds: false,
      moveRequested: false,
      map: undefined,
      selected: {
        geoSuggestion: undefined,
        searchSuggestion: undefined,
      },
      ui: {
        activeElement: undefined,
        searchResultHighlighted: null,
        loading: {
          search: false,
          autocomplete: false,
        },
        searchInputIds: ["location-marker", "searchInput", "find-close"],
        // geoInputIds: ["map-icon", "geoInput", "near-close"],
        focusedElement: null
      },
    };
  },
  created() {
    document.addEventListener("focusin", this.focusChanged);
   
    bus.$on(
      "getLocations",
      (
        locations,
        hasMoreLocations,
        page,
        concat,
        moveOnly,
        foundLocation
      ) => {

        const helper = () => {
          if (!concat) this.search.results = locations;
          else {
            const currentIds = this.search.results.map((result) => result.id);
            this.search.results = [
              ...this.search.results,
              ...locations.filter((loc) => {
                return !currentIds.includes(loc.id);
              }),
            ];
          }

          const mapCenter = this.map.getCenter().toArray();
          //if (!this.showNearbyPlaces) 
          this.mapMoved = false;

          this.search.results.sort((a, b) => {
            const aCoords = a.centroid.coordinates;
            const bCoords = b.centroid.coordinates;
            return distance(aCoords, mapCenter) - distance(bCoords, mapCenter);
          });

          this.ui.loading.search = false;
          this.search.hasMoreLocations = hasMoreLocations;
          this.search.currentPage = page;

          if (locations.length > 0 && this.search.term !== "") {
            // Save the search term
            if (
              !this.recentLocationSearches.includes(this.search.term) &&
              this.search.term !== ""
            ) {
              this.recentLocationSearches = [this.search.term].concat(
                this.recentLocationSearches
              );
            }
          }

//          if (foundLocation) {
//            if (
//              !this.recentGeoSearches.includes(this.search.geo.term) &&
//              this.search.geo.term !== "" &&
//              this.search.geo.term !== 'Map Area'
//            ) {
//              this.recentGeoSearches = [this.search.geo.term].concat(
//                this.recentGeoSearches
//              );
//            }
//          }
        }

        if (!this.map) this.getMapInstance().then(() => helper());
        else helper();

        return true;
      }
    );

    bus.$on("mapIconClicked", (evt) => this.viewDetail(evt));
    bus.$on("mapIconMouseenter", (evt) => this.hoverSearchResult({ locationId: evt, state: true }));
    bus.$on("mapIconMouseleave", (evt) => this.hoverSearchResult({ locationId: evt, state: false }));
    bus.$on("fitBounds", () => this.fittingBounds = true );
    bus.$on("searchMapLoaded", (map) => this.map = map );
  },
  mounted() {
    //auto-suggest

    bus.$on("focusedChanged", (focused) => {
      this.ui.focusedElement = focused;
    });

    bus.$on("closeSuggestions", () => {
      this.focusChanged();
    });
  },
  beforeDestroy() {
    document.removeEventListener("focusin", this.focusChanged);
  },
  watch: {
    $route(to, from) {
      // TODO: IS this ever called???
      this.$sessionStore.commit("setQueryParams", this.$route.query);
    },
    focusedElement() {
      if (this.searchSuggestions.length > 0 && this.ui.focusedElement >= 0) {
        if (typeof this.searchSuggestions[this.ui.focusedElement] === "object") {
          let suggestionObj = this.searchSuggestions[this.ui.focusedElement].value;
          this.selected.searchSuggestion = suggestionObj;
        } else {
          this.selectedTerm = this.searchSuggestions[this.ui.focusedElement];
        }
      } else if (this.geoSuggestions.length > 0 && this.ui.focusedElement >= 0) {
        if (typeof this.geoSuggestions[this.ui.focusedElement] === "object") {
          let geoObj = this.geoSuggestions[this.ui.focusedElement].description;
          this.selected.geoSuggestion = geoObj;
        } else {
          this.selectedGeo = this.geoSuggestions[this.ui.focusedElement];
        }
      }
      this.ui.focusedElement = -1;
    },
  },
  computed: {
    studyLocations() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split("/")[1] === "preview"
        ? this.$sessionStore.state.study.previews
        : this.$sessionStore.state.study.locations;
    },
    ...syncState(
      {
        search: "state.search.searchState|setSearchState",
        query: "state.search.query",
        liveSearch: 'state.search.liveSearch|setLiveSearch',
        mapMoved: 'state.search.mapMoved|setMapMoved'
      },
      { context: this, defaultStore: "$sessionStore" }
    ),
    ...syncState(
      {
        recentGeographies:
          "state.recentSearches.geographies|setRecentGeographies",
        recentResults: "state.recentSearches.results|setRecentResults",
        recentCategories: "state.recentSearches.categories|setRecentCategories",
        recentLocationSearches:
          "state.recentSearches.terms.location|setRecentLocationSearches",
        recentGeoSearches:
          "state.recentSearches.terms.geo|setRecentGeoSearches",
      },
      { context: this, defaultStore: "$persistingStore" }
    ),
    resultsEmpty() {
      return this.search.results.length === 0;
    },
    routeEmpty() {
      return Object.keys(this.$route.query).length === 0;
    },
    renderSearchAreaButton() {
      return (
        ("filter" in this.$route.query ||
          "search_term" in this.$route.query || 
          "return_results" in this.$route.query) &&
        this.mapMoved
      );
    },
    studyEmpty() {
      return this.studyLocations.length === 0;
    },
    isInitialState() {
      return (
        this.resultsEmpty &&
        this.autocompleteEmpty &&
        this.search.term === "" &&
        this.search.geo.term === "" &&
        this.studyEmpty
      );
    },
    geoSuggestions() {
      if (
        this.search.geo.term === "" &&
        this.ui.activeElement !== undefined &&
        // this.geoInputIds.includes(this.activeElement.id)
        this.ui.activeElement.id === "geoInput"
      ) {
        return this.recentGeoSearches;
      } else if (
        this.search.geo.term !== "" &&
        this.ui.activeElement !== undefined &&
        // this.geoInputIds.includes(this.activeElement.id)
        this.ui.activeElement.id === "geoInput"
      ) {
        // Filter and sort recent searches
        const recents = this.recentGeoSearches
          .filter((term) => {
            return term
              .toLowerCase()
              .includes(this.search.geo.term.toLowerCase());
          })
          .sort((a, b) => {
            // Sort based on no. of matching letters
            const aDiff = Math.abs(this.search.geo.term.length - a.length);
            const bDiff = Math.abs(this.search.geo.term.length - b.length);
            if (aDiff < bDiff) return -1;
            return 1;
          });

        // Remove conflicting google places
        const places = this.typeahead.googlePlaces.filter((place) => {
          return !recents.includes(place.description);
        });

        return recents.concat(places);
      }
      return [];
    },
    searchSuggestions() {
      if (
        this.search.term === "" &&
        this.ui.activeElement !== undefined &&
        this.ui.searchInputIds.includes(this.ui.activeElement.id)
      ) {
        return this.recentLocationSearches;
      } else if (
        this.search.term !== "" &&
        this.ui.activeElement !== undefined &&
        this.ui.searchInputIds.includes(this.ui.activeElement.id)
      ) {
        const categoryLUT = this.typeahead.categories.reduce(
          (prev, current) => ({
            ...prev,
            [current.value.toLowerCase().trim()]: true,
          }),
          {}
        );
        const resultsLUT = this.typeahead.results.reduce(
          (prev, current) => ({
            ...prev,
            [current.name.toLowerCase().trim()]: true,
          }),
          {}
        );
        const searchTerm = this.search.term.toLowerCase().trim();

        const recents = this.recentLocationSearches.filter((term) => {
          const parsed = term.toLowerCase().trim();
          const isInCategories = categoryLUT[parsed];
          const isInResults = resultsLUT[parsed];
          return (
            term.toLowerCase().includes(searchTerm) &&
            !isInCategories &&
            !isInResults
          );
        });

        const suggestions = recents
          .concat(this.typeahead.categories)
          .concat(this.typeahead.results);

        suggestions.sort((a, b) => {
          let aName =
            typeof a === "string" ? a : a.name || a.shortenedName || a.value;
          aName = aName.toLowerCase().trim();
          const aScore = compareTwoStrings(searchTerm, aName);
          let bName =
            typeof b === "string" ? b : b.name || b.shortenedName || b.value;
          bName = bName.toLowerCase().trim();
          const bScore = compareTwoStrings(searchTerm, bName);
          return bScore - aScore;
        });

        return suggestions;
      }
      return [];
    },
  },
  methods: {
    /**
     * Updates the route query params based on the params sent in.
     * If the current query params don't match, the search params will overwrite the old params.
     * @param {{}} searchParams The search parameters passed to the API.
     * @returns {Promise<boolean>} Returns a promise to signify completion.
     */
    setQueryParams(searchParams) {
      let needsUpdating = false;
      if (
        Object.keys(searchParams).length !==
        Object.keys(this.$route.query).length
      )
        needsUpdating = true;
      else {
        for (const key in searchParams) {
          if (!(key in this.$route.query)) {
            needsUpdating = true;
            break;
          } else {
            if (searchParams[key] !== this.$route.query[key]) {
              needsUpdating = true;
              break;
            }
          }
        }
      }

      if (needsUpdating) {
        for (const key in searchParams) {
          const val = searchParams[key];
          if (typeof val === 'object' || Array.isArray(val)) {
            searchParams[key] = JSON.stringify(val);
          }
        }
        return new Promise((resolve) => {
          setTimeout(() => {
            this.$router.push({
              path: "/explore/search",
              query: { ...searchParams },
            }).catch(_ => {});
            resolve(true);
          }, 0);
        });
      } else return Promise.resolve(false);
    },
    /**
     * Returns true if the active search input has suggestions.
     * @param {*} type 
     * @returns {boolean}
     */
    hasTypeahead(type) {
      const check =
        type === "category" ? this.searchSuggestions : this.geoSuggestions;
      return check.length > 0;
    },
    /**
     * Used to set the activeElement variable when the UI focus changes.
     * @param {*} evt 
     */
    focusChanged(evt) {
      if (!evt) this.ui.activeElement = undefined;
      else this.ui.activeElement = evt.target;
    },
    /**
     * Handler for the click event on the search area button.
     */
    async onSearchArea() {
      this.mapMoved = false;
      this.emitSearchBbox(undefined, 5000);
    },
    /**
     * Will emit an event to load more locations
     * if the previous search returned hasMoreLocations: true;
     */
    submitLoadMore() {
      if (this.search.hasMoreLocations) {
        const search = { ...this.$route.query };
        if (Object.keys(search).length > 0) {
          bus.$emit("loadMoreLocations", {
            search,
            page: this.search.currentPage + 1,
          });
        }
      }
    },
    /**
     * @returns {string} Bounds of the user viewport
     */
    async getBounds() {
      try {
        if (this.map === undefined) await this.getMapInstance();
        const bounds = this.map.getBounds();
        const tl = bounds.getNorthWest();
        const br = bounds.getSouthEast();
        return `${tl.lng},${tl.lat},${br.lng},${br.lat}`;
      } catch (err) {
        console.error("Map instance is undefined");
        console.log(err);
      }
    },
    /**
     * Fetches the map instance from SearchMap.vue.
     * @param {number} [timeout=5000] The time to wait for the instance to be returned in ms.
     * @returns {Promise<mapboxgl.Map>} The Mapbox instance.
     * @throws {Promise<Error>}
     */
    async getMapInstance(timeout = 5000) {
      if (this.map !== undefined) return this.map;
      const map = await emitWithPromise({
        bus,
        event: "getSearchMapInstance",
        callbackEvent: "returnSearchMapInstance",
        timeout,
      });
      this.map = map;
      return map;
    },
    /**
     * Resets the UI to the initial state.
     */
    resetToInitial() {
      this.setQueryParams({});
      this.search.term = "";
      this.search.geo.term = "";
      this.search.results = [];
      this.typeahead.categories = [];
      this.typeahead.googlePlaces = [];
      bus.$emit("clearMarkers");
      this.$sessionStore.commit("clearSearchState", []);
      if (this.resetSearchLayer !== undefined && typeof this.resetSearchLayer === 'function') this.resetSearchLayer();
    },
    /**
     * Resets the "Find" input element.
     */
    resetFindInput() {
      this.search.term = "";
      this.typeahead.categories = [];
      this.typeahead.results = [];
      if (this.$refs.searchInput) {
        setTimeout(() => {
          this.$refs.searchInput.focus();
        }, 100);
      }
      // bus.$emit("repopulateMarkers", { results: this.search.results });
    },
    resetGeoInput() {
      this.search.geo.term = '';
      this.typeahead.geographies = [];
      if (this.$refs.geoInput) {
        setTimeout(() => {
          this.$refs.geoInput.focus();
        }, 100);
      }
    },
    /**
     * Search input handler.
     * @returns {undefined}
     */
    onSearchInput() {
      this.search.submitted = false;
      this.mapMoved = false;
      if (this.search.term === "") return;
      this.emitAutocomplete(this.search.term, "Category");
    },
    /**
     * Geography input handler.
     * @returns {undefined}
     */
    onGeoInput() {
      this.search.submitted = false;
      this.mapMoved = false;
      if (this.search.geo.term === "") return;
      this.emitAutocomplete(this.search.geo.term, "Geo");
    },
    /**
     * Enter key handler.
     * @returns {undefined}
     */
    onEnter(inputType) {

      if (this.suggestion !== undefined) this.emitSearchSuggestion(this.suggestion, inputType);
      else this.emitSearch({search_term: this.search.term, geo_term: this.search.geo.term});

    },
    /**
     * Clears the typeahead box, either 'find' or anything else for 'near'.
     * If type if not specified, it will clear both.
     * @param {*} type 
     */
    clearTypeahead(type = undefined) {
      setTimeout(() => {
        if (type !== undefined) {
          if (type === "find") {
            this.typeahead.categories = [];
            this.typeahead.results = [];
          } else {
            this.typeahead.googlePlaces = [];
          }
        } else {
          this.typeahead.categories = [];
          this.typeahead.results = [];
          this.typeahead.googlePlaces = [];
        }
      }, 0);
      bus.$emit("clearFocused");
    },
    /**
     * Changes the router view to show the location details in the left pane.
     * @param {*} result The location.
     * @param {boolean} single_marker If true, it will repopulate the markers with a single marker.
     */
    viewDetail(result, single_marker) {
      if (typeof single_marker !== "undefined" && single_marker) {
        bus.$emit("repopulateMarkers", { results: [result] });
      }
      if (typeof result.centroid === "string")
        result.centroid = JSON.parse(result.centroid);

      bus.$emit("fitMarker", result.centroid.coordinates);
      const encodedPlace = btoa(JSON.stringify(result));
      const query = { place: encodedPlace };
      if (this.recentResults.find((el) => el.id === result.id) === undefined) {
        const recentResult = Object.assign({}, result);
        recentResult.recent = true;
        this.recentResults = [recentResult].concat(this.recentResults);
      }
      this.$router.push({
        path: "/explore/details",
        query,
      });
    },
    /**
     * Handler for hovering over a search result.
     * @param {*} param0 
     * @returns 
     */
    hoverSearchResult({ locationId, state }) {
      if (!$) return;
      $(".location-pin").removeClass("result-highlight");
      if (state) {
        this.ui.searchResultHighlighted = locationId;
        $(".location-pin-" + locationId).addClass("result-highlight");
      } else {
        this.ui.searchResultHighlighted = null;
      }
    },
    /**
     * Scroll handler, it will attempt to load more locations
     * when the user hits the bottom of the search results list.
     * @param {*} opts
     */
    checkScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.submitLoadMore();
      }
    },
  },
};