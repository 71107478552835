<template>
  <div>
    <div class="section">
      <CreateHeader :useRouteMeta="true"></CreateHeader>

      <LargeJobWarning type="Reports" />
    </div>
    <div class="section">
      <div class="report-name">
        <p class="required">*Required</p>
        <h2 class="input-label">Name</h2>
        <b-form-input v-model="rawName" class="job-name-input" placeholder="Name is required"
          :state="isNameStateValid"></b-form-input>
        <p class="subtext">
          Limit 75 characters. Invalid text: |\:();{}*?/'"
        </p>
      </div>
    </div>
    <div class="section">
      <div class="report-type">
        <p class="required">*Required</p>
        <h2 class="input-label">Template</h2>
        <b-dropdown :text="templateText" class="filter-control status-dropdown">
          <b-dropdown-item @click="selectTemplate(template)" v-for="template in templates" :key="template.id">
            {{ template.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import CreateHeader from './CreateHeader.vue';
import CardUtil from '../../../../services/cards/cardUtil.js';
import { syncState } from '../../../../utils/syncState';
import LargeJobWarning from '@/components/dataExplorer/shared/LargeJobWarning.vue';

export default {
  mixins: [CardUtil],
  components: { CreateHeader, LargeJobWarning },
  data() {
    return {
      rawName: this.$sessionStore.state.jobRequest.reportOutputOptions.reportName,
      selectedTemplate: this.$sessionStore.state.jobRequest.template
    };
  },
  created() {
    this.provisionTemplates().then(() => {
      this.selectedTemplate = this.defaultTemplate;
      this.$sessionStore.commit('setReportTemplate', this.selectedTemplate);
    });
  },
  beforeMount() {
    if (!this.$route.query.clone) {
      this.rawName = '';
    }
  },
  watch: {
    rawName() {
      if (this.isNameStateValid) this.jobName = this.rawName;
      else this.jobName = '';
    }
  },
  computed: {
    defaultTemplate() {
      if (this.templates.length === 0) {
        return this.$sessionStore.state.jobRequest.template;
      }
      const def = this.templates.find(template => {
        return template.default === 1;
      });
      if (def !== undefined) return def;
      else return this.$sessionStore.state.jobRequest.template;
    },
    templateText() {
      if (!this.selectedTemplate) return "Select a report template";
      else return this.selectedTemplate.name;
    },
    ...syncState({
      jobName: 'state.jobRequest.reportOutputOptions.reportName|setReportName'
    }, { context: this, defaultStore: '$sessionStore' }),
    isNameStateValid() {
      if (this.rawName === '') return false;
      var format = /[<>|:()&;{}*'"\/?]/;
      if (format.test(this.rawName)) {
        return false;
      } else if (this.rawName.length > 200) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    selectTemplate(template) {
      this.selectedTemplate = template;
      this.$sessionStore.commit('setReportTemplate', template)
    }
  }
}
</script>

<style lang="scss" scoped>
.report-type,
.report-name {
  width: 25%;
}
</style>