<template>
  <div>
    <h3 style="margin-top: 20px">
      Custom Context
      <img
        id="tooltip-target-1"
        v-b-tooltip.hover
        :src="require('pinnacle-lib/assets/icon/info.svg')"
      />
    </h3>
    <div class="text">
      <b-textarea
        id="GEOJSON"
        name="GEOJSONn"
        rows="5"
        cols="60"
        :placeholder="`Add custom context GeoJSON here. If left blank, ${ Constants.company_name }'s POI polygon set will be used for context instead.`"
        :state="jState"
        v-model="json"
        @change="jsonValidate()"
      >
      </b-textarea>
    </div>
    <b-tooltip
      custom-class="longTooltip"
      target="tooltip-target-1"
      triggers="hover"
      placement="right"
    >
      <div>
        <img :src="require('pinnacle-lib/assets/icon/info.svg')" /> Custom
        Context Help
      </div>
      <br />
      <p>
        In the option field below, enter a geojson that contains custom context
        POI polygons. Then, if a visitor to the study location(s) enters these
        context POI polygons on their way to or from the study locations (within
        the time period), the final dataset will be appended with the
        information from this geoJSON file. The entry should be standard geoJSON
        format, but with the following enhancements:
      </p>
      <div style="justify-content: start !important">
        <ul>
          <li>
            use properties.polygon_id to populate the polygon_id column in the
            final dataset.
          </li>
          <li>use properties.name to populate the polygon_name column.</li>
          <li>
            use properties.category with a string or properties.categories with
            an array of strings to populate the polygon_category column.
          </li>
        </ul>
      </div>
      <br />
      <p style="font-style: italic">
        Note: if neither name or polygon_id are present, the name and id column
        will default to whatever is in features.id.
      </p>
      <br />
      <p>
        Click
        <a href="pinnacle-lib/assets/js/intersections.js" download
          >here</a
        >
        to download an example geoJSON.
      </p>
    </b-tooltip>
  </div>
</template>
<script>
import { ConstantsService } from "@/services/constants.js";
export default {
  mixins: [ConstantsService],
  data() {
    return {
      jState: null,
      json: this.$sessionStore.state.jobRequest.contextPoiGeojson || "",
    };
  },
  methods: {
    jsonValidate() {
      try {
        //parse the file before entering it as a var
        let text = this.json.trim();
        let parsed = JSON.parse(text);
        // let result = JSON.stringify(parsed, null, "\t")
        this.$sessionStore.commit("setPOIJSON", parsed);
        this.jState = true;
      } catch (err) {
        console.log(err);
        this.jState = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .tooltip-inner {
  max-width: 500px !important;
}
</style>