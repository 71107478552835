
/**
 * Saves a user to localStorage store. Anything provided that we have mapped to the store will be saved.
 * @param {*} user The user data.
 */
export function saveUser(response) {
  const { accessToken, refreshToken, expired, permissions, company, userData  } = response;
  if (accessToken !== undefined) localStorage.setItem("accessToken", accessToken);
  if (refreshToken !== undefined) localStorage.setItem("refreshToken", refreshToken);
  if (expired !== undefined) localStorage.setItem('_e', JSON.stringify(expired));
  if (permissions !== undefined)  localStorage.setItem('_p', JSON.stringify(permissions));
  if (company !== undefined)  localStorage.setItem('_c', JSON.stringify(company));
  if (userData !== undefined) {
    localStorage.setItem("_ud", JSON.stringify(userData));
  }
} 