<template>
  <!-- <b-collapse
    v-model="dropdown"
    id="communityInsightLayers"
    class="main-toggle-content"
  > -->
  <div class="main-toggle-content">
    <div>
      <ul class="insight-list" accordion="childAccordion">
        <li>
          <div class="d-flex align-items-center">
            <button
              class="
                d-flex
                align-items-center
                justify-content-between
                toggle-btn
              "
              :class="localDropdown.pd ? '' : 'collapsed'"
              :aria-expanded="localDropdown.pd ? 'true' : 'false'"
              @click="localDropdown.pd = !localDropdown.pd"
            >
              <div class="details" @click="selectInsight('pd')">
                <layer-icon
                  :icon="require('pinnacle-lib/assets/icon/map-tod-large.svg')"
                  alt="insight-icon"
                  :loading="Object.keys(loading.pd).length > 0"
                ></layer-icon>
                Activity
                <div class="insights-arrow">
                  <img
                    :src="require('pinnacle-lib/assets/icon/insights-arrow.svg')"
                    alt="right-arrow"
                  />
                </div>
              </div>
            </button>
            <b-checkbox
              id="tod-radio"
              name="tod-radio"
              class="radio"
              @change="toggleLayer($event, pd)"
              v-model="status.pd"
              size="lg"
            >
            </b-checkbox>
          </div>
          <!-- <b-collapse
            class="toggle-content active-border"
            accordion="childAccordion"
            v-model="localDropdown.pd"
          > -->
          <!-- <div id="time-of-day-trend"> -->
          <!-- <div class="input-container">
                <label> Quarter(s) </label>
                <select
                  @change="updateSelectedLayer(pd)"
                  name="quarter_options"
                  v-model="pd.api_params.quarter"
                >
                  <template v-for="option in quarter_options">
                    <option :value="option.value" :key="option.value">
                      {{ option.label }}
                    </option>
                  </template>
                </select>
              </div> -->
          <!-- <div class="input-container">
                <label> Day(s) of Week </label>
                <TODDayGrid @newSelection="updateTODDay"></TODDayGrid>
              </div> -->

          <!-- TIME SELECTION -->

          <!-- <div class="input-container">
                <label> Hour(s) </label>
                <TODTimeGrid @newSelection="updateTODTime"></TODTimeGrid>
              </div> -->
          <!-- </div> -->
          <!-- </b-collapse> -->
        </li>
        <li>
          <div class="d-flex align-items-center">
            <button
              class="
                d-flex
                align-items-center
                justify-content-between
                toggle-btn
              "
              :class="localDropdown.gt ? null : 'collapsed'"
              :aria-expanded="localDropdown.gt ? true : false"
              @click="localDropdown.gt = !localDropdown.gt"
            >
              <div class="details" @click="selectInsight('gt')">
                <layer-icon
                  :icon="require('pinnacle-lib/assets/icon/map-info-large.svg')"
                  alt="insight-icon"
                  :loading="Object.keys(loading.gt).length > 0"
                ></layer-icon>
                Popularity
                <div class="insights-arrow">
                  <img
                    :src="require('pinnacle-lib/assets/icon/insights-arrow.svg')"
                    alt="right-arrow"
                  />
                </div>
              </div>
            </button>
            <b-checkbox
              id="gt-radio"
              name="gt-radio"
              class="radio"
              @change="toggleLayer($event, gt)"
              v-model="status.gt"
              size="lg"
            ></b-checkbox>
          </div>
        </li>
      </ul>
    </div>
    <!-- </b-collapse> -->
  </div>
</template>

<script>
import axios from "axios";

import LayerIcon from "@/components/maps/LayerIcon";

export default {
  props: ["dropdown", "toggleSelectedLayer", "updateSelectedLayer", "loading"],
  components: {
    LayerIcon,
    // ColorPill,
    // TODTimeGrid,
    // TODDayGrid,
  },
  data() {
    return {
      debounce: {
        pd: undefined,
      },
      quarter_options: [{ label: "All", value: "all" }],
    };
  },
  created() {
    this.loadQuarterOptions();
  },
  computed: {
    month_options() {
      return [
        { label: "6 months", value: "6" },
        { label: "12 months", value: "12" },
        { label: "18 months", value: "18" },
      ];
    },
    localDropdown: {
      get() {
        return this.$sessionStore.state.studyMap.ci.localDropdown;
      },
      set(value) {
        this.$sessionStore.commit("setCILocalDropdown", value);
      },
    },
    status: {
      get() {
        return this.$sessionStore.state.studyMap.ci.status;
      },
      set(value) {
        this.$sessionStore.commit("setCIStatus", value);
      }
    },
    statusPD: {
      get() {
        return this.$sessionStore.state.studyMap.ci.status.pd;
      },
      set(value) {
        this.$sessionStore.commit("setCIStatus", value);
      },
    },
    statusGT: {
      get() {
        return this.$sessionStore.state.studyMap.ci.status.gt;
      },
      set(value) {
        this.$sessionStore.commit("setCIStatus", value);
      }
    },
    pd: {
      get() {
        return this.$sessionStore.state.studyMap.ci.pd;
      },
      set(value) {
        this.$sessionStore.commit("setCIPulseDaypart", value);
      },
    },
    gt: {
      get() {
        return this.$sessionStore.state.studyMap.ci.gt;
      },
      set(value) {
        this.$sessionStore.commit("setCIGrowthTrends", value);
      },
    },
  },
  methods: {
    selectInsight(insight) {
      this.$emit("insightSelected", insight);
    },
    loadLayers() {
      for (const key of Object.keys(this.status)) {
        if (this.status[key]) this.toggleLayer(true, this[key]);
      }
    },
    updateTODTime(selection) {
      this.pd.api_params.time_of_day = selection;
      this.updateTOD();
    },
    updateTODDay(selection) {
      this.pd.api_params.day_of_week = selection;
      this.updateTOD();
    },
    updateTOD() {
      if (this.debounce.pd) {
        clearTimeout(this.debounce.pd);
        delete this.debounce.pd;
      }
      this.debounce.pd = setTimeout(() => {
        this.updateSelectedLayer(this.pd);
      }, 500);
    },
    toggleLayer(evt, selection) {
      for (const prefix of Object.keys(this.status)) {
        if (prefix !== selection.prefix) this.status[prefix] = false;
      }
      this.toggleSelectedLayer(evt, selection);
    },
    async loadQuarterOptions() {
      try {
        let headers = this.getHeaders();

        const { data } = await axios.get(
          `${process.env.VUE_APP_PINNACLE_API}/v1/pulse-daypart-options`,
          {
            ...headers,
          }
        );
        this.quarter_options = data.records;
      } catch (err) {
        if (
          err &&
          err.reponse &&
          err.response.data &&
          err.response.data.message
        ) {
          console.log(err.response.data);
          if (!err.response.data.success && !err.response.data.statusCode) {
            console.log("refresh route");
            this.refreshToken().then((res) => {
              if (res.success && attempt < 5) {
                attempt++;
                //this can be re-enginerred to not be recursive, but I added a return in the other function just in case
                this.loadQuarterOptions();
              } else {
                ///this should push the user to login page to grab a new refresh token
              }
            });
          } else {
            this.err = err.response.data.errors[0].message;
            console.log({ err });
          }
        } else {
          console.log(err);
        }
      }
    },
  },
  watch: {
    statusPD() {
      if (this.statusPD) {
        this.$sessionStore.commit("setGTStatus", false);
      }
    },
    statusGT() {
      if (this.statusGT) {
        this.$sessionStore.commit("setPDStatus", false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-toggle-content {
  background-color: white;
  height: 160px;
}
.insights-arrow {
  position: relative;
  min-width: 10px;
  margin-left: 10px;
  // .tooltip-txt {
  //   visibility: hidden;
  //   width: 200px;
  //   background-color: #e3ebf6;
  //   color: black;
  //   text-align: center;
  //   border-radius: 6px;
  //   padding: 5px 0;
  //   box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.3);
  //   position: absolute;
  //   z-index: 10;
  //   top: calc(100% + 10px);
  //   right: -100px;
  // }
  // &:hover .tooltip-txt {
  //   visibility: visible;
  // }
}
</style>