<template>
  <tr>
    <th scope="row">
      <div class="d-flex justify-content-center">
        <input 
          type="checkbox" 
          id="monday" 
          value=1 
          v-model="daysOfWeek" 
        />
      </div>
    </th>
    <td>
      <div class="d-flex justify-content-center">
        <input 
          type="checkbox" 
          id="tuesday" 
          value=2 
          v-model="daysOfWeek" 
        />
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-center">
        <input 
          type="checkbox" 
          id="wednesday" 
          value=3 
          v-model="daysOfWeek" 
        />
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-center">
        <input 
          type="checkbox" 
          id="thursday" 
          value=4 
          v-model="daysOfWeek" 
        />
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-center">
        <input 
          type="checkbox" 
          id="friday" 
          value=5 
          v-model="daysOfWeek" 
        />
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-center">
        <input 
          type="checkbox" 
          id="saturday" 
          value=6 
          v-model="daysOfWeek" 
        />
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-center">
        <input 
          type="checkbox" 
          id="sunday" 
          value=0
          v-model="daysOfWeek" 
        />
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-center">
        <select v-model="startTime">
          <option v-for="time in getTimeOptions" :value="time.value" :key="`start${time.id}`">
            {{ time.value }}
          </option>
        </select>
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-center">
        <select v-model="endTime">            
          <option v-for="time in getTimeOptions" :value="time.value" :key="`end${time.id}`">
            {{ time.value }}
          </option></select>
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-center">
        <inline-svg
          :src="require('pinnacle-lib/assets/icon/x-icon.svg')"
          class="close-icon"
          v-b-modal="dayPartId"
        />
        <b-modal
          :id="dayPartId"
          title="Are you sure?"
          ok-title="Yes"
          header-class="modal-title"
          content-class="modal-content"
          @ok="destroyDayPart"
          centered
        >
          Are you sure you want to remove this Day Part?
        </b-modal>
      </div>
    </td>
  </tr>
</template>

<script>
import { bus } from '@/main';

export default {
  data() {
    const daysOfWeek = this.presets.daysOfWeek;
    const startTime = this.presets.startTime.slice(0, 5);
    const endTime = this.presets.endTime.slice(0, 5);
    return {
      daysOfWeek: [...daysOfWeek],
      startTime: startTime,
      endTime: endTime,
    }
  },
  props: ['presets'],
  methods: {
    destroyDayPart() {
      // emits event that removes this dayPart from the timeframe data and DOM
      bus.$emit('destroyDayPart', this.$vnode.key);
    },
  },
  computed: {
    dayPart() {
      // the entire dayPart object, sent anytime a dayPart is updated
      return { 
        daysOfWeek: this.daysOfWeek,
        startTime: this.startTime,
        endTime: this.endTime,
        id: this.$vnode.key,
       }
    },
    dayPartId() {
      // generates unique id for each dayPart component, specifically for modal that removes this dayPart
      return `day-part-id-${this.$vnode.key}`
    },
    getTimeOptions() {
      // used to populate dropdowns with every half hour between 00:00 and 23:30
      // provides the value and a unique id to avoid different dayPart components interacting with each other
      const timeOptions = [];
      for (let i = 0; i < 24; i++) {
        if (i.toString().length < 2) {
          timeOptions.push({ 
            value: `0${i.toString()}:00`,
            id: `${this.$vnode.key}-${i}a` 
          })
          timeOptions.push({
            value: `0${i.toString()}:30`,
            id: `${this.$vnode.key}-${i}b`
          })
        } else {
          timeOptions.push({
            value: `${i.toString()}:00`,
            id: `${this.$vnode.key}-${i}a`
          })
          timeOptions.push({ 
            value: `${i.toString()}:30`, 
            id: `${this.$vnode.key}-${i}b` 
          })
        }
      }
      return timeOptions;
    },
    validTimes() {
      // validates whether or not the start time is before the end time
      return this.startTime < this.endTime
    },
    validDates() {
      // validates whether the daysOFWeek array is empty or not
      return this.daysOfWeek.length > 0;
    },
    times() {
      // links start and end times together so watcher can track both simultaneously
      const { startTime, endTime } = this;
      return {
        startTime,
        endTime
      }
    },
  },
  watch: {
    dayPart() {
      // emits an event that updates this dayPart in Timeframe
      bus.$emit('updateDayPart', this.dayPart);

      if (this.validDates) {
        // removes invalid date message for this dayPart instance
        bus.$emit('validDayPart', 'Each Day Part must have at least one day checked');
      }

      if (this.validTimes) {
        // removes invalid times message for this dayPart instance
        bus.$emit('validDayPart', 'Day Part start times must be before end times');
      }

      if (this.validDates && this.validTimes) {
        // emits an event that will indicate if the dayPart object is valid for submission
        return bus.$emit('validDayPart', this.dayPart);
      } 

      if (!this.validDates) {
        // print at least one box must be checked
        bus.$emit('invalidDayPart', 'Each Day Part must have at least one day checked');
      }
      
      if (!this.validTimes) {
        // print start time must be before end time
        bus.$emit('invalidDayPart', 'Day Part start times must be before end times');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  border: 1px solid black;
  width: 100%;
  height: 0;
}
.close-icon {
  cursor: pointer;
}  
::v-deep .modal-backdrop {
  box-shadow: inset 0 0 2000px hwb(0deg 100% 0% / 50%) !important;
  background-color: rgb(22 34 68 / 50%) !important;
  backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
}
</style>