import axios from "axios";
import { VisualizationType } from "@/services/map/types";
const { CancelToken } = axios;
import { forEach } from 'lodash';
import { getHeaders, tokenNeedsRefresh, refreshToken } from 'pinnacle-lib/utils/permissionsUtil';

export default class DataService {

  constructor() {
    this.cancelTokens = {};
  }

  fetchTileURL({filters}) {
    let url = `${process.env.VUE_APP_PINNACLE_API}`;
    let query_params = {};
    switch (filters.visualization) {
      case VisualizationType.PD.name:
        url += `/v1/pulse-daypart/{z}/{x}/{y}`;
        query_params = {...filters.api_params};
        forEach(filters.api_params, function(val, i) {
          if (val == 'all') {
            delete query_params[i]; 
          }
        });
        url += '?' + $.param(query_params);
        return url;
      case VisualizationType.GT.name:
        url += `/v1/growth-trends/{z}/{x}/{y}`;
        query_params = {...filters.api_params};
        forEach(filters.api_params, function(val, i) {
          if (val == 'all') {
            delete query_params[i]; 
          }
        });
        url += '?' + $.param(query_params);
        return url;
      case VisualizationType.CL.name:
        url += `/v1/competitive-landscape/{z}/{x}/{y}`;
        
        if (filters.api_params.filter) {
          let params = {...filters.api_params};
          if (params.filter) {
            params.filter = JSON.stringify(params.filter);
          }
          url += '?' + $.param(params);
        }

        console.log(url);
        return url;
      case VisualizationType.PLACES_CLUSTER.name:
        if(filters.api_params.search_term){
          filters.api_params.search_term = filters.api_params.search_term.replace('&', ' ');
        }
        url += `/v1/locations-clusters/{z}/{x}/{y}`;
        url += '?' + $.param(filters.api_params);
        console.log(url);
        return url;
      case VisualizationType.PLACES_CHOROPLETH.name:
        url += `/v1/locations-choropleth/{z}/{x}/{y}`;
        url += '?' + $.param(filters.api_params);
        console.log(url);
        return url;
    }
    console.error(`No tile URL for ${filters.visualization}`);
    return undefined;
  }

  async fetchBatchData({id, locations, filters}) {
    const idString = locations.map(loc => loc.id).join(',');

    let url = `${process.env.VUE_APP_PINNACLE_API}/v2/`;
    let api_params = Object.assign({}, filters.api_params);
    if (tokenNeedsRefresh()) await refreshToken(axios);
    const headers = getHeaders();

    switch (filters.visualization) {
      case VisualizationType.HEXBIN.name:
        url += `h3/${idString}`;
        break;
      case VisualizationType.HEATMAP.name:
        url += `heatmap/${idString}`;
        break;
      case VisualizationType.ROUTES.name:
        url += `pathing-routes/${idString}`;
        break;
      case VisualizationType.ADMIN.name:
        url += `admin/${idString}`;
        break;
      default:
        throw new Error(`No such visualization: ${filters.visualization}`);
    }
    try {
      const source = CancelToken.source();
      this.cancelTokens[id] = source;
      const response = await axios.get(url, {...headers, params: {...api_params}, cancelToken: source.token});

      let data = response.data;

      let isDataEmpty = true;
      for (const location of locations) {
        if (data[location.id].features.length !== 0) {
          isDataEmpty = false;
        }
      }
      if (isDataEmpty) {
        const error = new Error(`Data unavailable for the selected dates.`);
        error.code = -3;
        throw error;
      }
      
      return data;

    } catch (err) {
      if (axios.isCancel(err)) return undefined;
      console.error(err);
      let newError;
      if ('response' in err && err.response.data.statusCode == 422) {
        newError = new Error(err.response.data.message);
        newError.code = -2;
      } else if ('code' in err) {
        newError = err;
      }  else {
        newError = new Error(`Request failed for ${filters.visualization} visualization id: ${id}`);
      }
      throw newError;
    } finally {
      delete this.cancelTokens[id];
    }
  }

  async fetchData({id, location, filters}) {

    let url = `${process.env.VUE_APP_PINNACLE_API}`;
    let api_params = Object.assign({}, filters.api_params);
    if (tokenNeedsRefresh()) await refreshToken(axios);
    const headers = getHeaders();

    switch(filters.visualization) {
      case VisualizationType.HEXBIN.name:
        url += `/v2/h3/${location.id}`;
        break;
      case VisualizationType.HEATMAP.name:
        url += `/v1/heatmap/${location.id}`;
        break;
      case VisualizationType.ROUTES.name:
        url += `/v1/pathing-routes/${location.id}`;
        break;
      case VisualizationType.ADMIN.name:
        // Temporary data_type
        url += `/v2/admin/${location.id}`;
        break;
      default:
        throw new Error(`No such visualization: ${filters.visualization}`);
    }
    try {
      const source = CancelToken.source();
      this.cancelTokens[id] = source;
      console.log(axios.getUri({url, headers, params: {...api_params}}));
      const response = await axios.get(url, {...headers, params: {...api_params}, cancelToken: source.token});
      //console.log(response.data);

      // Cache the data for future usage
      let data = response.data;
      if ('geojson' in data) data = data.geojson;
      if (location.id in data) data = data[location.id];

      if (data.features.length === 0) {
        const error = new Error(`Data unavailable for the selected dates at location: ${location.shortenedName} (${location.id})`);
        error.code = -3;
        throw error;
      }

      return data;
    } catch (err) {
      if (axios.isCancel(err)) {
        return undefined;
      }
      let newError;
      console.error(err);
      if ('response' in err && err.response.data.statusCode == 422) {
        newError = new Error(err.response.data.message + ` Location: ${location.shortenedName} (${location.id})`);
        newError.code = -2;
      } else if ('code' in err) {
        newError = err;
      } else {
        newError = new Error(`Request failed for ${filters.visualization} at location: ${location.shortenedName} (${location.id})`);
      }
      throw newError;
    } finally {
      delete this.cancelTokens[id];
    }
  }

  cancelRequest(id) {
    // console.log(this.cancelTokens);
    if (id in this.cancelTokens) {
      console.log('Request cancelled');
      this.cancelTokens[id].cancel('Operation cancelled by user.');
      delete this.cancelTokens[id];
    }
  }

}