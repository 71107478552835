<template>
  <div class="search-results-list">
    <!-- SEARCH RESULTS -->
    <div
      v-for="result of results"
      :key="result.id"
      class="search-results-item"
      @mouseenter="emitMouseEnter(result)"
      @mouseleave="emitMouseLeave(result)"
      :class="{ highlight: searchResultHighlighted === result.id }"
    >
      <div @click="viewDetail(result)" class="result-container">
        <div class="result-icon">
          <div
            class="brand-img brand-avatar brand-avatar-48"
            :style="getImgContainerStyle(result)"
          >
            <img
              :src="getCategoryAvatar(result)"
              :style="getImgStyle(result)"
            />
          </div>
        </div>
        <div class="result-info">
          <div>
            <p class="name-bold">{{ result.name | str_limit }}</p>
            <p class="address">{{ result.address | str_limit(32) }}</p>
          </div>
          <div class="button-container">
            <template v-if="isInStudy(result)">
              <img
                class="play-remove"
                :src="require('pinnacle-lib/assets/icon/play-check.svg')"
                :id="`playlist-remove-${result.id}`"
                @click="studyChanged({ location: result, status: false })"
                @click.stop="''"
              />
              <b-tooltip
                :target="`playlist-remove-${result.id}`"
                triggers="hover"
                :noninteractive="true"
                delay="0"
                :no-fade="true"
              >
                Remove from comparison
              </b-tooltip>
            </template>
            <template v-else-if="studyLocations.length < 5">
              <img
                class="play-check"
                :src="require('pinnacle-lib/assets/icon/play-plus.svg')"
                :id="`playlist-add-${result.id}`"
                @click="studyChanged({ location: result, status: true })"
                @click.stop="''"
              />
              <b-tooltip
                :target="`playlist-add-${result.id}`"
                triggers="hover"
                :noninteractive="true"
                delay="0"
                :no-fade="true"
              >
                Add to comparison
              </b-tooltip>
            </template>
            <!--- if the study already has 5 locations -->
            <template v-else-if="studyLocations.length >= 5">
              <img
                class="play-unavailable"
                :src="require('pinnacle-lib/assets/icon/play-unavailable.svg')"
                :id="`playlist-unavailable-${result.id}`"
                @click.stop="viewDetail"
              />
              <b-tooltip
                :target="`playlist-unavailable-${result.id}`"
                triggers="hover"
                :noninteractive="true"
                delay="0"
                :no-fade="true"
              >
              Comparison full
              </b-tooltip>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["results", "searchResultHighlighted"],
  data() {
    return {
    };
  },
  watch: {},
  computed: {
    studyLocations() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split("/")[1] === "preview"
        ? this.$sessionStore.state.study.previews
        : this.$sessionStore.state.study.locations;
    },
  },
  methods: {
    viewDetail(result) {
      this.$emit("viewDetail", result);
    },
    emitMouseEnter(result) {
      this.$emit("mouseEnter", { locationId: result.id, state: true });
    },
    emitMouseLeave(result) {
      this.$emit("mouseLeave", { locationId: result.id, state: false });
    },
    isInStudy(result) {
      return this.studyLocations.some((loc) => loc.id === result.id);
    },
    checkMove(evt) {
      const { element } = evt.draggedContext;
      return (
        this.studyLocations.length < 5 &&
        !this.studyLocations.map((loc) => loc.id).includes(element.id)
      );
    },
    studyChanged(evt) {
      const { status, location } = evt;
      if (status) {
        if (this.studyLocations.length === 5) return;
        // Append the location to the study if not already there
        const newLocations = [...this.studyLocations, location];
        this.$sessionStore.commit("setLocations", newLocations);
      } else {
        // Remove the location
        const newLocations = this.studyLocations.filter(
          (loc) => loc.id !== location.id
        );
        this.$sessionStore.commit("setLocations", newLocations);
      }
    },
  },
  filters: {},
};
</script>
<style lang="scss" scoped>
.play-unavailable {
  display: none;
}
.draggable-container {
  .result-container {
    margin-top: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;

    b {
      display: none;
    }

    p {
      display: none;
    }

    // .brand-avatar-48 {
    //   @extend .brand-avatar-64;
    // }
  }
}

.search-results-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  position: relative;

  .search-results-item:hover .play-check,
  .search-results-item:hover .play-unavailable{
    display: block;
  }

  .search-results-item {
    border-bottom: 2px solid #f9fafc;
    background-color: white;
    height: 80px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .play-check {
      display: none;
    }

    .result-container {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 16px;
      gap: 10px;

      .result-icon {
        flex: 1;
        margin-right: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .result-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        position: relative;

        .button-container {
          position: relative;
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .name-bold {
          font-weight: bold;
          font-size: 16px;
          color: #253561;
        }
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
          font-weight: bold;
          font-size: 14px;
          color: #6e84a3;
        }

        .study-checkbox {
          ::v-deep .custom-control-label::after {
            background-color: white;
            border: 1px solid rgba(192, 196, 209, 1);
            border-radius: 3px;
          }

          ::v-deep
            .custom-control-input:checked
            ~ .custom-control-label::after {
            background-color: $blue;
            border: 1px solid $blue;
          }

          ::v-deep
            .custom-control-input:disabled
            ~ .custom-control-label::after {
            background-color: lightgray;
            border: 1px solid rgba(192, 196, 209, 1);
          }
        }
      }
    }
  }

  .highlight {
    background-color: lighten(#f9fbfd, 1);
  }

  .search-results-item:hover {
    background-color: lighten(#f9fbfd, 1);
  }
}
</style>
