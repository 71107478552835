<template>
    <div class="keyHide">
       <div class="listSect">
        <img :src="require('pinnacle-lib/assets/icon/smlist.svg')" style='margin-right: 10px;' />
        <p style='white-space: nowrap;' >Map Key</p>
       </div>
       <div class="listSect color" v-for='location of locations' style='justify-content: space-between' :key="location.id">
        <span style='display: flex; flex-direction: row;'>

          <div class="brand-icon-container">
            <img 
                :src="getCategoryAvatar(location)"
                :style="getImgStyle(location)"
                :alt='location.name'  
                class='brand-icon'
            />
          </div>

          <p class='place' >{{location.name}}</p>
          <p class='address' >{{location.address}}</p>
        </span>
        <div class='colorCirc' />
       </div>
       <div v-if='status.cl' class="listSect" style='justify-content: space-between'>
        <p class='label'>Nearby Places</p>
        <div class='greyCirc' />
       </div>
       <div v-if='status.routes' class="bigListSect" >
        <div class='tab' >
          <p class='label' > Visitor Routes to Place </p>
          <img :src="require('pinnacle-lib/assets/icon/pathKey.svg')"  alt='path' />
        </div>
        <div class='tab'>
          <div style='margin-right: 6px;' class='greyLabel'> Less Popular</div>
          <div class='pathing'></div>
          <div style='margin-left: 6px;' class='greyLabel'> More Popular</div>
        </div>
       </div>
       <div v-if='status.wh' class="bigListSect" >
        <div class='tab' >
          <p class='label' > Visitor Home and Work Locations </p>
          <img v-if='visual === "Hexagon"' :src="require('pinnacle-lib/assets/icon/hex.svg')"  alt='hex' />
          <img v-if='visual === "Geographic Areas"' :src="require('pinnacle-lib/assets/icon/geo.svg')"  alt='geo' />
          <img v-if='visual === "Heatmap"' :src="require('pinnacle-lib/assets/icon/heat.svg')"  alt='heat' />
        </div>
        <div class='tab'>
          <div style='margin-right: 6px;' class='greyLabel'> Less </div>
          <div v-if='visual === "Hexagon" || visual === "Geographic Areas"'class='wh'></div>
          <div v-if='visual === "Heatmap"' class='rbWH'></div>
          <div style='margin-left: 6px;' class='greyLabel'> More </div>
        </div>
       </div>
       <div v-if='statusCi.pd' class="bigListSect" style='justify-content: space-between'>
         <div class='tab' >
          <p class='label' > Community Activity </p>
          <img :src="require('pinnacle-lib/assets/icon/geo.svg')"  alt='geo' />
        </div>
        <div class='tab'>
          <div style='margin-right: 6px;' class='greyLabel'> Less </div>
          <div class='act'></div>
          <div style='margin-left: 6px;' class='greyLabel'> More </div>
        </div>
       </div>
       <div v-if='statusCi.gt' class="bigListSect" style='justify-content: space-between'>
         <div class='tab' >
          <p class='label' > Community Popularity </p>
          <img :src="require('pinnacle-lib/assets/icon/geo.svg')"  alt='geo' />
        </div>
        <div class='tab'>
          <div style='margin-right: 6px;' class='greyLabel'> Decline </div>
          <div class='pop'></div>
          <div style='margin-left: 6px;' class='greyLabel'> Growth </div>
        </div>
       </div>
    </div>
</template>

<script>
    export default {
        computed: {
            locations(){
            //this will check if route is /preview, and pull from the appropriate vuex store
            return this.$route.path.split('/')[1] === 'preview' ? this.$sessionStore.state.study.previews : this.$sessionStore.state.study.locations;
            },
            status() {
                return this.$sessionStore.state.studyMap.pi.status;
            },
            statusCi() {
                return this.$sessionStore.state.studyMap.ci.status;
            },
            visual(){
                return this.$sessionStore.state.studyMap.pi.wh.visualization;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .keyHide{
        display: none;
    }
    .key{
        // padding: 2px;
        gap: 2px;
      
        width: 100%;
        // height: 392px;
        z-index: 1000;
        background: #F9FAFC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    }
    .listSect{
        display: flex;
        flex-direction: row;
        border-width: 1px;
        border-color: #E3EBF6;
        border-style: solid;
        height: 40px;
        padding: 8px 16px;
        background-color: #ffffff;
    }
    .bigListSect{
        display: flex;
        flex-direction: column;
        border-width: 1px;
        border-color: #E3EBF6;
        border-style: solid;
        height: 64px;
        padding: 8px 16px;
        background-color: #ffffff;
    }
    .greyCirc{
        width: 16px;
        height: 16px;
        background: #6E84A3;
        border: 2px solid #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;
    }
    .colorCirc{
        width: 16px;
        height: 16px;
        border: 2px solid #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;
    }
    @for $i from 1 through length($location-colors) {
        $color: nth($location-colors, $i);
        .color:nth-child(#{$i + 1}) {
            .colorCirc{
            background: $color;
            }
        }
    }
    .pathing{
        width: 99px;
        height: 12px;
        background: linear-gradient(90deg, #50F3CF 0%, #F9FE08 45.31%, #F9FE08 54.69%, #FF2202 100%);
        border: 1px solid #E3EBF6;
        border-radius: 12px;
        flex: none;
        flex-grow: 1;
        margin-top: 3px
    }
    .wh{
        height: 12px;
        background: linear-gradient(90deg, rgba(110, 132, 163, 0) 0%, #6E84A3 100%);
        border: 1px solid #E3EBF6;
        border-radius: 12px;
        /* Inside auto layout */
        flex: none;
        flex-grow: 1;
        margin-top: 3px
    }
    .act{
        height: 12px;
        background: linear-gradient(90deg, rgba(255, 33, 2, 0) 0%, #FF2102 100%);
        border: 1px solid #E3EBF6;
        border-radius: 12px;
        /* Inside auto layout */
        flex: none;
        flex-grow: 1;
        margin-top: 3px
    }
    .rbWH{
        height: 12px;
        background: linear-gradient(90deg, #2C7BE5 15.62%, #8AC926 30.73%, #8AC926 43.75%, #F9FE08 59.37%, #F9FE08 73.96%, #FF1E01 88.02%, #FF1E01 100%);
        border: 1px solid #E3EBF6;
        border-radius: 12px;
        /* Inside auto layout */
        flex: none;
        flex-grow: 1;
        margin-top: 3px
    }
    .pop{
        height: 12px;
        background: linear-gradient(90deg, #2C7BE5 0%, #FF2102 100%);
        border: 1px solid #E3EBF6;
        border-radius: 12px;
        /* Inside auto layout */
        flex: none;
        flex-grow: 1;
        margin-top: 3px
    }
    .tab{
        justify-content: space-between; 
        flex-direction: row;
        display: flex;
        margin-bottom: 8px;
    }
    .place{
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 700;
        color: #12263F;
        margin-bottom: 0px;
        max-width: 150px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .label{
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
        margin-bottom: 0px;
        color: #12263F;
        white-space: nowrap; 
        overflow: hidden;
    }
    .greyLabel{
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
        margin-bottom: 0px;
        color: #6E84A3;
        white-space: nowrap; 
        overflow: hidden;
    }
    .address{
        color: #6E84A3;
        margin-left: 10px;
        max-width: 150px; 
        height: 22px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .brand-icon-container {
        width: 48px;
        display: flex;
        justify-content: center;
    }
    .brand-icon{
        margin-right: 0;
        max-width: 48px;
        max-height: 22px;
        // position: relative;
        // background-position: center center !important;
        // background-size: contain !important;
        // background-repeat: no-repeat !important;
        // -ms-flex-negative: 0;
        // flex-shrink: 0;
        // background-origin: content-box !important;
        // background: white;
        // vertical-align: middle;
    }
</style>
