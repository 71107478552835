<template>
  <div class="d-flex paneContainer"> 
    <RightPaneDatePicker v-if="showPicker" />
    <LayerSidebar class="layer-sidebar" v-show="showInsights && !showPicker" ></LayerSidebar>
  </div>
</template>
<script>
import RightPaneDatePicker from "@/components/partials/RightPaneDatePicker";
import LayerSidebar from "@/components/maps/LayerSidebar";
import { bus } from "@/main";

export default {
components: {
    RightPaneDatePicker,
    LayerSidebar
  },
    data() {
    return {
        showPicker: false,
        showInsights: false
    }
    },
    mounted() {
    bus.$on("showDatePicker", () => {
        this.showPicker = true;
    });
    bus.$on("closeDatePicker", () => {
       this.showPicker = false;
    });

    bus.$off("closeButton");
    bus.$on("closeButton", (evt) => {
      if (evt.id === "layerPicker") {
        this.toggleSidebar();
      }
    });

    bus.$off("studyMapLoading");
    bus.$on("studyMapLoading", (loading) => {
      this.loading = loading;
    });
    bus.$on("openInsights", () => {
        this.showInsights = true;
        this.showPicker = false;
    });
    bus.$on("closeInsights", () => {
        this.showInsights = false;
        this.showPicker = false;
    })
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
      bus.$emit("resizeMap");
    },
  },
};
</script>
<style lang="scss" scoped>
.paneContainer {
  height: 100%;
  max-height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
}
.layer-sidebar {
    height: 100%;
    width: 100%;
    border-left: 1px solid $border-color;
}
</style>