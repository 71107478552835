<template>
  <div class="work-home-container">
    <InsightsContainer
      title="Home and Work"
      :icon="require('pinnacle-lib/assets/icon/map-work-large.svg')"
      :tooltip="tooltip"
      insight="wh"
      :selection="wh"
      insightFamily="pi"
      :toggleSelectedLayer="toggleSelectedLayer"
    >
      <div class="work-home-content">
        <div class="options">
          <label>Display Mode</label>
          <select
            @change="updateSelectedLayer(wh)"
            name="wh_visualizations"
            v-model="wh.visualization"
          >
            <template v-for="visualization in wh_visualizations">
              <option :value="visualization" :key="visualization">
                {{ visualization }}
              </option>
            </template>
          </select>
        </div>
        <div class="options">
          <label>Include</label>
          <select
            @change="updateSelectedLayer(wh)"
            name="locations"
            v-model="wh.api_params.visitor_info"
          >
            <option value="Home">Home</option>
            <option value="Work">Work</option>
            <option value="Both">Both</option>
          </select>
        </div>
        <div class="options">
          <div v-if="wh.visualization === 'Hexagon'">
            <label>
              <span>Resolution</span>
              <span style="margin-left: 6px">{{ wh.api_params.resolution }}</span>
            </label>
            <b-form-input
              @change="updateSelectedLayer(wh)"
              type="range"
              step="1"
              min="4"
              max="8"
              v-model="wh.api_params.resolution"
            ></b-form-input>
          </div>
          <div 
            v-if="wh.visualization === 'Geographic Areas'"
            class="options"
          >
            <label>Geography Level</label>
            <select
              @change="updateSelectedLayer(wh)"
              name="geo_levels"
              v-model="wh.api_params.geography_level"
            >
              <template v-for="geo_level in geography_levels">
                <option :value="geo_level" :key="geo_level">
                  {{ geo_level }}
                </option>
              </template>
            </select>
          </div>
        </div>
      </div>
    </InsightsContainer>
  </div>
</template>

<script>
import InsightsContainer from '@/components/maps/InsightsContainer.vue';
import {
  GeoType,
  VisualizationType
} from "@/services/map/types";

export default {
  components: { 
    InsightsContainer 
  },
  props: ['toggleSelectedLayer', 'updateSelectedLayer'],
  data() {
    return {
      tooltip: 'This shows where visitors live and work'
    }
  },
  computed: {
    wh: {
      get() {
        return this.$sessionStore.state.studyMap.pi.wh;
      },
      set(value) {
        this.$sessionStore.commit('setPIWorkHome', value);
      }
    },
    geography_levels() {
      return Object.values(GeoType);
    },
    wh_visualizations() {
      return Object.values(VisualizationType)
        .filter((val) => val.prefix === "wh")
        .map((val) => val.name);
    }
  }
}
</script>

<style lang="scss" scoped>
.work-home-container {

  .work-home-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 24px;

    .options {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      label {
        color: #858EA7;
        font-size: 14px;
      }
      select {
        padding: 10px 16px;
        border: 1px solid #CFD3DC;
        border-radius: 4px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: url('pinnacle-lib/assets/icon/arrow-down-select.svg');
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) center;
        background-size: 10px;
      }
    }
  }
}
</style>