export default {
  state: {
    isLoadingToLocation: false,
    location: {
      shortenedName: "",
      address: "",
      uptrend: false,
      estimated_visitor: 0,
      center: [],
      avatar_category: "",
      avatar_subsector: "",
      avatar_sector: "",
      municipality: '',
      sector: '',
      subsector: '',
      city: '',
      country_code: ''
    },
  },
  mutations: {
    setLoadingToLocation: (state, payload) =>
      (state.isLoadingToLocation = payload),
    setLocation: (state, payload) => (state.location = payload),
  },
};
