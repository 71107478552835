import MapManager from "@/services/map/mapManager";
import { VisualizationType, getNameFromId } from '@/services/map/types';


export default class HoverManager extends MapManager {

  constructor(mapInstance, mapService) {
    super(mapInstance);
    this.mapService = mapService;
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: [-8, -11],
      maxWidth: '400px',
      className: 'popup-class'
    });
    this.layers = new Set();
    this.metadata = {};
    this.chartData = {};
    this.chartInstance = undefined;
    this.popupTimer = undefined;
    this.cursorOnMap = false;

    this.map.on("mousemove", this.mouseMove.bind(this));
    this.map.on("mouseout", this.mouseOut.bind(this));
    this.map.on("mouseover", this.mouseOver.bind(this));
  }

  showPopup(coords, html) {
    this.popup.setLngLat(coords).setHTML(html).addTo(this.map);
  }

  generateChart(layerId, feature, locations) {
    const chartCanvas = `<canvas id="cell-share-chart-${layerId}" width="250" height="250"></canvas>`;

    // console.log(feature.properties.cell_share);
    // console.log(Object.values(JSON.parse(feature.properties.cell_share)));
    this.chartData[layerId] = {
      type: 'pie',
      data: {
        labels: locations.map(loc => loc.shortenedName),
        datasets: [{
          label: 'Cell Share',
          data: Object.values(JSON.parse(feature.properties.cell_share)),
          backgroundColor: locations.map(loc => loc.baseColor)
        }]
      },
      options: {
        legend: {
          display: true,
          position: 'top'
        },
        plugins: {
          datalabels: {
            display: false
          }
        }
      }
    };
    return chartCanvas;
  }

  initChart(layerId) {
    const ctx = document.getElementById(`cell-share-chart-${layerId}`);
    this.chartInstance = new window.Chart(ctx, this.chartData[layerId]);
  }

  generateLabel(name, feature, metadata) {
    let label;
    let property;
    switch (name) {
      case VisualizationType.PD.name:
        label = 'Activity per Hour';
        property = `${(feature.properties.density).toFixed(1)}`;
        break;
      case VisualizationType.GT.name:
        label = 'Popularity Trend';
        property = `${(feature.properties.trend * 10).toFixed(1)}`;
        break;
      case VisualizationType.HEXBIN.name:
      case VisualizationType.ADMIN.name:
        if (metadata.visitor_info === 'Both')
          label = 'Home and Work Location Density';
        else label = `${metadata.visitor_info} Location Density`;
        property = `${(feature.properties.density * 100).toFixed(1)}%`;
        break;
      default:
        property = `${(feature.properties.density * 100).toFixed(1)}%`;
        label = 'Density'
    }
    let html = `<p style="color: #6E84A3; margin: 0; display: inline-block;">${label}:</p> ${property}<br/>`;
    return html;
  }

  clean() {
    if (this.popup.isOpen()) this.popup.remove();
    if (this.chartInstance) {
      this.chartInstance.destroy();
      delete this.chartInstance;
    }
    this.chartData = {};
  }

  mouseMove(e) {

    clearTimeout(this.popupTimer);

    if (this.popup.isOpen()) {
      this.popup.remove();
      this.clean();
    }

    const features = this.map.queryRenderedFeatures(e.point, {layers: Array.from(this.layers)});
    if (features.length === 0) {
      this.clean();
      return;
    }

    let html = ``;
    let layerId;
    let chartLayerId;
    let hasChart = false;
    for (const feature of features) {
      layerId = feature.layer.id;
      const name = getNameFromId(layerId);
      const metadata = this.metadata[layerId];
      if (metadata.locations) {
        html += `<div>${this.generateChart(layerId, feature, metadata.locations)}</div>`;
        hasChart = true;
        chartLayerId = layerId;
      } else html += `<div>${this.generateLabel(name, feature, metadata)}</div>`;
    }

    html = `<div>${html}</div>`;

    this.popupTimer = setTimeout(() => {

      if (!this.cursorOnMap) return;
      this.showPopup(e.lngLat, html);
      if (hasChart) this.initChart(chartLayerId);
      delete this.popupTimer;
      
    }, 350);
  }

  mouseOut() {
    if (this.popup.isOpen()) {
      this.popup.remove();
      this.clean();
    }
    this.cursorOnMap = false;
  }

  mouseOver() {
    this.cursorOnMap = true;
  }

  addHoverPopup(layerId, metadata, locations) {
    this.layers.add(layerId);
    const meta = Object.assign({}, metadata);
    if (locations) meta.locations = locations;
    this.metadata[layerId] = meta;
  }

  removeHoverPopup(layerId) {
    this.clean();
    this.layers.delete(layerId);
    delete this.chartData[layerId];
    delete this.metadata[layerId];
  }

}