<template>
  <div class="study-layout">
    <!-- Copy Link pop-up message -->
    <div
      v-if="showAlert"
      class="alert alert-info alert-dismissible fade show copyLinkMessage"
      role="alert"
    >
      <CopyLinkBell class="copyLink-bell" />
      <p>Link has been copied to your clipboard!</p>
      <button
        type="button"
        class="close"
        style="color: white; font-size: 18px"
        @click="closeAlert"
      >
        x
      </button>
    </div>
    <!-- <div class="topbar-container">
      <Topbar />
    </div> -->

    <!-- invalid Polygon alert modal -->
    <b-modal
      v-model="invalidLocationModal"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="polygonErrorAlertModal">
        <p>Selected place is invalid. Please return to search and try again.</p>
        <b-button variant="primary" @click="returnToSearch()"
          >Return to Search</b-button
        >
      </div>
    </b-modal>

    <div class="content-container">
      <LeftPane v-if="this.$route.name !== 'preview-map-details'">
        <PseudoBreadcrumbs
          :noClose="!shouldReturnToStudy"
          :origin="origin"
          currentTitle="Place"
        ></PseudoBreadcrumbs>
        <PlaceDetails
          v-if="loaded"
          :isPreview="true"
          :selectedLocation="studyLocations[0]"
        >
          <!-- checkbox --->

          <template v-slot:study-checkbox>
            <template v-if="isInStudy">
              <img
                class="play-remove"
                :src="require('pinnacle-lib/assets/icon/play-check.svg')"
                id="remove-target-"
                @click="studyChanged({ location, status: false })"
                alt="Play Remove"
              />
              <b-tooltip
                target="remove-target-"
                triggers="hover"
                placement="top-right"
              >
                Remove from comparison
              </b-tooltip>
            </template>
            <template v-else-if="studyLocations2.length < 5">
              <img
                class="play-check"
                :src="require('pinnacle-lib/assets/icon/play-plus.svg')"
                id="add-target-"
                @click="studyChanged({ location, status: true })"
                alt="Play Plus"
              />
              <b-tooltip
                target="add-target-"
                triggers="hover"
                placement="top-right"
              >
                Add to comparison
              </b-tooltip>
            </template>
            <template v-else-if="studyLocations2.length >= 5">
              <img
                class="play-check"
                :src="require('pinnacle-lib/assets/icon/play-unavailable.svg')"
                id="unavailable-target-"
                alt="Play Unavailable"
              />
              <b-tooltip
                target="unavailable-target-"
                triggers="hover"
                placement="top-right"
              >
                Comparison full
              </b-tooltip>
            </template>
          </template>

          <template v-slot:buttons>
            <div class="button-container">
              <!-- <button @click="compareHandler" class="compare-button">
                Compare
              </button> -->
              <button class="opened-button">Opened</button>
              <button @click="copyLink" class="copy-button">
                <img :src="require('pinnacle-lib/assets/icon/link-icon.svg')" alt="Copy" />
                Copy Link
              </button>
            </div>
          </template>
        </PlaceDetails>
        <PlacesDetailsCard />
      </LeftPane>

      <LeftPane
        v-if="this.$route.name === 'preview-map-details'"
        :needBreadcrumbs="true"
      >
        <!-- <LeftPane> -->
        <router-view name="header"></router-view>
        <router-view name="sidebar"></router-view>
      </LeftPane>

      <!-- <div class="right-panel"> -->
      <!-- <div class="study-content">
          <div class="topbar-container">
            <Topbar />
          </div>
          <router-view />
        </div> -->

      <div class="right-panel">
        <div class="topbar-container">
          <Topbar :preview="true" />
        </div>
        <div class="charts-content">
          <div class="study-content">
            <router-view />
          </div>

          <div
            class="closeInsightsBtn"
            :class="{ 'd-none': !showCloseInsights }"
          >
            <img
              :src="require('pinnacle-lib/assets/icon/closeBtn-Insights2.svg')"
              @click="closeInsights"
              alt="close-insights"
            />
          </div>
          <div
            :class="!showPicker && !onMap ? 'd-none' : 'right-panel-datepicker'"
            v-show="
              showRightPane &&
              (showPicker || $route.name !== 'preview-charts')
            "
          >
            <RightPane> </RightPane>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteGuard from "pinnacle-lib/mixins/RouteGuard";
import Topbar from "@/components/partials/Topbar";
import PlacesDetailsCard from "@/components/partials/PlacesDetailsCard.vue";
import CopyLinkBell from "@/components/partials/CopyLinkBell.vue";
import { bus } from "@/main";
import LeftPane from "@/components/partials/LeftPane";
import PlaceDetails from "@/components/partials/PlaceDetails";
import RightPane from "@/components/partials/RightPane";
import PseudoBreadcrumbs from "@/components/partials/PseudoBreadcrumbs.vue";

export default {
  mixins: [RouteGuard],
  components: {
    Topbar,
    CopyLinkBell,
    LeftPane,
    PlaceDetails,
    PlacesDetailsCard,
    RightPane,
    PseudoBreadcrumbs,
  },
  data() {
    return {
      ids: [],
      showAlert: false,
      loaded: false,
      showRightPane: false,
      showInsights: false,
      invalidLocationModal: false,
      showPicker: false,
    };
  },
  computed: {
    studyLocations() {
      return this.$sessionStore.state.study.previews;
    },
    routeName() {
      return this.$route.name;
    },
    lastViewedStudy() {
      return this.$sessionStore.state.crumbs.lastViewedStudy;
    },
    shouldReturnToStudy() {
      return this.lastViewedStudy !== undefined;
    },
    origin() {
      if (this.shouldReturnToStudy) {
        const route = Object.assign({}, this.lastViewedStudy);
        route.title = "Comparison";
        return route;
      }
      return { path: "/explore/search", title: "Search" };
    },
    onMap() {
      return (
        this.$route.name === "preview-map" ||
        this.$route.name === "preview-map-details"
      );
    },
    showCloseInsights() {
      if (this.onMap && this.showInsights && !this.showPicker) {
        return true;
      } else {
        return false;
      }
    },
    locationId() {
      // const parsed = JSON.parse(atob(this.$route.query.place));
      // return parsed
      return this.$route.params.ids;
    },
    studyLocations2() {
      return this.$sessionStore.state.study.locations;
    },
    isInStudy() {
      return this.studyLocations2.some((loc) => loc.id === this.locationId);
    },
    location() {
      return this.studyLocations[0];
    },
  },
  created() {},
  mounted() {
    this.syncStudy();
    // this.getComparisonData();

    bus.$off("copyLinkAlert");
    bus.$on("copyLinkAlert", () => {
      this.showAlert = !this.showAlert;
      setTimeout(() => (this.showAlert = false), 3000);
    });

    bus.$on("showDatePicker", () => {
      this.showRightPane = true;
      this.showPicker = true;
    });

    bus.$on("closeDatePicker", () => {
      if (this.showInsights) {
        this.showRightPane = true;
        this.showPicker = false;
      } else {
        this.showRightPane = false;
        this.showPicker = false;
      }
    });

    bus.$on("openInsights", () => {
      this.showRightPane = true;
      this.showInsights = true;
    });

    bus.$on("closeInsights", () => {
      this.showRightPane = false;
      this.showInsights = false;
    });
  },
  methods: {
    // compareHandler() {
    //   let newLocations = this.studyLocations;
    //   this.$sessionStore.commit("setLocations", newLocations);
    //   this.$router.push({ path: `/explore/search`});
    // },
    copyLink() {
      const clipboardInput = document.createElement("input");
      const url = window.location.href;
      document.body.appendChild(clipboardInput);
      clipboardInput.value = url;
      clipboardInput.select();
      document.execCommand("copy");
      document.body.removeChild(clipboardInput);

      bus.$emit("copyLinkAlert");
    },
    // async getComparisonData() {
    //   try {
    //     const headers = getHeaders(this);
    //     const res = await this.$http.get(
    //       `/v2/location-details/${this.$route.params.ids}`,
    //       headers
    //     );
    //     this.commonElement = res.data.meta_data.common_element;
    //     this.isLoading = true;
    //     this.location = res
    //     console.log(res)
    //   } catch (err) {
    //     console.log("ERROR", err);
    //     await this.refreshToken();
    //     this.getComparisonData();
    //   }
    // },
    async syncStudy() {
      // Checks the route param ids against the ids in the sessionStore
      this.$sessionStore.commit("setLoading", true);
      this.loaded = false;
      this.ids = this.$route.params.ids;
      const matches = this.studyLocations.length === 1 && this.location.id === this.ids;
      if (!matches) {
        try {
          if (this.tokenNeedsRefresh()) await this.refreshToken();
          const headers = this.getHeaders();
          const res = await this.$http.get(
            `/v2/location-details/${this.$route.params.ids}`,
            headers
          );
          this.$sessionStore.commit(
            "setPreviews",
            res.data.locations.sort((a, b) => {
              return this.ids.indexOf(a.id) - this.ids.indexOf(b.id);
            })
          );
        } catch (err) {
          console.log(err);
          if (err.response.status == 400) {
            this.invalidLocationModal = true;
            return;
          }
          this.err = err.response.data.errors[0].message;
          console.log({ err });
        }
      }
      //   }
      this.$sessionStore.commit("setLoading", false);
      this.loaded = true;
      // Preload routes
      //   try {
      //     if (this.tokenNeedsRefresh()) await this.refreshToken();
      //     const headers = getHeaders(this);
      //     const res = await this.$http.get(`/v1/trigger-cache/${this.$route.params.ids}`, headers);
      //     //console.log('Preload response: ', res);
      //   } catch (err) {
      //     console.log(err);
      //   }
    },
    closeAlert() {
      this.showAlert = !this.showAlert;
    },
    returnToSearch() {
      this.$router.push({ path: `/explore/search` });
    },
    studyChanged(evt) {
      const { status, location } = evt;
      if (status) {
        if (this.studyLocations2.length === 5) return;
        const newLocations = [...this.studyLocations2, location];
        this.$sessionStore.commit("setLocations", newLocations);
      } else {
        const newLocations = this.studyLocations2.filter(
          (loc) => loc.id !== location.id
        );
        this.$sessionStore.commit("setLocations", newLocations);
      }
      console.log(this.studyLocations2.map((loc) => loc.id));
    },
    closeInsights() {
      bus.$emit("closeInsights");
    },
  },
  watch: {
    "$route.params.ids"() {
      this.syncStudy();
    },
  },
};
</script>
<style lang="scss" scoped>
.study-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.content-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.study-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.charts-content {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.right-panel {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.right-panel-datepicker {
  display: flex;
  height: 100%;
  width: 380px;
  background-color: white;
  border-left: 1px solid $border-color;
  z-index: 6;
  position: absolute;
  right: 0;
  overflow-y: auto;
}
.closeInsightsBtn {
  position: absolute;
  right: 375px;
  top: 95px;
  z-index: 5;

  &:hover {
    cursor: pointer;
  }
}

.copyLinkMessage {
  position: absolute;
  z-index: 1000;
  display: flex;
  background-color: #2c7be5;
  width: 100%;
  height: 3.2em;
  top: 0%;
  border-radius: 0;
  border-color: transparent;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.03);
  p {
    font-family: Cerebri Sans;
    font-size: 15px;
    color: white;
    margin-left: 1.25em;
    font-weight: 100;
  }
  .copyLink-bell {
    margin-left: 1.25em;
  }
}
.button-container {
  // margin: 16px 24px;
  padding: 0 1.5rem 0 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: row;
}
.copy-button {
  width: 40%;
  border-radius: 4px;
  border-width: 0;
  margin-bottom: 16px;
  margin-left: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #edf2f9;
  color: #6e84a3;
}
.compare-button {
  width: 45%;
  border-radius: 4px;
  border-width: 0;
  margin-bottom: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #edf2f9;
  color: #6e84a3;
}

.opened-button {
  width: 60%;
  height: 46px;
  border-radius: 4px;
  border-width: 0;
  margin-bottom: 16px;
  background: #6e84a3;
  color: #ffffff;
}

.polygonErrorAlertModal {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.play-check,
.play-remove {
  cursor: pointer;
}
::v-deep .modal-backdrop {
  box-shadow: inset 0 0 2000px hwb(0deg 100% 0% / 50%) !important;
  background-color: rgb(22 34 68 / 50%) !important;
  backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
}
</style>