<template>
  <div class="d-flex align-items-start flex-column options-panel">
    <div class="d-flex align-items-start options-inner-card">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.options-panel {
  width: 100%;
  height: 100%; // just for testing
  background: white;
  flex-direction: column;
  border-radius: 6px;
}

.options-panel + .options-panel {
  margin-top: 2rem;
}

::v-deep .options-inner-card {
  flex-direction: column;
  color: #4e5558;
}

::v-deep .options-list, ::v-deep .options-inner-card {
  width: 100%;
}

::v-deep .split-pane + div {
  margin-top: 2rem;
}

::v-deep .report-options-container + .report-options-container {
  margin-top: 2rem;
}
</style>