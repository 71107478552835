<template>
    <span>
        <b-modal id="export-data-modal" centered hide-header hide-footer  no-close-on-esc >
            <h3 style="text-align:center; margin: 15px">Export Data</h3>
            <ul class="export-data-list">
                <li v-for="report in card_reports">
                    <a :href="report.reportUrl">{{ report.reportType }}</a>
                </li>
            </ul>
            <button
                class="primary-buttonB"
                variant="primary"
                @click='$bvModal.hide("export-data-modal")'
                >
                Okay
            </button>    
        </b-modal>
        <ExportPDFModal></ExportPDFModal>
        <EmbedModal :embedSrc="embed_src"></EmbedModal>
        <!-- <b-button :href="origin" variant="secondary" size="sm" class="overlay">Share</b-button> -->
        <!-- b-button :href="origin" variant="secondary" size="sm" class="overlay" @click.prevent="openExportPDFModal(report)">Export As PDF</b-button> -->
        
        <iframe title="" @click="logger()" id="project-iframe" v-if="bool === true"style="height:calc(100% - 10px);width:100%;border:0" :src="hash"></iframe>
        <div v-if="bool === false"> 
            <div class="denied">
                <h1>Access denied</h1>
                <p>This Project url is not active.</P>
            </div>
        </div>
    </span>
</template>

<script>
import CardUtilMixin from "../../services/cards/cardUtil.js";
import RouteGuard from "pinnacle-lib/mixins/RouteGuard";
import ExportPDFModal from "../home/new/ExportPDFModal.vue";
import EmbedModal from "../home/new/EmbedModal.vue";

window.footTrafficAdmin = true;
window.footTrafficWidgetPermissons = {
  none: true,
  view: false,
  edit: false
};
window.cardSystemDownloadData = true;
window.cardSystemExportPDF = true;
window.cardSystemEmbed = true;

export default {
  components: { ExportPDFModal, EmbedModal },
  data() {
    return {
        hash: '',
        bool: null,
        edit: false,
        origin: `${window.location.origin}/home/manage-shares/${this.$route.params.id}`,
        report: {
            id: null,
            name: "",
        },
        card_reports: [],
        cards_base_url: "https://cards.ubermedia.com",
        embed_src: "",
    }
  },
  mixins: [ CardUtilMixin, RouteGuard ],
  methods: {
    async getLink(){
        try{
            const data = await await this.getCardData(`${process.env.VUE_APP_PINNACLE_API}/v2/get-project/${this.$route.params.id}`);
            if(data.status === 200){
                this.report = data.data;
                this.hash = `${this.cards_base_url}/${this.report.project_hash}/`
                this.bool = true;
            }
        }
        catch(err){
            this.bool = false;
        }
    },
    editHandler(){
        this.edit = this.edit ? false : true;
    },
    eventHandler(){
        console.log('clicked')
    },
    logger(){
        let iframe = document.getElementById('project-iframe')
        console.log(iframe)
    },
    async onIframeMessage(event) {
        //console.log(event)
        if (event.data && event.data.message == "widget_download") {
            let card_id = event.data.widgetDownloadEvent.id;
            let response = await this.getCardReports(card_id);
            console.log(response);
            this.card_reports = response.data.reportResults;
            this.$bvModal.show("export-data-modal");
        }
        if (event.data && event.data.message == "widget_embed") {
            let card_id = event.data.widgetEvent.id;
            
            // fetch embed link
            let response = await this.getEmbedHash(this.$route.params.id);
            let share_hash = response.data.data[0].share_hash;

            // componentId
            let componentId = event.data.widgetEvent.componentId;
            
            let host = window.location.host;
            let protocol = window.location.protocol;
            // construct the embed src
            this.embed_src = `${protocol}//${host}/embed/${share_hash}?c=%5B${componentId}%5D`;

            this.$bvModal.show("embed-modal");
        }
        if (event.data && event.data.message == "pdf_export") {
            this.openExportPDFModal(this.report);
        }
        // if (event.data.message == 'refresh_page') {
        //     location.reload();
        // return;
        // }
    },
    /**
     * Opens the export as PDF modal for a given report.
     * @param {*} report 
     */
     openExportPDFModal(report) {
      const clone = { id: report.id, name: report.name };
      this.$router.push({
        query: {
          exportPDF: btoa(JSON.stringify(clone))
        }
      });
    },
},
  mounted() {
    let host = window.location.host;
    // if (host.indexOf("pinnacle.near.com") == -1) {
    //     this.cards_base_url = "https://cards.ubermedia.com"
    // }
    if (host.indexOf("localhost") !== -1) {
        this.cards_base_url = "http://uber.ravefire.com:3000"
    }
    this.getLink();

    let superAdmin = this.$persistingStore.state.user.permissions.find(item => item.name === "Super Admin");
    window.footTrafficAdmin = true;
    let download_permission_name = 'Card Report Data Download';
    window.cardSystemDownloadData = superAdmin || this.$persistingStore.state.user.permissions.find(item => item.name === download_permission_name);
    window.cardSystemExportPDF = true;
    window.cardSystemEmbed = superAdmin || this.$persistingStore.state.user.permissions.find(item => item.name === 'Card Report Embed');

    window.footTrafficWidgetPermissons.none = false; 
    window.footTrafficWidgetPermissons.view = false;
    window.footTrafficWidgetPermissons.edit = true;

    window.addEventListener("message", this.onIframeMessage, false);
    setTimeout(() => {
        var head = document.head;
        var script = document.createElement("script");
        script.type = "text/javascript";
        let baseUrl = this.cards_base_url;
        script.src = baseUrl+"/js/CardRouting.js";
        head.appendChild(script); 
    }, 1000)


        // }, 30000);
  }
};
</script>

<style scoped>
    .denied{
        margin-left: 35%;
        margin-top: 5%;
    }
    .overlay{
        position: fixed;
        left: 93.5%;
        margin-top: 20px;
    }
    #project-iframe{
        overflow: hidden !important;
    }
    ul.export-data-list {
        padding-left:15px;
    }
    ul.export-data-list li {
        list-style:none;
    }
    .primary-buttonB{
        width: 98%;
        border-radius: 4px;
        border-width: 0;
        padding-top: 12px;
        padding-bottom: 12px;
        background: #2c7be5;
        color: #ffffff;
    }
</style>