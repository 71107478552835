<template>
  <LeftPane
    :needBreadcrumbs="needBreadcrumbs"
  >
    <router-view></router-view>
  </LeftPane>
</template>
<script>

import LeftPane from "@/components/partials/LeftPane";

export default {
  components: {
    LeftPane
  },
  computed: {
    needBreadcrumbs() {
      if (this.$route.path === '/explore/details' || this.$route.path === '/explore/comparison') {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>