<template>
  <div class="title-container" :class="{ 'blue' : dark, 'light' : !dark, 'large' : size !== 'sm', 'small' : size === 'sm' }">
    <div class="icon">
      <img :src="require('pinnacle-lib/assets/icon/comparison-list.svg')" />
    </div>
    <div class="column">
      <p class="title">Comparison</p>
      <p class="number">
        <span>{{ number }}</span> out of <span>5</span> places
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["dark", "size"],
  data() {
    return {};
  },
  methods: {},
  computed: {
    number() {
      return this.$sessionStore.state.study.locations.length;
    },
  },
};
</script>
<style lang="scss" scoped>

.max {
  font-weight: normal;
}
.light {
  background: #ffffff;

  .title {
    color: #12263f;
  }

  .icon {
    background: #eaf2fd;
  }

  .number {
    span {
      color: black;
    }
    color: #6e84a3;
  }
}

.blue {
  background: rgba(44, 123, 229, 1);

  .title {
    color: white;
    font-size: 16px;
  }

  .icon {
    background: rgba(234, 242, 253, 1);
  }

  .number {
    span {
      color: black;
    }
    color: rgba(150, 189, 242, 1);
  }
}

.large {

  .column {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 18px;
    line-height: 23px;
    margin: auto 0;
  }

  .icon {
    width: 48px;
    height: 48px;
    padding-top: 15px;
    padding: 8px;
    border-radius: 4px;
    margin: 16px 24px;
  }

  .number {
    font-size: 14px;
    margin: auto 0;
  }

}

.small {

  gap: 10px;
  
  .column {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }

  .title {
    font-size: 16px;
    margin: auto 0;
  }

  .icon {
    width: 48px;
    height: 48px;
    padding-top: 15px;
    padding: 8px;
    border-radius: 4px;
  }

  .number {
    font-size: 12px;
    margin: auto 0;
  }
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  .column {
    flex-direction: column;
  }
  .title {
    font-weight: bold;
    margin: auto 0;
    font-size: 16px;
  }
  .icon {
    display: flex;
    justify-content: center;
  }
  .number {
    font-weight: bold;
    margin: auto 0;
  }
}
</style>