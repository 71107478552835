<template>
  <div id="map-container">
    <div class="embed-responsive">
      <StudyMap />
    </div>
  </div>
</template>

<script>
import StudyMap from "@/components/shared/StudyMap";
import { bus } from "@/main";

export default {
  components: {
    StudyMap,
  },
  data() {
    return {
    };
  },
  mounted() {
    bus.$off("closeButton");
    bus.$on("closeButton", (evt) => {
      if (evt.id === "layerPicker") {
        this.toggleSidebar();
      }
    });

    bus.$off("studyMapLoading");
    bus.$on("studyMapLoading", (loading) => {
      this.loading = loading;
    });
  },
  methods: {
    // toggleSidebar() {
    //   this.sidebarCollapsed = !this.sidebarCollapsed;
    //   bus.$emit("resizeMap");
    // },
  },
};
</script>

<style lang="scss" scoped>
#map-container {
  width: 100%;
  height: 100%;
  display: flex;

  .right-sidebar {
    height: 100%;
    width: 400px;
    background-color: white;
    border-left: 1px solid $border-color;

    .collapsed-sidebar {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .insights-icon {
        margin-top: 12px;
      }

      .layer-icon {
        margin-top: 12px;
      }
    }
  }

  .collapsed {
    width: 80px;
  }

  .mapboxgl-ctrl-top-left {
    margin-top: 20px;
    margin-left: 20px;
    z-index: 4;
  }

  .mapboxgl-ctrl-geocoder {
    width: 328px;
    height: 40px;
    border-radius: 6px;
  }

  .embed-responsive,
  .mapboxgl-canvas,
  .mapboxgl-map,
  canvas {
    height: 100%;
  }

  .embed-responsive {
    position: relative;
    flex: 1;
  }
}

.header-container {
  background: #0062ff;
  border-radius: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  padding: 3px 20px;
}
</style>
