<template>
<div>
  <button
    v-for="item in Object.keys(timeButtons)"
    :key="item"
    @click="select(item); toggle()"
    class="timeSelectionBtn"
    :class="{ activeTimeBtn: timeButtons[item] }"
  >
    {{ item }}
  </button>

  <!-- <p>
    Shortcuts:
    <template v-for="option in time_of_day_options">
      <a
        @click="
          shortcut(option.label, option.values)
        "
        class="shortcut"
        :value="option.values"
        :key="option.label"
      >
        {{ option.label }}
      </a>
    </template>
  </p> -->
</div>
</template>

<script>
import { bus } from "@/main";
import moment from "moment";

export default {
  name: 'tod-time-grid',
  props: ['checked', 'selection', 'toggleSelectedLayer'],
  data() {
    return {
      timeButtons: {
        "12AM": true,
        "1AM": true,
        "2AM": true,
        "3AM": true,
        "4AM": true,
        "5AM": true,
        "6AM": true,
        "7AM": true,
        "8AM": true,
        "9AM": true,
        "10AM": true,
        "11AM": true,
        "12PM": true,
        "1PM": true,
        "2PM": true,
        "3PM": true,
        "4PM": true,
        "5PM": true,
        "6PM": true,
        "7PM": true,
        "8PM": true,
        "9PM": true,
        "10PM": true,
        "11PM": true,
      },
    };
  },
  mounted() {
    bus.$off("timeButtonsOn");
    bus.$on("timeButtonsOn", () => {
      for (const time in this.timeButtons) {
        this.timeButtons[time] = true;
      }
      this.$sessionStore.commit("setTODTime", this.selected);
    });

    bus.$off("timeButtonsOff");
    bus.$on("timeButtonsOff", () => {
      for (const time in this.timeButtons) {
        this.timeButtons[time] = false;
      }
      this.$sessionStore.commit("setTODTime", this.selected);
    });
  },
  computed: {
    time_of_day_options() {
      return [
        { 
          label: "All", 
          values: [
            '12am', '1am', '2am', '3am', 
            '4am', '5am', '6am', '7am', 
            '8am', '9am', '10am', '11am', 
            '12pm', '1pm', '2pm', '3pm', 
            '4pm', '5pm', '6pm', '7pm', 
            '8pm', '9pm', '10pm', '11pm'
          ] 
        },
        { label: "Breakfast", values: ['6am', '7am', '8am', '9am', '10am'] },
        { label: "Lunch", values: ['11am', '12pm', '1pm'] },
        {
          label: "Afternoon",
          values: ['2pm', '3pm', '4pm'],
        },
        { label: "Dinner", values: ['5pm', '6pm', '7pm'] },
        { label: "Evening", values: ['8pm', '9pm', '10pm'] },
        {
          label: "Night",
          values: ['11pm', '12am', '1am', '2am', '3am', '4am', '5am'],
        },
      ];
    },
    selected() {
      const selectedArr = Object.keys(this.timeButtons).filter(key => this.timeButtons[key]).map(key => moment(key, ['ha']).format('HH:mm'));
      return selectedArr.join(',');
    },
    timeOfDayActive() {
      return this.$sessionStore.state.studyMap.ci.pd.api_params.time_of_day.length > 0;
    },
    status() {
      return this.$sessionStore.state.studyMap.ci.status.pd;
    }
  },
  methods: {
    toggle() {
      for (const button in this.timeButtons) {
        if (this.timeButtons[button]) {
          this.$emit("toggleOn");
        }
      }
    },
    select(item) {
      this.timeButtons[item] = !this.timeButtons[item];
      this.$emit('newSelection', this.selected);
    },
    // shortcut(label, values) {
    //   const shouldClear = values.every(val => this.timeButtons[val]);
    //   if (shouldClear) {
    //     values.forEach(val => this.timeButtons[val] = false);
    //   } else {
    //     values.forEach(val => this.timeButtons[val] = true);
    //   }
    //   this.$emit('newSelection', this.selected);
    // }
  },
  watch: {
    timeOfDayActive() {
      this.toggleSelectedLayer(this.status, this.selection);
    }
  }
}
</script>

<style lang="scss" scoped>

.timeSelectionBtn {
  height: 32px;
  width: 48px;
  background-color: #ffffff;
  color: #6e84a3;
  border: 0.5px solid #e3ebf6;
  margin-left: 0px;
  margin-right: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 14px !important;
  padding: 0;
}

.activeTimeBtn {
  background-color: #2c7be5 !important;
  color: white !important;
}

.shortcut {
  text-decoration: underline;
  margin-right: 2px;
  cursor: pointer;
  color: #6e84a3;
}

</style>