import { p2pReports } from "../../../components/dataExplorer/reportTypes";

export function validateDatasetSelections() {
  if (this.isReportIdSelected('GENERATOR_REPORT') && this.generatorSecondsBefore === undefined) return false;
  if (this.isReportIdSelected('ESTIMATED_VISITS_2_REPORT')) {
    if (this.evOptions.dwellTime === undefined) return false;
    if (this.evOptions.category === '') return false;
  }
  if (this.isReportIdSelected('LOCATION_AFFINITY_REPORT')) {
    if (this.locationAffinityReportOptions === undefined) return false;
    if (this.locationAffinityReportOptions.minDate === undefined) return false;
    if (this.locationAffinityReportOptions.maxDate === undefined) return false;
  }
  if (this.isReportIdSelected(['CLUSTER_REPORT', Object.keys(p2pReports[0].formats)])) {
    if (this.secondsBefore === undefined || this.secondsAfter === undefined) return false;
  }
  if (this.isReportIdSelected('CLUSTER_REPORT')) {
    if (this.clusterReportOptions === undefined || this.clusterReportOptions.minDevices === undefined || this.clusterReportOptions.speedLimit === undefined) return false;
  }
  if (this.isReportIdSelected('OGS')) {
    if (this.locationReportOptions === undefined) return false;
    if (this.locationReportOptions.ogsSecondsBefore === undefined || this.locationReportOptions.ogsSecondsAfter === undefined) return false;
    if (this.minDevices === undefined) return false;
  }
  if (this.isReportIdSelected("DWELL_TIME")) {
    if (this.dwellOptions === undefined) return false;
    if (this.dwellOptions.dwellSecondsBefore === undefined || this.dwellOptions.dwellSecondsAfter === undefined) return false;
    if (this.dwellOptions.dwellMinTime === undefined || this.dwellOptions.outerWindowMaxTime === undefined || this.dwellOptions.visitSeparationTime === undefined) return false;
    if (this.dwellOptions.employeeMinDwellTime === undefined || this.dwellOptions.employeeMinDays === undefined) return false;
  }
  const hasReportSelected = this.selectedReportIds.size > 0;
  return hasReportSelected;
}