<template>
  <div class="main-container">
    <p v-if="label !== undefined">{{ label }}</p>
    <div class="radio-buttons-container">
      <template v-for="option in options">
        <div class="button-container" :key="option">
          <b-form-radio
            v-model="selected"
            type="radio"
            :name="options.join(',')"
            :id="option"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </template>
    </div>
    <p class="subtext" v-if="subtext !== undefined">{{ subtext }}</p>
  </div>
</template>
<script>
export default {
  props: ["options", "default", "label", "subtext"],
  mounted() {
    this.selected = this.default;
  },
  data() {
    return {
      selected: undefined,
    };
  },
  methods: {
    setSelected(value) {
      this.selected = value;
    }
  },
  watch: {
    selected() {
      this.$emit("change", this.selected);
    },
  },
};
</script>
<style lang="scss" scoped>

.main-container {
  margin-bottom: 1rem;
}

.radio-buttons-container {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;

  .button-container {
    border-bottom: 0.5px solid #e3ebf6;

    div {
      display: inline-block;
    }

    label {
      cursor: pointer;
    }
  }

  .button-container:not(:first-child) {
    padding-left: 1rem;
  }
}
</style>