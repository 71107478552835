export function provisionAvatar(location) {
  // return !location.avatar_subsector || location.avatar_subsector === 'None' ? 
  //   (location.avatar_sector && location.avatar_sector !== 'None' ? 
  //     {type: 'sector', url: location.avatar_sector} 
  //     : {type: 'default', url: 'https://pinnacle-avatars.ubermedia.com.s3.amazonaws.com/prod/Default.svg'}) 
  //   : {type: 'subsector', url: location.avatar_subsector};
  return !location.avatar_subsector || location.avatar_subsector === 'None' || !location.avatar_subsector.endsWith("svg") ? 
  (location.avatar_sector && location.avatar_sector !== 'None' ? 
    {type: 'sector', url: location.avatar_sector} 
    : {type: 'default', url: 'https://pinnacle-avatars.ubermedia.com.s3.amazonaws.com/prod/Default.svg'}) 
  : {type: 'subsector', url: location.avatar_subsector};
}