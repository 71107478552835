<template>
  <div>
    <div
      class="left-nav-lightbox"
      v-if="isToggled"
      @click="toggleLeftNav"
    ></div>
    <nav
      class="
        navbar navbar-vertical
        fixed-start
        navbar-expand-md navbar-light
        sidebar-collapsed
      "
      :class="{ 'sidebar-expanded': isToggled }"
    >
      <div class="container-fluid">
        <a class="navbar-brand" @click="() => {navigate({name: 'home-datasets', path: '/home/datasets', meta: { appName: 'pinnacle' }})}">
          <span>
            <inline-svg
              :src="require('../../assets/icon/pinnacle-sidebar-logo.svg')"
            />
          </span>
        </a>
        <div class="collapse navbar-collapse">
          <div>
            <!-- Divider -->
            <hr class="navbar-divider" />

            <!-- Navigation -->
            <ul
              class="navbar-container-list navbar-nav"
              :class="{
                'd-flex': !isToggled,
                'align-items-center': !isToggled,
              }"
            >
              <NavItem
                :isToggled="isToggled"
                :currentRoute="currentRoute.includes('/home')"
                title="Home"
                container=".navbar-container-list"
                :navigate="() => {navigate({name: 'home-feeds', path: '/home/feeds', meta: { appName: 'pinnacle' }})}"
                :icon="require('../../assets/icon/home-unselected.svg')"
                :iconSelected="require('../../assets/icon/home-selected.svg')"
                v-if="canSee('home')"
              ></NavItem>

              <NavItem
                :isToggled="isToggled"
                :currentRoute="currentRoute.includes('/explore') || currentRoute.includes('/study') || currentRoute.includes('/preview')"
                title="Places"
                container=".navbar-container-list"  
                :navigate="() => {navigate({name: 'explore', path: '/explore', meta: { appName: 'pinnacle' }})}"
                :icon="require('../../assets/icon/places-pin-unselected.svg')"
                :iconSelected="require('../../assets/icon/places-pin-selected.svg')"
                v-if="canSee('explore')"
              ></NavItem>

              <!-- <NavItem
                :isToggled="isToggled"
                :currentRoute="currentRoute.includes('/export-data')"
                title="Data"
                container=".navbar-container-list"
                :navigate="() => {navigate({name: 'export-data', path: '/export-data', meta: { appName: 'pinnacle' }})}"
                :icon="require('../../assets/icon/database-unselected.svg')"
                :iconSelected="require('../../assets/icon/database-selected.svg')"
                v-if="canSee('export-data')"
              ></NavItem> -->

              <!-- <NavItem
                :isToggled="isToggled"
                :currentRoute="currentRoute.includes('feed')"
                title="Feed Factory"
                container=".navbar-container-list"
                :navigate="() => {navigate({name: 'client-view', path: '/', meta: { appName: 'feedFactory' }})}"
                :icon="require('../../assets/icon/server-unselected.svg')"
                :iconSelected="require('../../assets/icon/server-selected.svg')"
              ></NavItem> -->
              
            </ul>

            <!-- Divider -->
            <hr class="navbar-divider" />

            <!-- Navigation -->
            <ul
              :class="{
                'd-flex': !isToggled,
                'align-items-center': !isToggled,
              }"
              class="help-links navbar-nav"
            >
              <NavItem
                :isToggled="isToggled"
                title="Documentation"
                container=".help-links"
                :navigate="() => {navigate({name: 'documentation', path: '/documentation', meta: { appName: 'pinnacle' }})}"
                :icon="require('../../assets/icon/documentation.svg')"
                :iconSelected="require('../../assets/icon/documentation-selected.svg')"
              >
                <img 
                  src="../../assets/icon/external-link.svg"
                  class="external-link-img" />
              </NavItem>

              <NavItem
                :isToggled="isToggled"
                :currentRoute="currentRoute.includes('/customer-support')"
                title="Customer Support"
                container=".help-links"
                :navigate="() => {navigate({name: 'customer-support', path: '/customer-support', meta: { appName: 'pinnacle' }})}"
                :icon="require('../../assets/icon/customer-support.svg')"
                :iconSelected="require('../../assets/icon/customer-support-selected.svg')"
              ></NavItem>

              <NavItem
                :isToggled="isToggled"
                :currentRoute="currentRoute.includes('/settings')"
                title="Settings"
                container=".help-links"
                :navigate="() => {navigate({name: 'settings', path: '/settings', meta: { appName: 'pinnacle' }})}"
                :icon="require('../../assets/icon/settings.svg')"
                :iconSelected="require('../../assets/icon/settings-selected.svg')"

              ></NavItem>
            </ul>
          </div>
          <div class="navbar-user d-none d-md-flex" id="sidebar-user">
            <div>
              <img
                src="../../assets/icon/open.svg"
                class="avatar-img cursor-pointer"
                alt="Expand sidebar"
                @click="toggleLeftNav"
                v-if="!isToggled"
              />
              <img
                src="../../assets/icon/collapse.svg"
                class="avatar-img cursor-pointer"
                alt="Collapse sidebar"
                @click="toggleLeftNav"
                v-if="isToggled"
              />
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import NavItem from '../internal/NavItem.vue';

const props = ['router', 'route', 'permissions', 'app'];

export default {
  name: "LeftNav",
  components: { NavItem },
  props,
  data() {
    return {
      isToggled: false,
    };
  },
  computed: {
    currentRoute() {
      return this.route.path;
    }
  },
  methods: {
    toggleLeftNav() {
      this.isToggled = !this.isToggled;
    },
    navigate(routerParams) {
      if (routerParams.meta.appName !== this.app) {
        const environment = this.getEnvironment();
        window.location.replace(this.getOrigin(routerParams.meta.appName, environment) + routerParams.path);
        return;
      }
      this.router.push(routerParams);
    },
    canSee(routeName) {
      return this.hasPermissions(this.routePermissions[routeName], this.permissions, false);
    }
  },
};
</script>

<style lang="scss" scoped>

::v-deep .cursor-pointer {
  cursor: pointer;
}
.navbar-brand {
  cursor: pointer;
  align-self: center;
}
.left-nav-lightbox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 250px !important;
  background-color: #1f2d3d;
  opacity: 0.5;
  display: block;
  z-index: 100000;
}

.navbar-collapse {
  justify-content: space-between;
}

.sidebar-collapsed {
  width: 80px !important;
  overflow: hidden;
  background-color: #162e4c;
  ::v-deep .nav-item {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    span {
      display: none;
    }
    .external-link-img {
      display: none;
    }
  }
}

.sidebar-expanded {
  width: 280px !important;
  overflow: hidden;
  background-color: #162e4c;
  ::v-deep .nav-item {
    justify-content: left;
    span {
      display: block;
    }
    .external-link-img {
      display: block;
    }
  }
  ::v-deep .nav-link {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #6e84a3 !important;
    opacity: 0.5 !important;
    cursor: pointer;

    &.highlight-color {
      opacity: 1 !important;
    }

    &.sub-link {
      color: #95aac9 !important;
      text-transform: uppercase !important;
      font-size: 10px;
      letter-spacing: 0.08rem;
      opacity: 1 !important;
      margin-top: 6px;
    }

    .icon-container {
      margin-right: 16px;
      margin-left: 4px;
      display: flex;
      align-items: center;
    }
  }
}

.navbar-divider {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  border-color: #2b415d !important;
}

.above-expand {
  width: 100% !important;
}

.navbar {
  border-bottom: none;
}

#sidebar-user {
  border-top: 1px solid #2b415d;
}

</style>
