var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('OptionsPanel',[_c('Option',[_c('PSelection',{attrs:{"title":"Location Visitor Datasets","desc":"These datasets will provide information on time of visits, and exact location visits.","reports":_vm.locVisReportsFiltered,"isReportActive":_vm.isReportActive},on:{"selectReport":_vm.selectReport}})],1)],1),_c('OptionsPanel',[_c('Option',[_c('PSelection',{attrs:{"title":"Foot Traffic Datasets","desc":"These datasets will provide trend and summary information on visitors to a location.","reports":_vm.footTrafficReports,"isReportActive":_vm.isReportActive},on:{"selectReport":_vm.selectReport},scopedSlots:_vm._u([{key:"option",fn:function(){return undefined},proxy:true}])})],1)],1),_c('OptionsPanel',[_c('Option',[_c('PSelection',{attrs:{"multiSelect":true,"title":"Common Evening and Common Daytime Location Datasets","desc":"These datasets will provide information about where your visitors spend their time in the evenings and workdays.","reports":_vm.CECDLocationReportsFiltered,"isReportActive":_vm.isReportActive},on:{"selectReport":_vm.selectReport},scopedSlots:_vm._u([{key:"inline-option",fn:function(){return [(
              _vm.jobRequest.didReportTypes.some((id) =>
                id.includes('CEL_CDL')
              ) ||
              (_vm.jobRequest.didReportType !== undefined &&
                _vm.jobRequest.didReportType.includes('CEL_CDL'))
            )?[_c('CEDEReport')]:_vm._e()]},proxy:true},{key:"option",fn:function(){return [(
              _vm.isReportIdSelected('RESIDENT_WORKER_REPORT') ||
              _vm.isReportIdSelected('VISITORS_HOME_REPORT') ||
              _vm.isReportIdSelected('CEL_CDL_LOCATION_REPORT')
            )?[_c('ResidentWorkerReport')]:_vm._e(),(
              _vm.isReportIdSelected('GENERATOR_REPORT') &&
              _vm.hasPermission(
                'Network',
                _vm.$persistingStore.state.user.permissions
              )
            )?[_c('GeneratorReport')]:_vm._e()]},proxy:true}])})],1)],1),(!_vm.isLargeJob)?_c('OptionsPanel',[_c('Option',[_c('PSelection',{attrs:{"multiSelect":true,"title":"Visitor/Behavioral Datasets","desc":"These datasets provide information about behavior of the visitors seen in a particular location over a set timeframe.","reports":_vm.visitorBehavioralReports,"isReportActive":_vm.isReportActive},on:{"selectReport":_vm.selectReport},scopedSlots:_vm._u([{key:"option",fn:function(){return [(_vm.isReportIdSelected('GEOSOCIAL_AFFINITY_REPORT'))?[_c('GeoSocialAffinity')]:_vm._e(),(_vm.isReportIdSelected('LOCATION_AFFINITY_REPORT'))?[_c('LocationAffinityReport')]:_vm._e()]},proxy:true}],null,false,3576846772)})],1)],1):_vm._e(),(!_vm.isLargeJob)?_c('OptionsPanel',[_c('Option',[_c('PSelection',{attrs:{"title":"Path-to-Purchase Datasets","desc":"These datasets will provide information on where visitors were before or after visiting a location, or movement patterns during a time period.","reports":_vm.p2pReports,"isReportActive":_vm.isReportActive},on:{"selectReport":_vm.selectReport},scopedSlots:_vm._u([{key:"option",fn:function(){return [(_vm.isReportIdSelected(_vm.pathingOptions))?[_c('PathingReportOptions'),(_vm.showJSON)?[_c('POIjson')]:_vm._e()]:_vm._e(),(_vm.isReportIdSelected('CLUSTER_REPORT'))?[_c('ClusterReportOptions')]:_vm._e()]},proxy:true}],null,false,898527078)})],1)],1):_vm._e(),(!_vm.isLargeJob)?_c('OptionsPanel',[_c('Option',[_c('PSelection',{attrs:{"title":"Location Datasets","desc":"Understand further information about your locations, including trade areas and dwell times.","reports":_vm.locationReports,"isReportActive":_vm.isReportActive},on:{"selectReport":_vm.selectReport},scopedSlots:_vm._u([{key:"option",fn:function(){return [(_vm.isReportIdSelected('OGS'))?[_c('OGSReport')]:_vm._e(),(_vm.isReportIdSelected('DWELL_TIME'))?[_c('DwellTimeReport')]:_vm._e()]},proxy:true}],null,false,1199358309)})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }