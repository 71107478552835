<template>
  <div class="report-selection-container" :class="clazz">
    <h2 v-if="title">{{ title }}</h2>
    <p class="desc graytext" v-if="desc">{{ desc }}</p>
    <p class="warn graytext" v-if="warn">{{ warn }}</p>
    <div class="split-pane">
      <div class="report-list">
        <template v-for="report in reports">
          <div
            class="report-item"
            v-if="report.id"
            :key="report.id"
            @click="selectReport(report)"
            v-bind:class="{ active: isReportActive(report) }"
          >
            <p>{{ report.name }}</p>
          </div>
          <slot name="inline-option" v-if="report.id === 'BASE_CEL_CDL_REPORT'"></slot>
        </template>
      </div>
      <div class="report-description">
        <slot name="description"></slot>
        <template v-if="selectedReports.length > 0">
          <div v-if="selectedReports.slice(-1)[0].formats !== undefined">
            <p v-if="currentFormat">{{ currentFormat.question }}</p>
            <p v-if="currentFormat" class="report-title">
              {{ currentFormat.addTitle }}
            </p>

            <p class="graytext" v-if="currentFormat">{{ currentFormat.addInfo }}</p>
            <p class="graytext" v-if="currentFormat">{{ currentFormat.addInfo2 }}</p>
          </div>

          <div v-else>
            <p v-if="selectedReports.slice(-1)[0].question">{{ selectedReports.slice(-1)[0].question }}</p>
            <p class="graytext" v-if="selectedReports.slice(-1)[0].addInfo">{{ selectedReports.slice(-1)[0].addInfo }}</p>
            <p class="graytext" v-if="selectedReports.slice(-1)[0].addInfo2" style ="font-style: italic;" >{{ selectedReports.slice(-1)[0].addInfo2 }}</p>
          </div>
        </template>
      </div>
    </div>
    <slot name="option"></slot>
  </div>
</template>

<script>

export default {
  name: 'PSelection',
  mounted() {},
  props: ["title", "desc", "warn", "multiSelect", "isReportActive", "reports", "clazz"],
  data() {
    return {
      selectedReports: []
    };
  },
  methods: {
    selectReport(report) {
      let action = 'remove';
      if (this.isReportActive(report)) {
        this.selectedReports = this.selectedReports.filter((_report) => (_report.id !== report.id));
      } else {
        action = 'add';
        if (!this.multiSelect) this.selectedReports.shift();
        this.selectedReports.push(report);
      }
      this.$emit('selectReport', {action, report});
    }
  },
  computed: {
    currentFormat() {
      const currentReport = this.selectedReports.slice(-1)[0];
      if (currentReport.formats !== undefined) {
        for (const formatId in currentReport.formats) {
          if (this.isReportActive(currentReport.formats[formatId]))
            return currentReport.formats[formatId];
        }
      }
      return null;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.report-selection-container {

  .desc {
    margin-bottom: 1em;
  }

  .warn {
    margin-bottom: 1em;
    color:red;
  }

  .split-pane {
    display: flex;
  }

  .report-list {
    width: 50%;

    .report-item {
      p {
        padding: 1rem;
        margin: 0;
      }
      &:hover {
      cursor: pointer;
      }
      
      border-bottom: 0.5px solid #e3ebf6;
    }

    .active {
      background-color: #e3ebf6;
    }
  }

  .report-description {
    flex: 1;
    margin-left: 1rem;

    .report-title {
      font-size: 14px;
      font-weight: bold;

    }
    p {
      font-weight: 300;
      line-height: 1.2em;
      margin-bottom: 1rem !important;
    }
  }
}

.report-description .report-title {
  font-weight: 700 !important;
}
</style>