<template>
    <div class="textContainer">
        <img
            :src="require('pinnacle-lib/assets/icon/pinnacle-logo.svg')"
            style="height: 76px;width: 96px"
            class="forgot-pw-brand-img center-block mb-3"
            alt="..."
        />
        <h1>Your access to Pinnacle Places has expired. Please contact your {{ Constants.company_name }} sales representative to renew your access.</h1>
    </div>
</template>

<script>
import { ConstantsService } from "@/services/constants.js";

export default {
  mixins: [ConstantsService],
}
</script>

<style lang="scss" scoped>
    .textContainer {
        text-align: center;
        margin-top: 20%;
    }
</style>