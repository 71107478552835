<template>
  <div id="home-page" class="home-page-container">
    <div v-if="showBanner">
    <b-alert show dismissible variant="warning" style="position: absolute; z-index: 1000;width: calc( 100vw - 100px )">
      <!--We are experiencing issues which may result in processing delays for Pinnacle Reports and Datasets.
      We apologize for the inconvenience.-->
      The queue is active and running. However, there is a higher than normal volume of jobs, therefore completion times may be delayed.
    </b-alert>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { GetMixin } from "pinnacle-lib";
export default {
  mixins: [GetMixin],
  data() {
    return {
      showBanner: false,
    };
  },
  mounted() {
    console.log('HomePage mounted');
    this.loadBanners();
  },
  methods: {
    async loadBanners() {   
      let urlStr = `${process.env.VUE_APP_PINNACLE_API}/v2/active-banners`;
      let data = await this.getFromURL(urlStr, { });

      console.log(data);
      if (data?.records?.length > 0) {
        this.showBanner = true;
      } else {
        this.showBanner = false;
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page-container {
  overflow: auto;
}
</style>