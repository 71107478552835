


export const LayerType = {
  COMMUNITY: {
    name: 'Community',
    prefix: 'community_layers'
  },
  PLACE: {
    name: 'Place',
    prefix: 'place_insights'
  }
};

export const VisualizationType = {
  PLACES_CLUSTER: {
    name: 'PlacesCluster',
    prefix: 'pcl',
    isVectorTile: true,
    isCluster: true,
    layerType: LayerType.PLACE.name
  },
  PLACES_CHOROPLETH: {
   name: 'PlacesChoropleth',
    prefix: 'pch',
    isVectorTile: true,
    isCluster: true,
    maxzoom: 10,
    layerType: LayerType.PLACE.name
  },
  HEXBIN: {
    name: 'Hexagon',
    prefix: 'wh',
    layerType: LayerType.PLACE.name,
    hasPopup: true,
    batch: true,
    needsDateRange: true
  },
  HEATMAP: {
    name: 'Heatmap',
    prefix: 'wh',
    layerType: LayerType.PLACE.name,
    needsDateRange: true
  },
  ADMIN: {
    name: 'Geographic Areas',
    prefix: 'wh',
    layerType: LayerType.PLACE.name,
    hasPopup: true,
    hasBoundary: true,
    batch: true,
    needsDateRange: true
  },
  ROUTES: {
    name: 'Routes',
    prefix: 'routes',
    layerType: LayerType.PLACE.name,
    needsDateRange: true
  },
  CL: {
    name: 'CompetitiveLandscape',
    prefix: 'cl',
    isVectorTile: true,
    isCluster: true,
    layerType: LayerType.PLACE.name
  },
  PD: {
    name: 'PulseDaypart',
    prefix: 'pd',
    layerType: LayerType.COMMUNITY.name,
    isCommunity: true,
    isVectorTile: true,
    hasPopup: true,
    hasBoundary: true
  },
  GT: {
    name: 'GrowthTrends',
    prefix: 'gt',
    layerType: LayerType.COMMUNITY.name,
    isCommunity: true,
    isVectorTile: true,
    hasPopup: true,
    hasBoundary: true
  }
};

export const GeoType = {
  CENSUS: 'Census',
  POSTAL: 'Postal',
  ADMIN: 'Admin'
};

export const CommunityLayerTypes = Object.values(VisualizationType).filter(val => val.layerType === LayerType.COMMUNITY.name);
export const PlaceLayerTypes = Object.values(VisualizationType).filter(val => val.layerType === LayerType.PLACE.name);

export function createBatchId(type, locations) {
  const locationIdString = btoa(locations.map(loc => loc.id).join(','));
  return `${type.prefix}_${type.name}_${locationIdString}_batch`;
}

export function createCommunityId(type) {
  return `${type.prefix}_${type.name}`;
}

export function createId(type, locationId) {
  return `${type.prefix}_${type.name}_${locationId}`;
}

export function getNameFromId(layerId) {
  return layerId.split('_')[1];
}

export function getTypeByName(name) {
  return Object.values(VisualizationType).filter(val => val.name === name)[0]
}

export function getTypeById(layerId) {
  const typeName = getNameFromId(layerId);
  return getTypeByName(typeName);
}

export function isCommunityLayerById(layerId) {
  const type = getTypeById(layerId);
  return type.layerType === LayerType.COMMUNITY.name;
}

export function isPlaceLayerById(layerId) {
  const type = getTypeById(layerId);
  return type.layerType === LayerType.PLACE.name;
}

export function isCommunityLayerByName(name) {
  const search = CommunityLayerTypes.filter(val => val.name === name);
  return search.length === 1;
}