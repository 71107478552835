<template>
  <div>
    <!-- <Searchbar class="searchbar" /> -->
    <div class="explore-container">
      <SearchPane></SearchPane>
      <SearchMapContainer></SearchMapContainer>
    </div>
    <b-modal id="success-register-modal" hide-footer hide-header>
      <div
        class="p-4 d-flex flex-column justify-content-center align-items-center"
      >
        <img :src="require('pinnacle-lib/assets/img/check-circle-fill.svg')" />
        <h2 class="mt-4">Your registration is complete!</h2>
        <p class="text-center">
          We are glad to have you here at Pinnacle! Start exploring the maps now
          or create a new study.<span role="img"> 📖 </span>
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RouteGuard from "pinnacle-lib/mixins/RouteGuard";
import SearchPane from "@/components/search/SearchPane";
import SearchMapContainer from "@/components/locations/SearchMapContainer";

export default {
  mixins: [RouteGuard],
  mounted() {
    const successRegister = this.$store.state.auth.successRegister;
    if (successRegister) {
      this.email = successRegister;
      this.$bvModal.show("success-register-modal");
      this.$store.commit("setSuccessRegister", "");
    }
  },
  components: { SearchPane, SearchMapContainer }
  // components: { Searchbar },
};
</script>

<style lang="scss" scoped>
.explore-container {
  display: flex;
  height: 100%;
  width: 100%;
}
::v-deep .modal-backdrop {
  box-shadow: inset 0 0 2000px hwb(0deg 100% 0% / 50%) !important;
  background-color: rgb(22 34 68 / 50%) !important;
  backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
}
</style>