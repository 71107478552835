<template>
  <div class="d-flex justify-content-between date-filters-card">
    <div class="dtp" @click="showDatePicker">
      <div class="filterCardDateRange">
        <img
          :src="require('pinnacle-lib/assets/icon/calendar-chart.svg')"
          class="drpCalendar"
          style="height: 18px; width: 18px"
        />
      </div>
      <div class="selectedDates">{{ selectedDates }}</div>
      <img
        :src="require('pinnacle-lib/assets/icon/arrow-down-select.svg')"
        class="calendarArrow"
        style="height: 8px; width: 8px; margin-left: 10px; margin-right: 4px"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { momentDateFormat, momentParseDateFormats } from "@/config/chart";
import { bus } from "@/main";

export default {
  data() {
    return {
      selectedDateRange: {},
      // startDate: moment()
      //   .subtract(1, "year")
      //   .subtract(2, "days")
      //   .format("MM/DD/YY"),
      // endDate: moment().subtract(2, "days").format("MM/DD/YY"),
      startDate: "",
      endDate: "",
      isFixed: false,
      isChartBusReady: false,
      dateRangeError: "",
    };
  },
  computed: {
    isLoadingToLocation() {
      return this.$sessionStore.state.study.loading;
    },
    selectedDates() {
      const startDateMomentSelected = moment(
        this.startDate,
        momentParseDateFormats
      );
      const endDateMomentSelected = moment(
        this.endDate,
        momentParseDateFormats
      );
      return (
        startDateMomentSelected.format("MM/DD/YY") +
        " - " +
        endDateMomentSelected.format("MM/DD/YY")
      );
    },
  },
  mounted() {
    this.isChartBusReady = false;
    bus.$off("chartBusReady");
    bus.$on("chartBusReady", () => {
      this.isChartBusReady = true;
      bus.$emit("setFilters", {
        start_date: this.startDate,
        end_date: this.endDate,
      });
    });

    bus.$off('update-date-range');
    bus.$on("update-date-range", (newStartDate, newEndDate) => {
      this.startDate = newStartDate;
      this.endDate = newEndDate;
      this.updateSelectedDateRange(this.startDate, this.endDate);
    });
    
    //set start and end dates
      if (Object.keys(this.$sessionStore.state.study.dateRange).length === 0) {
        this.startDate = moment()
        .subtract(1, "year")
        .subtract(2, "days")
        .format("MM/DD/YY");
      this.endDate = moment().subtract(2, "days").format("MM/DD/YY");
    } else {
      this.startDate =
        this.$sessionStore.state.study.dateRange.start_date;
      this.endDate =
        this.$sessionStore.state.study.dateRange.end_date;
    }
  },
  methods: {
    updateSelectedDateRange(startDate, endDate) {
      console.log('updateSelectedDateRange');
      const startDateMoment = moment(startDate, momentParseDateFormats);
      const endDateMoment = moment(endDate, momentParseDateFormats);

      this.startDate = startDateMoment.format(momentDateFormat);
      this.endDate = endDateMoment.format(momentDateFormat);

      console.log(this.startDate, this.endDate);

      //commiting new dates to store
      let dateRange = {
        start_date: startDateMoment.format(momentDateFormat),
        end_date: endDateMoment.format(momentDateFormat),
      };
      this.$sessionStore.commit("setDateRange", dateRange);

      //setting filters for charts
      if (this.isChartBusReady) {
        bus.$emit("setFilters", {
          start_date: startDateMoment.format(momentDateFormat),
          end_date: endDateMoment.format(momentDateFormat),
        });
      }
    },
    showDatePicker() {
      bus.$emit("showDatePicker");
    },
  },
  // destroyed() {
  //   let dateRange = {
  //     start_date: moment()
  //       .subtract(1, "year")
  //       .subtract(2, "days")
  //       .format("MM/DD/YYYY"),
  //     end_date: moment().subtract(2, "days").format("MM/DD/YYYY"),
  //   };
  //   this.$sessionStore.commit("setDateRange", dateRange);
  // },
};
</script>

<style lang="scss" scoped>
.selectedDates {
  font-size: 12px;
  margin-left: 8px;
  font-weight: 600;
}
.dtp {
  background-color: white;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 38px;
  border: 0.1px solid #ebf2fd;
  width: 95%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.topBarDRP {
  margin-left: 8%;
  display: flex;
}

.filterCardDateRange {
  .filtercardDates {
    font-size: 14px;
    color: #1f2d3d;
    height: 23px;
  }
}

::v-deep .reportrange-text {
  width: 180px !important;
  height: 35px;
  align-items: center;
  display: flex;
}

::v-deep .reportrange-text {
  span {
    font-size: 12px !important;
    display: flex;
    font-weight: 500;
    align-self: center;
  }
}

.filterCardDateRange {
  margin-left: 8px;
  background-color: white;
  display: flex;
}
</style>
