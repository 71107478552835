<template>
  <div>
    <b-alert :show="calculatingJobSize && longCalculation" fade variant="warning" class="large-job-alert">
      Evaluating Dataset Job Size...
    </b-alert>

    <div class="alert alert-warning large-job-alert" v-if="isLargeJob">
      <p>We are currently upgrading our infrastructure for processing large reports. At this time, we are restricting
        reports that exceed 20 Million Sq Feet per Year.<br />
        <i class="large-job-example">(Examples: 20 million sqft &times; 1 year or 40 million sqft &times; 1/2 year)</i>
      </p>

      <p v-if="type == 'Datasets'">These jobs will be processed by a separate workflow that is not currently visible in the user interface. We are
        working on exposing these jobs to users over the coming development iterations. You will receive an email
        confirmation when these large jobs are completed. 
        If you have any questions or concerns please contact us at <a href="mailto:support@azira.com">support@azira.com</a>.
      </p>

      <p>The job you requested is
        <span style="color:red">{{ numberFormat(polygonMetaData.AreaXInterval / 1000000, { maximumFractionDigits: 1 })
          }}</span>
        Million Sq Feet per Year, which exceeds the 20 Million Sq Feet per Year limit. 

        <span v-if="type == 'Datasets'">You can either decrease the size of the place(s) requested or decrease the time frame for the requested data to process different report types.</span>
        <span v-if="type == 'Reports'">You can either decrease the size of the place(s) requested or decrease the time frame for the requested report.  If you have any questions or concerns please contact us at <a href="mailto:support@azira.com">support@azira.com</a>.</span>
      </p>
      <p v-if="type == 'Datasets'">
        Please select a report to proceed or select "Go Back" to change your job's criteria.
      </p>
      <p v-if="type == 'Reports'">
        Please click Back to update your Report's criteria.
      </p>
      <div class="card large-job-metrics">
        <div class="card-body">
          <dl class="dl-horizontal">
            <dt>Max Allowed Area &times; Timeframe Interval:</dt>
            <dd>20 Million Sq Feet per Year</dd>
            <dt>Current Area &times; Timeframe Interval: </dt>
            <dd><span style="color:red">{{ numberFormat(polygonMetaData.AreaXInterval / 1000000, {
                maximumFractionDigits: 1 }) }}</span>
              Million Sq Feet per Year
            </dd>
            <dt>Total Area:</dt>
            <dd>{{ numberFormat(polygonMetaData.TotalArea / 1000000, { maximumFractionDigits: 1 }) }} Million Sq feet</dd>
            <dt>Timeframe Interval:</dt>
            <dd>{{ numberFormat(polygonMetaData.TimeframeInterval, { maximumSignificantDigits: 3 }) }} Years</dd>
          </dl>
          <p>
            <b>Decrease Timeframe Interval to:</b>
            {{ intervalFormat(polygonMetaData.ValidInterval) }}, (decrease by {{ intervalFormat(polygonMetaData.IntervalDiff) }})
          </p>
          <p style="padding-left:15px">or</p>
          <p>
            <b>Decrease Area to:</b>
            {{ areaFormat(polygonMetaData.ValidArea) }} (decrease by {{ areaFormat(polygonMetaData.AreaDiff) }})
          </p>
          <div v-if="type == 'Datasets'">
            <p style="padding-left:15px">or</p>
            <p>
              <b>CONTINUE</b>
              but be limited to Location Visitor, Foot Traffic, and Common Evening and Common Daytime Datasets for this Job
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import { syncState } from '@/utils/syncState.js';
import * as dayjs from 'dayjs'
import * as duration from 'dayjs/plugin/duration';
dayjs.extend(duration);
import VPService from '@/services/dataExplorer/vpService.js';
import JobService from '@/services/dataExplorer/jobService.js';
import { ConstantsService } from "@/services/constants.js";
export default {
  mixins: [ConstantsService, VPService, JobService],
  props: ['type'],
  data() {
    return {
      calculatingJobSize: false,
      longCalculation: false,
    }
  },
  methods: {
    numberFormat(number, options) {
    	if (typeof number == "undefined") return 0;
    	if (typeof options == "undefined") options = {};
    	return new Intl.NumberFormat('en-IN', options).format(number);
    },
    intervalFormat(number) {
      let options = {
        maximumSignificantDigits: 3,
      }
      let value = dayjs.duration({years: number}).asMonths();
      let label = 'Months';
      if (value < 1) {
        value = dayjs.duration({years: number}).asDays();
        label = 'Days';
      }
      let str_value = new Intl.NumberFormat('en-IN', options).format(value);
      str_value += ` ${label}`;
      return str_value; 
    },
    areaFormat(number) {
      let options = {
        maximumSignificantDigits: 3,
      }
      let value = number / 1000000;
      let str_value = new Intl.NumberFormat('en-IN', options).format(value);
      str_value += ' Million Sq feet';
      return str_value;  
    },
  },
  async created() {
    this.isLargeJob = false;
    this.calculatingJobSize = true;
    this.longCalculation = false;
    setTimeout(() => this.longCalculation = true, 1000);
    let parsedGeojson = await this.parseGeojson();
    this.calculateAreaXInterval(parsedGeojson);
    this.calculatingJobSize = false;
    this.$emit('calculationDone');
  },
  computed: {
    canUseLargePolygons() {
      return this.$persistingStore.state.user.company.roles.find(
        (item) => item.name === "DataAPI Allow Large Polygons In CreateJob"
      ) || this.hasPermission("Super Admin", this.$persistingStore.state.user.permissions);
    },
    ...syncState({
      selectedLocationMethod: 'state.jobRequest.selectedLocationMethod',
      selectedPolygons: 'state.jobRequest.selectedPolygons',
      excludedPolygons: 'state.jobRequest.excludedPolygons',
      selectAllParams: 'state.jobRequest.selectAllParams',
      polygonInputOptions: 'state.jobRequest.polygonInputOptions',
      uploadInput: 'state.jobRequest.uploadInput',
      polygonString: 'state.jobRequest.polygonString',
      attachedFile: 'state.jobRequest.attachedFile',
      drawnPolygonGeoJSON: 'state.jobRequest.drawnPolygonGeoJSON',
      startDateTime: 'state.jobRequest.startDateTime',
      endDateTime: 'state.jobRequest.endDateTime',
      generatorSecondsBefore: 'state.jobRequest.generatorSecondsBefore',
      locationAffinityReportOptions: 'state.jobRequest.locationAffinityReportOptions',
      secondsBefore: 'state.jobRequest.secondsBefore',
      secondsAfter: 'state.jobRequest.secondsAfter',
      clusterReportOptions: 'state.jobRequest.clusterReportOptions',
      locationReportOptions: 'state.jobRequest.locationReportOptions',
      minDevices: 'state.jobRequest.minDevices',
      dwellOptions: 'state.jobRequest.dwellTimeReportOptions',
      jobName: 'state.jobRequest.reportOutputOptions.reportName',
      selectedPlacesValid: 'state.jobRequest.selectedPlacesValid|setSelectedPlacesValid',
      allowLargePolygons: "state.jobRequest.allowLargePolygons|setLargePoly",
      isLargeJob: "state.jobRequest.isLargeJob|setIsLargeJob"
    }, { context: this, defaultStore: '$sessionStore' })
  }
}
</script>

<style lang="scss" scoped>
.large-job-alert {
  margin-top:15px;
  background-color:#fff3cd;
  border-color: #fff3cd;
  p {
    margin-bottom:15px;
  }
  .large-job-example {
    font-size:14px;
  }
  .large-job-metrics {
    font-size:14px;
    p {
      margin-bottom:0;
    }
    dt {
      float: left;
      width: 320px;
      clear: left;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    dd {
      margin-left: 330px;
    }
  }
}

</style>