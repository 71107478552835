<template>
  <li
    class="nav-item cursor-pointer"
    :class="currentRoute ? 'side-selected' : 'side-unselected'"
    v-b-tooltip="!isToggled ? options : ''"
    :title="title"
    target="explorer-container"
    :container="container"
  >
    <div>
      <div class="nav-link highlight-color" @click="navigate">
        <div class="icon-container" id="explorer-container">
          <inline-svg
            :src="iconSelected"
            width="22px"
            height="22px"
            v-if="currentRoute"
          />
          <inline-svg
            :src="icon"
            width="22px"
            height="22px"
            v-else
          />
        </div>
        <span :class="{ selected: currentRoute === 'explorer' }"
          >{{ title }}</span
        >
        <slot></slot>
      </div>
    </div>
  </li>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
const props = ['isToggled', 'currentRoute', 'title', 'container', 'icon', 'iconSelected', 'navigate'];
export default {
  name: 'NavItem',
  props,
  components: { InlineSvg },
  data() {
    return {
      options: {
        animation: false,
        trigger: 'hover',
        placement: 'right',
        interactive: false,
        boundary: 'document',
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.nav-link .external-link-img {
  height: 14px;
  width: 14px;
  margin-left: 0.5em;
  margin-top: 0.1em;
}
.side-selected {
  border-left: 2px solid white;
  border-right: 2px solid transparent;
}
.side-unselected {
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}

.selected {
  color: white;
}

.tooltip {
  opacity: 1 !important;
  margin-left: 10px !important;
}
.tooltip-inner {
  padding: 9px !important;
}

</style>