import moment from 'moment';
import { p2pReports } from '../../components/dataExplorer/reportTypes';

export default {
  clusterReportOptions: {
    required: false,
    reportId: 'CLUSTER_REPORT',
    defaults: {
      "nx": 2048,
      "ny": 2048,
      "dx": 20,
      "dy": 20,
      "kx": 40,
      "ky": 40,
      "geoPrecision": 5,
      "maxExtent": 1000000,
      "pointsThreshold": 2,
      "contourLevel": 5,
      "minArea": 2500,
      "tileLimit": 500,
      "speedLimit": 5.0,
      "minDevices": 3
    },
    deleteFields: ['clusterReportOptions']
  },
  generatorSecondsBefore: {
    required: false,
    reportId: 'GENERATOR_REPORT',
    value: 86400,
    deleteFields: ['generatorSecondsBefore']
  },
  estimatedVisits2Options: {
    required: false,
    reportId: 'ESTIMATED_VISITS_2_REPORT',
    defaults: {
      dwellTime: 10,
      category: ''
    },
    deleteFields: ['estimatedVisits2Options']
  },
  locationAffinityReportOptions: {
    required: false,
    reportId: 'LOCATION_AFFINITY_REPORT',
    defaults: {
      minDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
      useRegionalBaseline: false
    },
    deleteFields: ['locationAffinityReportOptions']
  },
  dwellTimeReportOptions: {
    required: false,
    reportId: 'DWELL_TIME',
    defaults: {
      generateJsonFile: false,
      dwellMinTime: 60,
      visitSeparationTime: 86400,
      outerWindowMaxTime: 1800,
      employeeMinDwellTime: 14400,
      employeeMinDays: 6,
      dwellSecondsBefore: 1800,
      dwellSecondsAfter: 1800,
      deviceLevelDwell: false
    },
    deleteFields: ['dwellTimeReportOptions']
  },
  // Not really sure what this is for tbh, does not show up in any documentation
  // dataPrivacyComponent: {
  //   required: false,
  //   reportId: 'RESIDENT_WORKER_REPORT',
  //   defaults: {
  //     includeFullGeo: false
  //   },
  //   deleteFields: ['dataPrivacyComponent']
  // },
  geoSocialAffinityReportOptions: {
    required: false,
    reportId: 'GEOSOCIAL_AFFINITY_REPORT',
    defaults: {
      includeScatterPlot: false
    },
    deleteFields: ['geoSocialAffinityReportOptions']
  },
  locationReportOptions: {
    required: false,
    reportId: ['OGS', 'DWELL_TIME'],
    defaults: {
      densityMinDevicesHigh: 1,
      densityMinDevicesMedium: 1,
      densityMinSquareMilesMedium: 15,
      densityMode: true,
      maxRetained: 0.7,
      ogsReportInputTypes: ['P2P', 'CEL', 'CDL'],
      ogsSecondsAfter: 1800,
      ogsSecondsBefore: 1800,
      p2pEqualWeight: false,
    },
    deleteFields: ['locationReportOptions']
  },
  secondsBefore: {
    required: false,
    reportId: ['CLUSTER_REPORT', ...Object.keys(p2pReports[0].formats)],
    value: 1800,
    deleteFields: ['secondsBefore']
  }, 
  secondsAfter: {
    required: false,
    reportId: ['CLUSTER_REPORT', ...Object.keys(p2pReports[0].formats)],
    value: 1800,
    deleteFields: ['secondsAfter']
  }, 
  // ogsSecondsBefore: {
  //   required: false,
  //   reportId: [...'p2p'],
  //   value: 1800,
  //   deleteFields: ['ogsSecondsBefore']
  // }, 
  // ogsSecondsAfter: {
  //   required: false,
  //   reportId: [...'p2p'],
  //   value: 1800,
  //   deleteFields: ['ogsSecondsAfter']
  // }, 

};