<template>
  <div class="typeahead-container" v-if="items.length > 0">
    <b-card class="typeahead-card">
      <ul
        role="listbox"
        aria-label="Autosuggestions"
        class="selectedAutosuggest"
      >
        <template v-for="(suggestion, idx) of items.slice(0, 5)">
          <template v-if="typeof suggestion === 'string'">
            <li
              :aria-posinset="idx"
              :aria-controls="`recent_term_${idx}`"
              @click="$emit('searchRecentTerm', suggestion)"
              :key="`${suggestion}-${idx}`"
            >
              <div class="suggestion-container">
                <img class="icon" :src="require('pinnacle-lib/assets/icon/clock.svg')" />
                <p>{{ suggestion }}</p>
              </div>
            </li>
          </template>
          <template v-else-if="'place_id' in suggestion">
            <li
              :aria-posinset="idx"
              :aria-controls="`place_id_${idx}`"
              @click="$emit('searchSuggestion', suggestion)"
              :key="suggestion.place_id"
            >
              <div class="suggestion-container">
                <img class="icon" :src="require('pinnacle-lib/assets/icon/search-icon.svg')" />
                <p>{{ suggestion.description }}</p>
              </div>
            </li>
          </template>
          <template v-else-if="suggestion.type === 'category'">
            <li
              :aria-posinset="idx"
              :aria-controls="`category_${idx}`"
              @click="$emit('searchSuggestion', suggestion)"
              :key="idx"
            >
              <div class="suggestion-container">
                <img
                  class="icon"
                  :src="
                    suggestion.recent
                      ? require('pinnacle-lib/assets/icon/clock.svg')
                      : require('pinnacle-lib/assets/icon/search-icon.svg')
                  "
                />
                <p>{{ suggestion.value }}</p>
                <p class="description">
                  {{ suggestion.field | category_field_filter | capitalize }}
                </p>
              </div>
            </li>
          </template>
          <template v-else-if="suggestion.type === 'geography'">
            <li
              :aria-posinset="idx"
              :aria-controls="`geography_${idx}`"
              @click="$emit('searchSuggestion', suggestion)"
              :key="idx"
            >
              <div class="suggestion-container">
                <img
                  class="icon"
                  :src="
                    suggestion.recent
                      ? require('pinnacle-lib/assets/icon/clock.svg')
                      : require('pinnacle-lib/assets/icon/search-icon.svg')
                  "
                />
                <p>
                  {{ suggestion | geography_value_filter }}
                </p>
                <p class="description">
                  {{ suggestion.field.split("_")[0] | capitalize }}
                </p>
              </div>
            </li>
          </template>
          <template v-else-if="!('type' in suggestion)">
            <li
              :aria-posinset="idx"
              :aria-controls="`address_${idx}`"
              @click="$emit('viewDetail', suggestion, true)"
              :key="idx"
            >
              <div class="suggestion-container">
                <img
                  class="icon"
                  :src="
                    suggestion.recent
                      ? require('pinnacle-lib/assets/icon/clock.svg')
                      : require('pinnacle-lib/assets/icon/location-marker.svg')
                  "
                />
                <p>{{ suggestion.name }}</p>
                <p class="description">{{ suggestion.address }}</p>
              </div>
            </li>
          </template>
        </template>
      </ul>
      <div v-if="logo !== undefined" class="logo-container">
        <img class="logo" :src="logo" />
      </div>
    </b-card>
  </div>
</template>

<script>
import { bus } from "@/main";
import { syncState } from "@/utils/syncState.js";

export default {
  props: ["items", "logo"],
  watch: {
    items() {
      document.onkeyup = this.handleKeyUp;
    },
    focused() {
      bus.$emit("focusedChanged", this.focused);
    },
  },
  data() {
    return {
      focused: -1,
      selectedTerm: [],
      selectedGeo: [],
    };
  },
  mounted(){
    bus.$on("clearFocused", () => {
      this.focused = -1;
    })
  },
  beforeDestroy() {
    document.onkeyup = undefined;
    this.focused = -1;
  },
  computed: {
    ...syncState({
      selectedSuggestion: 'state.search.selectedSuggestion|setSelectedSuggestion'
    }, {context: this}),
  },
  methods: {
    getAddressString(item) {
      return `${item.address}, ${item.city}, ${item.province}, ${item.postal1}`;
    },
    handleKeyUp(e) {
      let selected = document.getElementsByClassName("selectedAutosuggest");
      if (selected[0]) {
        let selectedSuggest = selected[0].children[this.focused];
        let selectedSuggestDown = selected[0].children[this.focused - 1];
        let selectedSuggestUp = selected[0].children[this.focused + 1];

        switch (e.key) {
          case "ArrowDown":
            if (selectedSuggest) {
              selectedSuggest.classList.remove("focused");
            }
            if (this.focused >= 4) {
              this.focused = -1;
            } else {
              this.focused += 1;
              if (selectedSuggestUp) {
                selectedSuggestUp.classList.add("focused");
                this.selectedSuggestion = selectedSuggestUp.children[0].children[1].textContent;
              }
            }
            break;

          case "ArrowUp":
            if (selectedSuggest) selectedSuggest.classList.remove("focused");
            if (this.focused == -1) this.focused = 5;
            this.focused -= 1;
            if (selectedSuggestDown) {
              selectedSuggestDown.classList.add("focused");
              this.selectedSuggestion = selectedSuggestDown.children[0].children[1].textContent;
            }
            break;

          case "Escape":
            bus.$emit("closeSuggestions");
            this.selectedSuggestion = undefined;
            this.focused = -1;
            break;
          
          case "Enter":
            bus.$emit("closeSuggestions");
            this.selectedSuggestion = undefined;
            this.focused = -1;
            break;
        }
      }

      if (this.items.length > 0) this.selectedSuggestion = this.items[this.focused];

    },
  },
  filters: {
    geography_value_filter(geography) {
      if (
        !geography.field.includes("country") &&
        !geography.field.includes("province") &&
        !geography.field.includes("municipality")
      )
        return `${geography.value}, ${geography.province}`;
      else return geography.value;
    },
    category_field_filter(field) {
      if (field === "category") return "brand";
      if (field === "subsector") return "industry";
      return field;
    },
  },
};
</script>


<style lang="scss" scoped>
p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.typeahead-container {
  position: absolute;
  z-index: 2;
  width: 100%;

  .typeahead-card {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0;

    ::v-deep .card-body {
      padding: 0;
    }

    .logo-container {
      display: flex;
      justify-content: flex-end;
      padding: 0.75rem;

      .logo {
        width: 35%;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        padding: 0.75rem;
        cursor: pointer;

        .suggestion-container {
          display: flex;

          p {
            margin: 0;
          }

          .icon {
            margin-right: 14px;
            margin-left: 8px;
            width: 20px;
            height: 20px;
            align-self: center;
          }

          .description {
            color: rgba(110, 132, 163, 1);
            margin-left: 8px;
          }
        }
      }

      .focused {
        background-color: $border-color !important;
      }

      li:hover {
        background-color: $border-color;
      }
    }
  }
}
</style>