<template>
  <div class="insight-container">
    <!-- Breadcrumb -->
    <div class="d-flex insight-top">
      <div class="insight-top-left" @click="backToInsights">
        <div class="back-symbol">&#60;</div>
        <p class="insight-name">{{ title }}</p>
      </div>
    </div>

    <!-- Icon, Title, Checkbox -->
    <div class="header">
      <div class="icon-title">
        <LayerIcon :loading="Object.keys(loadingState).length > 0" class="icon" :icon="icon"></LayerIcon>
        <div class="title">
          {{ title }}
        </div>
      </div>
      <!-- <input
        class="checkbox"
        type="checkbox"
        v-model="status[insight]"
        @change="toggleSelectedLayer(status[insight], selection)"
      /> -->
      <b-form-checkbox
        class="checkbox"
        type="checkbox"
        v-model="status"
        @change="toggleSelectedLayer(status, selection)"
      />

    </div>

    <!-- Tooltip -->
    <div class="info">
      <p>{{ tooltip }}</p>
      <p v-if="tooltip2"><br>{{ tooltip2 }}</p>
    </div>

    <slot><!-- Content --></slot>
  </div>
</template>

<script>
import LayerIcon from "@/components/maps/LayerIcon";
// import CloseButton from "@/components/partials/CloseButton";
import { bus } from "@/main";

export default {
  components: {
    LayerIcon,
    // CloseButton,
  },
  props: [
    "title", // the display name of the insight
    "icon", // the larger image
    "tooltip", // tooltip text
    "tooltip2",
    "insight", // name for insight that allows dynamically assigning status to v-model
    "selection", // object for passing to toggleSelectedLayer
    "insightFamily", // tells status which studyMap prop to check ('pi' or 'ci')
    "toggleSelectedLayer", // method that toggles a layer on or off
  ],
  data() {
    return {};
  },
  methods: {
    backToInsights() {
      bus.$emit("backToInsights"); // navigates from specific insight page back to main insight page
    },
    closeInsights() {
      bus.$emit("closeInsights");
    }
  },
  computed: {
    loadingState() {
      return this.$sessionStore.state.studyMap.ui.loading[this.insight];
    },
    status: {
      get() {
        return this.$sessionStore.state.studyMap[this.insightFamily].status[this.insight];
      },
      set(value) {
        this.$sessionStore.commit(`set${this.insight.toUpperCase()}Status`, value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.minimizeBtnInsights {
  cursor: pointer;
  width: 20px;
  height: 20px;
  align-self: center;
}
.insights-tooltip {
  z-index: 12000;
  top: 0 !important;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #FFFFFF !important;
  border-color: #2C7BE5 !important;
  background-color: #2C7BE5 !important;
}


 ::v-deep .checkbox {
  .custom-control-label::before {
    background-color: white;
    border: 0.5px solid #B1C2D9;
    height: 1.25rem;
    width: 1.25rem;
  }
  
  .custom-control-label::after {
    height: 1.25rem;
    width: 1.25rem;
  }
}
.insight-container {
  width: 100%;
  height: 100%;
  // padding-right: 18px;
  .insight-top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    padding: 6px;
    width: 100%;
    height: 60px;

    .insight-top-left {
      display: flex;
      padding: 10px;
      color: #6e84a3;
      font-weight: bold;
      font-size: 14px;
      margin-left: 5px;

      .back-symbol {
        margin: auto 5px;
      }
      .insight-name {
        margin: auto 5px;
      }
    }

    .insight-top-left:hover {
      cursor: pointer;
    }

    .close-button {
      width: 18.9%;
      display: flex;
      justify-content: center;

    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid $border-color;
    margin-bottom: 0;
    height: 80px;
    .icon {
      display: flex;
      align-self: center;
    }

    .icon-title {
      display: flex;
      padding: 18px;

      .title {
        font-size: 16px;
        font-weight: bold;
        color: #12263f;
        margin-left: 16px;
      }
    }
    .checkbox {
      width: 22px;
      height: 22px;
      margin-right: 15px;
      border-radius: 4px;
      align-self: center;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    padding: 16px 10px;
    border-bottom: 1px solid $border-color;
    font-size: 14px;
    color: #858ea7;

    p {
      text-align: left;
      margin: auto 16px;
    }
  }
}
</style>