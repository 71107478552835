<template>
    <div class="paneContainer">
        <Key />
        <div class="scroll">
            <slot></slot>
        </div>
        <template v-if="studyLocations.length > 0 && showComparisonBanner">
            <ComparisonBanner></ComparisonBanner>
        </template>
    </div>
</template>
<script>
import ComparisonBanner from "@/components/search/ComparisonBanner.vue";
import Key from '@/components/partials/Key.vue';
export default {
    components: {
        ComparisonBanner,
        Key
    },
    computed: {
        studyLocations() {
            return this.$sessionStore.state.study.locations;
        },
        showComparisonBanner() {
            return this.$route.name === 'search' || this.$route.name === 'search-details' || this.$route.name === 'preview-charts' || this.$route.name === 'preview-map' || this.$route.name === 'preview-map-details';
        },
    }
}
</script>
<style lang="scss" scoped>
    .paneContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0px;
        position: relative;
        width: 560px;
        height: 100%;
        top: 0px;
        background: #F9FAFC;
        border-width: 0 1px 0 0;
        border-style: solid;
        border-color: #E3EBF6;
    }
    .scroll {
        overflow-y: auto;
        position: relative;
        width: 100%;
    }
</style>